import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { FormRef } from '../../../../models/form';
import { OnboardingStep, OnboardingStepType } from '../../../../pages/Onboarding/OnboardingStepProvider';
import { Button } from '../../Buttons/Button';
import { Spacer } from '../../Spacer';
import OnboardingInfoForm, { OnboardingInfoFormValue } from './OnboardingInfoForm';
import OnboardingPasswordForm, { OnboardingPasswordFormValue } from './OnboardingPasswordForm';

const FormWrapper = styled.div`
  width: 400px;
  min-height: 252px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

interface StepActions {
  submit: () => void;
}

export interface OnboardingStepFormProps {
  initialData: OnboardingInfoFormValue;
  activeStep: OnboardingStep;
  nextStep: () => void;
  previousStep: () => void;
  onComplete: (data: OnboardingFormValue) => void;
}

export interface OnboardingFormValue {
  info?: OnboardingInfoFormValue;
  password?: OnboardingPasswordFormValue;
}

export const OnboardingStepForm: React.FC<OnboardingStepFormProps> = ({
  activeStep,
  nextStep,
  previousStep,
  initialData,
  onComplete,
}) => {
  const infoFormRef = useRef<FormRef<OnboardingInfoFormValue>>(null);
  const passwordFormRef = useRef<FormRef<OnboardingPasswordFormValue>>(null);

  const [onboardingInfo, setOnboardingInfo] = useState<OnboardingFormValue>({
    info: {
      emailNotifications: initialData.emailNotifications,
      fullName: initialData.fullName,
      phoneNumber: initialData.phoneNumber,
      username: initialData.username,
    },
  });
  const [currentStepActions, setCurrentStepActions] = useState<StepActions>({ submit() {} });

  const handleNextStep = () => currentStepActions.submit();
  const isNextStepDisabled = () => activeStep.label === 'Finished';

  useEffect(() => {
    let sumbitFn: () => void = () => {};
    if (activeStep.label === OnboardingStepType.INFO)
      sumbitFn = () => {
        infoFormRef.current?.submit();
      };

    if (activeStep.label === OnboardingStepType.PASSWORD)
      sumbitFn = () => {
        passwordFormRef.current?.submit();
      };
    setCurrentStepActions({
      submit: sumbitFn,
    });
  }, [setCurrentStepActions, activeStep, infoFormRef]);

  return (
    <>
      <FormWrapper>
        {activeStep.label === OnboardingStepType.INFO && (
          <OnboardingInfoForm
            ref={infoFormRef}
            initialValues={onboardingInfo.info}
            onCompleted={(data) => {
              setOnboardingInfo({ ...onboardingInfo, info: data });
              nextStep();
            }}
          />
        )}

        {activeStep.label === OnboardingStepType.PASSWORD && (
          <OnboardingPasswordForm
            ref={passwordFormRef}
            initialValues={onboardingInfo.password}
            onCompleted={(data) => {
              const newInfo = { ...onboardingInfo, password: data };
              setOnboardingInfo(newInfo);
              nextStep();
              onComplete(newInfo);
            }}
          />
        )}
      </FormWrapper>
      <Spacer type="stack" size="xxl" />
      {activeStep.label !== OnboardingStepType.FINISHED && (
        <ButtonContainer>
          <Button
            type="button"
            btnType="secondary"
            onClick={previousStep}
            disabled={activeStep.label === OnboardingStepType.INFO}
          >
            BACK
          </Button>
          <Spacer type="inline" size="xs" />
          <Button type="button" btnType="primary" onClick={handleNextStep} disabled={isNextStepDisabled()}>
            CONFIRM
          </Button>
        </ButtonContainer>
      )}
    </>
  );
};
