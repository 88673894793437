import React from 'react';

import { useHistory } from 'react-router-dom';

import { displayGqlErrors } from '../../../components/ui/ErrorList';
import { GqlMutationError } from '../../../graphql/gql-error';
import { useBusinessCenterQuery } from '../../../graphql/query/businessCenter';
import { UserProfileResponse } from '../../../graphql/query/userProfile';
import { AccountsColumn, SocialMedia } from './AccountColumn';
import { OnboardingAccount } from './UserSettings';

interface ManageTikTokAccountProps {
  data: UserProfileResponse;
  refetch: () => void;
}
export const ManageTikTokAccount: React.FC<ManageTikTokAccountProps> = ({ data }) => {
  const history = useHistory();
  const [list, setList] = React.useState<SocialMedia[]>([]);
  const onConnectTiktok = async () => {
    history.push('/auth/tiktok/user');
  };

  const businessCenters = data.myInfo.isConnectedToTikTok.list;
  const { businesses, loading, error } = useBusinessCenterQuery();

  const connectBusiness = async () => {};

  React.useEffect(() => {
    if (error) {
      displayGqlErrors(error as unknown as GqlMutationError);
    }
  }, [error]);

  React.useEffect(() => {
    if (!loading) {
      const list = businesses.map<SocialMedia>((businessCenter) => {
        return {
          name: businessCenter.name,
          description: '',
          avatar: '',
          id: businessCenter.id,
          groupId: '',
        };
      });
      setList(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  return (
    <AccountsColumn
      loading={false}
      socialMediaList={list}
      onConnectAccount={onConnectTiktok}
      isConnected={data.myInfo.isConnectedToTikTok.isActive}
      account={OnboardingAccount.TIKTOK}
      data={data}
      connectedPrev={businessCenters}
      connectLocationPage={connectBusiness}
    />
  );
};
