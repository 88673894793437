import React from 'react';

import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { Control, Controller, FieldError, FieldErrors, FieldValues } from 'react-hook-form';

import HelperMessage from '../HelperMessage';
import { DateInput } from '../TextInput/TextInput.styled';

interface DateFieldProps<FormValue extends FieldValues> {
  name: keyof FormValue & string;
  defaultValue?: string | number;
  placeholder?: string;
  control: Control<Record<string, any>>;
  disabled?: boolean;
  label: string;
  errors: FieldErrors<FormValue>;
  size: SizeType;
}

function DateField<FormValue extends FieldValues = FieldValues>(
  props: DateFieldProps<FormValue>
): React.ReactElement<DateFieldProps<FormValue>> {
  const { name, defaultValue, control, size, label, placeholder, disabled, errors } = props;
  return (
    <Controller
      disabled={disabled}
      as={DateInput}
      name={name}
      topLabel={label}
      size={size}
      control={control}
      defaultValue={defaultValue}
      placeholder={placeholder}
      helperText={
        (errors?.[name] && <HelperMessage type="danger" message={(errors[name] as FieldError)?.message} />) || null
      }
    />
  );
}

DateField.defaultProps = {
  label: '',
  type: 'date',
};

export default DateField;
