import React from 'react';

import styled from 'styled-components';

import { AccountMenu } from '../../Menu/AccountMenu';
import { LabelBodyB } from '../../ui/Text/Text.styled';

const AccountsLayoutWrapper = styled.section`
  height: 100%;
  display: flex;
`;
const SideContent = styled.aside`
  height: 100%;
  width: 200px;
  border-right: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
`;
const LayoutContent = styled.section`
  height: 100%;
  flex-grow: 1;
`;

const SideContentTitle = styled.div`
  display: flex;
  align-items: start;
  margin: 16px 0 16px 16px;
`;

export const AccountsLayout: React.FC = ({ children }) => (
  <AccountsLayoutWrapper>
    <SideContent>
      <SideContentTitle>
        <LabelBodyB>Accounts</LabelBodyB>
      </SideContentTitle>
      <AccountMenu />
    </SideContent>
    <LayoutContent>{children}</LayoutContent>
  </AccountsLayoutWrapper>
);
