import React, { useEffect, useState } from 'react';

import { Alert, Select } from 'antd';

import { useQuery } from '@apollo/client';

import styled from 'styled-components';

import { ReviewLayout } from '../../components/Layout/ReviewLayout';
import { PostCard } from '../../components/ui/PostCard';
import { Spacer } from '../../components/ui/Spacer';
import {
  FACEBOOK_LOCATION_POSTS,
  PostsByFbPageIdParams,
  PostsByFbPageIdResponse,
} from '../../graphql/query/facebookLocationPosts';
import { useFbPagesByOrgIdQuery } from '../../graphql/query/fbPagesByOrgId';
import { useParams } from 'react-router-dom';
import { SelectOption } from '../../components/ui/FormSelectField/FormSelectField';

const PageContent = styled.div`
  padding: 0px 30px;
  align-items: flex-start;
`;
export const FacebookPost: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [pageId, setPageId] = useState<string>('');
  const [pageName, setPageName] = useState<string>('');
  const selectedAccountKey = `facebook-${organizationId}`;
  const selectedAccount = localStorage.getItem(selectedAccountKey);
  const { facebookPages } = useFbPagesByOrgIdQuery(organizationId!);
  const { data } = useQuery<PostsByFbPageIdResponse, PostsByFbPageIdParams>(FACEBOOK_LOCATION_POSTS, {
    variables: {
      pageId: pageId,
    },
  });

  useEffect(() => {
    if (facebookPages?.length > 0) {
      const firstFbPage = facebookPages[0];
      if (selectedAccount) {
        const selected = facebookPages.find((fbPage) => fbPage.pageId === selectedAccount);
        if (selected) {
          setPageId(selected.pageId);
          setPageName(selected.name);
        } else {
          setPageId(firstFbPage.pageId);
          setPageName(firstFbPage.name);
          localStorage.setItem(selectedAccountKey, firstFbPage.locationId!);
        }
      } else {
        setPageId(facebookPages[0].pageId);
        setPageName(facebookPages[0].name);
        localStorage.setItem(selectedAccountKey, firstFbPage.locationId!);
      }
    }
  }, [facebookPages, selectedAccount, selectedAccountKey]);

  const facebookPageOptions: SelectOption[] = facebookPages.map<SelectOption>((fbPage) => ({
    label: fbPage.name,
    value: fbPage.pageId,
  }));
  return (
    <ReviewLayout
      pageName={pageName}
      filterColumn={
        <>
          <Select
            options={facebookPageOptions}
            value={pageId}
            onChange={(val) => {
              setPageId(val.toString());
              setPageName(facebookPages.find((item) => item.pageId === val)?.name || '');
              localStorage.setItem(selectedAccountKey, val.toString());
            }}
          />
          <Spacer type="stack" size="s" />{' '}
        </>
      }
      reviewColumn={
        <PageContent>
          {data?.getfbPostByFbPageId.length ? (
            data?.getfbPostByFbPageId.map((post) => (
              <>
                <PostCard
                  posts={{
                    clicks: post.clicks,
                    views: post.views,
                    summary: post.summary,
                    urlLink: post.urlLink,
                    createTime: post.createTime,
                    updateTime: post.updateTime,
                    mediaImgUrl: post.img,
                    name: post.id,
                    title: '',
                  }}
                  deletePost={() => {}}
                />
                <Spacer type="stack" size="l" />
              </>
            ))
          ) : (
            <Alert type="info" message={`No list of items to show`} showIcon />
          )}
        </PageContent>
      }
    />
  );
};
