import { gql } from '@apollo/client';
import { Partner } from '../query/partners';

export interface NewPartnerRequest {
  name: string;
  fullName: string;
  phoneNumber: string;
  email: string;
  password: string;
}

export interface NewPartnerParams {
  data: NewPartnerRequest;
}

export interface NewPartnerResponse {
  createPartner: Partner;
}

export const NEW_PARTNER = gql`
  mutation ($data: NewPartnerRequest!) {
    createPartner(data: $data) {
      id
      name
    }
  }
`;
