import React from 'react';

import { Table, Tooltip } from 'antd';
import moment from 'moment';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import styled from 'styled-components';

import { AccountIcon } from '../../../components/Menu/AccountMenu/AccountMenuItem/AccountMenuItem.styled';
import ItemInfoCard from '../../../components/ui/ItemInfoCard/ItemInfoCard';
import { SearchInput } from '../../../components/ui/TextInput/SearchInput.styled';
import { PostMetric, PostStatus } from '../../../graphql/query/inventoryByLocationId';
import { PostTypeEnum, useGetPostByLocationId } from '../../../graphql/query/postHistoryByLocationId';

const { Column } = Table;

const PageContent = styled.div`
  padding: 10px 10px;
  width: 100%;
  align-items: flex-start;
`;

const PageHeader = styled.div`
  width: 100%;
  height: 56px;
  padding: 12px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  display: flex;
  align-items: center;

  justify-content: space-between;
`;
const ContentAlert = styled.div`
  align-items: center;
  display: flex;
`;

interface PostHistoryProps {
  locationId: string;
  typePost: PostTypeEnum;
}
const PostHistoryTable: React.FC<PostHistoryProps> = ({ locationId, typePost }) => {
  const { posts, refetch } = useGetPostByLocationId({
    locationId: locationId,
    type: typePost,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (posts) {
      refetch({
        locationId: locationId,
        type: typePost,
        search: value,
      });
    }
  };
  const postHistory = posts || [];
  const title = typePost === PostTypeEnum.OFFER ? 'Offer Title' : 'Vehicle';
  const label = typePost === PostTypeEnum.OFFER ? 'Search by Offer title' : `Search by vehicle's name`;
  return (
    <>
      <PageHeader>
        <SearchInput placeholder={label} onChange={handleChange} removeBorder />
      </PageHeader>
      <PageContent>
        <Table className="wide-table" dataSource={postHistory} rowKey="id">
          <Column<PostMetric>
            title={title}
            width="240px"
            dataIndex="title"
            key="title"
            render={(_title, row) => <ItemInfoCard item={row.title} avatar={row.imageUrl} imgError={''} />}
          />

          {typePost === PostTypeEnum.VEHICLE ? (
            <Column<PostMetric>
              title="Posted On"
              width="160px"
              dataIndex="source"
              key="source"
              render={(source) => (
                <div>
                  <AccountIcon className={`ic ic_${source}`} />
                </div>
              )}
            />
          ) : null}

          <Column<PostMetric>
            title="Posted Date"
            width="160px"
            dataIndex="date"
            key="date"
            render={(date) => <div>{moment(date).format('DD MMMM')}</div>}
          />

          <Column<PostMetric>
            title=""
            width="40px"
            dataIndex="row"
            key="row"
            render={(_field, row) => {
              return row.status === PostStatus.ERROR ? (
                <ContentAlert>
                  <Tooltip placement="topLeft" title={row.statusMessage} arrowPointAtCenter>
                    <ExclamationCircleOutlined
                      style={{ fontSize: '24px', left: 10, position: 'absolute', color: 'red' }}
                    />
                  </Tooltip>
                </ContentAlert>
              ) : null;
            }}
          />
        </Table>
      </PageContent>
    </>
  );
};

export default PostHistoryTable;
