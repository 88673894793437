import axios from 'axios';

export const saveMonthlySurvey = async (token: string, surveyDate: string, numberSold: number) => {
  await axios.post(
    '/api/marketplace-listing/saveMonthlySurvey',
    { numberSold, surveyDate },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};
