import { gql } from '@apollo/client';

export interface Posts {
  id: string;
  summary: string;
  img: string;
  urlLink: string;
  views: string;
  clicks: string;
  createTime: Date;
  updateTime: Date;
  success: boolean;
}

export interface PostsByFbPageIdResponse {
  getfbPostByFbPageId: Posts[];
}

export interface PostsByFbPageIdParams {
  pageId: string;
}

export const FACEBOOK_LOCATION_POSTS = gql`
  query getFacebookPostByFbPageId($pageId: String!) {
    getfbPostByFbPageId(pageId: $pageId) {
      createTime
      updateTime
      id
      urlLink
      img
      summary
      views
      clicks
    }
  }
`;
