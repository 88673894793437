import { gql, useQuery } from '@apollo/client';

import { FacebookPage, FbPageFragment } from '../fragments/fbPageFragments';

export interface FbPageByOrgIdResponse {
  fbPagesByOrgId: FacebookPage[];
}

export interface FbPageByOrgIdParams {
  organizationId: string;
}

export const FB_PAGES_BY_ORG_ID = gql`
  query fbPagesByOrgId($organizationId: String!) {
    fbPagesByOrgId(organizationId: $organizationId) {
      ...FbPage
    }
  }
  ${FbPageFragment}
`;

export function useFbPagesByOrgIdQuery(organizationId: string) {
  const { data, loading, error } = useQuery<FbPageByOrgIdResponse, FbPageByOrgIdParams>(FB_PAGES_BY_ORG_ID, {
    variables: {
      organizationId,
    },
    fetchPolicy: 'cache-and-network',
  });
  return {
    facebookPages: data?.fbPagesByOrgId || [],
    loading,
    error,
  };
}
