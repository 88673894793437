import { gql } from '@apollo/client';

export interface DeleteGooglePostRequest {
  nameOfPost: string;
  googleLocationId: string;
}

export const DELETE_GOOGLE_POST = gql`
  mutation ($nameOfPost: String!, $googleLocationId: String!) {
    deleteGooglePostByGoogleLocation(nameOfGoogle: $nameOfPost, googleLocationId: $googleLocationId)
  }
`;
