import React, { useImperativeHandle } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormComponentProps, FormRef } from '../../../../models/form';
import { Spacer } from '../../Spacer';
import TextField from '../../TextField';

const ValidationSchema = Yup.object().shape({
  fullName: Yup.string().default('').required(),
  email: Yup.string().default('').required(),
  phoneNumber: Yup.string().default('').required(),
  receiveEmailNotifications: Yup.string().default('').required(),
});

export interface EditUserAccountFormValue {
  fullName: string;
  email: string;
  phoneNumber: string;
  receiveEmailNotifications: string;
}

export interface EditUserAccountFormProps extends FormComponentProps<EditUserAccountFormValue> {}

const EditUserAccountForm = React.forwardRef<FormRef<EditUserAccountFormValue>, EditUserAccountFormProps>(
  (props: EditUserAccountFormProps, ref) => {
    const { handleSubmit, control, errors, formState } = useForm<EditUserAccountFormValue>({
      resolver: yupResolver(ValidationSchema),
      mode: 'onTouched',
      defaultValues: {
        fullName: props.initialValues?.fullName,
        email: props.initialValues?.email,
        phoneNumber: props.initialValues?.phoneNumber,
        receiveEmailNotifications: props.initialValues?.receiveEmailNotifications,
      },
    });

    const onSubmit = (data: EditUserAccountFormValue) => {
      props.onCompleted(data);
    };
    const submitHandler = handleSubmit(onSubmit);
    useImperativeHandle(
      ref,
      () => ({
        formState,
        submit() {
          return submitHandler();
        },
      }),
      [formState, submitHandler]
    );
    return (
      <form onSubmit={submitHandler}>
        <TextField
          type="text"
          name="fullName"
          label="FULL NAME"
          placeholder="John Doe"
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="m" />
        <TextField
          type="email"
          name="email"
          label="EMAIL"
          placeholder="johndoe@mail.com"
          control={control}
          errors={errors}
          disabled={true}
        />
        <Spacer type="stack" size="m" />
        <TextField
          type="text"
          name="phoneNumber"
          label="PHONE"
          placeholder="(555) 555-1234"
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="m" />
      </form>
    );
  }
);

export default EditUserAccountForm;
