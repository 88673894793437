import React, { useImperativeHandle, useState } from 'react';

import { Key } from 'ts-key-enum';

import styled from 'styled-components';

import { FormComponentProps, FormRef } from '../../../../models/form';
import { PasswordInput } from '../../TextInput/TextInput.styled';

const FromStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;

export interface OnboardingPasswordFormValue {
  password: string;
}

export interface OnboardingPasswordFormProps extends FormComponentProps<OnboardingPasswordFormValue> {}

const OnboardingPasswordForm = React.forwardRef<FormRef<OnboardingPasswordFormValue>, OnboardingPasswordFormProps>(
  (props: OnboardingPasswordFormProps, ref) => {
    const { onCompleted } = props;
    const [password, setPassword] = useState(props.initialValues?.password || '');

    useImperativeHandle(
      ref,
      () => ({
        submit() {
          onCompleted({ password });
        },
      }),
      [onCompleted, password]
    );
    return (
      <FromStyled>
        <PasswordInput
          onKeyPress={(e) => e.key === Key.Enter && e.preventDefault()}
          type="password"
          name="password"
          topLabel="PASSWORD"
          defaultValue={password}
          onChange={(evt) => {
            const { value } = evt.target;
            setPassword(value);
          }}
        />
      </FromStyled>
    );
  }
);

export default OnboardingPasswordForm;
