import { debounce } from 'lodash';

export interface KeywordsTypes {
  keyword: string;
  isFound?: boolean;
}

export const cleanNames = (paragraph: string) =>
  paragraph
    ? paragraph
        .split(' ')
        .map((x) => x.replace(/[^#\w\s]|_/g, ''))
        .filter((x) => x)
    : [''];

export const searchForKeywords = debounce(
  (
    text: string,
    keywordsArray: KeywordsTypes[],
    setNewKeywordsState: (newKeywordState: KeywordsTypes[]) => void,
    includeHashtag?: boolean
  ) => {
    const newKeywordsState = keywordsArray.map((keywordObj) => {
      const { keyword } = keywordObj;
      const findMatch = text
        .split('\n')
        .join(' ')
        .split(' ')
        .map((x) => x.replace(/[^#\w\s]|_/g, '').toLowerCase())
        .filter((x) => x)
        .includes(`${includeHashtag ? '#' : ''}${keyword.toLowerCase()}`);
      if (findMatch) {
        keywordObj.isFound = true;
        return keywordObj;
      } else if (keywordObj.isFound) {
        keywordObj.isFound = false;
        return keywordObj;
      }
      return keywordObj;
    });
    setNewKeywordsState(newKeywordsState);
  },
  500
);
