import { gql } from '@apollo/client';

export interface EditUserAccountRequest {
  fullName: string;
  email: string;
  receiveEmailNotifications: boolean;
}

export const EDIT_USER_ACCOUNT = gql`
  mutation ($data: EditUserAccountRequest!) {
    updateMyInfo(data: $data) {
      id
      receiveEmailNotifications
      fullName
      phoneNumber
    }
  }
`;
