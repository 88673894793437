import { gql, useQuery } from '@apollo/client';

import { DashboardBaseInput } from './getTopPosts';
import { PostMetric } from './inventoryByLocationId';

interface SoldCarsRequest extends DashboardBaseInput {}
interface GetTopPostResponse {
  getSoldCars: PostMetric[];
}
interface GetSoldCarsParams {
  soldCarsRequest: SoldCarsRequest;
}

const GET_SOLD_CARS = gql`
  query getSoldCars($soldCarsRequest: SoldCarsRequest!) {
    getSoldCars(input: $soldCarsRequest) {
      postId
      inventoryId
      source
      status
      statusMessage
      date
      clicks
      views
      imageUrl
      title
      soldAt
      owner
    }
  }
`;

export function useGetSoldCars(params: GetSoldCarsParams) {
  const { data, error, loading } = useQuery<GetTopPostResponse, GetSoldCarsParams>(GET_SOLD_CARS, {
    variables: params,
    fetchPolicy: 'cache-and-network',
  });
  return {
    soldCars: data?.getSoldCars || [],
    error,
    loading,
  };
}
