import React from 'react';
import { match } from 'ts-pattern';
import styled from 'styled-components';
import { AccountIcon } from '../../../../components/Menu/AccountMenu/AccountMenuItem/AccountMenuItem.styled';
import { Button } from '../../../../components/ui/Buttons/Button';
import { Spacer } from '../../../../components/ui/Spacer';
import { LabelBodyB, LabelBodyR } from '../../../../components/ui/Text/Text.styled';
import { UserProfileResponse } from '../../../../graphql/query/userProfile';
import { OnboardingAccount } from '../UserSettings';
import { AccountInfo, Section, Title } from './AccountInfo';
import { ConnectPageLocationParam, LocationInfo } from './LocationInfo';
import { LoadingOutlined } from '@ant-design/icons';

const AccountColumnWrapper = styled.div`
  display: flex;
  width: 530px;
  padding: 40px;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
`;
export interface SocialMedia {
  name: string;
  description: string;
  avatar: string;
  id: string;
  groupId: string;
}

interface AccountsColumnsProps {
  account: OnboardingAccount;
  data: UserProfileResponse;
  isConnected: boolean;
  socialMediaList: SocialMedia[];
  loading: boolean;
  connectedPrev: string[];
  onConnectAccount: () => void;
  connectLocationPage: (connectLocationPage: ConnectPageLocationParam) => void;
}

type AccountTextType = 'name' | 'accounts' | 'icon' | 'shortName';
export const AccountTextLabels: Record<OnboardingAccount, Record<AccountTextType, string>> = {
  facebook: {
    name: 'Facebook',
    shortName: 'Facebook',
    accounts: 'Facebook Pages', // type of accounts that you can connect
    icon: 'ic_facebook',
  },
  google: {
    name: 'Google',
    shortName: 'Google',
    accounts: 'Google Locations',
    icon: 'ic_google',
  },
  tikTok: {
    name: 'TikTok Business Center',
    shortName: 'TikTok Center',
    accounts: 'TikTok Business Center',
    icon: 'ic_tiktok',
  },
  tikTokCreator: {
    name: 'TikTok Creator',
    shortName: 'TikTok Creator',
    accounts: 'TikTok Creator',
    icon: 'ic_tiktok',
  },
};

export const Header: React.FC<{ account: OnboardingAccount }> = ({ account }) => {
  return (
    <Section>
      <AccountIcon className={`ic ${AccountTextLabels[account].icon}`} />
      <Spacer type="inline" size="xs" />
      <Title> {AccountTextLabels[account].name} Settings</Title>
    </Section>
  );
};

export const AccountsColumn: React.FC<AccountsColumnsProps> = ({
  account,
  data,
  isConnected,
  socialMediaList,
  loading,
  connectedPrev,
  onConnectAccount,
  connectLocationPage,
}) => {
  const userInfo = match(account)
    .with(OnboardingAccount.FACEBOOK, () => data.myInfo.isConnectedToFacebook)
    .with(OnboardingAccount.GOOGLE, () => data.myInfo.isConnectedToGoogle)
    .with(OnboardingAccount.TIKTOK, () => data.myInfo.isConnectedToTikTok)
    .with(OnboardingAccount.TIKTOK_CREATOR, () => data.myInfo.isConnectedToTikTokCreator)
    .exhaustive();

  return (
    <AccountColumnWrapper>
      <Header account={account} />
      <Spacer type="stack" size="xs" />
      <LabelBodyR style={{ textAlign: 'initial' }}>
        First connect your {AccountTextLabels[account].name} Account to allow Local Shift access.
      </LabelBodyR>
      <Spacer type="stack" size="xs" />
      {isConnected && !(account === OnboardingAccount.TIKTOK_CREATOR) ? (
        <AccountInfo
          isConnected={isConnected}
          avatar={userInfo.picture || data.myInfo?.avatar?.signedUrl}
          fullName={userInfo.name || data.myInfo?.fullName}
          description={userInfo.info || data.myInfo.username}
          source={account}
        />
      ) : (
        <ButtonWrapper>
          <Button btnType="primary" size="small" onClick={onConnectAccount}>
            CONNECT {AccountTextLabels[account].name.toLocaleUpperCase()} ACCOUNT
          </Button>
        </ButtonWrapper>
      )}
      <Spacer type="stack" size="xs" />
      <LabelBodyB>{`Manage ${AccountTextLabels[account].accounts}`}</LabelBodyB>
      <Spacer type="stack" size="xs" />
      <LabelBodyR>Then connect the {AccountTextLabels[account].accounts}.</LabelBodyR>
      <Spacer type="stack" size="xs" />
      {loading ? (
        <LoadingOutlined />
      ) : (
        socialMediaList.map((socialMedia) => (
          <LocationInfo
            isConnected={connectedPrev.includes(socialMedia.id)}
            avatar={socialMedia.avatar}
            fullName={socialMedia.name}
            id={socialMedia.id}
            account={socialMedia.groupId}
            description={socialMedia.description}
            source={account}
            connect={connectLocationPage}
          />
        ))
      )}
    </AccountColumnWrapper>
  );
};
