import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  RawDraftContentBlock,
  RawDraftContentState,
  RawDraftEntityRange,
} from 'draft-js';
import { MentionData } from 'draft-js-mention-plugin';

/**
 * from this github issue https://github.com/draft-js-plugins/draft-js-plugins/issues/983
 * functions based on this gist https://gist.github.com/nicubarbaros/8a35a16dd45065891803bbfa3e451d15
 */

const getIndicesOf = (searchStr: string, str: string, caseSensitive = false) => {
  let tempStr = str;
  let tempSearchStr = searchStr;
  const searchStrLen = tempSearchStr.length;
  if (searchStrLen === 0) {
    return [];
  }
  let startIndex = 0;
  let index;
  const indices = [];
  if (!caseSensitive) {
    tempStr = tempStr.toLowerCase();
    tempSearchStr = tempSearchStr.toLowerCase();
  }

  while ((index = tempStr.indexOf(tempSearchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }
  return indices;
};

const getEntityRanges = (text: string, mentionName: string, mentionKey: number) => {
  const indices = getIndicesOf(mentionName, text);
  if (indices.length > 0) {
    return indices.map(
      (offset): RawDraftEntityRange => ({
        key: mentionKey,
        length: mentionName.length,
        offset,
      })
    );
  }
  return null;
};

export const createMentionEntitiesFromRaw = (rawContent: RawDraftContentState, tags: MentionData[]) => {
  const rawState = tags.map((tag) => ({
    type: 'mention',
    mutability: 'SEGMENTED',
    data: {
      mention: tag,
    },
  }));

  rawContent.entityMap = rawState.reduce((acc, entry, index) => {
    acc[index] = entry;
    return acc;
  }, {} as Record<string, any>);

  rawContent.blocks = rawContent.blocks.map((block): RawDraftContentBlock => {
    const ranges: RawDraftEntityRange[] = [];
    tags.forEach((tag, index) => {
      const entityRanges = getEntityRanges(block.text, tag.name, index);
      if (entityRanges) {
        ranges.push(...entityRanges);
      }
    });

    return { ...block, entityRanges: ranges };
  });

  return convertFromRaw(rawContent);
};

export const createMentionStateFromText = (text: string, tags: MentionData[]) => {
  const state = ContentState.createFromText(text);
  return createMentionEntitiesFromRaw(convertToRaw(state), tags);
};
