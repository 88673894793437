import React, { useContext } from 'react';
import { Checkbox, Modal, Table, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import formatDistance from 'date-fns/formatDistance';
import { ExclamationCircleOutlined, MailOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import AccountInfoCard from '../../components/ui/AccountInfoCard/AccountInfoCard';
import { displayGqlErrors } from '../../components/ui/ErrorList';
import IconButtonSimple from '../../components/ui/IconButtonSimple';
import { HasPermission } from '../../components/ui/Security';
import { snackbar } from '../../components/ui/Snackbar';
import { Spacer } from '../../components/ui/Spacer';
import { TagLabel } from '../../components/ui/TagLabel';
import { SearchInput } from '../../components/ui/TextInput/SearchInput.styled';
import { SideDrawerContext } from '../../context/SideDrawerProvider';
import { RESEND_INVITATION_TO_USER, ResendInvitationRequest } from '../../graphql/mutations/resendInvitationToUser';
import {
  TOGGLE_USER_STATUS,
  ToggleUserStatusRequest,
  ToggleUserStatusResponse,
} from '../../graphql/mutations/toggleUserStatus';
import { UsersResponseItem } from '../../graphql/query/usersByOrganizationId';
import { useRowSelection } from '../../hooks/useRowSelection';
import { RoleEnum } from '../../models/session';
import { createSortProps } from '../../utils/table.utils';
import { useParams } from 'react-router-dom';
import { NameId } from '../../components/utils/types';
import {
  SEND_MARKETPLACE_ONBOARDING_EMAIL,
  SendMarketplaceOnboardingEmailRequest,
} from '../../graphql/mutations/sendMarketplaceOnboardingEmail';
import { AccountIcon } from '../../components/Menu/AccountMenu/AccountMenuItem/AccountMenuItem.styled';
import { Button } from '../../components/ui/Buttons/Button';
import moment from 'moment';

const UserListWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const HeaderContainer = styled.header`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const ButtonContainer = styled.div`
  margin: 12px 16px;
  display: flex;
  flex-direction: row;
`;

const PageHeader = styled.div`
  width: 100%;
  height: 56px;
  padding: 12px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ActionHeader = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 20px 20px;
`;
const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
`;
const PageContent = styled.div``;

const { Column } = Table;
const { confirm } = Modal;
export const UserList: React.FC<{ users: any; refetch: any }> = ({ users, refetch }) => {
  const { organizationId, partnerId } = useParams<{ partnerId: string; organizationId: string }>();
  const isPartner = Boolean(partnerId);
  const sideDrawerContext = useContext(SideDrawerContext);
  const [resendInvitation] = useMutation<boolean, ResendInvitationRequest>(RESEND_INVITATION_TO_USER);
  const [changeToActiveOrArchived] = useMutation<ToggleUserStatusResponse, ToggleUserStatusRequest>(TOGGLE_USER_STATUS);
  const [sendMarketplaceOnboardingEmail] = useMutation<boolean, SendMarketplaceOnboardingEmailRequest>(
    SEND_MARKETPLACE_ONBOARDING_EMAIL
  );

  const newUserHandler = () => sideDrawerContext.toggleSideDrawer(sideDrawerContext.sideDrawerNames.upsertUser);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (users) {
      refetch({ organizationId, username: value });
    }
  };

  const handleCheckbox = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    if (checked) refetch({ organizationId: organizationId, onlyActive: undefined });
    else refetch({ organizationId: organizationId, onlyActive: true });
  };

  const toggleStatus = async (userId: string, isActive: boolean) => {
    const message = isActive ? 'Archived' : 'Unarchived';
    try {
      const success = await changeToActiveOrArchived({
        variables: { userId: userId },
      });
      if (success) {
        snackbar.success({
          message: `User ${message}`,
        });
      } else {
        snackbar.error({
          message: `User not ${message}`,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      displayGqlErrors(error, `Unable to ${message}`);
    }
  };

  const handleArchiveUser = async (userId: string, isActive: boolean) => {
    const actionName = isActive ? 'archive' : 'unarchive';
    const title = isActive ? 'Archive' : 'Unarchive';
    const consequence = isActive ? 'remove' : 'restore';
    const content = (
      <>
        <p>
          Are you sure you want to {actionName} this user and {consequence} their connection to automated posts?
        </p>
      </>
    );
    confirm({
      title: `${title} user`,
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: `Yes, ${actionName}`,
      content,
      onOk() {
        toggleStatus(userId, isActive);
      },
    });
  };

  const sendInvitation = async (userId: string) => {
    try {
      const success = await resendInvitation({
        variables: { userId: userId },
      });
      if (success) {
        snackbar.success({
          message: 'Invitation email sent',
        });
      } else {
        snackbar.error({
          message: 'Invitation not sent  ',
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      displayGqlErrors(error, 'Unable to send email');
    }
  };

  const onConfirmMarketplaceOnboardingEmail = async (userId: string) => {
    try {
      const success = await sendMarketplaceOnboardingEmail({
        variables: { userId },
      });
      if (success) {
        snackbar.success({
          message: `Onboarding email sent`,
        });
      } else {
        snackbar.error({
          message: `Onboarding email not sent`,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      displayGqlErrors(error, `Unable to archive location`);
    }
  };

  const confirmOnboardingEmail = async (userId: string) => {
    confirm({
      title: `Send Marketplace Onboarding Email`,
      icon: <MailOutlined />,
      okType: 'primary',
      okText: `Yes, send email`,
      content: `Would you like to send a marketplace onboarding email to this user?`,
      onOk() {
        onConfirmMarketplaceOnboardingEmail(userId);
      },
    });
  };

  const { rowSelection, selectedRowKeys } = useRowSelection<UsersResponseItem>();
  return (
    <UserListWrapper>
      <HeaderContainer>
        <div></div>
        <ButtonContainer>
          <HasPermission allowedRoles={[RoleEnum.SuperAdmin, RoleEnum.Partner, RoleEnum.Admin]}>
            <Button btnType="secondary" size="small" type="button" onClick={newUserHandler}>
              ADD USER
            </Button>
          </HasPermission>
        </ButtonContainer>
      </HeaderContainer>
      <PageHeader>
        <SearchInput placeholder="Search users" onChange={handleChange} removeBorder />
      </PageHeader>
      <ActionHeader>
        <Checkbox onChange={handleCheckbox}>Show Archived Users</Checkbox>
      </ActionHeader>
      <PageContent>
        <Table className="wide-table" dataSource={users} rowSelection={rowSelection} rowKey="id" pagination={false}>
          <Column<UsersResponseItem>
            title="User"
            width="240px"
            dataIndex="id"
            key="id"
            render={(name, record) => (
              <AccountInfoCard
                accountName={record?.fullName || ''}
                detail={record.username || ''}
                avatar={record?.avatar?.signedUrl || ''}
                active={selectedRowKeys.includes(name)}
              />
            )}
            {...createSortProps<UsersResponseItem>({
              type: 'string',
              field: 'fullName',
              multiple: 1,
            })}
          />
          <Column<UsersResponseItem>
            title="Position"
            width="160px"
            dataIndex="title"
            key="title"
            render={(title) => <>{title}</>}
          />
          <Column<UsersResponseItem> title="Role" width="160px" dataIndex="role" key="role" render={(role) => role} />
          <Column<UsersResponseItem>
            title="Locations"
            width="160px"
            dataIndex="locations"
            key="locations"
            render={(locations) => (
              <>
                {locations?.map((location: NameId) => (
                  <TagLabel color="default">{location.name}</TagLabel>
                ))}
              </>
            )}
          />
          <Column<UsersResponseItem>
            title="Last login"
            dataIndex="lastActiveAt"
            key="lastActiveAt"
            render={(lastActiveAt) =>
              lastActiveAt ? formatDistance(new Date(lastActiveAt), new Date(), { addSuffix: true }) : 'Never'
            }
          />
          <Column<UsersResponseItem>
            title="Status"
            dataIndex="isActive"
            key="isActive"
            render={(isActive, record) => {
              const archivedDateLabel = record.archivedAt ? ` - ${moment(record.archivedAt).format('MM/DD/YYYY')}` : '';
              const label = record.isActive ? 'active' : `archived${archivedDateLabel}`;
              return <TagLabel color={isActive ? 'greenLight' : 'red'}>{label}</TagLabel>;
            }}
          />

          <Column<UsersResponseItem>
            title="POC"
            dataIndex="isPoc"
            key="isPoc"
            render={(isPoc) => {
              if (isPoc) {
                return <TagLabel color={'greenLight'}>POC</TagLabel>;
              }
            }}
          />

          <Column<UsersResponseItem>
            title="Actions"
            dataIndex="mailConfirmed"
            key="mailConfirmed"
            render={(_mailConfirmed, record) => (
              <>
                <HasPermission allowedRoles={[RoleEnum.Admin, RoleEnum.SuperAdmin, RoleEnum.Partner]}>
                  <ActionsContainer>
                    <Tooltip placement="top" title="Send Dashboard Invite">
                      <IconButtonSimple
                        icon="email"
                        size="small"
                        color="default"
                        onClick={() => sendInvitation(record?.id)}
                      ></IconButtonSimple>
                    </Tooltip>
                    <Spacer size="xxs" type="inline" />
                    {!isPartner && record.marketplaceLicense && (
                      <>
                        <Tooltip placement="top" title="Send Marketplace Onboarding Email">
                          <AccountIcon
                            className="ic ic_fb_marketplace_filled"
                            onClick={() => {
                              confirmOnboardingEmail(record.id);
                            }}
                            style={{
                              cursor: 'pointer',
                            }}
                          />
                        </Tooltip>
                        <Spacer size="xxs" type="inline" />
                      </>
                    )}
                    <Tooltip placement="top" title={`Edit User`}>
                      <IconButtonSimple
                        icon="edit"
                        size="small"
                        onClick={() =>
                          sideDrawerContext.toggleSideDrawer(sideDrawerContext.sideDrawerNames.upsertUser, record)
                        }
                      />
                    </Tooltip>
                    <Spacer size="xxs" type="inline" />
                    <Tooltip placement="top" title={`${record.isActive ? 'Archive' : 'Unarchive'} User`}>
                      <IconButtonSimple
                        icon={record.isActive ? 'delete' : 'restore'}
                        size="small"
                        onClick={() => handleArchiveUser(record?.id, record?.isActive)}
                      ></IconButtonSimple>
                    </Tooltip>
                  </ActionsContainer>
                </HasPermission>
              </>
            )}
          />
        </Table>
      </PageContent>
    </UserListWrapper>
  );
};
