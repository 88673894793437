import React, { useRef } from 'react';

import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import styled from 'styled-components';

import { EDIT_USER_ACCOUNT, EditUserAccountRequest } from '../../../../graphql/mutations/editUserAccount';
import { UserProfileResponse } from '../../../../graphql/query/userProfile';
import { FormRef } from '../../../../models/form';
import { displayGqlErrors } from '../../ErrorList';
import SideDrawer from '../../SideDrawer';
import { snackbar } from '../../Snackbar';
import { LabelBodyB } from '../../Text/Text.styled';
import EditUserAccountForm, { EditUserAccountFormValue } from './EditUserAccount';

interface EditUserAccountSideDrawerProps {
  isSideDrawerVisible: boolean;
  toggleSideDrawer: (value?: boolean) => void;
  updateObject: UserProfileResponse;
}

const FormContainer = styled.section`
  margin: 24px 16px 16px 16px;
  width: 100%;
`;

const SectionTitleContainer = styled.div`
  margin-bottom: 32px;
`;

const EditUserAccountDrawer: React.FC<EditUserAccountSideDrawerProps> = ({
  isSideDrawerVisible,
  toggleSideDrawer,
  updateObject,
}) => {
  const formRef = useRef<FormRef<EditUserAccountFormValue>>(null);
  const initialValues: EditUserAccountFormValue = {
    email: updateObject?.myInfo.username || '',
    fullName: updateObject?.myInfo?.fullName || '',
    phoneNumber: updateObject?.myInfo?.phoneNumber || '',
    receiveEmailNotifications: updateObject?.myInfo.receiveEmailNotifications === true ? 'Active' : 'Inactive',
  };

  const [editUserAccount] = useMutation(EDIT_USER_ACCOUNT);
  const handleFormCompletedEditUserAccount = async (formData: EditUserAccountFormValue) => {
    try {
      const receiveEmailNotificationsState: boolean = formData.receiveEmailNotifications === 'Active' ? true : false;
      const userAccountData: EditUserAccountRequest = {
        ...formData,
        receiveEmailNotifications: receiveEmailNotificationsState,
      };
      await editUserAccount({
        variables: { data: userAccountData },
      });
      snackbar.success({
        message: 'User data updated Successfully',
      });
      toggleSideDrawer();
    } catch (error) {
      Sentry.captureException(error);
      displayGqlErrors(error, 'Unable to update user data');
    }
  };

  return (
    <SideDrawer
      heading="Edit account details"
      isDrawerVisible={isSideDrawerVisible}
      toggleDrawerVisible={toggleSideDrawer}
      ctaButtons={[
        {
          label: 'SAVE',
          onClick: async () => {
            formRef.current?.submit();
          },
          isDisabled: false,
        },
      ]}
    >
      <FormContainer>
        <SectionTitleContainer>
          <LabelBodyB>User details</LabelBodyB>
        </SectionTitleContainer>
        <EditUserAccountForm
          ref={formRef}
          onCompleted={handleFormCompletedEditUserAccount}
          initialValues={initialValues}
        />
      </FormContainer>
    </SideDrawer>
  );
};

export default EditUserAccountDrawer;
