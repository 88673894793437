import React, { useEffect } from 'react';

import { message } from 'antd';

import { useHistory, useLocation } from 'react-router-dom';

import { loginRequest } from '../../api/auth.api';
import { displayAxiosErrors } from '../../components/ui/ErrorList';
import { getTokenInfo } from '../../context/appReducer';
import { useSession } from '../../context/AppStateProvider';

export const LoginByCode: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const code = search.replace('?code=', '');
  const { loadSession } = useSession();

  useEffect(() => {
    const login = async () => {
      try {
        const res = await loginRequest({
          username: 'code',
          password: code || '',
        });
        const token = res.access_token;
        localStorage.setItem('rpro.token', token);
        const tokenInfo = getTokenInfo(token);
        loadSession({
          ...tokenInfo,
          accessToken: token,
        });
        history.push(`/app/dashboard`);
      } catch (err) {
        const error = displayAxiosErrors(err);
        if (error?.response?.data.statusCode === 401) {
          message.error(error?.response.data.message);
        } else {
          message.error('Invalid Code');
        }
        history.push('/login-localshift');
      }
    };
    login().catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <> </> // Todo : building an awesome loading session
  );
};
