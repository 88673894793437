import React from 'react';

import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import styled from 'styled-components';

import { Spacer } from '../Spacer';

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;

  .ant-checkbox {
    &:hover {
      color: ${(props) => props.theme.colors.purpleNavi.plus2};
    }
  }
`;

interface CheckBoxOptionProps {
  checked: boolean;
  setChecked: (active: boolean) => void;
  content: React.ReactNode;
}
export const CheckBoxOption: React.FC<CheckBoxOptionProps> = ({ checked, setChecked, content }) => {
  const onChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };
  return (
    <CheckBoxWrapper>
      <Checkbox checked={checked} onChange={onChange} />
      <Spacer type="inline" size="xxs" />
      {content}
    </CheckBoxWrapper>
  );
};
