import React, { useEffect, useMemo, useState } from 'react';

import { RadioChangeEvent } from 'antd/lib/radio';

import styled from 'styled-components';

import { ActionType } from '../../../../graphql/mutations/createPost';
import { InventoryItem } from '../../../../graphql/query/inventoryByLocationId';
import { parseToKeywordType } from '../../../../utils/responseCard.utils';
import { TextButton } from '../../Buttons/Buttons.styled';
import MentionInput from '../../MentionInput/MentionInput';
import { RadioGroupOption } from '../../RadioGroup/RadioGroup';
import { Spacer } from '../../Spacer';
import { LabelB } from '../../Text/Text.styled';
import { FacebookSource, InstagramSource } from '../CreatePostModal';
import { cleanNames, KeywordsTypes, searchForKeywords } from '../helpers';
import { CallToAction, CheckBoxSection, ImageSection, KeywordsSection } from './CustomFormSections';
import { PostInputStatusEnum, SocialAccountType } from './GeneralPost';

const InputSection = styled.div`
  padding-right: 24px;
`;

const StyledBackToDefault = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

const getCustomKeywords = (vehicle: InventoryItem): KeywordsTypes[] => {
  const newKeywords = Array.from(
    new Set([
      ...cleanNames(vehicle.body),
      vehicle.year.toString(),
      ...cleanNames(vehicle.model),
      vehicle.make || '',
      vehicle.driveType,
    ])
  ).filter((x) => x);

  return newKeywords.map((keyword) => {
    return {
      keyword: keyword,
    };
  });
};

interface SocialFormComponentProps {
  input: string;
  setCondition: (condition: ActionType) => void;
  account: SocialAccountType;
  condition: ActionType;
  title: string;
  item: InventoryItem;
  generalPost: string;
  keywords: KeywordsTypes[];
  setInput: (input: string) => void;
  setEdit: (edit: boolean) => void;
  images: string[];
  imageSelected: string;
  setMediaSource?: (source: string) => void;
  mediaSource: string;
  hashtags: string;
}

const SocialCustomForm: React.FC<SocialFormComponentProps> = ({
  input,
  account,
  setCondition,
  condition,
  title,
  item,
  setInput,
  generalPost,
  setEdit,
  hashtags,
  imageSelected,
  images,
  mediaSource,
  setMediaSource,
  ...other
}) => {
  const [editStatus, setEditStatus] = React.useState(!(`${generalPost}${hashtags}` === input));
  const [keywords] = useState<KeywordsTypes[]>(parseToKeywordType([...other.keywords!, ...getCustomKeywords(item!)]));
  const [keywordsState, setKeywordsState] = useState<KeywordsTypes[]>(() => keywords || []);
  const [keywordSelect, setKeywordSelect] = useState('');
  const checkKeywords = useMemo(() => searchForKeywords, []);
  const [postStatus, setPostStatus] = useState<PostInputStatusEnum>(PostInputStatusEnum.suggested);
  const actionTypeOptions: RadioGroupOption[] = [
    {
      label: 'Learn More',
      value: ActionType.LEARN_MORE,
    },
    {
      label: 'Call',
      value: ActionType.CALL,
    },
    account === 'facebook'
      ? {
          label: 'Message',
          value: ActionType.MESSAGE_PAGE,
        }
      : {
          label: 'Shop',
          value: ActionType.SHOP,
        },
  ];

  const handleChangeCondition = ({ target: { value } }: RadioChangeEvent) => {
    setCondition(value);
  };

  const revertPost = () => {
    setInput(`${generalPost}${hashtags}`);
    setEditStatus(false);
    setPostStatus(PostInputStatusEnum.reverted);
    setCondition(ActionType.SHOP);
  };

  useEffect(() => {
    if (`${generalPost}${hashtags}` !== input && postStatus === PostInputStatusEnum.suggested) {
      setPostStatus(PostInputStatusEnum.custom);
    }
  }, [account, generalPost, hashtags, input, postStatus]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setEdit(editStatus);
  }, [editStatus, setEdit]);

  useEffect(() => {
    const summary = input;
    summary && checkKeywords(summary, keywordsState, setKeywordsState, true);
    // Adding keywordState to the deps causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkKeywords, input]);

  const mediaGroupOptions: RadioGroupOption[] = [
    account === 'facebook'
      ? { label: 'Link', value: FacebookSource.LINK }
      : { label: 'Image', value: InstagramSource.IMAGE },
  ];
  item.videoUrl && mediaGroupOptions.push({ label: 'Video', value: FacebookSource.VIDEO });

  return (
    <>
      {account !== 'google' && (
        <CheckBoxSection mediaOption={mediaSource} setSourceOption={setMediaSource!} options={mediaGroupOptions} />
      )}
      {account !== 'facebook' && mediaSource !== 'video' && <ImageSection image={imageSelected} />}
      {account !== 'instagram' && mediaSource !== 'video' && (
        <CallToAction
          handleChangeCondition={handleChangeCondition}
          actionTypeOptions={actionTypeOptions}
          condition={condition}
        />
      )}
      {account !== 'google' && (
        <KeywordsSection setKeywordSelect={setKeywordSelect} keywords={keywords} keywordsState={keywordsState} />
      )}
      <LabelB>{title} description</LabelB>
      <Spacer type="stack" size="xxs" />
      <InputSection>
        <MentionInput
          {...{
            height: '256px',
            defaultValue: input,
            postStatus,
            setPostStatus,
            onChange: (val) => setInput(val),
            keyword: keywordSelect,
            addBorder: true,
            addHashtags: true,
          }}
        />
      </InputSection>
      <StyledBackToDefault>
        {postStatus === PostInputStatusEnum.custom && (
          <TextButton red noPadding size="small" onClick={revertPost}>
            BACK TO DEFAULT
          </TextButton>
        )}
      </StyledBackToDefault>
    </>
  );
};

export default SocialCustomForm;
