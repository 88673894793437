import { gql, useQuery } from '@apollo/client';

export type IneligibleMarketplaceInventoryReport = {
  reason: string;
  count: number;
}[];

export const INELIGIBLE_MARKETPLACE_INVENTORY = gql`
  query ($locationId: String!) {
    getIneligibleMarketplaceInventoryReport(locationId: $locationId) {
      reason
      count
    }
  }
`;

export const INELIGIBLE_MARKETPLACE_INVENTORY_CSV = gql`
  query ($locationId: String!) {
    getIneligibleMarketplaceInventoryCsv(locationId: $locationId)
  }
`;

export interface IneligibleMarketplaceInventoryReportResponse {
  getIneligibleMarketplaceInventoryReport: IneligibleMarketplaceInventoryReport;
}

export function useIneligibleMarketplaceCsv(locationId: string) {
  const { data, error, loading } = useQuery<{
    getIneligibleMarketplaceInventoryCsv: string;
  }>(INELIGIBLE_MARKETPLACE_INVENTORY_CSV, {
    variables: { locationId },
    context: {
      timeout: 60000,
    },
  });
  return {
    csv: data?.getIneligibleMarketplaceInventoryCsv,
    error,
    loading,
  };
}

export function useIneligibleMarketplaceReport(locationId: string) {
  const { data, error, loading } = useQuery<IneligibleMarketplaceInventoryReportResponse>(
    INELIGIBLE_MARKETPLACE_INVENTORY,
    {
      variables: { locationId },
      context: {
        timeout: 60000,
      },
    }
  );
  return {
    report: data?.getIneligibleMarketplaceInventoryReport || [],
    error,
    loading,
  };
}
