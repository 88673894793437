import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RadioChangeEvent, Table, Tooltip, message } from 'antd';
import Column from 'antd/lib/table/Column';
import styled from 'styled-components';
import { SideDrawerContext } from '../../../context/SideDrawerProvider';
import { LocationItem, LocationProductEnum, useLocationByOrgIdQuery } from '../../../graphql/query/locationsByOrgId';
import { createSortProps } from '../../../utils/table.utils';
import { AccountIcon } from '../../Menu/AccountMenu/AccountMenuItem/AccountMenuItem.styled';
import { hasProduct } from '../../utils/product.utils';
import { Heading4 } from '../Headings/Headings.styled';
import IconButtonSimple from '../IconButtonSimple';
import { MenuCompact } from '../MenuCompact';
import SimpleCard from '../SimpleCard';
import { Spacer } from '../Spacer';
import { LabelB, LabelR } from '../Text/Text.styled';
import { RadioGroup, RadioGroupOption } from '../RadioGroup/RadioGroup';
import { ActiveState } from '../../../pages/OrganizationAccounts/OrganizationAccounts';
import { copyTextToClipboard } from '../../../utils/clipboard.utils';
import moment from 'moment';
import { UsersResponseItem } from '../../../graphql/query/usersByOrganizationId';

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 16px 0;
`;
const LocationEmptyState = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 2px solid #f4f3f6;
  width: 100%;
  text-align: left;
`;

const LocationHeaderRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const CardStyled = styled(SimpleCard)`
  width: 100%;
  .card {
    border: none !important;
  }
`;

interface LocationTableProps {
  locations: LocationItem[];
  isActive: boolean;
  users: UsersResponseItem[];
}

const getIconColor = (isConnected: boolean) => {
  return isConnected ? 'filled' : 'grey';
};

export const LocationTable: React.FC<LocationTableProps> = ({ locations, isActive, users }) => {
  const { sideDrawerNames, toggleSideDrawer } = useContext(SideDrawerContext);

  return (
    <CardStyled>
      <Table className="borderless-header compact-table" dataSource={locations} rowKey="id" pagination={false}>
        <Column<LocationItem>
          title="Name"
          dataIndex="name"
          key="name"
          width="100"
          {...createSortProps<LocationItem>({
            type: 'string',
            field: 'name',
            multiple: 3,
          })}
          render={(name, record) => (
            <>
              <LabelB>{name}</LabelB>
              <Spacer type="stack" size="xxs" />
              <LabelR>
                {record.id}
                <IconButtonSimple
                  style={{ marginLeft: '5px' }}
                  icon="clipboard"
                  size="small"
                  onClick={() => {
                    copyTextToClipboard(record.id);
                    message.info('Location ID copied to clipboard');
                  }}
                />
              </LabelR>
              <Spacer type="stack" size="xxs" />
              <LabelR>Created On {moment(record.createdAt).format('MM/DD/YY')}</LabelR>
              {record.archivedAt && (
                <>
                  <Spacer type="stack" size="xxs" />
                  <LabelR>Archived On {moment(record.archivedAt).format('MM/DD/YY')}</LabelR>
                </>
              )}
            </>
          )}
        />
        <Column<LocationItem>
          title="POC"
          dataIndex="pocUserId"
          key="pocUserId"
          width="100"
          render={(pocUserId, _) => (
            <LabelR>{pocUserId ? users.find((user) => user.id === pocUserId)?.fullName : '-'}</LabelR>
          )}
        />
        <Column<LocationItem>
          title="Inventory"
          dataIndex="dealer.name"
          key="dealer.name"
          width="100"
          {...createSortProps<LocationItem>({
            type: 'string',
            field: 'dealer.name',
            multiple: 3,
          })}
          render={(_name, record) => <LabelR>{record.dealer ? record.dealer.name : 'No inventory'}</LabelR>}
        />
        <Column<LocationItem>
          title="Accounts"
          dataIndex="id"
          key="id"
          width="100"
          render={(_name, record) => {
            return (
              <div style={{ display: 'flex' }}>
                {hasProduct(record.products, LocationProductEnum.Facebook) && (
                  <>
                    <AccountIcon className={`ic ic_facebook_${getIconColor(Boolean(record.facebookPage?.pageId))}`} />
                    <Spacer type="inline" size="xxs" />
                  </>
                )}
                {hasProduct(record.products, LocationProductEnum.Instagram) && (
                  <>
                    <AccountIcon
                      className={`ic ic_ig_${getIconColor(Boolean(record.facebookPage?.hasInstagramPage))}`}
                    />
                    <Spacer type="inline" size="xxs" />
                  </>
                )}
                {hasProduct(record.products, LocationProductEnum.Google) && (
                  <>
                    <AccountIcon className={`ic ic_google_${getIconColor(Boolean(record.googleLocation?.id))}`} />
                    <Spacer type="inline" size="xxs" />
                  </>
                )}
                {hasProduct(record.products, LocationProductEnum.FbMarketplace) && (
                  <>
                    <AccountIcon
                      className={`ic ic_fb_marketplace_${getIconColor(record.marketplaceLicenses?.length > 0)}`}
                    />
                    <Spacer type="inline" size="xxs" />
                  </>
                )}
                {hasProduct(record.products, LocationProductEnum.TikTok) && (
                  <>
                    <AccountIcon className={`ic ic_tiktok_${getIconColor(Boolean(record.businessCenter))}`} />
                    <Spacer type="inline" size="xxs" />
                  </>
                )}
                {hasProduct(record.products, LocationProductEnum.GoogleAds) && (
                  <>
                    <AccountIcon className={`ic ic_google_ads_${getIconColor(Boolean(record.tagManagerStatus?.id))}`} />
                    <Spacer type="inline" size="xxs" />
                  </>
                )}
                {hasProduct(record.products, LocationProductEnum.FacebookAds) && (
                  <>
                    <AccountIcon className={`ic ic_fb_ads_${getIconColor(Boolean(record.facebookAdAccountId))}`} />
                    <Spacer type="inline" size="xxs" />
                  </>
                )}
              </div>
            );
          }}
        />
        <Column<LocationItem>
          title="Active Inventory"
          dataIndex="location.activeInventoryCount"
          key="location.activeInventoryCount"
          width="100"
          render={(_name, record) => <LabelR>{record.activeInventoryCount ? record.activeInventoryCount : '-'}</LabelR>}
        />
        <Column<LocationItem>
          title={() => {
            return (
              <>
                <LabelB>
                  <Tooltip
                    title={
                      'This is the total number of marketplace posts that are live on marketplace for this location. If this number is significantly higher than the active inventory count, there may be an issue marking marketplace posts as sold for this location.'
                    }
                  >
                    Marketplace Active
                  </Tooltip>
                </LabelB>
              </>
            );
          }}
          dataIndex="location.marketplaceStats"
          key="location.marketplaceStats"
          width="20"
          render={(_name, record) => (
            <LabelR>{record.marketplaceStats ? `${record.marketplaceStats.totalPosted}` : null}</LabelR>
          )}
        />
        <Column<LocationItem>
          title={() => {
            return (
              <>
                <LabelB>
                  <Tooltip
                    title={
                      'This is the total number of marketplace posts that are in a "Pending" status for this location. If consistently high, there is likely something wrong with one or more users or the inventory feed. i.e. Not logged in, chrome closed, Facebook location info incorrect, etc.'
                    }
                  >
                    Marketplace Pending
                  </Tooltip>
                </LabelB>
              </>
            );
          }}
          dataIndex="location.marketplaceStats"
          key="location.marketplaceStats"
          width="20"
          render={(_name, record) => (
            <LabelR>{record.marketplaceStats ? `${record.marketplaceStats.totalPending}` : null}</LabelR>
          )}
        />
        <Column<LocationItem>
          title={() => {
            return (
              <>
                <LabelB>
                  <Tooltip
                    title={
                      'This is the total number of unposted inventory that is eligible to be posted to marketplace for this location.If this number + the "Marketplace Active" column are significantly lower than the total number of active inventory for the location, check the "Marketplace Ineligible Report" for more in depth information. This number may be incorrect if the location has requested custom posting parameters, ie. used only inventory, exclude certain makes, etc.'
                    }
                  >
                    Marketplace Eligible
                  </Tooltip>
                </LabelB>
              </>
            );
          }}
          dataIndex="location.marketplaceStats"
          key="location.marketplaceStats"
          width="20"
          render={(_name, record) => (
            <LabelR>{record.marketplaceStats ? `${record.marketplaceStats.totalAvailableToPost}` : null}</LabelR>
          )}
        />
        <Column<LocationItem>
          title=""
          dataIndex="id"
          key="id"
          render={(_name, record) => (
            <Options
              record={record}
              isActive={isActive}
              toggleSideDrawer={toggleSideDrawer}
              sideDrawerNames={sideDrawerNames}
            />
          )}
        />
      </Table>
    </CardStyled>
  );
};

interface OrganizationLocationsListProps {
  organizationId: string;
  isActive: boolean;
  users: UsersResponseItem[];
}

export const Options: React.FC<{
  record: LocationItem;
  isActive: boolean;
  toggleSideDrawer: any;
  sideDrawerNames: any;
}> = ({ record, isActive, toggleSideDrawer, sideDrawerNames }) => {
  const [showOptions, setShowOptions] = useState(false);
  return (
    <MenuCompact
      setShowOptions={setShowOptions}
      visible={showOptions}
      options={[
        {
          label: 'Edit Location',
          active: isActive,
          onClick: () => {
            setShowOptions(false);
            toggleSideDrawer(sideDrawerNames.newLocation, record);
          },
        },
        {
          label: 'Location Settings',
          active: isActive,
          onClick: () => {
            setShowOptions(false);
            toggleSideDrawer(sideDrawerNames.locationSettings, record);
          },
        },
      ]}
    >
      <IconButtonSimple
        onClick={() => setShowOptions(true)}
        noColor
        {...{ icon: 'ellipsis', size: 'small' }}
        style={{ transform: 'rotate(90deg)' }}
      />
    </MenuCompact>
  );
};

export const OrganizationLocations: React.FC<OrganizationLocationsListProps> = ({
  organizationId,
  isActive,
  users,
}) => {
  const { sideDrawerNames, toggleSideDrawer } = useContext(SideDrawerContext);
  const [locationState, setLocationState] = useState<ActiveState>(ActiveState.ACTIVE);
  const { locations, refetch } = useLocationByOrgIdQuery(organizationId);

  const isEmpty = !locations?.length;

  const locationStateOptions: RadioGroupOption[] = [
    { label: 'Active', value: ActiveState.ACTIVE },
    { label: 'Archived', value: ActiveState.INACTIVE },
  ];

  useEffect(() => {
    setLocationState(ActiveState.ACTIVE);
  }, [organizationId, setLocationState]);

  const handleChangeCondition = useCallback(
    async ({ target: { value } }: RadioChangeEvent) => {
      setLocationState(value);
      if (value === ActiveState.INACTIVE) refetch({ organizationId, active: false });
      else refetch({ organizationId, active: true });
    },
    [organizationId, refetch]
  );

  return (
    <SectionContainer>
      <LocationHeaderRow>
        <Heading4>Locations</Heading4>
        <IconButtonSimple
          disabled={!isActive}
          icon="plus"
          size="medium"
          onClick={() => toggleSideDrawer(sideDrawerNames.newLocation)}
        />
      </LocationHeaderRow>
      <Spacer type="stack" size={'xs'}></Spacer>
      <RadioGroup
        value={locationState}
        defaultValue={ActiveState.ACTIVE}
        onChange={handleChangeCondition}
        options={locationStateOptions}
      ></RadioGroup>
      <Spacer type="stack" size={'xs'}></Spacer>
      {isEmpty && <LocationEmptyState>No locations added</LocationEmptyState>}
      {!isEmpty && <LocationTable isActive={isActive} locations={locations} users={users} />}
    </SectionContainer>
  );
};
