import { gql } from '@apollo/client';

export interface FacebookPage {
  pageId: string;
  name: string;
  isActive: boolean;
  locationId: string | null;
  avatar?: string | null;
  category?: string | null;
}

export const FbPageFragment = gql`
  fragment FbPage on FacebookPage {
    pageId
    name
    isActive
    locationId
    category
    avatar
  }
`;
