import React, { useEffect, useState } from 'react';

import { Spin } from 'antd';

import styled from 'styled-components';

import { AudioTrack } from '../../../graphql/query/findMusic';
import { capitalizeOnlyFirstLetter } from '../../../utils/formatText.utils';
import { Spacer } from '../Spacer';

const AudioListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Player = styled.audio`
  max-height: 32px;
  width: 100%;
  &::-webkit-media-controls-panel {
    box-shadow: ${(props) => props.theme.shadows.interaction};
    border-radius: 1px;
    border: 2px solid transparent;
  }
  &::-webkit-media-controls {
    height: 16px;
  }
  &::-webkit-media-controls-play-button,
  &::-webkit-media-controls-pause-button {
    height: 16px;
  }
`;

const AudioContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 12px;
  height: 100px;
  overflow-y: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  box-shadow: ${(props) => props.theme.shadows.l};
  border: solid 1px rgba(0, 0, 0, 0.07);
`;

const ActiveMessage = styled.div`
  color: ${(props) => props.theme.colors.white};
`;

const SongTitle = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: space-between;
  border: solid 1px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  color: ${(props) => (props.active ? props.theme.colors.white : props.theme.colors.dimGray.base)};
  font-weight: 600;
  width: 100%;
  align-items: center;
  padding: 8px;
  background-color: ${(props) => (props.active ? props.theme.colors.purpleNavi.plus2 : 'transparent')};
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.active ? props.theme.colors.purpleNavi.plus2 : props.theme.colors.dimGray.plus3};
  }
`;

interface VideoPostModalProps {
  tracks: AudioTrack[];
  setAudioUrl: (audio: string) => void;
  loading: boolean;
}

const AudioList: React.FC<VideoPostModalProps> = ({ tracks, setAudioUrl, loading }) => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState<number>(0);

  const handleTrackClick = (index: number) => {
    setCurrentTrackIndex(index);
  };

  useEffect(() => {
    setAudioUrl(tracks[currentTrackIndex]?.url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrackIndex]);

  return (
    <AudioListContainer>
      <Player src={tracks[currentTrackIndex]?.url} controls />
      <Spacer type="stack" size="xs" />
      <AudioContainer>
        {loading ? (
          <Spin />
        ) : (
          tracks.map((track, index) => (
            <SongTitle key={index} active={index === currentTrackIndex} onClick={() => handleTrackClick(index)}>
              <div>
                {index + 1}.- {capitalizeOnlyFirstLetter(track.title)}
              </div>
              {index === currentTrackIndex && <ActiveMessage>Selected</ActiveMessage>}
            </SongTitle>
          ))
        )}
      </AudioContainer>
    </AudioListContainer>
  );
};

export default AudioList;
