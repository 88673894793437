import React from 'react';

import { LinkPreview } from '@dhaiwat10/react-link-preview';

import styled from 'styled-components';

import { InventoryItem } from '../../../../graphql/query/inventoryByLocationId';
import HelperMessage from '../../HelperMessage';
import { Spacer } from '../../Spacer';
import { LabelB, LabelR as LabelRight, TextBold } from '../../Text/Text.styled';
import { SocialAccountType } from './GeneralPost';

const PostSummaryWrapper = styled.div`
  float: left;
  padding-left: 24px;
  width: 33.33%;
  position: relative;
  padding-bottom: 8px;
`;
export const FieldSection = styled.section`
  padding-top: 24px;
`;
const LinkText = styled(LabelB)`
  padding-bottom: 4px;
`;
const LinkSection = styled.section`
  padding-top: 16px;
`;
const Row = styled.div`
  padding-right: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
`;
const LabelR = styled(LabelRight)`
  text-align: left;
  margin-left: 8px;
`;
const VideoSection = styled.video`
  width: 400px;
  height: 230px;
  border-radius: 12px;
  border: 1px solid #111;
`;

const Input = styled.input`
  background: ${(props) => props.theme.layoutColors.background};
  cursor: text;
  font-family: ${(props) => props.theme.fonts.body};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`;

export const Field: React.FC<{ leftText: string; rightText?: string | number }> = ({ leftText, rightText }) => {
  return (
    <Row>
      <LabelB>{leftText}</LabelB>
      <LabelR>{rightText}</LabelR>
    </Row>
  );
};
interface PostSummaryColumnProps {
  item?: InventoryItem;
  setVdpUrl: (vdp: string) => void;
  vdp: string;
  videoUrl?: string;
  mediaFacebook: string;
  mediaInstagram: string;
  source: SocialAccountType;
}

const showVehicleInfo = (item?: InventoryItem) => {
  const priceField =
    item?.price === 0
      ? 'No price yet'
      : item?.price.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });

  return (
    <FieldSection>
      <Field rightText={item?.vehicle} leftText="Name"></Field>
      <Field rightText={priceField} leftText="Price"></Field>
      <Field rightText={`${item?.age ? item.age : '-'} Days`} leftText="Age"></Field>
      <Field rightText={item?.condition} leftText="Condition"></Field>
      <Field rightText={item?.stock} leftText="Stock"></Field>
      <Field rightText={item?.isVinListing ? item?.vin : 'No VIN Listing'} leftText="VIN"></Field>
      <Field rightText={item?.address} leftText="Address"></Field>
    </FieldSection>
  );
};

export const PostSummaryColumn: React.FC<PostSummaryColumnProps> = ({
  item,
  setVdpUrl,
  vdp,
  mediaFacebook,
  source,
  videoUrl,
  mediaInstagram,
}) => {
  const showVideo =
    (mediaFacebook === 'video' && source === 'facebook') || (mediaInstagram === 'video' && source === 'instagram');

  return (
    <PostSummaryWrapper>
      <LabelB>Post Summary</LabelB>
      {showVehicleInfo(item)}
      <LinkSection>
        <LinkText>
          <a target="_blank" rel="noreferrer" href={showVideo ? videoUrl : vdp}>
            {showVideo ? 'VIDEO PREVIEW' : 'VDP Link Preview'}
          </a>
        </LinkText>
        {!showVideo && <Input className="ant-input" onChange={(e) => setVdpUrl(e.currentTarget.value)} value={vdp} />}
        <Spacer type="stack" size="xxs" />
        <TextBold color="#5D63A4"></TextBold>
        {showVideo && videoUrl ? (
          <VideoSection controls>
            <source src={videoUrl} />
            Your browser does not support HTML5 video.
          </VideoSection>
        ) : (
          <LinkPreview
            openInNewTab={true}
            url={vdp}
            showLoader={true}
            imageHeight={250}
            descriptionLength={100}
            textAlign="left"
            fallback={<HelperMessage type="danger" message={`Error showing the link's preview`} />}
          />
        )}
      </LinkSection>
    </PostSummaryWrapper>
  );
};

export default PostSummaryColumn;
