import React from 'react';

import { spacing } from '../../StyleGuide/themes/mainTheme';

type SpaceSize = 'xxxs' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl';

export interface SpacerProps {
  type: string;
}

interface RegularSpaceProps extends SpacerProps {
  type: 'stack' | 'inline';
  size: SpaceSize;
}

export const Spacer: React.FC<RegularSpaceProps> = (props) => {
  switch (props.type) {
    case 'stack':
      return (
        <div
          style={{
            height: spacing.sizes[props.size],
          }}
        ></div>
      );
    default:
      return (
        <div
          style={{
            width: spacing.sizes[props.size],
          }}
        ></div>
      );
  }
};
