import { createGlobalStyle } from 'styled-components';

export const AntSelectStyles = createGlobalStyle`
  // select styles
  .ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      background: ${(props) => props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.dimGray.plus2};
      box-sizing: border-box;
      border-radius: 4px;
      .ant-select-selection-placeholder {
        color: ${(props) => props.theme.colors.dimGray.plus1};
      }
    }
    .ant-select-selection-item{
      color: ${(props) => props.theme.colors.dimGray.minus1};
    }
   .ant-select-selector:hover {
      box-shadow: ${(props) => props.theme.shadows.s};
      border-color: transparent;
      background-color: white;
      .ant-select-selection-placeholder {
        color: ${(props) => props.theme.colors.dimGray.minus1};
      }
    }

   &.ant-select-focused:not(.ant-select-disabled){
      .ant-select-selector {
        box-shadow: none;
        border-color: transparent !important;
        background-color: white;

        .ant-select-selection-placeholder {
          color: ${(props) => props.theme.colors.dimGray.minus1};
        }
      }

    }
    &.ant-select-open{
      .select-arrow-icon{
          transform: scaleY(-1);
        }
    }

  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    font-weight: 600;
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.purpleNavi.plus2};
    .ant-select-item-option-content{
    }
  }
  .ant-select-item{
    padding: 12px 16px;
    color: ${(props) => props.theme.colors.dimGray.minus2};
  }
  .ant-select-dropdown{
    box-shadow: ${(props) => props.theme.shadows.s};
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled){
    color: ${(props) => props.theme.colors.dimGray.minus2};
    background-color: ${(props) => props.theme.colors.dimGray.plus3};
  }
  .ant-select-item.ant-select-item-group {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    border-top: 2px solid #f4f3f6;
    cursor: default;
  }

`;
