import React from 'react';

import { Radio, Space } from 'antd';
import { RadioGroupProps as AntRadioGroupProps } from 'antd/lib/radio';

import { RadioGroupOption } from '../RadioGroup/RadioGroup';

export interface RadioGroupProps extends AntRadioGroupProps {
  options: RadioGroupOption[];
}

export const RadioGroupHorizontal = React.forwardRef<HTMLDivElement, RadioGroupProps>(({ options, ...props }, ref) => (
  <Radio.Group ref={ref} {...props} className="borderless">
    <Space direction="vertical">
      {options.map((opt) => (
        <Radio
          //@ts-ignore
          key={opt.value}
          value={opt.value}
        >
          {opt.label}
        </Radio>
      ))}
    </Space>
  </Radio.Group>
));
