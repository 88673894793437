import { createGlobalStyle } from 'styled-components';

export const AntCalendarStyles = createGlobalStyle`


.ant-picker-calendar {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	height: 100%;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner{
	border-color: ${(props) => props.theme.colors.purpleNavi.plus2};
	border-radius: 2px;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 24px 0;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
	font-size:18px;
}

// date color background selected
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date{
	background: #F4F3F6;
	&:hover {
    background: #F4F3F6;
		color: ${(props) => props.theme.colors.purpleNavi.plus2};
	}
}

// 
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value{
	color: ${(props) => props.theme.colors.purpleNavi.base};
	&:hover {
    color: ${(props) => props.theme.colors.purpleNavi.plus2};
	}
}

.ant-picker-cell-in-view.ant-picker-cell-disabled .ant-picker-cell-inner {
  pointer-events: all;
	cursor: not-allowed;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
	border-color: ${(props) => props.theme.colors.purpleNavi.plus2};
	color:white;
	background: ${(props) => props.theme.colors.purpleNavi.base};
	border-radius: 2px;
	&:hover {
		color: ${(props) => props.theme.colors.purpleNavi.plus2};
	}
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value{
	color: ${(props) => props.theme.colors.purpleNavi.plus2};
}

.ant-picker-today-btn {
	color: ${(props) => props.theme.colors.purpleNavi.base};
}

.ant-picker-today-btn:hover {
	color: ${(props) => props.theme.colors.purpleNavi.plus2};
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner{
	border-color: ${(props) => props.theme.colors.purpleNavi.plus2};
	background: ${(props) => props.theme.colors.purpleNavi.plus1};
	color: white;
	border-radius: 2px;
}

.ant-picker-ok button{
	font-family: 'Source Sans Pro', sans-serif;
	border-radius: 2px;
	background: ${(props) => props.theme.colors.purpleNavi.base};
	&:hover {
    background: ${(props) => props.theme.colors.purpleNavi.plus2};
	}
}

 .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: white;
  background: ${(props) => props.theme.colors.purpleNavi.base};
  border-color: ${(props) => props.theme.colors.purpleNavi.plus2};
	cursor: pointer;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner{
	border-color: ${(props) => props.theme.colors.purpleNavi.plus2};
	color:white;
	background: ${(props) => props.theme.colors.purpleNavi.base};
	border-radius: 2px;
	&:hover {
		color: ${(props) => props.theme.colors.purpleNavi.plus2};
	}
}

.ant-picker-cell-range-hover{
	border-color:  ${(props) => props.theme.colors.purpleNavi.plus2};
}


.ant-picker-now-btn{
	color: ${(props) => props.theme.colors.purpleNavi.base};
	  &:hover {
			color: ${(props) => props.theme.colors.purpleNavi.plus2};
		}
}

`;
