import { PostFrequency, PostPriority } from '../../graphql/mutations/upsertLocationSettings';
import { LocationProductEnum, LocationProducts, ProductSettings } from '../../graphql/query/locationsByOrgId';
import { stringToJson } from './convert.utils';
import { hasElement } from './enums.utils';

export interface PostSettings {
  postFrequency?: PostFrequency;
  carPriority?: PostPriority;
  repost?: boolean;
}

export function formatSettings(products: LocationProducts[]): ProductSettings {
  const settings: ProductSettings = {};
  for (const product of products) {
    //@ts-ignore
    settings[product.slug] = stringToJson(product.settings);
  }
  return settings;
}

export function formatPostSettings(postSettings: string): PostSettings {
  return stringToJson(postSettings);
}

export const hasProduct = (locationProducts: LocationProducts[], product: LocationProductEnum) => {
  const products = locationProducts.filter((product) => product.enabled).map((locationProduct) => locationProduct.slug);
  return hasElement(products, product);
};
