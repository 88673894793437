import { createGlobalStyle } from 'styled-components';

export const TableStyles = createGlobalStyle`


  .ant-table{

    tr {
      border: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
    }

    th,
    td,
    tr {
      min-height: 64px;
      height: 64px;
      font-size: 12px;
      line-height: 16px;
    }
  }




  th,
  .ant-table-thead > tr > th {
    background-color: ${(props) => props.theme.colors.white};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  .ant-table-column-sorter-full{
    height: 16px;
    width: 16px;
  }

  .compact-table {
    tr {
      height: 48px;
    }

    th,
    .ant-table-thead > tr > th,
    .ant-table-thead > tr {
      height: 40px;
      padding: 12px 16px;

    }

    td,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td  {
      height: 48px;
      padding: 6px 16px;
    }
    .ant-table-column-sorters {
      padding: 0;
      line-height: 16px;
    }

  }

  /**
  ** Sorter Icon styles
  */
 .ant-table-column-sorter-up + .ant-table-column-sorter-down {
   margin-top:0;
 }
  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    display: none;
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    display: block;
    position: relative;
    color: ${(props) => props.theme.colors.dimGray.plus2};
    overflow-wrap: normal;

    &:before {
      height: 16px;
      width: 16px;
      content: var( --icon-Icon_Small_Arrows_Arrow_down, 'X');
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'ResponsePro';
      font-size: 16px;
      color: ${(props) => props.theme.colors.dimGray.plus2};
      text-align: center;
    }
    svg {
      display:none;
    }
  }
  .ant-table-column-sorter-up.active:before {
    content: var( --icon-Icon_Small_Arrows_Arrow_up, 'X');
  }
  .ant-table-column-sorter-down.active:before {
    content: var( --icon-Icon_Small_Arrows_Arrow_down, 'X');
  }
  .ant-table-cell:hover {
    .ant-table-column-sorter-down.active:before ,
    .ant-table-column-sorter-up.active:before {
      color: ${(props) => props.theme.colors.viridianGreen.base};
    }
  }

  /**
  ** Multi select styles (checkbox column)
  */
  .ant-table-selection-column {
    width: 64px;
    margin-left: 40px;
  }
  // extra spacing for multi select column
  .wide-table .ant-table-selection-column {
    width: 0px;
    padding-left: 0px;
  }
  .ant-table-tbody > tr.ant-table-row-selected{
    > td {
      background-color: ${(props) => props.theme.colors.dimGray.plus3};
    }

    &:hover > td {
      filter: brightness(102%);
      background-color: ${(props) => props.theme.colors.dimGray.plus3};
    }
  }
/** Borderless header */
  .borderless-header {
    .ant-table-thead > tr,
    .ant-table-tbody > tr:first-child {
      border: solid 1px transparent !important;
    }
    .ant-table-thead > tr > th {
      border-bottom:none;
    }
  }

`;
