import * as React from 'react';
import { Route, RouteProps } from 'react-router';

interface ProtectedRouteProps extends RouteProps {
  authenticationPath: string;
}

// This doesn't really do much bc we just use the Apollo thing to send us to the login page if we aren't authenticated
export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  return <Route {...props} />;
};

export default ProtectedRoute;
