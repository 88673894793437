import { gql, useQuery } from '@apollo/client';

export enum LeadRevivalChannel {
  Email = 'Email',
  Text = 'Text',
}

interface LeadRevivalMetricsRequest {
  organizationIds: string[];
  locationIds: string[];
  startDate: Date;
  endDate: Date;
  channel: LeadRevivalChannel;
}

export interface LeadRevivalMetrics {
  channel: LeadRevivalChannel;
  campaign: string;
  sent: number;
  leads: number | null;
  sales: number | null;
  revenue: number | null;
  clicks: number | null;
  opens: number | null;
}

interface LeadRevivalMetricsResponse {
  getLeadRevivalMetrics: LeadRevivalMetrics[];
}

interface LeadRevivalMetricsParams {
  leadRevivalMetricsRequest: LeadRevivalMetricsRequest;
}

const GET_LEAD_REVIVAL_METRICS = gql`
  query getLeadRevivalMetrics($leadRevivalMetricsRequest: LeadRevivalMetricsRequest!) {
    getLeadRevivalMetrics(input: $leadRevivalMetricsRequest) {
      channel
      campaign
      sent
      leads
      sales
      revenue
      clicks
      opens
    }
  }
`;

export function useGetLeadRevivalMetrics(params: LeadRevivalMetricsParams) {
  const { data, error, loading } = useQuery<LeadRevivalMetricsResponse, LeadRevivalMetricsParams>(
    GET_LEAD_REVIVAL_METRICS,
    {
      variables: params,
      fetchPolicy: 'cache-and-network',
    }
  );
  return {
    leadRevivalMetrics: data?.getLeadRevivalMetrics || [],
    error,
    loading,
  };
}
