import { gql } from '@apollo/client';

export interface UploadAvatarRequest {
  data: {
    filename: string;
    ext: string;
    contentType: string;
  };
}

export interface UploadAvatarResponse {
  uploadUserAvatar: {
    id: number;
    signedUrl: string;
    uuid: string;
  };
}

export const UPLOAD_USER_AVATAR = gql`
  mutation ($data: CreateAttachmentInput!) {
    uploadUserAvatar(input: $data) {
      id
      uuid
      signedUrl
    }
  }
`;
