import styled from 'styled-components';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  .ant-typography-danger {
    font-size: 12px;
    color: ${(props) => props.theme.colors.red};
    font-weight: 600;
    padding-left: 20px;
    margin-top: 8px;
  }
`;

export const StyledSubmitWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
