import { gql } from '@apollo/client';

export interface ResetMarketplaceLicenseRequest {
  marketplaceToken: string;
}
export interface ResetMarketplaceLicenseResponse {
  resetMarketplaceLicense: boolean;
}

export const RESET_MARKETPLACE_LICENSE = gql`
  mutation resetMarketplaceLicense($marketplaceToken: String!) {
    resetMarketplaceLicense(marketplaceToken: $marketplaceToken)
  }
`;
