import { gql } from '@apollo/client';

export interface GenerateVideoParams {
  imagesSelected: string[];
  audioUrl: string;
  imageCaption: string;
  locationId: string;
}

export interface GenerateVideoRequest {
  input: GenerateVideoParams;
}

export interface GenerateVideoResponse {
  generateVideo: string;
}

export const GENERATE_VIDEO_CONTENT = gql`
  mutation generateVideo($input: GenerateVideoParams!) {
    generateVideo(input: $input)
  }
`;
