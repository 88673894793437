import React, { useEffect, useState } from 'react';
import { DashboardFilter } from '../../../../pages/Dashboard/hooks/useFilters';
import { Card, Divider, Image, Layout, List, Modal, ModalProps, Spin, Tag, Tooltip, Typography } from 'antd';
import styled from 'styled-components';
import { MarketplaceLead, useGetMarketplaceLeads } from '../../../../graphql/query/getMarketplaceLeads';
import { snackbar } from '../../Snackbar';
import NoImage from '../../../../assets/images/noimage.png';
const { Sider, Content } = Layout;
const { Title, Text } = Typography;

interface MarketplaceMessagesModalProps extends ModalProps {
  dashboardFilters: DashboardFilter;
  isOpen: boolean;
  marketplaceLicenseId: string;
}

const ModalTitle = () => (
  <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', gap: '8px' }}>
    <h2 style={{ margin: 0 }}>Marketplace Messages</h2>
    <Tooltip title="This feature is in beta. Not all data may be complete. Some conversations may be missing messages. Please reach out to support if you have questions.">
      <Tag
        style={{
          backgroundColor: '#004aad', // Darker blue color
          color: 'white', // White text
          borderRadius: '12px', // Rounded corners
          fontSize: '0.9rem', // Adjust font size
          padding: '2px 8px', // Adjust padding
          fontWeight: 'bold', // Bold text
        }}
      >
        BETA
      </Tag>
    </Tooltip>
  </div>
);
export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}
`;

export const MarketplaceMessagesModal: React.FC<MarketplaceMessagesModalProps> = ({
  marketplaceLicenseId,
  dashboardFilters,
  isOpen,
  ...props
}) => {
  const [selectedMessage, setSelectedMessage] = useState<MarketplaceLead | null>(null);

  const handleClickMessage = (message: MarketplaceLead) => {
    setSelectedMessage(message);
  };

  const {
    marketplaceLeads: messages,
    loading,
    error,
  } = useGetMarketplaceLeads({
    marketplaceLeadsRequest: {
      marketplaceLicenseId,
      startDate: dashboardFilters.dateRange.startDate,
      endDate: dashboardFilters.dateRange.endDate,
    },
  });

  useEffect(() => {
    if (messages.length > 0) {
      setSelectedMessage(messages[0]);
    }
  }, [messages]);

  if (loading) {
    return (
      <LoadingWrapper>
        <Spin size="large" />
      </LoadingWrapper>
    );
  }

  if (error) {
    snackbar.error({ message: `Failed to fetch messages marketplace messages: ${error}` });
  }

  if (messages.length === 0) return null;

  return (
    <Modal
      style={{ top: 20 }}
      width={1300}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}
      footer={null}
      open={isOpen}
      title={<ModalTitle />}
      {...props}
    >
      <Layout style={{ minHeight: '400px', maxHeight: 'calc(100vh - 150px' }}>
        {/* Left panel for conversations */}
        <Sider
          width={320}
          style={{
            backgroundColor: '#f9f9f9',
            padding: '16px',
            boxShadow: '2px 0 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            overflowY: 'auto',
          }}
        >
          <List
            dataSource={messages}
            renderItem={(message) => (
              <List.Item
                onClick={() => handleClickMessage(message)}
                style={{
                  cursor: 'pointer',
                  backgroundColor: selectedMessage?.id === message.id ? '#f0f8ff' : 'white',
                  borderRadius: '8px',
                  marginBottom: '8px',
                  padding: '12px',
                  transition: 'background-color 0.3s ease',
                }}
              >
                <Card bordered={false} hoverable>
                  {/* SOLD label */}
                  {message.isSold && (
                    <Text strong style={{ color: 'red', display: 'block', marginBottom: '4px' }}>
                      SOLD
                    </Text>
                  )}
                  <Image
                    src={message.imageUrl || NoImage}
                    alt={message.fullname}
                    style={{
                      width: '100%',
                      height: 'auto',
                      borderRadius: '8px',
                      marginBottom: '8px',
                      objectFit: 'cover',
                    }}
                    preview={false}
                  />
                  <Title level={5} style={{ color: '#747ABD', marginBottom: '4px' }}>
                    {message.title}
                  </Title>
                  <Text strong style={{ marginBottom: '4px' }}>
                    {message.fullname}
                  </Text>
                  <br />
                  <Text type="secondary">{message.messages[0]?.messageText.slice(0, 50)}...</Text>
                </Card>
              </List.Item>
            )}
            style={{ maxHeight: 'calc(100vh - 64px)', overflowY: 'auto' }}
          />
        </Sider>

        {/* Right panel for selected message */}
        <Layout style={{ padding: '16px', paddingBottom: 0 }}>
          <Content
            style={{
              background: '#fff',
              padding: '24px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              maxHeight: '100%',
              overflowY: 'auto', // Make this area scrollable if content overflows
            }}
          >
            {selectedMessage ? (
              <>
                <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
                  <Image
                    src={selectedMessage.imageUrl || NoImage}
                    alt={selectedMessage.fullname}
                    style={{
                      width: '80px',
                      height: '80px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                      marginRight: '16px',
                    }}
                    preview={false}
                  />
                  <div>
                    {selectedMessage.isSold && (
                      <Title level={3} style={{ marginBottom: '4px', color: 'red' }}>
                        SOLD
                      </Title>
                    )}
                    <Title
                      level={4}
                      style={{ color: '#747ABD', marginTop: '0', marginBottom: '4px', display: 'inline-block' }}
                    >
                      {selectedMessage.title}
                    </Title>
                    {/* SOLD label in main view */}

                    <br />
                    <Text strong>{selectedMessage.fullname}</Text>
                  </div>
                </div>
                <Divider />

                {/* Display messages */}
                <div>
                  {selectedMessage.messages.map((msg, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: msg.sender === 'You' ? 'flex-end' : 'flex-start',
                        marginBottom: '12px',
                      }}
                    >
                      <div
                        style={{
                          maxWidth: '70%',
                          backgroundColor: msg.sender === 'You' ? '#747ABD' : '#f1f1f1',
                          color: msg.sender === 'You' ? '#fff' : '#000',
                          padding: '10px',
                          borderRadius: '12px',
                          textAlign: 'left',
                        }}
                      >
                        <Text style={{ fontSize: '14px' }}>{msg.messageText}</Text>
                        <br />
                        {msg.timestamp && (
                          <Text type="secondary" style={{ fontSize: '12px' }}>
                            {msg.timestamp}
                          </Text>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <Text>Select a message to view its details</Text>
            )}
          </Content>
        </Layout>
      </Layout>
    </Modal>
  );
};
