import styled, { DefaultTheme } from 'styled-components';

interface HeadingProps {
  white?: boolean;
}

const headingColor = (props: HeadingProps & { theme: DefaultTheme }) =>
  props.white ? props.theme.colors.white : props.theme.layoutColors.headings;

export const Heading1 = styled.h1`
  color: ${(props) => headingColor(props)};
  font-family: ${(props) => props.theme.fonts.header};
  font-style: normal;
  font-weight: 900;
  font-size: 34px;
  line-height: 40px;
`;

export const Heading2 = styled.h2`
  color: ${(props) => headingColor(props)};
  font-family: ${(props) => props.theme.fonts.header};
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 32px;
`;

export const Heading3 = styled.h3`
  color: ${(props) => headingColor(props)};
  font-family: ${(props) => props.theme.fonts.header};
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 0;
`;

export const Heading4 = styled.h4<HeadingProps>`
  color: ${(props) => headingColor(props)};
  font-family: ${(props) => props.theme.fonts.header};
  font-style: normal;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  display: flex;
`;
