import { gql, useQuery } from '@apollo/client';

export interface AudioTrack {
  title: string;
  url: string;
}

export interface FindMusicResponse {
  findMusic: AudioTrack[];
}

export const FIND_MUSIC = gql`
  query findMusic {
    findMusic {
      title
      url
    }
  }
`;

export function useFindMusicQuery() {
  const { data, error, loading } = useQuery<FindMusicResponse>(FIND_MUSIC, {
    fetchPolicy: 'cache-and-network',
  });
  return {
    audioTracks: data?.findMusic || [],
    error,
    loading,
  };
}
