import { Upload } from 'antd';

import styled from 'styled-components';

import { UploadButtonProps } from './UploadButton';

const innerCircleSize = {
  medium: '48px',
  large: '60px',
  xLarge: '78px',
};

const buttonSize = {
  medium: '64px',
  large: '80px',
  xLarge: 'auto',
};

export const StyledUpload = styled(Upload)<UploadButtonProps>`
  display: flex;
  justify-content: center;
  .ant-upload-select-picture-card {
    border: 2px ${(props) => (props.error || !props.loaded ? 'dashed' : 'solid')};
    ${(props) =>
      props.error
        ? props.theme.colors.red
        : props.loaded
        ? props.theme.colors.dimGray.plus3
        : props.theme.colors.purpleNavi.plus2};
    width: ${(props) => buttonSize[props.size || 'medium']};
    height: ${(props) => buttonSize[props.size || 'medium']};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${(props) => props.radious || '50%'};
    cursor: pointer;
  }

  .ant-upload-select-picture-card:hover {
    border: 2px ${(props) => (props.error || !props.loaded ? 'dashed' : 'solid')};
    ${(props) =>
      props.error
        ? props.theme.colors.red
        : props.loaded
        ? props.theme.colors.dimGray.plus3
        : props.theme.colors.purpleNavi.plus2};
  }

  .ant-upload .ant-upload-drag {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    flex-direction: column;
  }

  .ant-upload .inner-circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: ${(props) => props.radious || '50%'};
    background-color: ${(props) => props.theme.colors.dimGray.plus3};
    width: ${(props) => props.customSize?.width || innerCircleSize[props.size || 'medium']};
    height: ${(props) => props.customSize?.height || innerCircleSize[props.size || 'medium']};
    color: ${(props) => props.theme.colors.purpleNavi.plus2};
    font-size: 20px;
  }

  .thumbnail-avatar {
    width: ${(props) => props.customSize?.width || innerCircleSize[props.size || 'medium']};
    height: ${(props) => props.customSize?.height || innerCircleSize[props.size || 'medium']};
    border-radius: ${(props) => props.radious || '50%'};
    object-fit: cover;
  }

  .inner-circle:hover .actions {
    display: flex;
  }

  .ant-upload-list-item-list-type-picture-card:hover .actions {
    display: flex;
  }

  .ant-upload-list-item-info {
    border-radius: 50%;
    width: ${(props) => innerCircleSize[props.size || 'medium']};
    height: ${(props) => innerCircleSize[props.size || 'medium']};
  }

  .ant-upload-list-item-list-type-picture-card {
    width: ${(props) => buttonSize[props.size || 'medium']};
    height: ${(props) => buttonSize[props.size || 'medium']};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .actions {
    position: absolute;
    top: ${(props) => (props.size === 'large' ? '10px' : '8px')};
    left: 10px;
    z-index: 10;
    white-space: nowrap;
    width: ${(props) => innerCircleSize[props.size || 'medium']};
    height: ${(props) => innerCircleSize[props.size || 'medium']};
    border-radius: 50%;
    display: none;
    background-color: ${(props) => props.theme.colors.dimGray.minus2};
    opacity: 0.8;
    transition: all 0.2s;
    align-items: center;
    justify-content: space-around;
    color: white;
  }

  button-action {
    cursor: pointer;
  }

  .progress-wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: ${(props) => (props.size === 'large' ? '36px' : '28px')};
    margin-top: 10px;
    background-color: ${(props) => props.theme.colors.dimGray.plus2};
    border-radius: 25px;
  }

  .ant-progress-bg {
    height: 2px;
    width: ${(props) => props.uploadProgress}%;
    background-color: ${(props) => props.theme.colors.purpleNavi.plus1};
    border-radius: 25px;
  }
`;

export const WhiteIcon = styled.i`
  color: white;
`;
