import React from 'react';

import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { AccountsLayout } from '../components/Layout/AccountsLayout';
import { useSession } from '../context/AppStateProvider';
import { RoleEnum } from '../models/session';
import FacebookInboxRoutes from './FacebookInboxRoutes';
import GoogleInboxRoutes from './GoogleInboxRoutes';

const InboxRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  const { session } = useSession();
  return (
    <AccountsLayout>
      <Switch>
        <Route path={`${path}/google`} component={GoogleInboxRoutes} />
        <Route path={`${path}/facebook`} component={FacebookInboxRoutes} />
        <Redirect to={session?.roles.includes(RoleEnum.FacebookTest) ? `${path}/facebook` : `${path}/google`} />
      </Switch>
    </AccountsLayout>
  );
};

export default InboxRoutes;
