import React, { useState } from 'react';

import * as Yup from 'yup';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

import { Controller, useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';

import {
  UPSERT_LOCATION_CONTENT,
  UpsertLocationContentParams,
  UpsertLocationContentResponse,
  UpsertLocationContentRequest,
} from '../../../../graphql/mutations/upsertLocationContent';
import { GET_CONTENT_BY_LOCATION_NAME, LocationContent } from '../../../../graphql/query/getContentByLocationId';
import { Button } from '../../Buttons/Button';
import { ModalWithFooter } from '../../ModalWithFooter';
import { snackbar } from '../../Snackbar';
import { Spacer } from '../../Spacer';
import TextAreaField from '../../TextField/TextAreaField';
import { WhiteIcon } from '../../UploadButton/UploadButton.styled';
import { ImageOption, ServiceImage as LocationContentImage } from '../UpsertOfferForm';
import UploadFiles from '../../UploadButton/UploadFiles';
import { UploadFile } from 'antd/lib/upload';

const CreatePostWrapper = styled.div`
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  padding: 0;
  height: 500px;
  margin-bottom: 48px;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 16px;
  margin-bottom: 16px;
`;

const SelectedImage = styled.div`
  width: '20%';
  padding: 16px;
`;

const SelectImageModal = styled(ModalWithFooter)`
  top: '84px';
`;

const ValidationSchema = Yup.object().shape({
  description: Yup.string().required(),
  mediaUrls: Yup.array().required(),
});

interface UpsertLocationContentValue {
  description: string;
  mediaUrls: { value: string }[];
}

interface UpsertLocationContentProps {
  setShowModal: (show: boolean) => void;
  updatedObject?: LocationContent;
  locationId: string;
  refetch: () => void;
}

export const UpsertLocationContentForm: React.FC<UpsertLocationContentProps> = ({
  setShowModal,
  updatedObject,
  locationId,
  refetch,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>([]);
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [upsertLocationContent] = useMutation<UpsertLocationContentResponse, UpsertLocationContentParams>(
    UPSERT_LOCATION_CONTENT,
    {
      refetchQueries: [GET_CONTENT_BY_LOCATION_NAME],
    }
  );
  const { handleSubmit, control, errors, formState, register } = useForm<UpsertLocationContentValue>({
    resolver: yupResolver(ValidationSchema),
    mode: 'onChange',
    defaultValues: {
      description: updatedObject?.description,
      mediaUrls:
        updatedObject?.mediaUrls.map((url) => ({
          value: url,
        })) ?? [],
    },
  });

  const { isValid } = formState;

  const { fields, append, remove } = useFieldArray({
    keyName: 'key',
    name: 'mediaUrls',
    control,
  });

  const onSubmit = async (data: UpsertLocationContentValue) => {
    const request: UpsertLocationContentRequest = {
      id: updatedObject?.id || undefined,
      description: data.description,
      locationId: locationId,
      mediaUrls: data.mediaUrls.map(({ value }) => value).join(','),
    };
    await snackbar.info({
      message: `Saving User Generated Content ...`,
    });
    const response = await upsertLocationContent({
      variables: { data: request },
    });
    if (response) {
      refetch();
      await snackbar.info({
        message: `User Generated Content was successfully saved`,
      });
      setShowModal(false);
    }
  };

  const submitHandler = handleSubmit(onSubmit);

  return (
    <>
      <SelectImageModal
        width={800}
        visible={showImageModal}
        onCancel={() => {
          setShowImageModal(false);
        }}
        onOk={() => {
          setShowImageModal(false);
        }}
        title={'Select Image'}
        okText={'Select'}
      >
        <UploadFiles
          onUploadSuccess={(file: UploadFile) => {
            const currentFiles = [...uploadedFiles];
            currentFiles.push(file);
            setUploadedFiles(currentFiles);
            append({ value: file.response.url });
          }}
          onRemoveFile={(file: UploadFile) => {
            const index = uploadedFiles.findIndex((uploadedFile) => uploadedFile === file);
            remove(index);
            const currentFiles = [...uploadedFiles];
            currentFiles.splice(index, 1);
            setUploadedFiles(currentFiles);
          }}
        />
      </SelectImageModal>
      <form onSubmit={submitHandler}>
        <CreatePostWrapper>
          <TextAreaField
            //@ts-ignore
            {...register('description')}
            name="description"
            size="large"
            rows={4}
            label="Description"
            control={control}
            errors={errors}
          />

          <ImageGrid>
            {fields.map(({ value: url }, index) => {
              return (
                <SelectedImage key={url}>
                  <Controller
                    name={`mediaUrls.${index}.value`}
                    defaultValue={url}
                    hidden={true}
                    as="input"
                    control={control}
                  />
                  <ImageOption>
                    <div className="actions">
                      <WhiteIcon
                        className="icon misc-edit"
                        onClick={() => {
                          setShowImageModal(true);
                        }}
                      />
                      <Spacer type="inline" size="xs" />
                      <WhiteIcon className="icon misc-delete" onClick={() => remove(index)} />
                    </div>
                    <LocationContentImage src={url} alt="service" />
                  </ImageOption>
                </SelectedImage>
              );
            })}
          </ImageGrid>

          <Spacer type="stack" size="xs" />

          {!uploadedFiles.length ? (
            <Button
              size="small"
              btnType="text"
              onClick={() => {
                setShowImageModal(true);
              }}
            >
              SELECT IMAGE
            </Button>
          ) : (
            <Button btnType="primary" onClick={submitHandler} disabled={!isValid}>
              SAVE
            </Button>
          )}
          <Spacer type="stack" size="xs" />
        </CreatePostWrapper>
      </form>
    </>
  );
};
