import { RoleEnum } from '../../../models/session';
import { NavigationLinkProps } from './NavigationLink';

export const sideNavConfig = (organizationId: string): NavigationLinkProps[] => {
  return [
    {
      icon: 'inbox',
      href: `/app/inbox/${organizationId}`,
      tooltipText: 'Inbox',
      forbiddenRoles: [RoleEnum.Admin, RoleEnum.Member, RoleEnum.Partner],
    },
    {
      icon: 'inventory',
      href: `/app/inventory/${organizationId}`,
      tooltipText: 'Inventory',
      forbiddenRoles: [RoleEnum.Admin, RoleEnum.Member],
    },
    {
      icon: 'people',
      href: `/app/users/${organizationId}`,
      tooltipText: 'Users',
      forbiddenRoles: [RoleEnum.Admin, RoleEnum.Member],
    },
    {
      icon: 'dashboard',
      href: `/app/dashboard?organizationIds=${organizationId}`,
      tooltipText: 'Reporting',
      forbiddenRoles: [RoleEnum.Admin, RoleEnum.Member],
    },
  ];
};

export const partnerSideNavConfig = (partnerId: string): NavigationLinkProps[] => {
  return [
    {
      icon: 'people',
      href: `/app/partner/users/${partnerId}`,
      tooltipText: 'Partner Users',
      forbiddenRoles: [RoleEnum.Admin, RoleEnum.Member],
    },
  ];
};
