import React from 'react';

import { Dropdown, Menu } from 'antd';

import { UpOutlined } from '@ant-design/icons';

import styled from 'styled-components';

import { PostButtonOptions } from '../../../../graphql/mutations/createPost';
import { Button } from '../../Buttons/Button';
import { Spacer } from '../../Spacer';
import { LabelB } from '../../Text/Text.styled';

const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
`;
interface DropdownButtonProps {
  buttons: PostButtonOptions[];
  setSelectedButton: (button: PostButtonOptions) => void;
}

const DropdownButton: React.FC<DropdownButtonProps> = ({ buttons, setSelectedButton }) => {
  const displayList = buttons?.map((button) => {
    return (
      <Menu.Item
        className="dropdown-buttons"
        onClick={(e) => {
          setSelectedButton(e.key as unknown as PostButtonOptions);
        }}
        key={button}
      >
        <LabelB> {button.replaceAll('_', ' ')} </LabelB>
      </Menu.Item>
    );
  });

  const dropDownOptions = <Menu>{displayList}</Menu>;

  return (
    <Dropdown overlay={dropDownOptions} placement="bottomLeft" trigger={['hover']}>
      <OptionsContainer>
        <Button>
          <UpOutlined />
          <Spacer type="inline" size="xxs" />
          OPTIONS
        </Button>
      </OptionsContainer>
    </Dropdown>
  );
};

export default DropdownButton;
