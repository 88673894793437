import { gql, useQuery } from '@apollo/client';

interface MarketplaceLeadsRequest {
  marketplaceLicenseId: string;
  startDate: Date;
  endDate: Date;
}

interface Message {
  messageText: string;
  sender: string;
  timestamp: string;
}

export interface MarketplaceLead {
  id: string;
  marketplaceChatId: string;
  createdAt: Date;
  updatedAt: Date;
  marketplaceId: string;
  fullname: string;
  messages: Message[];
  imageUrl: string | null;
  title: string;
  isSold: boolean;
}

interface MarketplaceLeadsResponse {
  getMarketplaceLeads: MarketplaceLead[];
}

interface MarketplaceLeadsParams {
  marketplaceLeadsRequest: MarketplaceLeadsRequest;
}

const GET_MARKETPLACE_LEADS = gql`
  query getMarketplaceLeads($marketplaceLeadsRequest: MarketplaceLeadsRequest!) {
    getMarketplaceLeads(input: $marketplaceLeadsRequest) {
      id
      marketplaceChatId
      createdAt
      updatedAt
      marketplaceId
      fullname
      isSold
      messages {
        messageText
        sender
        timestamp
      }
      imageUrl
      title
    }
  }
`;

export function useGetMarketplaceLeads(params: MarketplaceLeadsParams) {
  const { data, error, loading } = useQuery<MarketplaceLeadsResponse, MarketplaceLeadsParams>(GET_MARKETPLACE_LEADS, {
    variables: params,
    fetchPolicy: 'cache-and-network',
  });
  return {
    marketplaceLeads: data?.getMarketplaceLeads || [],
    error,
    loading,
  };
}
