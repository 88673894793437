import React from 'react';

import { ActionType } from '../../../../graphql/mutations/createPost';

type SummaryUpdate = (summary: string) => void;
type ActionUpdate = (action: ActionType) => void;
type ImageUpdate = React.Dispatch<React.SetStateAction<string[]>>;
type SetEdited = (edit: boolean) => void;
type PathUpdate = (path: string) => void;

export interface PostInfo {
  fbSummary: string;
  setFbSummary: SummaryUpdate;
  fbActionButton: ActionType;
  setFbActionButton: ActionUpdate;
  imageSelected: string[];
  setImageSelected: ImageUpdate;
  googleSummary: string;
  setGoogleSummary: SummaryUpdate;
  googleActionButton: ActionType;
  setGoogleActionButton: ActionUpdate;
  instagramSummary: string;
  setInstagramSummary: SummaryUpdate;
  isFbEdited: boolean;
  setIsFbEdited: SetEdited;
  isGoogleEdited: boolean;
  setIsGoogleEdited: SetEdited;
  isInstagramEdited: boolean;
  setIsInstagramEdited: SetEdited;
  vdpUrl: string;
  setVdpUrl: SummaryUpdate;
  imagePath: string;
  setImagePath: PathUpdate;
}

export function usePostInfo(): PostInfo {
  const [fbSummary, setFbSummary] = React.useState<string>('');
  const [fbActionButton, setFbActionButton] = React.useState<ActionType>(ActionType.LEARN_MORE);
  const [isFbEdited, setIsFbEdited] = React.useState<boolean>(false);
  const [isGoogleEdited, setIsGoogleEdited] = React.useState<boolean>(false);
  const [isInstagramEdited, setIsInstagramEdited] = React.useState<boolean>(false);
  const [imageSelected, setImageSelected] = React.useState<string[]>([]);
  const [googleSummary, setGoogleSummary] = React.useState<string>('');
  const [googleActionButton, setGoogleActionButton] = React.useState<ActionType>(ActionType.LEARN_MORE);
  const [instagramSummary, setInstagramSummary] = React.useState<string>('');
  const [vdpUrl, setVdpUrl] = React.useState<string>('');
  const [imagePath, setImagePath] = React.useState<string>('');

  return {
    fbSummary,
    setFbSummary,
    fbActionButton,
    setFbActionButton,
    imageSelected,
    setImageSelected,
    googleSummary,
    setGoogleSummary,
    googleActionButton,
    setGoogleActionButton,
    instagramSummary,
    setInstagramSummary: setInstagramSummary,
    isFbEdited,
    setIsFbEdited,
    isGoogleEdited,
    setIsGoogleEdited,
    isInstagramEdited,
    setIsInstagramEdited,
    vdpUrl,
    setVdpUrl,
    imagePath,
    setImagePath,
  };
}
