import { gql } from '@apollo/client';

export interface DeleteQueueRequest {
  data: string[];
}

export const DELETE_QUEUE = gql`
  mutation ($data: [String!]!) {
    removePostFromQueue(queueIds: $data)
  }
`;
