import React from 'react';

import styled from 'styled-components';

import { RoleEnum } from '../../../models/session';
import { HasPermission } from '../../ui/Security';
import { Spacer } from '../../ui/Spacer';
import { AccountMenuItem, AccountMenuItemProps } from './AccountMenuItem';

const AccountMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const AccountMenu: React.FC = () => {
  const menuItems: AccountMenuItemProps[] = [
    {
      iconClass: 'ic_google',
      label: 'Google',
      href: '/app/inbox/google',
      forbiddenRoles: [RoleEnum.FacebookTest, RoleEnum.Partner],
      subItems: [
        {
          label: 'Posts',
          href: '/app/inbox/google/posts',
          count: 0,
          forbiddenRoles: [RoleEnum.FacebookTest],
        },
      ],
    },
    {
      iconClass: 'ic_facebook',
      label: 'Facebook',
      href: '/app/inbox/facebook',
      subItems: [
        {
          label: 'Posts',
          href: '/app/inbox/facebook/posts',
          count: 0,
        },
      ],
    },
  ];
  return (
    <AccountMenuWrapper>
      {menuItems.map((itemProps, index) => (
        <HasPermission key={index} forbiddenRoles={itemProps.forbiddenRoles}>
          <AccountMenuItem {...itemProps} />
          <Spacer type="stack" size={'s'} />
        </HasPermission>
      ))}
    </AccountMenuWrapper>
  );
};
