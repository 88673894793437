import styled, { css } from 'styled-components';

export interface StyledButtonProps {
  size?: 'medium' | 'small';
  noPadding?: boolean;
  red?: boolean;
  state?: 'hover' | 'focus' | 'active' | '';
  alert?: boolean;
}

const buttonFontSize = {
  medium: '16px',
  small: '12px',
};

const buttonLineHeight = {
  medium: '24px',
  small: '16px',
};

const buttonDimensions = {
  medium: {
    height: '40px',
    width: '160px',
  },
  small: {
    height: '32px',
    width: '132px',
  },
};

export const TextButton = styled.button<StyledButtonProps>`
  ${({ noPadding, size }) =>
    !noPadding && size === 'small'
      ? css`
          padding: 8px 24px;
        `
      : css`
          padding: 8px 24px;
        `}
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.body};
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => buttonFontSize[props.size || 'small']};
  line-height: ${(props) => buttonLineHeight[props.size || 'small']};
  border: none;
  outline: none;
  background-color: transparent;
  color: ${(props) => props.theme.colors.purpleNavi.plus1};
  ${({ red, theme: { colors } }) =>
    red &&
    css`
      color: ${colors.red};
    `}
  cursor: pointer;
  transition: all 0.2s;
  ${({ theme, state, red }) =>
    state === 'hover'
      ? css`
          color: ${!red && theme.colors.purpleNavi.plus2};
        `
      : state === 'focus'
      ? css`
          color: ${!red && theme.colors.viridianGreen.base};
        `
      : state === 'active' &&
        css`
          color: ${(props) => props.theme.colors.purpleNavi.base};
        `}
  &:disabled {
    color: ${(props) => props.theme.colors.dimGray.plus2};
    cursor: not-allowed;
  }
`;
TextButton.defaultProps = {
  size: 'medium',
  type: 'button',
};

export const SelectedButton = styled(TextButton)`
  border: 1px solid ${(props) => props.theme.colors.purpleNavi.plus2};
  color: ${(props) => props.theme.colors.purpleNavi.base};
  background: ${(props) => props.theme.colors.white};
  box-shadow: none;
  border-radius: 8px;
  &:hover {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.purpleNavi.plus2};
    border: 1px solid transparent;
  }
`;

export const InactiveButton = styled(TextButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  height: ${(props) => buttonDimensions[props.size || 'small'].height};
  color: black;
  background-color: ${(props) => props.theme.colors.dimGray.plus3};
  box-shadow: ${(props) => props.theme.shadows.interaction};
  border-radius: ${(props) => (props.size === 'small' ? '6px' : '8px')};
  border: 2px solid transparent;
  ${({ theme, state }) =>
    state === 'hover'
      ? css`
          color: ${theme.colors.white};
          background-color: ${theme.colors.dimGray.plus2};
        `
      : state === 'focus'
      ? css`
          color: ${theme.colors.white};
          background-color: ${theme.colors.dimGray.minus1};
          border: 2px solid ${theme.colors.dimGray.plus1};
        `
      : state === 'active' &&
        css`
          border: 2px solid transparent;
          color: ${theme.colors.white};
          background-color: ${theme.colors.dimGray.base};
          box-shadow: none;
        `}
`;

export const PrimaryButton = styled(TextButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => buttonDimensions[props.size || 'small'].height};
  background: ${(props) => props.theme.colors.purpleNavi.plus1};
  box-shadow: ${(props) => props.theme.shadows.interaction};
  border-radius: ${(props) => (props.size === 'small' ? '6px' : '8px')};
  color: ${(props) => props.theme.colors.white};
  border: 2px solid transparent;
  ${({ theme, state }) =>
    state === 'hover'
      ? css`
          color: ${theme.colors.white};
          background-color: ${theme.colors.purpleNavi.plus2};
        `
      : state === 'focus'
      ? css`
          color: ${theme.colors.white};
          background-color: ${theme.colors.viridianGreen.minus1};
          border: 2px solid ${theme.colors.viridianGreen.plus1};
        `
      : state === 'active' &&
        css`
          border: 2px solid transparent;
          color: ${theme.colors.white};
          background-color: ${theme.colors.purpleNavi.base};
          box-shadow: none;
        `}
  &:disabled {
    color: ${(props) => props.theme.colors.dimGray.plus2};
    background-color: ${(props) => props.theme.colors.dimGray.plus3};
    box-shadow: none;
  }
`;

export const SecondaryProps = css`
  border: 1px solid ${(props) => props.theme.colors.purpleNavi.plus2};
  color: ${(props) => props.theme.colors.purpleNavi.base};
  background: ${(props) => props.theme.colors.white};
  box-shadow: none;
  border-radius: 8px;
`;
export const SecondaryButton = styled(TextButton)`
  ${SecondaryProps}
  ${({ theme, state }) =>
    state === 'hover'
      ? css`
          color: ${theme.colors.white};
          background-color: ${theme.colors.purpleNavi.plus2};
          border: 1px solid transparent;
        `
      : state === 'focus'
      ? css`
          color: ${theme.colors.purpleNavi.base};
          background-color: ${theme.colors.white};
          border: 1px solid ${theme.colors.viridianGreen.plus1};
        `
      : state === 'active' &&
        css`
          border: 1px solid transparent;
          color: ${theme.colors.white};
          background-color: ${theme.colors.purpleNavi.base};
        `}

  &:disabled {
    color: ${(props) => props.theme.colors.dimGray.plus2};
    background-color: ${(props) => props.theme.colors.white};
    border: 2px solid ${(props) => props.theme.colors.dimGray.plus3};
  }
  ${({ theme, alert, state }) =>
    alert &&
    css`
      border: 1px solid ${alert ? theme.colors.red : theme.colors.purpleNavi.plus2};
      color: ${alert ? theme.colors.red : theme.colors.purpleNavi.base};
      ${state === 'hover'
        ? `
          color: ${theme.colors.white};
          background-color: ${theme.colors.red};
          border: 1px solid ${theme.colors.red};
          box-shadow: ${theme.shadows.interaction};
        `
        : state === 'focus'
        ? `
          color: ${theme.colors.dimGray.minus2};
          background-color: ${theme.colors.white};
          border: 1px solid ${theme.colors.red};
          box-shadow: none;
        `
        : state === 'active' &&
          `
        color: ${theme.colors.white};
        background-color: ${theme.colors.red};
        box-shadow: none;
        `}
    `}
`;

export const ConnectAccount = styled(TextButton)`
  color: ${(props) => props.theme.colors.dimGray.plus2};
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  box-shadow: none;
  border-radius: 8px;
  ${({ theme, state }) =>
    state === 'hover'
      ? css`
          color: ${theme.colors.red};
          border: 1px solid ${theme.colors.red};
          background-color: ${(props) => props.theme.colors.white};
          .text-action-button {
            display: none;
          }
          &:before {
            content: 'DISCONNECT';
          }
        `
      : state === 'focus'
      ? css`
          color: ${theme.colors.red};
          border: 2px solid ${theme.colors.red};
          background-color: ${(props) => props.theme.colors.white};
          .text-action-button {
            display: none;
          }
          &:before {
            content: 'DISCONNECT';
          }
        `
      : state === 'active' &&
        css`
          color: ${theme.colors.white};
          background-color: ${theme.colors.red};
          box-shadow: none;
          .text-action-button {
            display: none;
          }
          &:before {
            content: 'DISCONNECT';
          }
        `}
`;

export const RoundButton = styled.button`
  position: absolute;
  cursor: pointer;
  top: -14px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: inline-block;
  padding: 4px ${({ theme }) => theme.spacing.sizes.xs};
  border-radius: 24px;
  border: 2px solid ${({ theme }) => theme.colors.dimGray.plus3};
  width: auto;
  color: ${({ theme }) => theme.colors.purpleNavi.plus1};
  background-color: white;
  &:enabled {
    ${({ theme }) => css`
      border: 2px solid ${theme.colors.aquamarine};
      color: ${theme.colors.dimGray.minus1};
    `};
  }
  &:focus {
    outline: 0;
  }
  &:hover:enabled {
    box-shadow: ${({ theme }) => theme.shadows.s};
  }
`;
