import { gql } from '@apollo/client';

import { SocialAccount } from '../../components/ui/VehicleColumn/modeViews/VehicleSocialAccounts';

export enum Condition {
  All = 'all',
  New = 'new',
  Used = 'used',
}

export enum Status {
  FOR_SALE = 'for_sale',
  SOLD = 'sold',
}

export enum PostStatus {
  ACTIVE = 'active',
  DELETED = 'deleted',
  WAIT = 'wait',
  ERROR = 'error',
}

export enum LinkStatus {
  BROKEN = 'broken',
  WORKINKG = 'working',
  UNKNOWN = 'unknown',
  INCORRECT = 'incorrect',
  MISSING = 'missing',
}
export interface PostMetric {
  postId: string;
  inventoryId: string;
  title: string;
  source: SocialAccount;
  date: Date;
  clicks?: number;
  leads?: number;
  views?: number;
  imageUrl?: string;
  status?: PostStatus;
  statusMessage?: string;
  owner?: string;
  soldAt?: Date;
  link?: string;
}
export interface InventoryItem {
  vin: string;
  id: string;
  externalId: string;
  vehicle?: string;
  stock?: string;
  firstSeen: Date;
  make?: string;
  condition: Condition;
  price: number;
  imgSource: string;
  age: number;
  vdp_url: string;
  model: string;
  year: number;
  features: string;
  vehDescription: string;
  soldAt?: Date;
  status: Status;
  modelNumber: string;
  transmission: string;
  videoUrl?: string;
  body: string;
  driveType: string;
  intColor: string;
  trim: string;
  engine: string;
  extColor: string;
  postMetric: PostMetric[];
  mileage: number;
  images: string[];
  address: string;
  postDescription: string;
  isVinListing: boolean;
  marketplacePriority: boolean;
  canListToMarketplace: boolean;
  defaultTitle: string;
  marketplaceIneligibleReasons?: string[];
  numberOfImages?: number;
}
export interface InventoryByLocationIdResponse {
  getInventoryByLocationId: InventoryItem[];
}
export interface InventoryByLocationIdParams {
  locationId: string;
  condition?: Condition;
  name?: string;
}

export const INVENTORY_BY_LOCATION_ID = gql`
  query ($locationId: String!, $condition: String, $name: String) {
    getInventoryByLocationId(locationId: $locationId, condition: $condition, name: $name) {
      id
      externalId
      make
      condition
      firstSeen
      age
      marketplacePriority
      canListToMarketplace
      price
      stock
      vehicle
      vin
      status
      imgSource
      videoUrl
      vdp_url
      model
      vehDescription
      year
      trim
      features
      soldAt
      modelNumber
      transmission
      marketplaceIneligibleReasons
      numberOfImages
      mileage
      body
      engine
      driveType
      images
      intColor
      defaultTitle
      extColor
      postDescription
      isVinListing
      postMetric {
        source
        date
        clicks
        views
        link
      }
      address
    }
  }
`;
