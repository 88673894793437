import { gql, useQuery } from '@apollo/client';

export interface LocationOnboardingInfo {
  id: string;
  name: string;
  address: string;
  state: string;
  city: string;
  zip: string;
  country: string;
  organization: {
    id: string;
    name: string;
  };
  onboardingStage: string;
}

export interface LocationsInOnboardingResponse {
  locationsInOnboarding: LocationOnboardingInfo[];
}
export interface LocationsInOnboardingParams {
  // TODO: This may need other filters
  partnersOnly?: boolean;
  search?: string;
}

export const LOCATIONS_IN_ONBOARDING_NAME = 'locationsInOnboarding';
export const LOCATIONS_IN_ONBOARDING = gql`
    query ${LOCATIONS_IN_ONBOARDING_NAME}($search: String, $partnersOnly: Boolean) {
    ${LOCATIONS_IN_ONBOARDING_NAME}(search: $search, partnersOnly: $partnersOnly) {
    id
    name
    address
    state
    city
    zip
    country
    organization {
      id
      name
    }
    onboardingStage
  }
}
`;
export function useLocationsInOnboardingQuery({ search, partnersOnly }: { partnersOnly?: boolean; search?: string }) {
  const { data, error, loading, refetch } = useQuery<LocationsInOnboardingResponse, LocationsInOnboardingParams>(
    LOCATIONS_IN_ONBOARDING,
    {
      variables: { search, partnersOnly },
    }
  );
  return {
    locationsInOnboarding: data?.locationsInOnboarding || [],
    error,
    loading,
    refetch,
  };
}
