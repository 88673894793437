import React from 'react';

import { Switch, useRouteMatch } from 'react-router-dom';

import { RestrictedRoute } from '../components/ui/Security';
import { RoleEnum } from '../models/session';
import QueueLocation from '../pages/QueueLocation';
import QueuePage from '../pages/QueuePage';

const QueueRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <RestrictedRoute forbiddenRoles={[RoleEnum.Admin]} path={`${path}`} component={QueuePage} exact />
      <RestrictedRoute forbiddenRoles={[RoleEnum.Admin]} path={`${path}/location/:id`} component={QueueLocation} />
    </Switch>
  );
};

export default QueueRoutes;
