import { Configuration, OpenAIApi } from 'openai';

const configuration = new Configuration({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
});

const openai = new OpenAIApi(configuration);

export async function createCompletion({ maxTokens, prompt }: { maxTokens: number; prompt: string }) {
  try {
    const result = await openai.createCompletion({
      model: 'gpt-3.5-turbo-instruct',
      prompt,
      temperature: 0.5,
      max_tokens: maxTokens,
    });

    const response = result.data.choices[0].text;
    return response?.replace(/^\s+|\s+$/g, '');
  } catch (e: any) {
    return `Something is going wrong, Please try again: ${e.message}`;
  }
}
