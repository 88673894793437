import { gql } from '@apollo/client';
import { UserFieldsFragment, UsersResponseItem } from './usersByOrganizationId';

export interface UsersByPartnerIdResponse {
  usersByPartnerId: UsersResponseItem[];
}

export interface UsersByPartnerIdParams {
  partnerId: string;
  onlyActive?: boolean;
  username?: string;
}

export const USERS_BY_PARTNER_ID_QUERY_NAME = 'usersByPartnerId';
export const USERS_BY_PARTNER_ID = gql`
  query ${USERS_BY_PARTNER_ID_QUERY_NAME}($partnerId: String!, $onlyActive: Boolean, $username: String) {
    usersByPartnerId(partnerId: $partnerId, onlyActive: $onlyActive, username: $username) {
      ...UserFields

    }
  }
  ${UserFieldsFragment}
`;
