import { gql } from '@apollo/client';

import { OfferPostTemplate } from '../query/getOfferPostTemplatesByLocationId';

export enum CadenceOptions {
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
  DAILY = 'daily',
  BIWEEKLY = 'biweekly',
}

export const MAX_COUPON_CODE_LENGTH = 58;
export interface UpsertOfferPostTemplateRequest {
  id?: string;
  locationId: string;
  cadence: CadenceOptions;
  couponCode?: string;
  termsAndConditions: string;
  link: string;
  title: string;
  details: string;
  imageUrl: string;
}

export interface UpsertOfferPostTemplateResponse {
  upsertOfferPostTemplate: OfferPostTemplate;
}

export interface UpsertOfferPostTemplateParams {
  data: UpsertOfferPostTemplateRequest;
}

export const UPSERT_OFFER_POST_TEMPLATE = gql`
  mutation ($data: UpsertOfferPostTemplateRequest!) {
    upsertOfferPostTemplate(data: $data) {
      id
      locationId
      archivedAt
      cadence
      couponCode
      termsAndConditions
      link
      updatedAt
      title
      details
      imageUrl
    }
  }
`;
