import 'antd/dist/antd.css';

import React, { useCallback, useState } from 'react';

import { Tooltip } from 'antd';

import { WarningTwoTone } from '@ant-design/icons';

import styled from 'styled-components';

import NoImage from '../../../assets/images/noimage.png';

const FACEBOOK_WIDTH_MIN = 600;

const Img = styled.img`
  min-width: 100px;
  max-width: 100%;
  max-height: 80px;
  object-fit: cover;
  border-radius: 8px;
  align-self: center;
  box-shadow: ${(props) => props.theme.shadows.l};
  margin: 24;
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const SocialIcon = styled.span`
  position: absolute;
  top: 1px;
  left: 0;
  background-color: #ff0000;
  color: white;
  font-size: 8px;
  padding: 2px 6px;
  border-radius: 50%;
  opacity: 80%;
  border: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
`;

interface ImageCardProps {
  src: string;
  setImagesSelected: React.Dispatch<React.SetStateAction<string[]>>;
  imagesSelected: string[];
}

const CardImage: React.FC<ImageCardProps> = ({ src, imagesSelected, setImagesSelected }) => {
  const [warning, setWarning] = useState<string | null>(null);
  const selected = imagesSelected.includes(src);
  const handleClick = () => {
    if (!selected) {
      setImagesSelected((prev) => [...prev, src]);
    } else {
      setImagesSelected(imagesSelected.filter((image) => src !== image));
    }
  };

  const checkSize = useCallback(
    (e) => {
      const intrinsicWidth = e.target.naturalWidth;
      if (intrinsicWidth < FACEBOOK_WIDTH_MIN) {
        setWarning('Image is too small. When posted to Facebook, it will show as a thumbnail.');
      }
    },
    [setWarning]
  );

  const handleErrorImage = (e: React.ChangeEvent<HTMLImageElement>) => {
    e.target.src = NoImage;
  };

  return (
    <>
      <Img onLoad={checkSize} onClick={handleClick} src={src || NoImage} onError={handleErrorImage} />
      {warning && (
        <Tooltip placement="topLeft" title={warning} arrowPointAtCenter>
          <WarningTwoTone style={{ fontSize: '24px', left: 10, position: 'absolute' }} twoToneColor="orange" />
        </Tooltip>
      )}
      {selected && <SocialIcon>{imagesSelected.findIndex((image) => image === src) + 1}</SocialIcon>}
    </>
  );
};

export default CardImage;
