import React, { useEffect, useState } from 'react';

import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import styled from 'styled-components';

import MentionInput from '../../MentionInput/MentionInput';
import { SelectOrUploadImage } from '../../SelectOrUploadImage/SelectOrUploadImage';
import { Spacer } from '../../Spacer';
import { LabelB } from '../../Text/Text.styled';
import { AccountItem } from '../CreatePostModal';
import { AccountCheckBox } from './AccountsCheckbox';

export enum PostInputStatusEnum {
  default = 'default',
  suggested = 'suggested',
  reverted = 'reverted',
  custom = 'custom',
  edit = 'edit',
}
const AccountsColumnWrapper = styled.div`
  float: left;
  width: 33.33%;
  border-right: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  padding-right: 16px;
  height: 100%;
`;

const ColumnImage = styled.div`
  display: flex;
  width: 100%;
  align-content: center;
`;
const InputSection = styled.div`
  padding-right: 24px;
  height: 200px;
`;

export type SocialAccountType = 'facebook' | 'instagram' | 'google' | 'tiktok' | '';

export interface GeneralPostProps {
  vin: string;
  accounts: AccountItem[];
  defaultText: string;
  setDefaultText: (description: string) => void;
  setSelectedAccounts: (accounts: string[]) => void;
  selectedAccounts: string[];
  images: string[];
  imageSelected: string[];
  setImageSelected: React.Dispatch<React.SetStateAction<string[]>>;
  setImagePath: (imagePath: string) => void;
}
export const GeneralPost: React.FC<GeneralPostProps> = ({
  accounts,
  defaultText,
  selectedAccounts,
  setSelectedAccounts,
  imageSelected,
  setImageSelected,
  setDefaultText,
  images,
  setImagePath,
  vin,
}) => {
  const handleChange = (checkedValue: CheckboxValueType[] = []) => {
    setSelectedAccounts(checkedValue as string[]);
  };
  const [postStatus, setPostStatus] = useState<PostInputStatusEnum>(PostInputStatusEnum.suggested);
  useEffect(() => {
    setPostStatus(PostInputStatusEnum.reverted);
  }, [defaultText]);

  return (
    <AccountsColumnWrapper>
      <LabelB>Placements</LabelB>
      <Spacer type="stack" size="xxs" />
      <AccountCheckBox accounts={accounts} selectedAccounts={selectedAccounts} onChange={handleChange} />
      <Spacer type="stack" size="xxs" />
      <LabelB>Choose image/video for your post</LabelB>
      <Spacer type="stack" size="xxs" />
      <ColumnImage>
        <SelectOrUploadImage
          images={images}
          imageSelected={imageSelected}
          setImagesSelected={setImageSelected}
          setImagePath={setImagePath}
        />
      </ColumnImage>
      <Spacer type="stack" size="s" />
      <LabelB>General Description for all Platforms</LabelB>
      <Spacer type="stack" size="xs" />
      <InputSection>
        <MentionInput
          {...{
            inputId: vin,
            height: '256px',
            postStatus,
            setPostStatus,
            defaultValue: defaultText,
            onChange: (val) => setDefaultText(val),
            addBorder: true,
          }}
        />
      </InputSection>
    </AccountsColumnWrapper>
  );
};

export default GeneralPost;
