import { gql, useQuery } from '@apollo/client';
import { CadenceOptions } from '../mutations/upsertOfferPostTemplate';

export interface OfferPostTemplate {
  id: string;
  locationId: string;
  archivedAt: boolean;
  cadence: CadenceOptions;
  couponCode?: string;
  termsAndConditions: string;
  link: string;
  title: string;
  details: string;
  updatedAt: string;
  imageUrl: string;
}

export interface GetOfferPostTemplatesResponse {
  getOfferPostTemplatesByLocationId: OfferPostTemplate[];
}

export interface GetOfferPostTemplatesParams {
  search?: string;
  cadence?: string;
  locationId: string;
}

export const GET_OFFER_TEMPLATES_BY_LOCATION = gql`
  query getOfferPostTemplatesByLocationId($locationId: String!, $search: String, $cadence: String) {
    getOfferPostTemplatesByLocationId(locationId: $locationId, search: $search, cadence: $cadence) {
      id
      locationId
      archivedAt
      cadence
      couponCode
      termsAndConditions
      link
      updatedAt
      title
      details
      imageUrl
    }
  }
`;

export function useGetOfferPostTemplates(params: GetOfferPostTemplatesParams) {
  const { data, error, loading, refetch } = useQuery<GetOfferPostTemplatesResponse, GetOfferPostTemplatesParams>(
    GET_OFFER_TEMPLATES_BY_LOCATION,
    {
      variables: params,
      fetchPolicy: 'cache-and-network',
    }
  );

  return {
    refetch,
    offerPostTemplates: data?.getOfferPostTemplatesByLocationId || [],
    error,
    loading,
  };
}
