import { gql } from '@apollo/client';

export interface ConnectFacebookPagesParams {
  pageIds: string[];
}

export interface ConnectFacebookPagesResponse {
  connectFacebookPages: { id: string; success: boolean };
}

export const CONNECT_FACEBOOK_PAGES = gql`
  mutation ConnectFacebookPages($pageIds: [String!]!) {
    connectFacebookPages(pageIds: $pageIds) {
      id
      success
    }
  }
`;
