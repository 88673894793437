import React, { useEffect, useImperativeHandle, useState } from 'react';

import * as Yup from 'yup';

import { useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

import { Controller, FieldError, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { MATCH_CURRENT_PASSWORD } from '../../../../graphql/query/matchCurrentPassword';
import { FormComponentProps, FormRef } from '../../../../models/form';
import { PasswordValidationResult, validatePassword, validationItems } from '../../../utils/ValidationCriteriaSchema';
import HelperMessage from '../../HelperMessage';
import { Spacer } from '../../Spacer';
import { PasswordInput } from '../../TextInput/TextInput.styled';
import ValidationCriteria from '../../ValidationCriteria';

const FromStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;

export interface ChangePasswordFormValue {
  oldPassword: string;
  newPassword: string;
}

interface ChangePasswordFormProps extends FormComponentProps<ChangePasswordFormValue> {}

export const ChangePasswordForm = React.forwardRef<FormRef<ChangePasswordFormValue>, ChangePasswordFormProps>(
  (props: ChangePasswordFormProps, ref) => {
    const [password] = useState(props.initialValues?.newPassword || '');
    const [validation, setValidation] = useState<PasswordValidationResult>(validatePassword(password));
    const [validateCurrentPassword, { data }] = useLazyQuery(MATCH_CURRENT_PASSWORD);

    const ValidationSchema = Yup.object().shape({
      oldPassword: Yup.string().default('').required('Missing current password'),
      newPassword: Yup.string()
        .default('')
        .test('newPasswordRules', 'Missing requirements', (value) => {
          if (value) {
            var validations = validatePassword(value);
            setValidation(validations);
            return validations.isValid;
          } else {
            setValidation({
              isValid: false,
              hasLowercase: false,
              hasMinLenght: false,
              hasNumber: false,
              hasUppercase: false,
            });
            return false;
          }
        })
        .required('New password required'),
    });

    const { handleSubmit, control, errors, formState, setValue, setError, clearErrors } =
      useForm<ChangePasswordFormValue>({
        resolver: yupResolver(ValidationSchema),
        mode: 'onChange',
      });

    const onSubmit = (data: ChangePasswordFormValue) => {
      props.onCompleted(data);
    };
    const submitHandler = handleSubmit(onSubmit);
    useImperativeHandle(
      ref,
      () => ({
        formState,
        submit: submitHandler,
      }),
      [formState, submitHandler]
    );

    useEffect(() => {
      if (data?.matchCurrentPassword?.valid) {
        clearErrors('oldPassword');
      } else {
        setError('oldPassword', { type: 'wrong-password', message: 'Wrong password' });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const validateOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.target.value) {
        validateCurrentPassword({ variables: { password: e.target.value } });
      }
    };

    const validateOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue('oldPassword', e.target.value);
      if (!e.target.value) {
        setError('oldPassword', { type: 'missing-password', message: 'Missing password' });
      } else {
        clearErrors('oldPassword');
      }
    };

    return (
      <FromStyled onSubmit={submitHandler}>
        <Controller
          render={() => {
            return (
              <PasswordInput
                topLabel={'CURRENT PASSWORD'}
                defaultValue={''}
                onBlur={validateOnBlur}
                onChange={validateOnChange}
                helperText={
                  !!errors['oldPassword'] ? (
                    <HelperMessage type="danger" message={(errors['oldPassword'] as FieldError)?.message} />
                  ) : null
                }
              />
            );
          }}
          control={control}
          name={'oldPassword'}
        />
        <Spacer type="stack" size="s" />
        <Controller
          as={PasswordInput}
          name={'newPassword'}
          control={control}
          topLabel={'NEW PASSWORD'}
          defaultValue={''}
          helperText={
            !!errors['newPassword'] ? (
              <HelperMessage type="danger" message={(errors['newPassword'] as FieldError)?.message} />
            ) : null
          }
        />
        <Spacer type="stack" size="s" />
        {validationItems.map((v, index) => (
          <React.Fragment key={v.key}>
            <ValidationCriteria label={v.label} valid={validation[v.key]} />
            {validationItems.length - 1 !== index && <Spacer type="stack" size="xs" />}
          </React.Fragment>
        ))}
      </FromStyled>
    );
  }
);
