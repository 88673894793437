import React, { useCallback, useState } from 'react';
import { DatePicker, InputNumber, Button, Typography, message } from 'antd';
import moment, { Moment } from 'moment';
import { saveMonthlySurvey } from '../../api/marketplace.api';
import styled from 'styled-components';

const { Title, Paragraph } = Typography;

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
`;

const ExtensionSalesSurvey: React.FC = () => {
  const [month] = useState<Moment>(moment().subtract(1, 'month'));
  const [carsSold, setCarsSold] = useState<number | null>(null);
  const [submitted, setSubmitted] = useState(false);

  const query = new URLSearchParams(window.location.search);
  const queryObject = Object.fromEntries(query.entries());
  const token = queryObject.token;

  const handleCarsSoldChange = (value: number | null) => {
    setCarsSold(value);
  };

  const handleSubmit = useCallback(async () => {
    if (carsSold !== null) {
      try {
        const monthFormatted = month.format('MM/YYYY');
        saveMonthlySurvey(token, monthFormatted, carsSold);
        setSubmitted(true);
      } catch (error) {
        message.error('Failed to submit the survey. Please try again.');
      }
    }
  }, [token, month, carsSold]);

  if (submitted) {
    return (
      <Main>
        <Title level={2}>Thank you!</Title>
      </Main>
    );
  }

  return (
    <Main>
      <div style={{ maxWidth: '400px', textAlign: 'center' }}>
        <Paragraph style={{ fontSize: '16px', marginBottom: '20px' }}>
          Hey there! We'd love to know how well the tool is working for you. Could you let us know how many cars you
          sold last month?
        </Paragraph>
        <Title level={3}>How many cars did you sell last month on Marketplace?</Title>
        {/* 
      // @ts-ignore */}
        <DatePicker disabled={true} picker="month" value={month} style={{ marginBottom: '16px', width: '100%' }} />
        <InputNumber
          min={0}
          value={carsSold}
          onChange={handleCarsSoldChange}
          placeholder="Cars sold"
          style={{ width: '100%', marginBottom: '16px' }}
        />
        <Button type="primary" onClick={handleSubmit} disabled={carsSold === null}>
          Submit
        </Button>
      </div>
    </Main>
  );
};

export default ExtensionSalesSurvey;
