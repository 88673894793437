import { gql } from '@apollo/client';

export interface PrioritizeOnMarketplaceParams {
  inventoryId: string;
  marketplacePriority: boolean;
}

export const PRIORITIZE_ON_MARKETPLACE = gql`
  mutation prioritizeOnMarketplace($inventoryId: String!, $marketplacePriority: Boolean!) {
    prioritizeOnMarketplace(inventoryId: $inventoryId, marketplacePriority: $marketplacePriority) {
      id
      marketplacePriority
    }
  }
`;
