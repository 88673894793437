import React from 'react';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../ui/Buttons/Button';
import { Spacer } from '../../ui/Spacer';
import { LabelB } from '../../ui/Text/Text.styled';

const AccountMenuWrapper = styled.div`
  padding: 24px 16px;
  width: 200px;

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 15px;
    right: 4px;
  }

  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0px;
  }

  .ant-collapse-content {
    text-align: left;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
    padding-top: 12px;
  }
`;

const QueueLocationMenuWrapper = styled(AccountMenuWrapper)`
  padding: 0px 16px;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
`;

interface QueueLocationMenuProps {
  locationName: string;
  organizationId: string;
  organizationName: string;
  locationId: string;
}
export const QueueLocationMenu: React.FC<QueueLocationMenuProps> = ({ locationName, organizationId, locationId }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(`/app/inventory/${organizationId}/${locationId}`);
  };
  return (
    <>
      <QueueLocationMenuWrapper>
        <LabelB>{locationName}</LabelB>
        <Spacer type="stack" size="s" />
        <ButtonWrapper>
          <Button btnType="secondary" size="small" onClick={handleClick}>
            View Inventory
          </Button>
        </ButtonWrapper>
      </QueueLocationMenuWrapper>
    </>
  );
};
