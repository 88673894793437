import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RadioChangeEvent, Table, message } from 'antd';
import Column from 'antd/lib/table/Column';
import styled from 'styled-components';
import { SideDrawerContext } from '../../../context/SideDrawerProvider';
import { createSortProps } from '../../../utils/table.utils';
import { Heading4 } from '../Headings/Headings.styled';
import IconButtonSimple from '../IconButtonSimple';
import SimpleCard from '../SimpleCard';
import { Spacer } from '../Spacer';
import { LabelB, LabelR } from '../Text/Text.styled';
import { RadioGroup, RadioGroupOption } from '../RadioGroup/RadioGroup';
import { ActiveState } from '../../../pages/OrganizationAccounts/OrganizationAccounts';
import { copyTextToClipboard } from '../../../utils/clipboard.utils';
import { useOrganizationsByPartnerId } from '../../../graphql/query/organizationsByPartnerId';
import { OrganizationItem } from '../../../graphql/query/organizationList';
import { Link } from 'react-router-dom';

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 32px 0 16px 0;
`;
const OrganizationEmptyState = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 2px solid #f4f3f6;
  width: 100%;
  text-align: left;
`;

const OrganizationHeaderRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const CardStyled = styled(SimpleCard)`
  width: 100%;
  .card {
    border: none !important;
  }
`;

interface OrganizationTableProps {
  organizations: OrganizationItem[];
  isActive: boolean;
}

export const OrganizationTable: React.FC<OrganizationTableProps> = ({ organizations }) => {
  return (
    <CardStyled>
      <Table className="borderless-header compact-table" dataSource={organizations} rowKey="id" pagination={false}>
        <Column<OrganizationItem>
          title="Name"
          dataIndex="name"
          key="name"
          width="100"
          {...createSortProps<OrganizationItem>({
            type: 'string',
            field: 'name',
            multiple: 3,
          })}
          render={(name, record) => (
            <>
              <LabelB>
                {/* // 
                @ts-ignore */}
                <Link to={`/app/organization/${record.id}`}>{name}</Link>
              </LabelB>
              <Spacer type="stack" size="xxs" />
              <LabelR>
                {record.id}
                <IconButtonSimple
                  style={{ marginLeft: '5px' }}
                  icon="clipboard"
                  size="small"
                  onClick={() => {
                    copyTextToClipboard(record.id);
                    message.info('Organization ID copied to clipboard');
                  }}
                />
              </LabelR>
            </>
          )}
        />
      </Table>
    </CardStyled>
  );
};

interface PartnerOrganizationsListProps {
  partnerId: string;
  isActive: boolean;
}

export const PartnerOrganizations: React.FC<PartnerOrganizationsListProps> = ({ partnerId, isActive }) => {
  const { sideDrawerNames, toggleSideDrawer } = useContext(SideDrawerContext);
  const [organizationState, setOrganizationState] = useState<ActiveState>(ActiveState.ACTIVE);
  const { organizations, refetch } = useOrganizationsByPartnerId(partnerId);

  const isEmpty = !organizations?.length;

  const organizationStateOptions: RadioGroupOption[] = [
    { label: 'Active', value: ActiveState.ACTIVE },
    { label: 'Archived', value: ActiveState.INACTIVE },
  ];

  useEffect(() => {
    setOrganizationState(ActiveState.ACTIVE);
  }, [partnerId, setOrganizationState]);

  const handleChangeCondition = useCallback(
    async ({ target: { value } }: RadioChangeEvent) => {
      setOrganizationState(value);
      if (value === ActiveState.INACTIVE) refetch({ partnerId, active: false });
      else refetch({ partnerId, active: true });
    },
    [partnerId, refetch]
  );

  return (
    <SectionContainer>
      <OrganizationHeaderRow>
        <Heading4>Partner Organizations</Heading4>

        <IconButtonSimple
          disabled={!isActive}
          icon="plus"
          size="medium"
          onClick={() => toggleSideDrawer(sideDrawerNames.newOrganization)}
        />
      </OrganizationHeaderRow>
      <Spacer type="stack" size={'xs'}></Spacer>
      <RadioGroup
        value={organizationState}
        defaultValue={ActiveState.ACTIVE}
        onChange={handleChangeCondition}
        options={organizationStateOptions}
      ></RadioGroup>
      <Spacer type="stack" size={'xs'}></Spacer>
      {isEmpty && <OrganizationEmptyState>No organizations added</OrganizationEmptyState>}
      {!isEmpty && <OrganizationTable isActive={isActive} organizations={organizations} />}
    </SectionContainer>
  );
};
