import React from 'react';

import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Button as ButtonStyle } from '../../../components/ui/Buttons/Button';
import { StyledForm, StyledSubmitWrapper } from '../../../components/ui/FormLogin';
import { Spacer } from '../../../components/ui/Spacer';
import TextField from '../../../components/ui/TextField';
import { FormComponentProps } from '../../../models/form';

const Button = styled(ButtonStyle)`
  width: 100%;
`;

export interface ResetPasswordFormValue {
  password: string;
  confirmPassword: string;
}

interface ResetPasswordFormProps extends FormComponentProps<ResetPasswordFormValue> {}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {
  const ValidationSchema = Yup.object().shape({
    password: Yup.string()
      .default('')
      .test('newPasswordRules', 'Missing requirements', (value) => {
        return Boolean(value);
      })
      .required('Password required.'),
    confirmPassword: Yup.string()
      .default('')
      .required('Confirm password required.')
      .oneOf([Yup.ref('password')], "Passwords don't match"),
  });

  const { handleSubmit, control, errors, formState } = useForm<ResetPasswordFormValue>({
    resolver: yupResolver(ValidationSchema),
    shouldFocusError: true,
    mode: 'onChange',
  });
  const onSubmit = (data: ResetPasswordFormValue) => props.onCompleted(data);

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <TextField
        type="password"
        name="password"
        defaultValue=""
        placeholder="New Password"
        control={control}
        errors={errors}
      />
      <Spacer type="stack" size="xs" />
      <TextField
        type="password"
        name="confirmPassword"
        placeholder="Confirm New Password"
        defaultValue=""
        control={control}
        errors={errors}
      />
      <Spacer type="stack" size="s" />
      <Spacer type="stack" size="xs" />
      <StyledSubmitWrapper>
        <Button btnType="primary" type="submit" disabled={formState.touched && !formState.isValid}>
          RESET PASSWORD
        </Button>
      </StyledSubmitWrapper>
    </StyledForm>
  );
};

export default ResetPasswordForm;
