import React from 'react';

import styled from 'styled-components';

import { SideDrawerProvider } from '../../../context/SideDrawerProvider';
import { useUserCountByOrgId } from '../../../graphql/query/userCountByOrgId';
import { LabelBodyB } from '../../ui/Text/Text.styled';
import UserNavigationLink from './UserNavigationLink';
import { useParams } from 'react-router-dom';

const UsersLayoutWrapper = styled.section`
  height: 100%;
  display: flex;
`;
const SideContent = styled.aside`
  height: 100%;
  width: 200px;
  flex-shrink: 0;
  border-right: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
`;
const LayoutContent = styled.section`
  height: 100%;
  flex-grow: 1;
`;

const UserNavigationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
`;

const UsersLayout: React.FC = ({ children }) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { userCount } = useUserCountByOrgId(organizationId || '');
  return (
    <SideDrawerProvider>
      <UsersLayoutWrapper>
        <SideContent>
          <UserNavigationHeader>
            <LabelBodyB>People</LabelBodyB>
          </UserNavigationHeader>
          <UserNavigationLink icon="users" label="Users" count={userCount} href="/app/users" />
        </SideContent>
        <LayoutContent>{children}</LayoutContent>
      </UsersLayoutWrapper>
    </SideDrawerProvider>
  );
};

export default UsersLayout;
