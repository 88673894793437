import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { OrganizationLayout } from '../../components/Layout/OrganizationLayout';
import { CustomerNotes } from '../../components/ui/CustomerNotes';
import { displayGqlErrors } from '../../components/ui/ErrorList/ErrorList';
import { Heading4 } from '../../components/ui/Headings/Headings.styled';
import OrganizationCardHeader from '../../components/ui/OrganizationCardHeader';
import OrganizationGeneralCard from '../../components/ui/OrganizationGeneralCard';
import { OrganizationLocations } from '../../components/ui/OrganizationLocations';
import OrganizationTab from '../../components/ui/OrganizationTab';
import OrganizationUserList from '../../components/ui/OrganizationUserList';
import { RadioGroup, RadioGroupOption } from '../../components/ui/RadioGroup/RadioGroup';
import { snackbar } from '../../components/ui/Snackbar';
import { Spacer } from '../../components/ui/Spacer';
import { SearchInput } from '../../components/ui/TextInput/SearchInput.styled';
import { SideDrawerContext } from '../../context/SideDrawerProvider';
import {
  TOGGLE_ORGANIZATION_STATUS,
  ToggleOrganizationStatusRequest,
  ToggleOrganizationStatusResponse,
} from '../../graphql/mutations/toggleOrganizationStatus';
import {
  ORGANIZATION_LIST,
  OrganizationItem,
  OrganizationListRequest,
  OrganizationListResponse,
} from '../../graphql/query/organizationList';
import { SideNavigation } from '../../components/Menu/SideNavigation';
import { Partner } from '../../graphql/query/partners';
import { sideNavConfig } from '../../components/Menu/SideNavigation/sideNavConfig';
import { USERS_BY_ORG_ID, UsersByOrgIdParams, UsersByOrgIdResponse } from '../../graphql/query/usersByOrganizationId';

const OrganizationMainView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`;

const OrganizationMainViewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
`;

const NoOrgContainer = styled.div`
  text-align: left;
  margin: 40px;
`;
const OptionsHeader = styled.div`
  text-align: center;
  margin: 15px;
  margin-top: 0px;
`;

const BodyWrapper = styled.div`
  display: flex;
`;

const DetailColumn = styled.div`
  flex-grow: 1;
  padding: 0 40px;
`;

const UserListColumn = styled.div`
  padding-top: 16px;
`;

export interface CurrentOrganizationsTypes {
  active?: boolean;
  isActive: boolean;
  imageSrc?: string;
  isNew: boolean;
  orgName: string;
  orgCityState: string;
  domain: string;
  id: string;
  partner?: Partner;
}

export enum ActiveState {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  PARTNER = 'Partner',
}
const { confirm } = Modal;

const OrganizationAccounts: React.FC = () => {
  const { data, refetch } = useQuery<OrganizationListResponse, OrganizationListRequest>(ORGANIZATION_LIST, {
    variables: { onlyActive: true },
  });
  const [orgState, setOrgState] = useState<ActiveState>(ActiveState.ACTIVE);
  const sideDrawerContext = useContext(SideDrawerContext);
  const organizations = useMemo(() => data?.organizations || [], [data?.organizations]);
  const [currentOrganization, setCurrentOrganization] = useState<OrganizationItem | undefined>(undefined);
  const [toggleOrganizationStatus] = useMutation<ToggleOrganizationStatusResponse, ToggleOrganizationStatusRequest>(
    TOGGLE_ORGANIZATION_STATUS
  );
  const history = useHistory();

  const { organizationId: selectedOrganizationId } = useParams<{ organizationId: string }>();

  useEffect(() => {
    console.log('in user effect');
    let organization: OrganizationItem | undefined = undefined;
    if (organizations.length > 0 && !selectedOrganizationId) {
      organization = organizations[0];
    } else if (organizations.length && selectedOrganizationId) {
      organization = organizations.find((item) => selectedOrganizationId === item.id);
    }

    organization = organization || organizations[0];
    if (organization) {
      setCurrentOrganization(organization);
      history.push(`/app/organization/${organization.id}`);
    }
  }, [organizations, history, selectedOrganizationId]);

  const { data: userData } = useQuery<UsersByOrgIdResponse, UsersByOrgIdParams>(USERS_BY_ORG_ID, {
    variables: {
      organizationId: selectedOrganizationId,
      onlyActive: true,
    },
  });
  const organizationUsers = userData?.usersByOrgId || [];

  const currentOrganizations: CurrentOrganizationsTypes[] =
    organizations?.map((org) => ({
      isSelected: true,
      isNew: true,
      isActive: org.isActive,
      orgName: org.name,
      orgCityState: org.headquarters,
      domain: org.domain,
      id: org.id,
      imageSrc: org.avatar?.signedUrl,
      partner: org.partner,
    })) || [];

  const emptyList = !organizations?.length;

  const changeCurrentOrganization = (id: string) => {
    history.push(`/app/organization/${id}`);
  };

  const editOrganizationHandler = () => {
    sideDrawerContext.toggleSideDrawer(sideDrawerContext.sideDrawerNames.editOrganization, currentOrganization);
  };

  const modeGroupOptions: RadioGroupOption[] = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
    { label: 'Partner', value: 'Partner' },
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (data) {
      const onlyActive = ActiveState.ACTIVE === orgState || ActiveState.PARTNER === orgState;
      refetch({
        onlyActive,
        partnersOnly: ActiveState.PARTNER === orgState,
        name: value,
      });
    }
  };

  const handleChangeCondition = ({ target: { value } }: RadioChangeEvent) => {
    setOrgState(value);
    if (value === ActiveState.INACTIVE) {
      refetch({ onlyActive: false, partnersOnly: false });
    } else if (value === ActiveState.PARTNER) {
      console.log('fetch partner');
      refetch({ onlyActive: true, partnersOnly: true });
    } else {
      refetch({ onlyActive: true, partnersOnly: false });
    }
  };

  const handleToggleOrganizationStatus = async () => {
    if (currentOrganization) {
      const message = currentOrganization.isActive ? 'Archived' : 'Unarchived';
      try {
        const success = await toggleOrganizationStatus({
          variables: { organizationId: currentOrganization.id },
        });
        if (success) {
          snackbar.success({
            message: `Organization ${message}`,
          });
          if (organizations.length > 1 && orgState !== ActiveState.ACTIVE) {
            setCurrentOrganization(organizations.find((item) => selectedOrganizationId === item.id));
          }
        } else {
          snackbar.error({
            message: `Organization not ${message}`,
          });
        }
      } catch (error) {
        Sentry.captureException(error);
        displayGqlErrors(error, `Unable to ${message}`);
      }
    }
  };

  const handleArchiveOrganization = async () => {
    const actionName = currentOrganization?.isActive ? 'Archive' : 'Unarchive';
    confirm({
      title: `${actionName} Organization`,
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: `YES, ${actionName}`,
      content: `Are you sure you want to ${actionName} this Organization`,
      onOk() {
        handleToggleOrganizationStatus();
      },
    });
  };

  const navigationOptions = sideNavConfig(selectedOrganizationId);

  const tagLabels = currentOrganization?.partner
    ? [{ color: 'viridianGreen', tagText: currentOrganization.partner.name }]
    : [];

  return (
    <OrganizationLayout
      leftContent={
        <>
          <OptionsHeader>
            <SearchInput placeholder="Search" onChange={handleChange} />
            <Spacer type="stack" size="xs" />
            <RadioGroup
              size="middle"
              defaultValue={ActiveState.ACTIVE}
              onChange={handleChangeCondition}
              options={modeGroupOptions}
            ></RadioGroup>
            {/* <Filter onChange={handleCheckBox}>Show also archived</Filter> */}
          </OptionsHeader>
          {currentOrganizations?.map((organization) => (
            <React.Fragment key={organization?.id}>
              <OrganizationTab
                onClick={() => changeCurrentOrganization(organization?.id)}
                active={selectedOrganizationId === organization.id}
                imageSrc={organization?.imageSrc}
                orgName={organization.orgName}
                orgCityState={organization.orgCityState}
                organizationId={organization?.id}
                partner={organization.partner}
              ></OrganizationTab>

              <Spacer type="stack" size="xs" />
            </React.Fragment>
          ))}
        </>
      }
    >
      {emptyList ? (
        <NoOrgContainer>
          <Heading4>No organizations.</Heading4>
          <Heading4>Create one to get started.</Heading4>
        </NoOrgContainer>
      ) : (
        currentOrganization && (
          <OrganizationMainViewWrapper>
            <SideNavigation config={navigationOptions} />
            <OrganizationMainView>
              <OrganizationCardHeader
                companyName={currentOrganization?.name}
                cityState={currentOrganization?.headquarters}
                imageSrc={currentOrganization.avatar?.signedUrl || ''}
                onEditClick={editOrganizationHandler}
                isActive={currentOrganization.isActive}
                toggleOrganizationStatus={handleArchiveOrganization}
                partner={currentOrganization.partner}
                tagLabels={tagLabels}
              ></OrganizationCardHeader>
              <BodyWrapper>
                <DetailColumn>
                  <OrganizationGeneralCard
                    isActive={currentOrganization.isActive}
                    website={currentOrganization.domain}
                    headquartersCityState={currentOrganization?.headquarters}
                    createdAt={currentOrganization.createdAt}
                    archivedAt={currentOrganization.archivedAt}
                  />
                  <OrganizationLocations
                    isActive={currentOrganization.isActive}
                    organizationId={currentOrganization.id}
                    users={organizationUsers}
                  />
                  <UserListColumn>
                    <OrganizationUserList isActive={currentOrganization.isActive} users={organizationUsers} />
                  </UserListColumn>
                  <CustomerNotes organizationId={currentOrganization.id} />
                </DetailColumn>
              </BodyWrapper>
            </OrganizationMainView>
          </OrganizationMainViewWrapper>
        )
      )}
    </OrganizationLayout>
  );
};

export default OrganizationAccounts;
