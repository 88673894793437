import React from 'react';
import { DashboardFilter } from '../../../../pages/Dashboard/hooks/useFilters';
import { SocialAccount } from '../../VehicleColumn/modeViews/VehicleSocialAccounts';
import { CardStyled } from './Components.styles';
import { useGetCampaignMetrics } from '../../../../graphql/query/getCampaignMetrics';
import { Table } from 'antd';
import Column from 'antd/lib/table/Column';

interface CampaignReportProps {
  dashboardFilters: DashboardFilter;
}

export const CampaignReport: React.FC<CampaignReportProps> = ({ dashboardFilters }) => {
  const { campaignMetrics } = useGetCampaignMetrics({
    campaignMetricsRequest: {
      organizationIds: dashboardFilters.organizationIds,
      startDate: dashboardFilters.dateRange.startDate,
      endDate: dashboardFilters.dateRange.endDate,
      platform: SocialAccount.Facebook,
      locationIds: dashboardFilters.locationIds,
    },
  });

  if (campaignMetrics.length === 0) return null;

  return (
    <CardStyled>
      <Table pagination={false} className="wide-table" dataSource={campaignMetrics} rowKey="platform">
        <Column title="Platform" dataIndex="platform" key="platform" />
        <Column title="Impressions" dataIndex="impressions" key="impressions" />
        <Column title="Clicks" dataIndex="clicks" key="clicks" />
        <Column title="Cost per Click" dataIndex="costPerClick" key="costPerClick" />
        <Column title="Leads" dataIndex="leads" key="leads" />
        <Column title="Cost per Lead" dataIndex="costPerLead" key="costPerLead" />
        <Column title="Spend" dataIndex="spend" key="spend" />
      </Table>
    </CardStyled>
  );
};
