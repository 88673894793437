import { gql } from '@apollo/client';

export interface PublishVideoParams {
  locationId: string;
  videoUrl: string;
  inventoryId: string;
  vin: string;
  caption: string;
}
export interface PublishVideoRequest {
  input: PublishVideoParams;
}

export const PUBLISH_VIDEO = gql`
  mutation ($input: PublishVideoRequest!) {
    publishVideo(input: $input)
  }
`;
