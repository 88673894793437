import React from 'react';

import { Control, Controller, FieldError, FieldErrors, FieldValues } from 'react-hook-form';

import HelperMessage from '../HelperMessage';
import { TextInputButton } from '../TextInput/TextInput.styled';

interface TextFieldProps<FormValue extends FieldValues> {
  name: keyof FormValue & string;
  label?: string;
  type?: string;
  defaultValue?: string | number;
  placeholder?: string;
  control: Control<Record<string, any>>;
  errors: FieldErrors<FormValue>;
  disabled?: boolean;
  action?: () => void;
  textAction?: string;
  disableAction: boolean;
}

function TextButtonActionField<FormValue extends FieldValues = FieldValues>(
  props: TextFieldProps<FormValue>
): React.ReactElement<TextFieldProps<FormValue>> {
  const { name, defaultValue, control, type, label, errors, placeholder, disabled, action, textAction, disableAction } =
    props;
  return (
    <Controller
      disabled={disabled}
      as={TextInputButton}
      type={type}
      name={name}
      control={control}
      topLabel={label}
      defaultValue={defaultValue}
      placeholder={placeholder}
      textActionButton={textAction}
      actionButton={action}
      showAction={disableAction}
      helperText={
        (errors?.[name] && <HelperMessage type="danger" message={(errors[name] as FieldError)?.message} />) || null
      }
    />
  );
}
TextButtonActionField.defaultProps = {
  label: '',
  type: 'text',
};

export default TextButtonActionField;
