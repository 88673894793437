import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { OrganizationLayout } from '../../components/Layout/OrganizationLayout';
import { displayGqlErrors } from '../../components/ui/ErrorList/ErrorList';
import { Heading4 } from '../../components/ui/Headings/Headings.styled';
import OrganizationCardHeader from '../../components/ui/OrganizationCardHeader';
import OrganizationTab from '../../components/ui/OrganizationTab';
import { RadioGroup, RadioGroupOption } from '../../components/ui/RadioGroup/RadioGroup';
import { snackbar } from '../../components/ui/Snackbar';
import { Spacer } from '../../components/ui/Spacer';
import { SearchInput } from '../../components/ui/TextInput/SearchInput.styled';
import { SideDrawerContext } from '../../context/SideDrawerProvider';
import { Partner, usePartnerQuery } from '../../graphql/query/partners';
import {
  TOGGLE_PARTNER_STATUS,
  TogglePartnerStatusRequest,
  TogglePartnerStatusResponse,
} from '../../graphql/mutations/togglePartnerStatus';
import { PartnerOrganizations } from '../../components/ui/PartnerOrganizations';
import PartnerUserList from '../../components/ui/PartnerUserList';
import { SideNavigation } from '../../components/Menu/SideNavigation';
import { partnerSideNavConfig } from '../../components/Menu/SideNavigation/sideNavConfig';

const PartnerMainView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`;

const PartnerMainViewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
`;

const NoOrgContainer = styled.div`
  text-align: left;
  margin: 40px;
`;
const OptionsHeader = styled.div`
  text-align: center;
  margin: 15px;
  margin-top: 0px;
`;

const BodyWrapper = styled.div`
  display: flex;
`;

const DetailColumn = styled.div`
  flex-grow: 1;
  padding: 40px;
`;

type CurrentPartnerType = Partner & { isSelected: boolean; isNew: boolean };

export enum ActiveState {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}
const { confirm } = Modal;

/**
 * TODO:
 * Make this all partner related
 * Update queries, mutations, etc. to reflect this
 *
 */

const PartnerAccounts: React.FC = () => {
  // TODO: Update this query
  const { partners, refetch } = usePartnerQuery(true);
  const [partnerState, setPartnerStatus] = useState<ActiveState>(ActiveState.ACTIVE);
  const sideDrawerContext = useContext(SideDrawerContext);
  const [currentPartner, setCurrentPartner] = useState<Partner | undefined>(undefined);
  const [togglePartnerStatus] = useMutation<TogglePartnerStatusResponse, TogglePartnerStatusRequest>(
    TOGGLE_PARTNER_STATUS
  );
  const history = useHistory();

  const { partnerId: selectedPartnerId } = useParams<{ partnerId: string }>();

  useEffect(() => {
    if (partners.length > 0 && !selectedPartnerId) {
      const defaultPartnerId = partners[0].id;
      history.push(`/app/partner/${defaultPartnerId}`);
      setCurrentPartner(partners.find((item) => defaultPartnerId === item.id));
    } else if (partners.length && selectedPartnerId) {
      setCurrentPartner(partners.find((item) => selectedPartnerId === item.id));
    }
  }, [partners, history, selectedPartnerId]);

  const currentPartners: CurrentPartnerType[] =
    partners?.map((partner) => ({
      isSelected: true,
      isNew: true,
      ...partner,
    })) || [];

  const emptyList = !partners?.length;

  const changeCurrentPartner = (id: string) => {
    history.push(`/app/partner/${id}`);
  };

  const editPartnerHandler = () => {
    sideDrawerContext.toggleSideDrawer(sideDrawerContext.sideDrawerNames.editPartner, currentPartner);
  };

  const modeGroupOptions: RadioGroupOption[] = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (partners) {
      const onlyActive = ActiveState.ACTIVE === partnerState;
      refetch({
        onlyActive,
        name: value,
      });
    }
  };

  const handleChangeCondition = ({ target: { value } }: RadioChangeEvent) => {
    setPartnerStatus(value);
    if (value === ActiveState.INACTIVE) {
      refetch({ onlyActive: false });
    } else {
      refetch({ onlyActive: true });
    }
  };

  const handleTogglePartnerStatus = async () => {
    if (currentPartner) {
      const message = currentPartner.isActive ? 'Archived' : 'Unarchived';
      try {
        const success = await togglePartnerStatus({
          variables: { partnerId: currentPartner.id },
        });
        if (success) {
          snackbar.success({
            message: `Partner ${message}`,
          });
          if (partners.length > 1 && partnerState !== ActiveState.ACTIVE) {
            setCurrentPartner(partners.find((item) => selectedPartnerId === item.id));
          }
        } else {
          snackbar.error({
            message: `Partner not ${message}`,
          });
        }
      } catch (error) {
        Sentry.captureException(error);
        displayGqlErrors(error, `Unable to ${message}`);
      }
    }
  };

  const handleArchivePartner = async () => {
    const actionName = currentPartner?.isActive ? 'Archive' : 'Unarchive';
    confirm({
      title: `${actionName} Partner`,
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: `YES, ${actionName}`,
      content: `Are you sure you want to ${actionName} this partner`,
      onOk() {
        handleTogglePartnerStatus();
      },
    });
  };

  const navigationOptions = partnerSideNavConfig(selectedPartnerId);

  const tagLabels = currentPartner?.type ? [{ color: 'viridianGreen', tagText: currentPartner.type }] : [];

  return (
    <OrganizationLayout
      title="Partners"
      leftContent={
        <>
          <OptionsHeader>
            <SearchInput placeholder="Search" onChange={handleChange} />
            <Spacer type="stack" size="xs" />
            <RadioGroup
              size="middle"
              defaultValue={ActiveState.ACTIVE}
              onChange={handleChangeCondition}
              options={modeGroupOptions}
            ></RadioGroup>
            {/* <Filter onChange={handleCheckBox}>Show also archived</Filter> */}
          </OptionsHeader>
          {currentPartners?.map((partner) => (
            <React.Fragment key={partner?.id}>
              <OrganizationTab
                onClick={() => changeCurrentPartner(partner?.id)}
                active={selectedPartnerId === partner.id}
                orgName={partner.name}
                orgCityState=""
                organizationId={partner.id}
              ></OrganizationTab>
              <Spacer type="stack" size="xs" />
            </React.Fragment>
          ))}
        </>
      }
    >
      {emptyList ? (
        <NoOrgContainer>
          <Heading4>No partners.</Heading4>
          <Heading4>Create one to get started.</Heading4>
        </NoOrgContainer>
      ) : (
        currentPartner && (
          <PartnerMainViewWrapper>
            <SideNavigation config={navigationOptions} />
            <PartnerMainView>
              <OrganizationCardHeader
                companyName={currentPartner?.name}
                cityState={''}
                imageSrc={''}
                onEditClick={editPartnerHandler}
                isActive={currentPartner.isActive}
                toggleOrganizationStatus={handleArchivePartner}
                partner={currentPartner}
                tagLabels={tagLabels}
              ></OrganizationCardHeader>
              <BodyWrapper>
                <DetailColumn>
                  <PartnerUserList isActive={currentPartner.isActive} partnerId={currentPartner?.id} />
                  <PartnerOrganizations isActive={currentPartner.isActive} partnerId={currentPartner.id} />
                </DetailColumn>
              </BodyWrapper>
            </PartnerMainView>
          </PartnerMainViewWrapper>
        )
      )}
    </OrganizationLayout>
  );
};

export default PartnerAccounts;
