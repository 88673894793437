import { gql, useQuery } from '@apollo/client';

import { PostMetric } from './inventoryByLocationId';
import { PostTypeEnum } from './postHistoryByLocationId';

export interface DashboardBaseInput {
  platforms: string[];
  postTypes: PostTypeEnum[];
  organizationIds: string[];
  locationIds: string[];
  startDate: Date;
  endDate: Date;
}
interface TopPostRequest extends DashboardBaseInput {}
interface GetTopPostResponse {
  getTopPosts: PostMetric[];
}
interface GetTopPostParams {
  topPostRequest: TopPostRequest;
}

const GET_TOP_POSTS = gql`
  query getTopPosts($topPostRequest: TopPostRequest!) {
    getTopPosts(input: $topPostRequest) {
      postId
      inventoryId
      source
      status
      statusMessage
      date
      clicks
      views
      imageUrl
      title
      owner
      link
    }
  }
`;

export function useGetTopPost(params: GetTopPostParams) {
  const { data, error, loading } = useQuery<GetTopPostResponse, GetTopPostParams>(GET_TOP_POSTS, {
    variables: params,
    fetchPolicy: 'cache-and-network',
  });
  return {
    getTopPosts: data?.getTopPosts || [],
    error,
    loading,
  };
}
