import React from 'react';

import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { GooglePosts } from '../pages/GooglePostsPage';

const GoogleInboxRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/posts`} component={GooglePosts} />
      <Redirect to={`${path}/posts`} />
    </Switch>
  );
};

export default GoogleInboxRoutes;
