import React, { useCallback } from 'react';
import { Card, Typography, Row, Col, Spin, Divider, Layout, Button, List, Tag, Space, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';
import { FaRegClock, FaSyncAlt } from 'react-icons/fa';
import { REFRESH_LOCATION_HEALTH_SUMMARY } from '../../../graphql/mutations/refreshLocationHealthSummary';
import * as Sentry from '@sentry/react';
import { displayGqlErrors } from '../../../components/ui/ErrorList';
import NotesSection from '../../../components/ui/Notes/NotesSection';
import { UPDATE_LOCATION_HEALTH_SUMMARY } from '../../../graphql/mutations/updateLocationHealthSummary';
import confirm from 'antd/lib/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
const { Title, Text, Paragraph } = Typography;
const { Content } = Layout;

type LocationHealthSummaryResponse = {
  locationHealthSummary: LocationHealthSummary;
};

interface PointOfContact {
  name: string;
  email: string;
  phone: string;
}

interface HealthReason {
  id: string;
  severity: string;
  type: string;
  name: string;
  description: string;
  dismissible: boolean;
  dismissedAt: string;
}

type LocationHealthSummary = {
  organizationName: string;
  locationName: string;
  poc?: PointOfContact;
  healthLevel: string;
  lastRefreshedAt: string;
  snoozedUntil?: string | null;
  notes?: string | null;
  healthReasons: HealthReason[];
  postingUsers: {
    id: string;
    name: string;
    healthStatus: {
      health: string;
      reasons: string;
      updatedAt: string;
    };
  }[];
};

// GraphQL Query
const LOCATION_HEALTH_SUMMARY = gql`
  query locationHealthSummary($locationId: String!) {
    locationHealthSummary(locationId: $locationId) {
      organizationName
      locationName
      poc {
        name
        email
        phone
      }
      healthLevel
      lastRefreshedAt
      snoozedUntil
      notes
      healthReasons {
        id
        severity
        type
        name
        description
        dismissible
        dismissedAt
      }
      postingUsers {
        id
        name
        healthStatus {
          health
          reasons
          updatedAt
        }
      }
    }
  }
`;

const LocationHealthSummary: React.FC = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const { data, loading, error } = useQuery<LocationHealthSummaryResponse>(LOCATION_HEALTH_SUMMARY, {
    variables: { locationId },
  });

  const [refreshLocationHealthSummary] = useMutation(REFRESH_LOCATION_HEALTH_SUMMARY, {
    refetchQueries: [LOCATION_HEALTH_SUMMARY],
  });
  const [updateLocationHealthSummary] = useMutation(UPDATE_LOCATION_HEALTH_SUMMARY);

  const onRefreshHealthSummary = useCallback(async () => {
    try {
      await refreshLocationHealthSummary({ variables: { locationId } });
    } catch (e) {
      Sentry.captureException(error);
      displayGqlErrors(error, `Unable to update token status`);
    }
  }, [locationId]);

  const onSaveNotes = useCallback(
    async (content: string) => {
      try {
        await updateLocationHealthSummary({
          variables: {
            input: {
              locationId,
              notes: content,
            },
          },
        });
      } catch (e) {
        Sentry.captureException(error);
        displayGqlErrors(error, `Unable to update token status`);
      }
    },
    [locationId]
  );

  const onConfirmSnooze = useCallback(async () => {
    try {
      await updateLocationHealthSummary({
        variables: {
          input: {
            locationId,
            snoozedUntil: moment().add(3, 'days').toISOString(),
          },
        },
      });
    } catch (e) {
      Sentry.captureException(error);
      displayGqlErrors(error, `Unable to update token status`);
    }
  }, [locationId]);

  const onSnoozeClick = useCallback(async () => {
    confirm({
      title: 'Are you sure you want to snooze this location?',
      icon: <ExclamationCircleOutlined />,
      okText: `Yes`,
      content: 'Snoozing a location will hide it from the dashboard for 3 days.',
      onOk() {
        onConfirmSnooze();
      },
    });
  }, [locationId]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <Text type="danger">Error loading data</Text>
      </div>
    );
  }

  if (!data?.locationHealthSummary) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <Text type="danger">No data available</Text>
      </div>
    );
  }

  const {
    organizationName,
    locationName,
    poc,
    healthLevel,
    lastRefreshedAt,
    notes,
    healthReasons,
    postingUsers,
    snoozedUntil,
  } = data.locationHealthSummary;

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#fff' }}>
      <Content>
        <LocationCard
          {...{
            organizationName,
            locationName,
            poc,
            healthLevel,
            lastRefreshedAt,
            onRefreshClick: onRefreshHealthSummary,
            onSnoozeClick: onSnoozeClick,
            snoozedUntil,
          }}
        />

        <PageSection>
          <Title level={4} style={{ textAlign: 'left', color: 'rgb(74, 79, 137)' }}>
            Notes
          </Title>
          <NotesSection style={{ width: '100%' }} initialValue={notes ?? undefined} onSave={onSaveNotes} />
        </PageSection>
        <HealthReasonsSection healthReasons={healthReasons} onDismiss={() => console.log('TODO')} />

        <div style={{ marginBottom: '24px' }}>
          <Title level={4} style={{ color: 'rgb(74, 79, 137)' }}>
            Posting Users
          </Title>
          <List
            bordered
            dataSource={postingUsers}
            renderItem={(user) => (
              <List.Item>
                <Space direction="vertical">
                  <Text strong>{user.name}</Text>
                  <Text>Health Level: {user.healthStatus.health}</Text>
                  <Text>Updated At: {new Date(user.healthStatus.updatedAt).toLocaleString()}</Text>
                  <Text>Reasons: {user.healthStatus.reasons}</Text>
                </Space>
              </List.Item>
            )}
          />
        </div>

        <Divider />

        <div style={{ marginBottom: '24px' }}>
          <Title level={4} style={{ color: 'rgb(74, 79, 137)' }}>
            Health Notes
          </Title>
          <Paragraph>{notes || 'No notes available for this location.'}</Paragraph>
        </div>
        <Divider />
      </Content>
    </Layout>
  );
};

interface LocationCardProps {
  organizationName: string;
  locationName: string;
  poc?: PointOfContact;
  healthLevel: string;
  lastRefreshedAt: string | number | Date;
  snoozedUntil?: string | number | Date | null;
  onRefreshClick: () => void;
  onSnoozeClick: () => void;
}

const healthColorMap: Record<string, string> = {
  red: 'red',
  yellow: 'gold',
  green: 'green',
};

const LocationCard: React.FC<LocationCardProps> = ({
  organizationName,
  locationName,
  poc,
  healthLevel,
  lastRefreshedAt,
  snoozedUntil,
  onRefreshClick,
  onSnoozeClick,
}) => {
  return (
    <PageSection
      title={
        <Row justify="space-between" align="middle" style={{ textAlign: 'left' }}>
          <div>
            <Title level={4} style={{ marginBottom: 0, color: 'rgb(74, 79, 137)' }}>
              {locationName} <Tag color={healthColorMap[healthLevel.toLowerCase()] || 'default'}>{healthLevel}</Tag>
            </Title>
            <Text style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)' }}>
              Last Refreshed: {new Date(lastRefreshedAt).toLocaleString()}
            </Text>
            <br></br>
            {snoozedUntil && (
              <Text style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)' }}>
                Snoozed Until: {new Date(snoozedUntil).toLocaleString()}
              </Text>
            )}
          </div>
          <Row gutter={8} align="middle">
            <Tooltip title="Snooze Location">
              <Button onClick={onSnoozeClick} type="link" icon={<FaRegClock />} />
            </Tooltip>
            <Tooltip title="Refresh Health">
              <Button onClick={onRefreshClick} type="link" icon={<FaSyncAlt />} />
            </Tooltip>
          </Row>
        </Row>
      }
      headStyle={{
        backgroundColor: 'rgba(74, 79, 137, 0.1)',
        borderBottom: 'none',
        borderRadius: '12px 12px 0 0',
      }}
    >
      <Row gutter={[16, 24]} style={{ textAlign: 'left' }}>
        <Col span={24}>
          <Text strong>Organization Name:</Text> <Text>{organizationName}</Text>
        </Col>
        {poc && (
          <Col span={24}>
            <Text strong>Point of Contact:</Text>
            <div
              style={{
                marginTop: '8px',
                padding: '8px',
                background: 'rgba(74, 79, 137, 0.05)',
                borderRadius: '8px',
              }}
            >
              <Text>{poc.name}</Text>
              <br />
              <Text>{poc.email}</Text>
              <br />
              {poc.phone && <Text>{poc.phone}</Text>}
            </div>
          </Col>
        )}
      </Row>
    </PageSection>
  );
};

interface HealthReasonsSectionProps {
  healthReasons: HealthReason[];
  onDismiss: (id: string) => void; // Function to handle dismissing a health reason
}

const PageSection: React.FC<{
  children: React.ReactNode;
  title?: React.ReactNode;
  headStyle?: React.CSSProperties;
}> = ({ children, title, headStyle }) => {
  return (
    <Content style={{ padding: '24px', width: '100%' }}>
      <Card
        style={{
          backgroundColor: '#fff',
          borderColor: 'rgb(74, 79, 137)',
          borderRadius: '12px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          marginBottom: '0px',
        }}
        title={title}
        headStyle={headStyle}
      >
        {children}
      </Card>
    </Content>
  );
};

const HealthReasonsSection: React.FC<HealthReasonsSectionProps> = ({ healthReasons, onDismiss }) => {
  return (
    <PageSection>
      <Title level={4} style={{ textAlign: 'left', color: 'rgb(74, 79, 137)' }}>
        Health Reasons
      </Title>
      <Row gutter={[16, 16]}>
        {healthReasons.map((reason) => (
          <Col span={12} key={reason.id}>
            <Card
              key={reason.id}
              bodyStyle={{ padding: '8px' }}
              style={{
                marginBottom: '8px',
                borderColor: 'rgb(74, 79, 137)',
                borderRadius: '12px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                width: '100%',
              }}
            >
              <Row justify="space-between" align="middle">
                <Col>
                  <Text strong>{reason.name}</Text>
                  <Tag
                    style={{ marginLeft: '8px' }}
                    color={reason.severity === 'Red' ? 'red' : reason.severity === 'Yellow' ? 'gold' : 'Green'}
                  >
                    {reason.severity}
                  </Tag>
                </Col>
                {reason.dismissible && (
                  <Col>
                    <Button
                      type="link"
                      onClick={() => onDismiss(reason.id)}
                      style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.65)' }}
                    >
                      Dismiss
                    </Button>
                  </Col>
                )}
              </Row>
              <Row>
                <Text
                  style={{
                    maxWidth: '70%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    display: 'inline-block',
                  }}
                >
                  {reason.description}
                </Text>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </PageSection>
  );
};

export default LocationHealthSummary;
