import React from 'react';

import styled from 'styled-components';

import { Heading1, Heading2, Heading4 } from '../Headings/Headings.styled';
import { LabelB, LabelBodyB } from '../Text/Text.styled';

const wrapperSizes = {
  extraSmall: '24px',
  small: '32px',
  medium: '40px',
  large: '64px',
  extraLarge: '80px',
};

const AvatarWrapper = styled.div<ImageAvatarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid
    ${(props) => (props.active ? props.theme.colors.purpleNavi.plus2 : props.theme.colors.dimGray.plus3)};
  width: ${(props) => wrapperSizes[props.size || 'medium']};
  height: ${(props) => wrapperSizes[props.size || 'medium']};
  flex-shrink: 0;
`;

export const innerWrapperSizes = {
  extraSmall: '18px',
  small: '24px',
  medium: '30px',
  large: '48px',
  extraLarge: '60px',
};

export const LetterAvatar = styled.div<ImageAvatarProps>`
  display: table;
  border-radius: 50%;
  text-align: center;
  width: ${(props) => innerWrapperSizes[props.size || 'medium']};
  height: ${(props) => innerWrapperSizes[props.size || 'medium']};
  background-color: ${(props) => props.theme.colors.viridianGreen.plus2};
  * {
    display: table-cell;
    color: ${(props) => props.theme.colors.viridianGreen.minus1};
    vertical-align: middle;
  }
`;

export const LogoAvatar = styled.img<ImageAvatarProps>`
  display: block;
  object-fit: cover;
  height: ${(props) => innerWrapperSizes[props.size || 'medium']};
  width: ${(props) => innerWrapperSizes[props.size || 'medium']};
  border-radius: 50%;
  flex-shrink: 0;
`;
export type AvatarSizes = 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge';
interface ImageAvatarProps {
  src?: string;
  size?: AvatarSizes;
  active?: boolean;
  nameAvatar?: string;
}

const AvatarImage: React.FC<ImageAvatarProps> = ({ src, active, size, nameAvatar, ...props }) => {
  const getFirstLetter = (name?: string) => {
    if (!name) return '?';
    const clearEmptySpaces = name.replace(' ', '');
    return clearEmptySpaces.charAt(0).toUpperCase();
  };

  return (
    <AvatarWrapper size={size} active={active || false} {...props}>
      {src ? (
        <LogoAvatar src={src} size={size} />
      ) : (
        <LetterAvatar size={size} {...props}>
          {size === 'extraSmall' ? (
            <LabelB>{getFirstLetter(nameAvatar)}</LabelB>
          ) : size === 'small' ? (
            <LabelBodyB>{getFirstLetter(nameAvatar)}</LabelBodyB>
          ) : size === 'medium' ? (
            <Heading4>{getFirstLetter(nameAvatar)}</Heading4>
          ) : size === 'large' ? (
            <Heading2>{getFirstLetter(nameAvatar)}</Heading2>
          ) : (
            <Heading1>{getFirstLetter(nameAvatar)}</Heading1>
          )}
        </LetterAvatar>
      )}
    </AvatarWrapper>
  );
};

export default AvatarImage;
