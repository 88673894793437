import { gql } from '@apollo/client';

export interface addCustomImageRequest {
  inventoryId: string;
  imagePath: string;
}
export interface addCustomImageResponse {
  addCustomImage: {
    id: string;
    images: string;
  };
}

export const ADD_CUSTOM_IMAGE = gql`
  mutation addCustomImage($inventoryId: String!, $imagePath: String!) {
    addCustomImage(inventoryId: $inventoryId, imagePath: $imagePath) {
      id
      images
    }
  }
`;
