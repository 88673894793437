import React from 'react';
import { Spacer } from '../../../ui/Spacer';

import { AccountSubItemWrapper } from './AccountSubItem.styled';

export interface AccountSubItemProps {
  label: string;
  notificationCount: number;
  active?: boolean;
}

export const AccountSubItem: React.FC<AccountSubItemProps> = ({ active, label, notificationCount }) => (
  <AccountSubItemWrapper active={active}>
    <div className="label">{label}</div>
    <Spacer type="inline" size="xs" />
    <div className="notification-count">{notificationCount}</div>
  </AccountSubItemWrapper>
);
