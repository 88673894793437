import { gql } from '@apollo/client';

import { CatalogTypes } from '../query/createFbCatalog';

export interface ExportInventoryRequest {
  locationId: string;
  place: CatalogTypes;
}

export interface ExportInventoryResponse {
  exportInventory: boolean;
}

export const EXPORT_INVENTORY = gql`
  mutation exportInventory($locationId: String!, $place: String!) {
    exportInventory(locationId: $locationId, place: $place)
  }
`;
