import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import LoginLayout from '../../components/Layout/LoginLayout';
import { Button } from '../../components/ui/Buttons/Button';
import { OnboardingFormValue, OnboardingStepForm } from '../../components/ui/Forms/OnboardingForm/OnboardingStepForm';
import { Heading2, Heading4 } from '../../components/ui/Headings/Headings.styled';
import { snackbar } from '../../components/ui/Snackbar';
import { Spacer } from '../../components/ui/Spacer';
import Stepper from '../../components/ui/Stepper';
import {
  ACCEPT_INVITATION,
  AcceptInvitationParams,
  AcceptInvitationResponse,
} from '../../graphql/mutations/acceptInvitation';
import { ONBOARDING_DATA, OnboardingDataParams, OnboardingDataResponse } from '../../graphql/query/getOnboardingData';
import { LeftContent } from './OnboardingLeftContent';
import { ONBOARDING_STEPS, StepProvider, useStepCtx } from './OnboardingStepProvider';

const MainWrapper = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FinalStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OnboardingFlow: React.FC = () => {
  const { activeStep, nextStep, previousStep } = useStepCtx();
  const [submitted, setSubmitted] = useState(false);
  const [acceptInvitation] = useMutation<AcceptInvitationResponse, AcceptInvitationParams>(ACCEPT_INVITATION);

  const history = useHistory();
  const location = useLocation<{ code: string }>();
  const confirmEmailId = location?.state?.code;
  if (!confirmEmailId) history.push('/login');
  const { data, error } = useQuery<OnboardingDataResponse, OnboardingDataParams>(ONBOARDING_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      confirmEmailId,
    },
  });
  useEffect(() => {
    if (error || data?.onboardingData.errorMessage) {
      const errorMessage = error?.message ?? data?.onboardingData.errorMessage;
      snackbar.error({ message: errorMessage });
      history.push('/login');
    }
  }, [data, error, history]);

  const handleFormSubmit = async (onboardingData: OnboardingFormValue) => {
    const { info, password } = onboardingData;
    await acceptInvitation({
      variables: {
        data: {
          confirmEmailId,
          fullName: info!.fullName,
          phone: info!.phoneNumber,
          emailNotifications: info!.emailNotifications,
          password: password!.password,
          confirmPassword: password!.password,
          username: info!.username,
        },
      },
    });
    setSubmitted(true);
  };
  return (
    <>
      <Stepper steps={ONBOARDING_STEPS} activeStep={activeStep} />
      <Spacer type="stack" size="xxl" />
      {data && activeStep.label !== 'Finished' && (
        <OnboardingStepForm
          activeStep={activeStep}
          nextStep={nextStep}
          previousStep={previousStep}
          onComplete={handleFormSubmit}
          initialData={{
            emailNotifications: data.onboardingData.emailNotifications,
            fullName: data.onboardingData.fullName,
            phoneNumber: data.onboardingData.phone,
            username: data.onboardingData.username,
          }}
        />
      )}
      {activeStep.label === 'Finished' && (
        <FinalStep>
          <Heading4> Your account is ready! </Heading4>
          <Spacer type="stack" size="l" />
          <Button btnType="primary" onClick={() => history.push('/login')} disabled={!submitted}>
            LOG IN NOW
          </Button>
        </FinalStep>
      )}
    </>
  );
};
const MainContent: React.FC = () => {
  return (
    <MainWrapper>
      <MainContainer>
        <Heading2>Sign up for Local Shift</Heading2>
        <OnboardingFlow />
      </MainContainer>
    </MainWrapper>
  );
};

const OnboardingByEmail = () => {
  return (
    <StepProvider>
      <LoginLayout leftContent={<LeftContent />} mainContent={<MainContent />}></LoginLayout>
    </StepProvider>
  );
};

export default OnboardingByEmail;
