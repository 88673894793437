import { gql, useQuery } from '@apollo/client';
import { OrganizationFieldsFragment, OrganizationItem } from './organizationList';

export interface OrganizationsByPartnerIdResponse {
  organizationsByPartnerId: OrganizationItem[];
}
export interface OrganizationsByPartnerIdParams {
  partnerId: string;
  active?: boolean;
}

export const ORGANIZATIONS_BY_PARTNER_ID = gql`
  query ($partnerId: String!, $active: Boolean) {
    organizationsByPartnerId(partnerId: $partnerId, active: $active) {
      ...OrganizationFields
    }
  }
  ${OrganizationFieldsFragment}
`;
export function useOrganizationsByPartnerId(partnerId: string, active = true) {
  const { data, refetch, loading, error } = useQuery<OrganizationsByPartnerIdResponse, OrganizationsByPartnerIdParams>(
    ORGANIZATIONS_BY_PARTNER_ID,
    {
      variables: {
        partnerId,
        active,
      },
    }
  );
  return {
    refetch,
    organizations: data?.organizationsByPartnerId || [],
    loading,
    error,
  };
}
