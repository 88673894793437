import React from 'react';

import styled from 'styled-components';

import NoImage from '../../../../assets/images/noimage.png';

const ImageSelectedWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Img = styled.img`
  width: 78px;
  height: 49px;
  object-fit: cover;
  border-radius: 16px;
  align-self: center;
  margin: 24;
`;

interface ImageSelectionProps {
  image: string;
}
const ImageSelection: React.FC<ImageSelectionProps> = ({ image }) => {
  const handleErrorImage = (e: React.ChangeEvent<HTMLImageElement>) => {
    e.target.src = NoImage;
  };

  return (
    <ImageSelectedWrapper>
      <div>
        <Img src={image} onError={handleErrorImage} />
      </div>
    </ImageSelectedWrapper>
  );
};

export default ImageSelection;
