import { useEffect, useState } from 'react';

export interface ServiceFilters {
  setCadence: (condition: string) => void;
  cadence: string;
}

export function useServiceFilters(): ServiceFilters {
  const [cadence, setCadence] = useState<string>('');

  useEffect(() => {
    setCadence('');
  }, []);

  return { cadence, setCadence };
}
