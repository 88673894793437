import React, { useState } from 'react';

import { Empty } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

import styled from 'styled-components';

import { useGetSoldCars } from '../../../../graphql/query/getSoldCars';
import { PostTypeEnum } from '../../../../graphql/query/postHistoryByLocationId';
import { DashboardFilter } from '../../../../pages/Dashboard/hooks/useFilters';
import { RadioGroupOption } from '../../RadioGroup/RadioGroup';
import { Spacer } from '../../Spacer';
import { LabelB, LabelBodyB } from '../../Text/Text.styled';
import { SocialAccount } from '../../VehicleColumn/modeViews/VehicleSocialAccounts';
import { VehicleCardStyle } from './Components.styles';
import { PopularPostELement } from './PopularPost';
import { VerticalRadioGroup } from './VerticalRadioGroup';

interface SoldCarsProps {
  dashboardFilters: DashboardFilter;
}

export const SoldCars: React.FC<SoldCarsProps> = ({ dashboardFilters }) => {
  const [account, setAccount] = useState<SocialAccount>(SocialAccount.FacebookMarketplace);
  const { soldCars } = useGetSoldCars({
    soldCarsRequest: {
      organizationIds: dashboardFilters.organizationIds,
      startDate: dashboardFilters.dateRange.startDate,
      endDate: dashboardFilters.dateRange.endDate,
      platforms: [account],
      locationIds: dashboardFilters.locationIds,
      postTypes: [PostTypeEnum.VEHICLE],
    },
  });
  const handleChangeCondition = ({ target: { value } }: RadioChangeEvent) => {
    setAccount(value);
  };

  const options: RadioGroupOption[] = [
    { label: 'Marketplace', value: 'facebook_marketplace' },
    { label: 'Facebook', value: 'facebook' },
    { label: 'Instagram', value: 'instagram' },
    { label: 'TikTok', value: 'tik_tok' },
  ];

  return (
    <VehicleCardStyle>
      <PopularPostWrapper>
        <PopularHeading>
          <LabelBodyB>Sold Cars</LabelBodyB>
          <VerticalRadioGroup
            options={options}
            onChange={handleChangeCondition}
            defaultValue={account}
            selected={account}
          />
        </PopularHeading>
        <Spacer type="stack" size="s" />
        <Label>#Sold At</Label>
      </PopularPostWrapper>
      <VehicleList>
        {soldCars.map((post, pos) => {
          return <PopularPostELement type={'sold'} position={pos} postMetric={post} />;
        })}
        {soldCars?.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </VehicleList>
    </VehicleCardStyle>
  );
};

export const VehicleList = styled.div``;
const PopularPostWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const PopularHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;
const Label = styled(LabelB)`
  align-self: flex-end;
`;
