export interface PasswordValidationResult {
  isValid: boolean;
  hasUppercase: boolean;
  hasLowercase: boolean;
  hasMinLenght: boolean;
  hasNumber: boolean;
}
export const validatePassword = (password: string): PasswordValidationResult => {
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasMinLenght = password.length >= 8;
  const isValid = hasNumber && hasMinLenght && hasLowercase && hasUppercase;
  return {
    isValid,
    hasUppercase,
    hasLowercase,
    hasMinLenght,
    hasNumber,
  };
};
interface ValidationItem {
  label: string;
  key: keyof PasswordValidationResult;
}
export const validationItems: ValidationItem[] = [
  {
    label: 'Must contain at least 8 characters',
    key: 'hasMinLenght',
  },
  {
    label: 'Must contain at least one uppercase letter',
    key: 'hasUppercase',
  },
  {
    label: 'Must contain at least one lowercase letter',
    key: 'hasLowercase',
  },
  {
    label: 'Must contain at least one number',
    key: 'hasNumber',
  },
];
