import React from 'react';

import { DatePicker, Input } from 'antd';
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker';
import { InputProps, PasswordProps, TextAreaProps } from 'antd/lib/input';
import moment from 'moment';

import styled from 'styled-components';

import { Button } from '../Buttons/Button';
import { LabelBold } from '../Text/Text.styled';
import { DoneIcon } from '../ValidationCriteria/ValidationCriteria';

const TextInputContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  &.with-label {
    padding-top: 18px;
  }
`;

const LabelBoldStyled = styled(LabelBold)`
  position: absolute;
  top: 0px;
  left: 0;
`;

export interface TextInputProps extends InputProps {
  topLabel?: string;
  helperText?: JSX.Element | null;
}

// @ts-ignore
export const TextInput = React.forwardRef<Input, TextInputProps>(({ topLabel, helperText, ...props }, ref) => {
  const className = topLabel ? 'with-label' : '';
  return (
    <TextInputContainer className={className}>
      {topLabel && <LabelBoldStyled>{topLabel?.toUpperCase()}</LabelBoldStyled>}
      <Input {...props} ref={ref} />
      {helperText}
    </TextInputContainer>
  );
});

export interface TextAreaInputProps extends TextAreaProps {
  topLabel?: string;
  helperText?: JSX.Element | null;
}

export const TextAreaInput = React.forwardRef<typeof Input.TextArea, TextAreaInputProps>(
  ({ topLabel, helperText, size, ...props }, _ref) => {
    const className = topLabel ? 'with-label' : '';
    return (
      <TextInputContainer className={className}>
        {topLabel && <LabelBoldStyled>{topLabel?.toUpperCase()}</LabelBoldStyled>}
        <Input.TextArea {...props} style={{ fontSize: '12px', lineHeight: '16px' }} />
        {helperText}
      </TextInputContainer>
    );
  }
);

export interface TextInputButtonProps extends InputProps {
  topLabel?: string;
  helperText?: JSX.Element | null;
  showAction?: boolean;
  actionButton?: () => void;
  textActionButton?: string;
}

// @ts-ignore
export const TextInputButton = React.forwardRef<Input, TextInputButtonProps>(
  ({ topLabel, helperText, actionButton, textActionButton, showAction, ...props }, ref) => {
    const className = topLabel ? 'with-label' : '';

    return (
      <TextInputContainer className={className}>
        {topLabel && <LabelBoldStyled>{topLabel?.toUpperCase()}</LabelBoldStyled>}
        <Input
          {...props}
          ref={ref}
          suffix={
            !showAction ? (
              <Button btnType="text" noPadding size="small" onClick={actionButton}>
                {textActionButton}
              </Button>
            ) : (
              <DoneIcon active={true} className="icon icon-small-misc-done-fill-circle" />
            )
          }
        />
        {helperText}
      </TextInputContainer>
    );
  }
);

export interface PasswordInputProps extends PasswordProps {
  topLabel?: string;
  helperText?: JSX.Element | null;
}

// @ts-ignore
export const PasswordInput = React.forwardRef<Input, PasswordInputProps>(({ topLabel, helperText, ...props }, ref) => {
  const className = topLabel ? 'with-label' : '';
  return (
    <TextInputContainer className={className}>
      {topLabel && <LabelBoldStyled>{topLabel?.toUpperCase()}</LabelBoldStyled>}
      <Input.Password {...props} ref={ref} />
      {helperText}
    </TextInputContainer>
  );
});

export interface DateInputProps extends PickerDateProps<moment.Moment> {
  topLabel?: string;
  helperText?: JSX.Element | null;
}

export const DateInput = React.forwardRef<any, DateInputProps>(({ topLabel, helperText, ...props }, ref) => {
  const className = topLabel ? 'with-label' : '';
  return (
    <TextInputContainer className={className}>
      {topLabel && <LabelBoldStyled>{topLabel?.toUpperCase()}</LabelBoldStyled>}
      {/* 
// @ts-ignore */}
      <DatePicker {...props} ref={ref} />
      {helperText}
    </TextInputContainer>
  );
});
