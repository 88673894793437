import React from 'react';

import { message } from 'antd';

import * as Sentry from '@sentry/react';

import styled from 'styled-components';

import { forgotPasswordRequest } from '../../api/auth.api';
import LoginLayout from '../../components/Layout/LoginLayout';
import {
  DisclaimerText,
  LoginFormContainer,
  MainContentLoginLayout,
} from '../../components/Layout/MainContentLoginLayout';
import { Heading2 } from '../../components/ui/Headings/Headings.styled';
import LeftContent from '../../components/ui/LeftLoginContent';
import { Spacer } from '../../components/ui/Spacer';
import EnhancedForgotPasswordForm, { ForgotPasswordFormValue } from './ForgotPasswordForm';

const Title = styled(Heading2)`
  text-align: center;
`;
interface StatusForgotPassword {
  headTitle: string;
  disclaimerText: string;
}

const MainContent: React.FC = () => {
  const [isSentMessage, setIsSentMessage] = React.useState(false);
  const [status, setStatus] = React.useState<StatusForgotPassword>({
    headTitle: 'Forgot Password?',
    disclaimerText: "Enter your email  and we'll send you a code to reset your password.",
  });
  const handleFormSubmit = async (data: ForgotPasswordFormValue) => {
    try {
      await forgotPasswordRequest({
        username: data.email,
      });
      setIsSentMessage(true);
      setStatus((prev) => ({
        ...prev,
        headTitle: 'A reset code has been sent to your email',
        disclaimerText: `If you can't see the message could you try again.`,
      }));
    } catch (error: any) {
      Sentry.captureException(error);
      if (error.response.data.statusCode === 404) message.error(error.response.data.message);
      else message.error("Sorry we Could't sent email, try again later");
    }
  };

  return (
    <MainContentLoginLayout>
      <LoginFormContainer>
        <Spacer type="stack" size="s" />
        <Title>{status.headTitle}</Title>
        <Spacer type="stack" size="l" />
        <EnhancedForgotPasswordForm
          actionButtonName={isSentMessage ? 'TRY AGAIN' : 'SEND CODE'}
          onCompleted={handleFormSubmit}
        />
      </LoginFormContainer>
      <Spacer type="stack" size="xs" />
      <DisclaimerText>{status.disclaimerText}</DisclaimerText>
    </MainContentLoginLayout>
  );
};

const ForgotPassword = () => <LoginLayout leftContent={<LeftContent />} mainContent={<MainContent />}></LoginLayout>;

export default ForgotPassword;
