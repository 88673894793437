import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { HasPermission } from '../../../ui/Security/HasPermission';
import { Spacer } from '../../../ui/Spacer';
import { AccountSubItem } from '../AccountSubItem';
import {
  AccountIcon,
  AccountLabel,
  AccountMenuItemWrapper,
  AccountSubItemListWrapper,
  ConnectAccountBtn,
} from './AccountMenuItem.styled';

export interface AccountSubItemProps {
  label: string;
  href?: string;
  count?: number;
  forbiddenRoles?: string[];
}

export interface AccountMenuItemProps {
  label: string;
  iconClass: string;
  disconnected?: boolean;
  href: string;
  forbiddenRoles?: string[];
  subItems: AccountSubItemProps[];
}

export const AccountMenuItem: React.FC<AccountMenuItemProps> = ({ label, iconClass, disconnected, subItems }) => {
  const router = useLocation();

  return (
    <>
      <AccountMenuItemWrapper>
        <AccountIcon className={`ic ${iconClass}`} />
        <Spacer type="inline" size="xs" />
        <AccountLabel>{label}</AccountLabel>
      </AccountMenuItemWrapper>
      {disconnected && <ConnectAccountBtn />}
      <AccountSubItemListWrapper>
        {subItems.map((item, index) => (
          <HasPermission key={index} forbiddenRoles={item.forbiddenRoles}>
            <Link to={item.href || router.pathname}>
              <AccountSubItem
                key={item.label}
                label={item.label}
                notificationCount={item.count || 0}
                active={router.pathname === item.href}
              />
            </Link>
          </HasPermission>
        ))}
      </AccountSubItemListWrapper>
    </>
  );
};
