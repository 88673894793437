import { Partner, PartnerType } from '../graphql/query/partners';

export const PARTNER_ONBOARDING_SCHEDULE_LINK = 'https://meetings.hubspot.com/ashlyn-draper/reseller-onboarding';

export function showOnboardingScheduleLink(partner: Partner | undefined) {
  if (partner?.type === PartnerType.LocalShiftOwnership) {
    return true;
  }

  return false;
}
