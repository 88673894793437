import { CompareFn, SortOrder } from 'antd/lib/table/interface';
import { get } from 'lodash';

export type SorterField<RecordType> =
  | boolean
  | CompareFn<RecordType>
  | {
      compare?: CompareFn<RecordType>;
      /** Config multiple sorter order priority */
      multiple?: number;
    };

export type ValueCompareFn<T> = (a: T, b: T) => number;

export interface SorterArgs<RecordType extends object, K extends keyof RecordType> {
  field: K | string;
  multiple?: number;
  type?: 'string' | 'number' | 'custom' | 'array';
  customSorter?: CompareFn<RecordType>;
  sortDirections?: SortOrder[];
}

export interface SorterReturn<RecordType = any> {
  sorter: SorterField<RecordType>;
  sortDirections?: SortOrder[];
}

export const compareStrings = (a: string = '', b: string = '') => {
  a = a ? a : '';
  b = b ? b : '';
  return a.toLowerCase().localeCompare(b.toLowerCase());
};

export const compareNumbers = (a: number = 0, b: number = 0) => a - b;
export const compareArrays = (a: unknown[], b: unknown[]) => a?.length ?? 0 - b?.length ?? 0;

export function sorterWrapper<RecordType extends object, K extends keyof RecordType>(
  field: K | string,
  sortFn: ValueCompareFn<RecordType[K]>
) {
  return (a: RecordType, b: RecordType, _sortOrder?: SortOrder) => sortFn(get(a, field), get(b, field));
}

export function createSorter<RecordType extends object, K extends keyof RecordType>(
  field: K | string,
  sortFn: ValueCompareFn<RecordType[K]>,
  multiple?: number
): SorterField<RecordType> {
  const sorter = sorterWrapper<RecordType, K>(field, sortFn);
  return multiple
    ? {
        compare: sorter,
        multiple,
      }
    : sorter;
}

const sorterLookup = {
  string: compareStrings,
  number: compareNumbers,
  array: compareArrays,
  custom: '',
};

export const createSortProps = <RecordType extends object>({
  field,
  multiple,
  type = 'string',
  sortDirections = ['descend', 'ascend'],
  customSorter = (_a, _b, _sort) => 0,
}: SorterArgs<RecordType, keyof RecordType>): SorterReturn<RecordType> => {
  const sorter = sorterLookup[type] as any as ValueCompareFn<RecordType[keyof RecordType]>;
  return {
    sorter: sorter ? createSorter<RecordType, keyof RecordType>(field, sorter, multiple) : customSorter,
    sortDirections,
  };
};
