import { gql } from '@apollo/client';

export interface ConnectGoogleLocationParams {
  resourcename: string;
  accountName: string;
}

export const CONNECT_GOOGLE_LOCATIONS = gql`
  mutation connectGoogleLocation($resourcename: String!, $accountName: String!) {
    connectGoogleLocation(resourcename: $resourcename, accountName: $accountName)
  }
`;
