import React, { useState } from 'react';

import styled from 'styled-components';

import { AvatarWithLabel } from '../../../../components/ui/AvatarImage/AvatarWithLabel';
import { Button } from '../../../../components/ui/Buttons/Button';
import { ModalWithFooter } from '../../../../components/ui/ModalWithFooter';
import { Label, LabelBodyR, LabelBold } from '../../../../components/ui/Text/Text.styled';
import { AccountTextLabels } from './AccountColumn';
import { AccountInfoProps, RowContainer, Section, TextContainer } from './AccountInfo';

const LabelBoldStyled = styled(LabelBold)`
  color: ${(props) => props.theme.colors.dimGray.minus2};
`;

export interface ConnectPageLocationParam {
  id: string;
  account?: string;
}

interface LocationInfoProps extends AccountInfoProps {
  id: string;
  account?: string;
  textAccount?: string;
  connect: (connectPagLocation: ConnectPageLocationParam) => void;
}
export const LocationInfo: React.FC<LocationInfoProps> = ({
  avatar,
  fullName,
  description,
  id,
  account,
  isConnected,
  source,
  connect,
}) => {
  const [showModal, setShowModal] = useState(false);
  const onConnect = async () => {
    await connect({
      id,
      account,
    });
  };
  const onDisconnect = async () => {
    setShowModal(true);
  };
  return (
    <Section>
      <RowContainer>
        <AvatarWithLabel imgUrl={avatar || ''} name={fullName || ''} label={source}>
          <TextContainer>
            <LabelBoldStyled>{fullName}</LabelBoldStyled>
            <Label>{description}</Label>
          </TextContainer>
        </AvatarWithLabel>
        {isConnected ? (
          <Button btnType={'connect'} size="small" style={{ width: '108px' }} onClick={onDisconnect}>
            <span className="text-action-button">{'CONNECTED'}</span>
          </Button>
        ) : (
          <Button btnType={'primary'} size="small" style={{ width: '108px' }} onClick={onConnect}>
            {'CONNECT'}
          </Button>
        )}
      </RowContainer>
      <ModalWithFooter
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={() => setShowModal(false)}
        title={`Disconnect ${AccountTextLabels[source].accounts}?`}
        okText={'DISCONNECT'}
      >
        <LabelBodyR>You will be not longer available to manage the information of “{fullName || ''}”</LabelBodyR>
      </ModalWithFooter>
    </Section>
  );
};
