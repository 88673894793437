import React from 'react';

import Modal from 'antd/lib/modal';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { logoutRequest } from '../../../api/auth.api';
import { useSession } from '../../../context/AppStateProvider';
import { Heading3 } from '../../ui/Headings/Headings.styled';
import { Spacer } from '../../ui/Spacer';
import { LabelB } from '../../ui/Text/Text.styled';

const { confirm } = Modal;

const UserProfileWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const UserDetail = styled.div`
  display: flex;
  flex-direction: column;
  width: 496px;
  border-right: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  padding-left: 40px;
  padding-right: 40px;
`;

const UserDetailHeader = styled.div`
  height: 160px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
`;

const StyledHeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const ActivityFeed = styled.div``;

const AvatarHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledLogOutButton = styled.i`
  font-size: 24px;
  cursor: pointer;
`;

interface UserProfileLayoutProps {
  leftContent?: JSX.Element;
  leftHeaderAvatar?: JSX.Element;
  userName?: string;
  position?: string;
}

export const UserProfileLayout: React.FC<UserProfileLayoutProps> = ({ children, ...props }) => {
  const history = useHistory();
  const { cleanSession } = useSession();

  const handleLogOut = async () => {
    try {
      await logoutRequest();
    } catch (err) {
      Sentry.captureException(err);
    }
    cleanSession();
    history.push('/login');
  };

  const showConfirm = () => {
    confirm({
      title: 'Confirmation',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: 'Yes, log me out',
      content: 'Are you sure you want to log out?',
      onOk() {
        handleLogOut();
      },
    });
  };
  return (
    <UserProfileWrapper>
      <UserDetail>
        <UserDetailHeader>
          <AvatarHeaderWrapper>
            {props.leftHeaderAvatar}
            <StyledLogOutButton className="icon arrows-arrow-log-out" onClick={showConfirm} />
          </AvatarHeaderWrapper>
          <Spacer type="stack" size="xs" />
          <StyledHeaderTitle>
            <Heading3>{props.userName}</Heading3>
            <LabelB>{props.position}</LabelB>
          </StyledHeaderTitle>
        </UserDetailHeader>
        {props.leftContent}
      </UserDetail>
      <ActivityFeed>{children}</ActivityFeed>
    </UserProfileWrapper>
  );
};
