import { gql } from '@apollo/client';

export interface ChangeNoteResponse {
  changeCustomerNote: boolean;
}

export interface ChangeNoteRequest {
  organizationId: string;
  customerNote: string;
}

export const CHANGE_NOTE = gql`
  mutation ($organizationId: String!, $customerNote: String!) {
    changeCustomerNote(organizationId: $organizationId, customerNote: $customerNote)
  }
`;
