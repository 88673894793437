import styled, { css } from 'styled-components';

export interface AccountSubItemWrapperProps {
  active?: boolean;
  adjustHoverleft?: boolean;
}
export const AccountSubItemWrapper = styled.div<AccountSubItemWrapperProps>`
  position: relative;
  width: 100%;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  color: ${(props) => props.theme.colors.dimGray.plus1};
  cursor: pointer;
  .label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-grow: 1;
    font-size: 12px;
    justify-content: flex-start;
    :hover {
      color: ${(props) => props.theme.colors.dimGray.minus2};
    }
  }
  .notification-count {
    width: 16px;
    font-size: 10px;
    text-align: right;
  }
  ${(props) =>
    props.active &&
    css`
      .label {
        color: ${props.theme.colors.purpleNavi.base};
      }
      .notification-count {
        color: ${props.theme.colors.purpleNavi.plus1};
      }
      &::before {
        display: block;
        position: absolute;
        content: ' ';
        width: 4px;
        top: 8px;
        bottom: 8px;
        left: ${props.adjustHoverleft ? '180px' : '-8px'};
        border-radius: 4px;
        background-color: ${(props) => props.theme.colors.purpleNavi.base};
      }
    `}
`;
