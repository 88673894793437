import { gql } from '@apollo/client';

import { UpsertLocationFormValue } from '../../components/ui/Forms/NewLocationForm';
import { MutationParam } from './';
import { LocationFieldsFragment } from '../query/locationsByOrgId';

export interface UpsertLocationRequest extends UpsertLocationFormValue {
  id?: string;
  organizationId: string;
}

export interface UpsertLocationParams extends MutationParam<UpsertLocationRequest> {}

export const UPSERT_LOCATION = gql`
  mutation ($data: UpsertLocationRequest!) {
    upsertLocation(data: $data) {
      ...LocationFields
    }
  }
  ${LocationFieldsFragment}
`;
