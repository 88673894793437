import React, { useState } from 'react';

import styled, { css } from 'styled-components';

import { capitalizeOnlyFirstLetter } from '../../../utils/formatText.utils';
import { AccountLabel } from '../../Menu/AccountMenu/AccountMenuItem/AccountMenuItem.styled';
import { SocialAccountType } from '../CreatePostModal/components/GeneralPost';
import { Spacer } from '../Spacer';

const AccountItemWrapper = styled.div<{ active: boolean; disabled: boolean }>`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  border: solid 1px ${(props) => props.theme.colors.purpleNavi.plus2};
  border-radius: 16px;
  ${(props) =>
    props.active &&
    css`
      border: solid 2px ${(props) => props.theme.colors.purpleNavi.plus2};
      background-color: ${(props) => props.theme.colors.purpleNavi.plus2};
      color: ${(props) => props.theme.colors.white};
    `}
  ${(props) =>
    props.disabled &&
    css`
      border: solid 2px ${(props) => props.theme.colors.dimGray.plus3};
      background-color: ${(props) => props.theme.colors.dimGray.plus3};
    `}

  margin: 0px 5px;
  &:hover {
    transform: scale(1.05);
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;
const AccountIcon = styled.div`
  height: 16px;
  width: 16px;
  cursor: default;
`;

interface InventoryCardProps {
  name: SocialAccountType;
  selectedItems: string[];
  currentSelected: SocialAccountType;
  setCurrentSelected: (actual: SocialAccountType) => void;
}

export const AccountCard: React.FC<InventoryCardProps> = ({
  name,
  selectedItems,
  currentSelected,
  setCurrentSelected,
}) => {
  const [active, setActive] = useState<boolean>(currentSelected === name);
  const [disable, setDisable] = useState<boolean>(!selectedItems.includes(name));
  const handleClick = () => {
    if (!disable) setCurrentSelected(name);
  };

  React.useEffect(() => {
    setActive(name === currentSelected);
  }, [currentSelected, name]);

  React.useEffect(() => {
    setDisable(!selectedItems.includes(name));
  }, [selectedItems, name]);

  return (
    <AccountItemWrapper disabled={disable} active={active} onClick={() => handleClick()}>
      <AccountIcon className={`ic ic_${name}`} />
      <Spacer type="inline" size="xxs" />
      <AccountLabel disabled={disable} active={active}>
        {capitalizeOnlyFirstLetter(name)}
      </AccountLabel>
    </AccountItemWrapper>
  );
};
