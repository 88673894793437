import React from 'react';

import { Modal, Table } from 'antd';
import moment from 'moment';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import styled from 'styled-components';

import { AccountIcon } from '../../../components/Menu/AccountMenu/AccountMenuItem/AccountMenuItem.styled';
import ItemInfoCard from '../../../components/ui/ItemInfoCard/ItemInfoCard';
import { snackbar } from '../../../components/ui/Snackbar';
import { Spacer } from '../../../components/ui/Spacer';
import { TagLabel } from '../../../components/ui/TagLabel';
import { SearchInput } from '../../../components/ui/TextInput/SearchInput.styled';
import { DELETE_QUEUE, DeleteQueueRequest } from '../../../graphql/mutations/deleteQueue';
import {
  GET_QUEUE_BY_LOCATION,
  GetLocationsWithQueueParams,
  GetLocationsWithQueueResponse,
  PostInQueueElement,
  useQueueByLocation,
} from '../../../graphql/query/getQueueByLocation';
import { Condition } from '../../../graphql/query/inventoryByLocationId';
import { useRowSelection } from '../../../hooks/useRowSelection';
import { decodeEntities } from '../../../utils/formatText.utils';

const { confirm } = Modal;
const { Column } = Table;
const PageContent = styled.div`
  padding: 10px 10px;
  width: 100%;
  align-items: flex-start;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
`;
const DeleteButton = styled.div`
  margin-right: 18px;
  display: flex;
  font-size: 20px;
  .icon {
    color: ${(props) => props.theme.colors.red};
  }
  &:hover {
    cursor: pointer;
  }
`;

const PageHeader = styled.div`
  width: 100%;
  height: 56px;
  padding: 12px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  display: flex;
  align-items: center;

  justify-content: space-between;
`;
interface QueuePageColumnParams {
  locationId: string;
}
export const QueueLocationTable: React.FC<QueuePageColumnParams> = ({ locationId }) => {
  const { queueList, refetch } = useQueueByLocation({ locationId });
  const [deleteQueue] = useMutation<any, DeleteQueueRequest>(DELETE_QUEUE, {
    update: (cache, { data }) => {
      const queryVariables: GetLocationsWithQueueParams = {
        locationId: locationId,
      };
      const querydata = cache.readQuery<GetLocationsWithQueueResponse, GetLocationsWithQueueParams>({
        query: GET_QUEUE_BY_LOCATION,
        variables: queryVariables,
      }) || { getQueueByLocation: [] };
      const mergedData: GetLocationsWithQueueResponse = {
        getQueueByLocation: [...querydata.getQueueByLocation, data!],
      };
      cache.writeQuery<GetLocationsWithQueueResponse, GetLocationsWithQueueParams>({
        query: GET_QUEUE_BY_LOCATION,
        variables: queryVariables,
        data: mergedData,
      });
    },
  });

  const { rowSelection, selectedRowKeys, selected, setSelectedRowKeys, setSelected } =
    useRowSelection<PostInQueueElement>();
  const handleDelete = async () => {
    try {
      await deleteQueue({
        variables: { data: selectedRowKeys as string[] },
      });
      setSelectedRowKeys([]);
      setSelected(false);
      snackbar.success({
        message: selectedRowKeys.length > 1 ? 'Queues deleted Successfully' : 'Queue deleted Succesfully',
      });
    } catch (error) {
      Sentry.captureException(error);
      snackbar.error({
        message: selectedRowKeys.length > 1 ? 'Unable to delete Queues' : 'Unable to delete queue',
      });
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    refetch({
      locationId: locationId,
      name: value,
    });
  };

  const showConfirm = () => {
    confirm({
      title: selectedRowKeys.length > 1 ? 'Delete queues' : 'Delete queue',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: 'YES, DELETE',
      content:
        selectedRowKeys.length > 1
          ? 'Are you sure you want to delete these queues'
          : 'Are you sure you want to delete this queue',
      onOk() {
        handleDelete();
      },
    });
  };

  return (
    <>
      <PageHeader>
        <SearchInput placeholder="Search by Vehicle" onChange={handleChange} removeBorder />
        <ActionsWrapper>
          {selected && (
            <DeleteButton onClick={showConfirm}>
              <i className="icon misc-delete" />
            </DeleteButton>
          )}
        </ActionsWrapper>
      </PageHeader>
      <PageContent>
        <Table className="wide-table" dataSource={queueList} rowKey="id" rowSelection={rowSelection}>
          <Column<PostInQueueElement>
            title="Vehicle"
            width="240px"
            dataIndex="vehicle"
            key="vehicle"
            render={(_inventory, row) => (
              <ItemInfoCard
                item={decodeEntities(row.inventory.vehicle!)}
                avatar={row.inventory.imgSource || ''}
                imgError={''}
              />
            )}
          />
          <Column<PostInQueueElement>
            title="Price"
            width="160px"
            dataIndex="price"
            key="price"
            render={(_inventory, row) => (
              <div>
                {row.inventory.price === 0
                  ? 'No price yet '
                  : row.inventory!.price!.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
              </div>
            )}
          />

          <Column<PostInQueueElement>
            title="Days on Market"
            width="160px"
            dataIndex="age"
            key="age"
            render={(_inventory, row) => <div>{row.inventory.age} Days</div>}
          />

          <Column<PostInQueueElement>
            title="Condition"
            width="160px"
            dataIndex="condition"
            key="condition"
            render={(_inventory, row) => (
              <TagLabel color="default">{row.inventory.condition === Condition.New ? 'new' : 'pre-owned'}</TagLabel>
            )}
          />

          <Column<PostInQueueElement>
            title="Social Media"
            width="160px"
            dataIndex="socialMedia"
            key="socialMedia"
            render={(socialMedia) => (
              <LabelWrapper>
                {socialMedia.map((social: string) => {
                  return (
                    <div>
                      <AccountIcon style={{ padding: '4px' }} className={`ic ic_${social}`} />
                      <Spacer size="s" type={'inline'} />
                    </div>
                  );
                })}
              </LabelWrapper>
            )}
          />

          <Column<PostInQueueElement>
            title="Posted Date"
            width="160px"
            dataIndex="postedDate"
            key="postedDate"
            render={(_dateSent, _row, index) => (
              <div>
                {moment()
                  .add(index + 1, 'days')
                  .format('MMMM DD')}
              </div>
            )}
          />
        </Table>
      </PageContent>
    </>
  );
};
