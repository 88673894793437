import { gql } from '@apollo/client';

export interface ArchiveLocationStatusRequest {
  locationId: string;
}

export const ARCHIVE_LOCATION = gql`
  mutation ($locationId: String!) {
    archiveLocation(locationId: $locationId)
  }
`;

export const UNARCHIVE_LOCATION = gql`
  mutation ($locationId: String!) {
    unarchiveLocation(locationId: $locationId)
  }
`;
