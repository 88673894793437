import styled, { css } from 'styled-components';

export const TextBold = styled.span`
  font-weight: 600;
`;

export const Label = styled.span<{ white?: boolean }>`
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  ${(props) =>
    props.white &&
    css`
      color: ${props.theme.colors.white};
    `}
`;

export const LabelSmall = styled(Label)`
  font-size: 10px;
`;

export const LabelBold = styled(Label)`
  display: flex;
  font-weight: 600;
`;

export const LabelBoldSmall = styled(LabelBold)`
  font-size: 10px;
`;
export const LabelBodyB = styled(LabelBold)`
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
`;
export const LabelB = styled(LabelBold)<{ inline?: boolean; color?: string }>`
  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  font-weight: 600px;
`;
export const LabelR = styled(Label)<{ inline?: boolean }>`
  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  font-weight: 400px;
  text-align: left;
`;

export const LabelRText = styled(Label)<{ inline?: boolean }>`
  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}
  color: ${(props) => props.theme.colors.dimGray.plus1};
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  font-weight: 400px;
  text-align: left;
`;

export const LabelBodyR = styled(Label)`
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  font-weight: 400;
`;

export const H4 = styled.h4`
  font-size: 20px;
  font-style: normal;
  line-height: 25px;
`;
export const H3 = styled.h4`
  font-size: 22px;
  font-weight: 900;
  line-height: 24px;
  margin-bottom: 0px;
`;

export const SmallLabelB = styled(LabelSmall)`
  font-weight: 600;
  font-style: normal;
`;
