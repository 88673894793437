import { gql, useQuery } from '@apollo/client';

export interface TikTokBusinessCenter {
  id: string;
  name: string;
}

export interface BusinessCenterForAccountResponse {
  businessCenters: TikTokBusinessCenter[];
}

export const TIKTOK_BUSINESS_CENTER = gql`
  query businessCenters {
    businessCenters {
      id
      name
    }
  }
`;

export function useBusinessCenterQuery() {
  const { data, error, loading } = useQuery<BusinessCenterForAccountResponse>(TIKTOK_BUSINESS_CENTER);
  return {
    businesses: data?.businessCenters || [],
    error,
    loading,
  };
}
