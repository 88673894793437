import { KeywordsTypes } from '../components/ui/CreatePostModal/helpers';
import { LocationAccountsInfo } from '../graphql/query/getLocationAccountsInfoInfo';
import { InventoryItem } from '../graphql/query/inventoryByLocationId';
import { decodeEntities } from './formatText.utils';

export const formatItem = (item?: InventoryItem) => {
  const defaultMessage = decodeEntities(item?.postDescription!);
  return defaultMessage;
};

const removeBlankSpace = (keyword?: string) => (keyword ? keyword.replace(/ /g, '') : '');
interface KeywordsParams {
  location?: LocationAccountsInfo;
  item?: InventoryItem;
  keywords?: KeywordsTypes[];
}
export const generateKeywordsText = (keywordsparams: KeywordsParams) => {
  const keywords = keywordsparams.keywords?.map((key) => key.keyword) || [];
  const bodyParts = keywordsparams.item?.body?.split(' ') ?? [];
  const keywordsAutomatic = [
    keywordsparams.item?.make,
    ...(bodyParts ?? null),
    keywordsparams.location?.name,
    keywordsparams.location?.city,
    keywordsparams.location?.state,
    ...(keywords ?? null),
  ];
  const hashtags = keywordsAutomatic.map((keyword) => {
    const keywordParsed = removeBlankSpace(keyword);
    return `#${keywordParsed}`;
  });
  const removeDuplicated = hashtags
    .filter((c, index) => {
      return hashtags.indexOf(c) === index;
    })
    .join(' ');
  return decodeEntities(`\n\n${removeDuplicated}`);
};
