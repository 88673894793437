import { gql } from '@apollo/client';

export interface ToggleMarketplaceWarmupStatusRequest {
  marketplaceToken: string;
}
export interface ToggleMarketplaceWarmupStatusResponse {
  toggleMarketplaceWarmupStatus: boolean;
}

export const TOGGLE_MARKETPLACE_WARMUP_STATUS = gql`
  mutation toggleMarketplaceWarmupStatus($marketplaceToken: String!) {
    toggleMarketplaceWarmupStatus(marketplaceToken: $marketplaceToken)
  }
`;
