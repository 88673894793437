import React from 'react';

import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button as ButtonStyle } from '../../../components/ui/Buttons/Button';
import { StyledForm, StyledSubmitWrapper } from '../../../components/ui/FormLogin';
import { Spacer } from '../../../components/ui/Spacer';
import TextField from '../../../components/ui/TextField';
import { FormComponentProps } from '../../../models/form';

const ValidationSchema = Yup.object().shape({
  email: Yup.string().default('').required('Email required.'),
  password: Yup.string().default('').required('Password required.'),
});

const Button = styled(ButtonStyle)`
  width: 100%;
`;

export interface LoginFormValue {
  email: string;
  password: string;
}

export interface LoginFormProps extends FormComponentProps<LoginFormValue> {}

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { handleSubmit, control, errors, formState } = useForm<LoginFormValue>({
    resolver: yupResolver(ValidationSchema),
    mode: 'onChange',
  });
  const history = useHistory();
  const onSubmit = (data: LoginFormValue) => props.onCompleted(data);
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <TextField type="email" placeholder="Email" name="email" control={control} defaultValue="" errors={errors} />
      <Spacer type="stack" size="xs" />

      <TextField
        type="password"
        placeholder="Password"
        name="password"
        defaultValue=""
        control={control}
        errors={errors}
      />
      <Spacer type="stack" size="xs" />
      <Button btnType="text" onClick={() => history.push('/forgot-password')}>
        Forgot password?
      </Button>
      <Spacer type="stack" size="s" />
      <StyledSubmitWrapper>
        <Button btnType="primary" type="submit" disabled={formState.touched && !formState.isValid}>
          LOG IN
        </Button>
      </StyledSubmitWrapper>
    </StyledForm>
  );
};

export default LoginForm;
