import { gql } from '@apollo/client';

export const CHANGE_PASSWORD = gql`
  mutation ($data: ChangePasswordRequest!) {
    changePassword(data: $data) {
      ... on User {
        id
        username
      }
      ... on UserError {
        errorMessage
      }
    }
  }
`;
