import React, { useContext, useState, useCallback } from 'react';

export interface StepProviderValue {
  activeStep: OnboardingStep;
  nextStep: () => void;
  previousStep: () => void;
}

export interface OnboardingStep {
  label: string;
  order: number;
  description: string;
  required: boolean;
}

export enum OnboardingStepType {
  INFO = 'Info',
  PASSWORD = 'Password',
  FINISHED = 'Finished',
}

export const ONBOARDING_STEPS: OnboardingStep[] = [
  {
    description: 'Set your personal information',
    label: OnboardingStepType.INFO,
    order: 0,
    required: true,
  },
  {
    description: 'Set your password',
    label: OnboardingStepType.PASSWORD,
    order: 1,
    required: true,
  },
  {
    description: 'Finished',
    label: OnboardingStepType.FINISHED,
    order: 2,
    required: true,
  },
];

export const StepContext = React.createContext<StepProviderValue | null>(null);

export const StepProvider: React.FC = ({ children }) => {
  const [activeStep, setActiveStep] = useState<OnboardingStep>(ONBOARDING_STEPS[0]);

  const previousStep = useCallback(() => {
    const activeIndex = activeStep.order;
    const previousIndex = Math.max(activeIndex - 1, 0);
    const previous = ONBOARDING_STEPS[previousIndex];
    setActiveStep(previous);
  }, [activeStep]);

  const nextStep = useCallback(() => {
    const activeIndex = activeStep.order;
    const nextIndex = Math.min(activeIndex + 1, ONBOARDING_STEPS.length - 1);
    const next = ONBOARDING_STEPS[nextIndex];
    setActiveStep(next);
  }, [activeStep]);

  const ctxValue: StepProviderValue = {
    activeStep,
    previousStep,
    nextStep,
  };

  return <StepContext.Provider value={ctxValue}>{children}</StepContext.Provider>;
};

export const useStepCtx = () => {
  const context = useContext(StepContext);
  if (context === null) {
    throw new Error('useStepCtx must be used within a StepProvider');
  }
  return context;
};
