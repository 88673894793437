import { gql } from '@apollo/client';

export interface OnboardingDataResponse {
  onboardingData: OnboardingData;
}
export interface OnboardingData {
  emailNotifications: boolean;
  fullName: string;
  phone: string;
  username: string;
  errorMessage?: string;
}
export interface OnboardingDataParams {
  confirmEmailId: string;
}

export const ONBOARDING_DATA = gql`
  query onboardingData($confirmEmailId: String!) {
    onboardingData(confirmEmailId: $confirmEmailId) {
      ... on OnboardingData {
        emailNotifications
        fullName
        phone
        username
      }
      ... on OnboardingError {
        errorMessage
      }
    }
  }
`;
