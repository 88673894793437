import { gql } from '@apollo/client';
import { FacebookCampaignObjective } from '../../components/utils/types';

export interface CreateFacebookCampaignRequest {
  locationId: string;
  objective: FacebookCampaignObjective;
}
export interface CreateFacebookCampaignResponse {
  success: boolean;
  message?: string;
}

export const CREATE_FACEBOOK_CAMPAIGN = gql`
  mutation createFacebookCampaign($locationId: String!, $objective: String!) {
    createFacebookCampaign(locationId: $locationId, objective: $objective) {
      success
      message
    }
  }
`;
