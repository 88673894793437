import React, { useState } from 'react';

import { match } from 'ts-pattern';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import { ConnectAccountsLayout } from '../../../components/Layout/ConnectAccountsLayout';
import { ConnectedAccountsMenu } from '../../../components/Menu/ConnectedAccountsMenu';
import { ModalWithFooter } from '../../../components/ui/ModalWithFooter';
import { LabelBodyR } from '../../../components/ui/Text/Text.styled';
import { useMyProfileQuery, UserProfileResponse } from '../../../graphql/query/userProfile';
import { ManageFbAccount } from './ManageFbAccount';
import { ManageGoogleAccount } from './ManageGoogleAccount';
import { ManageTikTokAccount } from './ManageTikTokAccount';
import { ManageTikTokCreator } from './ManageTikTokCreator';

export const validateAuthentication = (data: UserProfileResponse): boolean => {
  const {
    myInfo: { isConnectedToFacebook, isConnectedToGoogle, isConnectedToTikTok },
  } = data;
  const connectedToFacebook = isConnectedToFacebook.isActive;
  const connectedToGoogle = isConnectedToGoogle.isActive;
  const connectedToTikTok = isConnectedToTikTok.isActive;
  return connectedToFacebook || connectedToGoogle || connectedToTikTok;
};

export enum OnboardingAccount {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  TIKTOK = 'tikTok',
  TIKTOK_CREATOR = 'tikTokCreator',
}
export const UserSettings = () => {
  let { account } = useParams<{ account: string }>();
  const history = useHistory();
  const location = useLocation<{ loginRedirected?: boolean }>();
  const isLoginRedirect = location.state?.loginRedirected ?? false;
  const { data, loading, refetch } = useMyProfileQuery();
  const [showConnectMessage, setShowConnectMessage] = useState(true);
  const selected = account as OnboardingAccount;
  React.useEffect(() => {
    if (data) {
      const isAuthenticated = validateAuthentication(data);
      if (isAuthenticated && isLoginRedirect) history.push(`/app/dashboard`);
      setShowConnectMessage(!isAuthenticated);
    }
  }, [data, history, loading, isLoginRedirect]);

  const userAccountColumn = (data: UserProfileResponse) =>
    match(selected)
      .with(OnboardingAccount.FACEBOOK, () => <ManageFbAccount data={data} refetch={refetch} />)
      .with(OnboardingAccount.GOOGLE, () => <ManageGoogleAccount data={data} refetch={refetch} />)
      .with(OnboardingAccount.TIKTOK, () => <ManageTikTokAccount data={data} refetch={refetch} />)
      .with(OnboardingAccount.TIKTOK_CREATOR, () => <ManageTikTokCreator data={data} refetch={refetch} />)
      .exhaustive();

  return (
    <>
      {!loading && data && (
        <>
          <ConnectAccountsLayout
            userAccountColumn={userAccountColumn(data)}
            accountsColumn={<ConnectedAccountsMenu selected={selected || OnboardingAccount.GOOGLE} data={data} />}
          ></ConnectAccountsLayout>
          <ModalWithFooter
            visible={showConnectMessage}
            onCancel={() => setShowConnectMessage(false)}
            onOk={() => setShowConnectMessage(false)}
            title={'Now, it’s time to connect your accounts!'}
            okText={'CONNECT ACCOUNTS'}
          >
            <div>
              <LabelBodyR>
                It’s great to have you with us, {data?.myInfo?.fullName}! To start, connect your accounts
              </LabelBodyR>
            </div>
          </ModalWithFooter>
        </>
      )}
    </>
  );
};
