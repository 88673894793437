import { gql, useQuery } from '@apollo/client';

export interface UserCountByOrgIdResponse {
  userCountByOrgId: number;
}
export interface UserCountByOrgIdParams {
  organizationId: string;
}

export const USER_COUNT_BY_ORG_ID = gql`
  query userCountByOrgId($organizationId: String!) {
    userCountByOrgId(organizationId: $organizationId)
  }
`;

export function useUserCountByOrgId(organizationId: string) {
  const { data, error, loading } = useQuery<UserCountByOrgIdResponse, UserCountByOrgIdParams>(USER_COUNT_BY_ORG_ID, {
    variables: {
      organizationId: organizationId,
    },
    fetchPolicy: 'cache-and-network',
  });
  return {
    userCount: data?.userCountByOrgId || 0,
    error,
    loading,
  };
}
