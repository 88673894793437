import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SideMenuLayout } from '../../components/Layout/SideMenuLayout';
import { HealthScoreColumn } from './HealthScoreColumn/HealthScoreColumn';
import { RadioGroup, RadioGroupOption } from '../../components/ui/RadioGroup/RadioGroup';
import { RadioChangeEvent } from 'antd';
import { Spacer } from '../../components/ui/Spacer';
import { FilterByNumber } from '../../components/ui/Filters';
import {
  HealthLevel,
  LocationHealthSummary,
  useLocationHealthSummaryQuery,
} from '../../graphql/query/locationHealthSummaries';

const MenuWrapper = styled.div`
  padding: 16px 16px;
  width: 100%;
`;

const FilterWrapper = styled.div`
  align-items: left;
  text-align: left;
`;

enum AccountType {
  ALL = 'ALL',
  PARTNER = 'Partner',
}

const accountTypeOptions: RadioGroupOption[] = [
  { label: 'All', value: AccountType.ALL },
  { label: 'Partner', value: AccountType.PARTNER },
];

const HealthScoreDashboard: React.FC = () => {
  const [accountType, setAccountType] = useState<AccountType>(AccountType.ALL);
  const { locationHealthSummaries: healthSummaries, refetch, error } = useLocationHealthSummaryQuery({});
  const [filters, setFilters] = useState<HealthLevel[]>([]);
  const [filteredSummaries, setFilteredSummaries] = useState<LocationHealthSummary[]>([]);

  const redCount = healthSummaries.filter((summary) => summary.healthLevel === HealthLevel.Red).length;
  const yellowCount = healthSummaries.filter((summary) => summary.healthLevel === HealthLevel.Yellow).length;
  const greenCount = healthSummaries.filter((summary) => summary.healthLevel === HealthLevel.Green).length;

  useEffect(() => {
    if (healthSummaries.length && filters.length) {
      const filteredSummaries = healthSummaries.filter((summary) =>
        filters.includes(summary.healthLevel as HealthLevel)
      );
      setFilteredSummaries(filteredSummaries);
    } else if (!error) {
      setFilteredSummaries(healthSummaries);
    }
  }, [filters, healthSummaries, error]);

  const handleChangeCondition = ({ target: { value } }: RadioChangeEvent) => {
    setAccountType(value);
    if (value === AccountType.PARTNER) {
      refetch({ partnersOnly: true });
    } else {
      refetch({ partnersOnly: false });
    }
  };

  const handleSearch = useCallback(
    (search: string) => {
      if (search) {
        refetch({
          partnersOnly: AccountType.PARTNER === accountType,
          search,
        });
      }
    },
    [accountType, refetch]
  );

  return (
    <SideMenuLayout
      title="Health Summaries"
      sideColumn={
        <MenuWrapper>
          <RadioGroup
            size="middle"
            defaultValue={accountType}
            onChange={handleChangeCondition}
            options={accountTypeOptions}
          ></RadioGroup>
          <Spacer type="stack" size="s" />
          <FilterWrapper>
            <FilterByNumber
              title="Health Level"
              selectedItems={filters}
              options={[
                { id: HealthLevel.Red, name: `${HealthLevel.Red} (${redCount})` },
                { id: HealthLevel.Yellow, name: `${HealthLevel.Yellow} (${yellowCount})` },
                { id: HealthLevel.Green, name: `${HealthLevel.Green} (${greenCount})` },
              ]}
              onChange={(checkedValue) => setFilters(checkedValue as HealthLevel[])}
            />
          </FilterWrapper>
        </MenuWrapper>
      }
      contentColumn={<HealthScoreColumn healthSummaries={filteredSummaries} handleSearch={handleSearch} />}
    />
  );
};

export default HealthScoreDashboard;
