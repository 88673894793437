import { gql, useQuery } from '@apollo/client';

export enum HealthLevel {
  Red = 'Red',
  Yellow = 'Yellow',
  Green = 'Green',
}

export interface LocationHealthSummary {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  locationId: string;
  locationName: string;
  organizationId: string;
  organizationName: string;
  healthLevel: HealthLevel;
  notes?: string;
  snoozedUntil?: Date;
  lastRefreshedAt?: Date;
  lastRefreshStatus?: Date;
}

export interface LocationHealthSummaryResponse {
  locationHealthSummaries: LocationHealthSummary[];
}
export interface LocationsHealthSummaryParams {
  partnersOnly?: boolean;
  search?: string;
}

export const LOCATIONS_HEALTH_SUMMARIES_NAME = 'locationHealthSummaries';
export const LOCATION_HEALTH_SUMMARIES = gql`
    query ${LOCATIONS_HEALTH_SUMMARIES_NAME}($search: String, $partnersOnly: Boolean) {
    ${LOCATIONS_HEALTH_SUMMARIES_NAME}(search: $search, partnersOnly: $partnersOnly) {
      id
      createdAt
      updatedAt
      locationId
      locationName
      organizationId
      organizationName
      healthLevel
      notes
      snoozedUntil
      lastRefreshedAt
      lastRefreshStatus
  }
}
`;
export function useLocationHealthSummaryQuery({ search, partnersOnly }: { partnersOnly?: boolean; search?: string }) {
  const { data, error, loading, refetch } = useQuery<LocationHealthSummaryResponse, LocationsHealthSummaryParams>(
    LOCATION_HEALTH_SUMMARIES,
    {
      variables: { search, partnersOnly },
    }
  );
  return {
    locationHealthSummaries: data?.locationHealthSummaries || [],
    error,
    loading,
    refetch,
  };
}
