import React, { useCallback, useState } from 'react';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useMutation, useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import {
  EXPORT_INVENTORY,
  ExportInventoryRequest,
  ExportInventoryResponse,
} from '../../../graphql/mutations/exportInventoryToShop';
import { CatalogTypes } from '../../../graphql/query/createFbCatalog';
import { Condition } from '../../../graphql/query/inventoryByLocationId';
import { VehicleFilters } from '../../../pages/Inventory/hooks/useVehicleFilters';
import { Button as ButtonEl } from '../../ui/Buttons/Button';
import { FilterByNumber } from '../../ui/Filters';
import { RadioGroup, RadioGroupOption } from '../../ui/RadioGroup/RadioGroup';
import { snackbar } from '../../ui/Snackbar';
import { Spacer } from '../../ui/Spacer';
import { filtersOptions } from '../../ui/VehicleColumn/utils/filters';
import { OptionsWrapper, RadioOption } from './InventoryMenu';
import {
  INELIGIBLE_MARKETPLACE_INVENTORY_CSV,
  useIneligibleMarketplaceReport,
} from '../../../graphql/query/ineligibleMarketplaceInventory';
import Modal from 'antd/lib/modal/Modal';
import { Permission, useHasPermission } from '../../../hooks/useHasPermission';

const FilterWrapper = styled.div`
  align-items: left;
  text-align: left;
`;
const Button = styled(ButtonEl)`
  width: 100%;
`;

interface VehicleMenuProps {
  hasVehicleCatalog: boolean;
  hasShopId: boolean;
  locationId: string;
  VehicleFilters: VehicleFilters;
}
export const VehicleMenu: React.FC<VehicleMenuProps> = (props) => {
  const { hasVehicleCatalog, hasShopId, locationId, VehicleFilters } = props;
  const { selectedFilters, setSelectedFilters, setCondition } = VehicleFilters;
  const [exportInventory] = useMutation<ExportInventoryResponse, ExportInventoryRequest>(EXPORT_INVENTORY);
  const [showMarketplaceReport, setShowMarketplaceReport] = useState(false);
  const handleCategoryChange = (checkedValue: CheckboxValueType[] = []) => {
    setSelectedFilters(checkedValue as number[]);
  };

  const canExportInventory = useHasPermission(Permission.ExportInventory);

  const [getIneligibleMarketplaceInventoryCsv] = useLazyQuery<
    {
      getIneligibleMarketplaceInventoryCsv: string;
    },
    { locationId: string }
  >(INELIGIBLE_MARKETPLACE_INVENTORY_CSV);
  const { report: marketplaceReport } = useIneligibleMarketplaceReport(locationId || '');
  const conditionGroupOptions: RadioGroupOption[] = [
    { label: 'All', value: Condition.All },
    { label: 'New', value: Condition.New },
    { label: 'Used', value: Condition.Used },
  ];

  const handleChangeCondition = ({ target: { value } }: RadioChangeEvent) => {
    setCondition(value);
  };

  const toggleShowMarketplaceReport = useCallback(() => {
    setShowMarketplaceReport(!showMarketplaceReport);
  }, [showMarketplaceReport]);

  const onDownloadMarketplaceReport = async () => {
    const { data } = await getIneligibleMarketplaceInventoryCsv({ variables: { locationId } });
    const csv = data?.getIneligibleMarketplaceInventoryCsv;
    if (!csv) return;

    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = `Marketplace Ineligible Report-${locationId}.csv`;
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const onExportInventory = async (place: CatalogTypes) => {
    try {
      await exportInventory({
        variables: {
          locationId,
          place: place,
        },
      });
      snackbar.success({
        message: 'Inventory exported',
      });
    } catch (e) {
      Sentry.captureException(e);
      snackbar.error({
        message: `Inventory wasn't exported`,
      });
    }
  };

  return (
    <div>
      <OptionsWrapper>
        <RadioOption>
          <RadioGroup
            onChange={handleChangeCondition}
            defaultValue={Condition.All}
            options={conditionGroupOptions}
          ></RadioGroup>
        </RadioOption>
      </OptionsWrapper>
      <Spacer type="stack" size="s" />
      <FilterWrapper>
        <FilterByNumber
          title="Filters"
          selectedItems={selectedFilters}
          options={filtersOptions}
          onChange={handleCategoryChange}
        />
        <Spacer type="stack" size="s" />
        <Button btnType="secondary" size="small" type="button" onClick={toggleShowMarketplaceReport}>
          Marketplace Ineligible Report
        </Button>
        <Spacer type="stack" size="xxs" />
        <Button btnType="secondary" size="small" type="button" onClick={() => onDownloadMarketplaceReport()}>
          Download Marketplace Report
        </Button>
        <Spacer type="stack" size="xxs" />
        {canExportInventory && hasShopId && (
          <>
            <Button
              btnType="secondary"
              size="small"
              type="button"
              onClick={() => onExportInventory(CatalogTypes.PRODUCT)}
            >
              Export to Shop
            </Button>
            <Spacer type="stack" size="xxs" />
          </>
        )}
        {canExportInventory && hasVehicleCatalog && (
          <>
            <Button
              btnType="secondary"
              size="small"
              type="button"
              onClick={() => onExportInventory(CatalogTypes.VEHICLE)}
            >
              Export to Vehicles Tab
            </Button>
            <Spacer type="stack" size="xxs" />
          </>
        )}
        <Modal open={showMarketplaceReport} onOk={toggleShowMarketplaceReport} onCancel={toggleShowMarketplaceReport}>
          <h1>Marketplace Ineligible Report</h1>
          <p>
            {marketplaceReport?.map((item: any) => (
              <div key={item.reason}>
                {item.reason} - {item.count}
              </div>
            ))}
          </p>
        </Modal>
      </FilterWrapper>
    </div>
  );
};
