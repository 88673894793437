import React, { useState } from 'react';

import { StyledComponent } from 'styled-components';

import {
  ConnectAccount,
  InactiveButton,
  PrimaryButton,
  SecondaryButton,
  SelectedButton,
  StyledButtonProps,
  TextButton,
} from './Buttons.styled';

export interface ButtonProps extends StyledButtonProps {
  btnType?: 'primary' | 'secondary' | 'text' | 'connect' | 'inactive' | 'selected';
  disabled?: boolean;
  alert?: boolean;
}

export const Button: React.FC<ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  btnType,
  alert,
  ...btnProps
}) => {
  const [btnState, setBtnState] = useState<'active' | 'hover' | 'focus' | ''>('');
  let Element: StyledComponent<'button', any, StyledButtonProps, never>;

  switch (btnType) {
    case 'primary':
      Element = PrimaryButton;
      break;
    case 'secondary':
      Element = SecondaryButton;
      break;
    case 'inactive':
      Element = InactiveButton;
      break;
    case 'connect':
      Element = ConnectAccount;
      break;
    case 'selected':
      Element = SelectedButton;
      break;
    default:
      Element = TextButton;
      break;
  }
  return (
    <Element
      type="button"
      {...btnProps}
      state={btnState}
      onMouseOver={() => setBtnState('hover')}
      onMouseDown={() => setBtnState('active')}
      onMouseUp={() => setBtnState('hover')}
      onMouseLeave={() => setBtnState('')}
      onKeyUp={() => setBtnState('focus')}
      onBlur={() => setBtnState('')}
      tabIndex={0}
      alert={alert || false}
    ></Element>
  );
};
Button.defaultProps = {
  btnType: 'secondary',
};
