import React from 'react';

import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import styled from 'styled-components';

import AccountInfoCard from '../../../../components/ui/AccountInfoCard/AccountInfoCard';
import { Button } from '../../../../components/ui/Buttons/Button';
import { snackbar } from '../../../../components/ui/Snackbar';
import { H4 } from '../../../../components/ui/Text/Text.styled';
import {
  DISCONNECT_USER_ACCOUNT,
  DisconnectUserAccountRequest,
  DisconnectUserAccountResponse,
} from '../../../../graphql/mutations/disconnectAccount';
import { OnboardingAccount } from '../UserSettings';

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TextContainer = styled.div`
  flex-direction: column;
  line-break: after;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

export const RowContainer = styled.div`
  width: 500px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 0;
`;
export const Title = styled(H4)`
  font-weight: bold;
  padding-top: 5px;
  color: ${(props) => props.theme.colors.dimGray.minus2};
`;

export interface AccountInfoProps {
  avatar?: string;
  fullName?: string;
  description?: string;
  isConnected?: boolean;
  source: OnboardingAccount;
}

export const AccountInfo: React.FC<AccountInfoProps> = ({ avatar, fullName, description, source }) => {
  const [disconnectUser] = useMutation<DisconnectUserAccountResponse, DisconnectUserAccountRequest>(
    DISCONNECT_USER_ACCOUNT
  );
  const onDisconnect = async () => {
    try {
      await disconnectUser({
        variables: {
          platform: source,
        },
      });
      snackbar.error({
        message: `User Account disconnected`,
      });
      window.location.reload();
    } catch (error) {
      Sentry.captureException(error);
      snackbar.error({
        message: `User Account can't disconnect its account`,
      });
    }
  };
  return (
    <Section>
      <RowContainer>
        <AccountInfoCard size="medium" avatar={avatar} accountName={fullName || ''} detail={description || ''} />
        <Button btnType={'connect'} size="small" style={{ width: '108px' }} onClick={onDisconnect}>
          <span className="text-action-button">{'CONNECTED'}</span>
        </Button>
      </RowContainer>
    </Section>
  );
};
