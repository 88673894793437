import React, { useEffect, useState } from 'react';

import ImgCrop from 'antd-img-crop';
import { RcFile } from 'antd/lib/upload';
//@ts-ignore
import { RcCustomRequestOptions, UploadListType } from 'antd/lib/upload/interface';
import axios, { AxiosRequestConfig } from 'axios';

import { FileImageOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { UPLOAD_USER_AVATAR, UploadAvatarRequest, UploadAvatarResponse } from '../../../graphql/mutations/uploadAvatar';
import { LabelBodyR } from '../Text/Text.styled';
import { StyledUpload, WhiteIcon } from './UploadButton.styled';

export interface UploadButtonProps {
  size: 'medium' | 'large' | 'xLarge';
  customSize?: { width: string; height: string };
  radious?: string;
  loaded?: boolean;
  error?: boolean;
  uploadProgress?: number;
  onChange: (data: UploadAvatarResponse) => void;
  defaultAvatar?: {
    id: number;
    signedUrl: string;
  };
  setExternalImage?: (image: string) => void;
  showLabel?: boolean;
  listType?: UploadListType;
}

export const contentTypes = {
  'image/png': 'PNG',
  'image/jpg': 'JPG',
  'image/jpeg': 'JPEG',
};

const UploadButton: React.FC<UploadButtonProps> = ({
  radious,
  customSize,
  onChange,
  showLabel,
  listType = 'picture-card',
  setExternalImage,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [error, setError] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileList, setFileList] = useState<any>([]);
  const [deleted, setDeleted] = useState<boolean>(false);
  const deletedResponse: UploadAvatarResponse = { uploadUserAvatar: { id: 0, signedUrl: '', uuid: '' } };
  const [uploadAvatar, { data }] = useMutation<UploadAvatarResponse, UploadAvatarRequest>(UPLOAD_USER_AVATAR);

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const removeFile = async () => {
    setLoaded(false);
    setError(false);
    setFileList([]);
    setDeleted(true);
  };

  useEffect(() => {
    if (props.defaultAvatar?.signedUrl) {
      setLoaded(true);
      setImgUrl(props.defaultAvatar.signedUrl);
    }
  }, [props.defaultAvatar?.signedUrl]);

  const handleChange = (info: any) => {
    let newFileList = [...fileList];
    newFileList[0] = info.file;
    setFileList(newFileList);
    setUploadProgress(info.file.percent);
    setDeleted(false);
    if (info.file.status === 'uploading') {
      setLoading(true);
      setError(false);
    }
    if (info.file.status === 'done') {
      setLoading(false);
      getBase64(info.file.originFileObj, (imgURL: string) => {
        setImgUrl(imgURL);
      });
      setLoaded(true);
      setError(false);
    }
    if (info.file.status === 'error') {
      setLoading(false);
      setLoaded(false);
      setError(true);
    }

    if (info.fileList.length === 0) {
      setLoaded(false);
      setError(false);
    }
  };

  useEffect(() => {
    if (data) {
      onChange(data!);
    }
    if (deleted) {
      onChange(deletedResponse);
    }
  });
  const handleBeforeUpload = async ({ name, type }: RcFile): Promise<void> => {
    // Fetches the Signed URL from S3 bucket
    // Prepend with the ID to make the file name unique
    let typen = type as keyof typeof contentTypes;
    await uploadAvatar({
      variables: {
        data: {
          contentType: contentTypes[typen],
          ext: name.split('.').pop()?.toUpperCase()!,
          filename: name.split('.').slice(0, -1).join('.'),
        },
      },
    });
    if (data) {
      onChange(deleted ? deletedResponse : data!);
    }
  };

  const handleUpload = async ({ file }: RcCustomRequestOptions) => {
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: (e: ProgressEvent) => {
        setUploadProgress(Math.round((e.loaded * 100) / e.total));
      },
    };
    await axios
      .put(data?.uploadUserAvatar.signedUrl!, file, config)
      .then((result) => {
        const { url } = result.config;
        const path = url!.slice(0, url?.indexOf('?X-Goog-Algorithm='));
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        if (setExternalImage) {
          setExternalImage(path);
          setLoading(false);
        } else {
          setImgUrl(path);
          setLoaded(true);
          setLoading(false);
          setError(false);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        setError(true);
        setLoading(false);
        setLoaded(false);
      });
  };

  return (
    <>
      <ImgCrop aspect={4 / 3}>
        <StyledUpload
          method="PUT"
          uploadProgress={uploadProgress}
          fileList={fileList}
          size={props.size}
          loaded={loaded}
          openFileDialogOnClick={!loaded && !error}
          error={error}
          listType={listType}
          showUploadList={false}
          className="avatar-uploader"
          onChange={handleChange}
          action={data?.uploadUserAvatar.signedUrl}
          beforeUpload={handleBeforeUpload}
          customRequest={(e) => handleUpload(e)}
          radious={radious}
          customSize={customSize}
        >
          {listType === 'picture-card' ? (
            <div className="ant-upload ant-upload-drag">
              {loaded ? (
                <div className="ant-upload-list-item-list-type-picture-card">
                  <img className="ant-upload-list-item-image thumbnail-avatar" src={imgUrl} alt="avatar" />
                  <div className="actions">
                    <WhiteIcon className="icon misc-edit" />
                    <WhiteIcon className="icon misc-delete" onClick={removeFile} />
                  </div>
                </div>
              ) : (
                <div className="inner-circle">
                  {error ? (
                    <>
                      <FileImageOutlined />
                      <div className="actions">
                        <WhiteIcon className="icon misc-edit" />
                        <WhiteIcon className="icon misc-delete" onClick={removeFile} />
                      </div>
                    </>
                  ) : (
                    <div>
                      <i className="icon misc-camera" />
                      {showLabel && <LabelBodyR>Drag and drop an image to this area to upload.</LabelBodyR>}
                    </div>
                  )}
                  {loading && (
                    <div className="progress-wrapper">
                      <div className="ant-progress-bg"></div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </StyledUpload>
      </ImgCrop>
    </>
  );
};

export default UploadButton;
