import React from 'react';

import { Spacer } from '../../ui/Spacer';
import { VerticalTabItemWrapper } from './VerticalTabItem.styled';

const iconLookup = {
  users: 'icon-small-submodule-users',
  customers: 'icon-small-submodule-customers',
  directory: 'icon-small-submodule-directory',
};

export interface NavigationTabItemProps {
  label: string;
  icon: keyof typeof iconLookup;
  count?: number;
  active?: boolean;
}
export const VerticalTabItem: React.FC<NavigationTabItemProps> = ({ active, icon, label, count }) => (
  <VerticalTabItemWrapper active={active || false}>
    <i className={`icon tab-icon ${iconLookup[icon]}`} />
    <Spacer type="inline" size="xs" />
    <div className="tab-text">{label}</div>
    <Spacer type="inline" size="s" />

    <div className="tab-count">{count || 0}</div>
  </VerticalTabItemWrapper>
);
