import React from 'react';

import styled from 'styled-components';

import { OnboardingAccount } from '../../../pages/SettingsPage/UserSettings/UserSettings';
import { Heading4 } from '../Headings/Headings.styled';
import { AvatarSizes, innerWrapperSizes, LetterAvatar } from './AvatarImage';

const SocialAccountsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LogoAvatar = styled.img<ImageAvatarProps>`
  display: block;
  object-fit: cover;
  height: ${(props) => innerWrapperSizes[props.size || 'medium']};
  width: ${(props) => innerWrapperSizes[props.size || 'medium']};
  border-radius: 50%;
  flex-shrink: 0;
  z-index: 0;
`;

const Label = styled.span<ImageAvatarProps>`
  position: absolute;
  height: 15px;
  width: 15px;
  top: 17px;
  left: 17px;
  border-radius: 50%;
  z-index: 1;
  border: 1px solid #ffffff;
  border-radius: 24px;
  background-color: white;
`;

interface ImageAvatarProps {
  src?: string;
  size?: 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge';
  active?: boolean;
  nameAvatar?: string;
}

const SocialIcon = styled.div`
  position: relative;
  left: 0;
  top: 0;
  padding-right: 8px;
`;

interface AvatarWithLabelProps {
  imgUrl: string;
  includeLabel?: boolean;
  name: string;
  label: string;
  size?: AvatarSizes;
}

export const AvatarWithLabel: React.FC<AvatarWithLabelProps> = ({
  imgUrl,
  name,
  label,
  children,
  includeLabel = true,
  size,
}) => {
  const icon = label === OnboardingAccount.TIKTOK_CREATOR ? 'tiktok' : label;
  return (
    <SocialAccountsWrapper>
      <SocialIcon>
        {imgUrl ? (
          <LogoAvatar size={size} src={imgUrl || ''} />
        ) : (
          <LetterAvatar size={size}>
            <Heading4>{name?.charAt(0).toUpperCase() || '?'}</Heading4>
          </LetterAvatar>
        )}
        {includeLabel && <Label className={`ic ic_${icon.toLowerCase()}`} />}
      </SocialIcon>
      {children}
    </SocialAccountsWrapper>
  );
};
