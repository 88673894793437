import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { Spacer } from '../../ui/Spacer';
import { NavigationTabItemProps, VerticalTabItem } from '../../ui/VerticalTabItem/VerticalTabItem';

export interface UserNavigationLinkProps extends NavigationTabItemProps {
  href?: string;
}
const UserNavigationLink: React.FC<UserNavigationLinkProps> = ({ href, ...props }) => {
  const router = useLocation();
  let isActive = false;
  if (router.pathname === href) {
    isActive = true;
  }
  return (
    <Link to={href || '/'}>
      <VerticalTabItem icon={props.icon} label={props.label} count={props.count} active={isActive} />
      <Spacer type="stack" size="xs" />
    </Link>
  );
};
export default UserNavigationLink;
