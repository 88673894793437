import React, { useState, useEffect } from 'react';
import { Input, Typography } from 'antd';

const { TextArea } = Input;
const { Text } = Typography;

interface NotesSectionProps {
  initialValue?: string;
  onSave: (content: string) => Promise<void>;
  style?: React.CSSProperties;
  className?: string;
}

const NotesSection: React.FC<NotesSectionProps> = ({ initialValue = '', onSave, style, className }) => {
  const [content, setContent] = useState(initialValue);
  const [isSaving, setIsSaving] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setContent(initialValue);
  }, [initialValue]);

  const handleContentChange = (value: string) => {
    setContent(value);
    setHasUnsavedChanges(true);

    if (saveTimeout) clearTimeout(saveTimeout);

    const timeout = setTimeout(async () => {
      setIsSaving(true);
      try {
        await onSave(value);
        setHasUnsavedChanges(false);
      } catch (err) {
        console.error('Failed to save notes:', err);
      } finally {
        setIsSaving(false);
      }
    }, 1000);

    setSaveTimeout(timeout);
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges || isSaving) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges, isSaving]);

  useEffect(() => {
    return () => {
      if (saveTimeout) clearTimeout(saveTimeout);
    };
  }, [saveTimeout]);

  return (
    <div style={style} className={className}>
      <TextArea
        value={content}
        onChange={(e) => handleContentChange(e.target.value)}
        placeholder="Write your notes here..."
        autoSize={{ minRows: 5, maxRows: 10 }}
      />
      <Text
        type="secondary"
        style={{
          display: 'block',
          marginTop: '8px',
          fontStyle: 'italic',
        }}
      >
        {isSaving ? 'Saving...' : hasUnsavedChanges ? 'Changes not saved yet.' : 'All changes saved.'}
      </Text>
    </div>
  );
};

export default NotesSection;
