import { InventoryItem } from '../graphql/query/inventoryByLocationId';

export function decodeEntities(encodedString: string) {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = encodedString;
  return textArea.value.replaceAll('&quot;', ' ');
}

export function capitalizeOnlyFirstLetter(word?: string) {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase();
}

export function capitalizeFirstLetter(word?: string) {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function displayRange(numberOfCharacters: number, name: string) {
  if (name?.length < numberOfCharacters) {
    return name;
  }
  const shortName = name?.slice(0, numberOfCharacters) + '...';
  return shortName;
}

export function formatVehicleField(item: InventoryItem): string {
  const { condition, year, make, model, trim } = item;
  if (condition && year && make && model) {
    return `${condition} ${year} ${make} ${model} ${trim}`.trim();
  }

  return item?.vehicle || item?.model || '';
}
