import React from 'react';

import styled from 'styled-components';

import { Spacer } from '../Spacer';
import { LabelR } from '../Text/Text.styled';

const ValidationCriteriaWrapper = styled.div`
  display: flex;
  align-items: center;
  & > ${LabelR} {
    color: ${(props) => props.theme.colors.dimGray.minus1};
  }
`;
export const DoneIcon = styled.span<{ active: boolean }>`
  height: 13.3px;
  width: 13.3px;
  font-size: 12px;
  color: ${(props) => (props.active ? props.theme.colors.purpleNavi.base : props.theme.colors.dimGray.plus2)};
`;

interface ValidationCriteriaProps {
  label: string;
  valid: boolean;
}
const ValidationCriteria: React.FC<ValidationCriteriaProps> = (props) => (
  <ValidationCriteriaWrapper>
    <DoneIcon active={props.valid} className="icon icon-small-misc-done-fill-circle" />
    <Spacer type="inline" size="xxs" />
    <LabelR>{props.label}</LabelR>
  </ValidationCriteriaWrapper>
);
export default ValidationCriteria;
