import React from 'react';

import styled, { css } from 'styled-components';

export const AccountMenuItemWrapper = styled.div`
  width: 100%;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ConnectAccountWrapper = styled.div`
  width: 100%;
  padding: 8px 16px 8px 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .label {
    font-size: 12px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.purpleNavi.plus1};
  }
`;
export const ConnectAccountBtn: React.FC<{ onClick?: () => void; className?: string }> = ({ onClick, className }) => (
  <ConnectAccountWrapper onClick={onClick} className={className}>
    <span className="label">Connect account</span>
    <i className="arrow-icon ic ic_arrow_right "></i>
  </ConnectAccountWrapper>
);

export const AccountIcon = styled.span`
  height: 16px;
  width: 16px;
`;

export const AccountLabel = styled.div<{ active?: boolean; disabled?: boolean }>`
  font-weight: 600;
  color: ${(props) => props.theme.colors.dimGray.minus1};
  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.colors.white};
    `}
  ${(props) =>
    props.disabled &&
    css`
      color: ${(props) => props.theme.colors.dimGray.plus2};
    `}
`;

export const AccountSubItemListWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-left: 32px;
  &::before {
    display: block;
    position: absolute;
    content: ' ';
    width: 4px;
    top: 8px;
    bottom: 8px;
    left: 24px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.dimGray.plus3};
  }
`;
