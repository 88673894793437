import React, { useImperativeHandle } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormComponentProps, FormRef } from '../../../../models/form';
import { Spacer } from '../../Spacer';
import TextField from '../../TextField';

const ValidationSchema = Yup.object().shape({
  fullName: Yup.string().default('').required(),
  username: Yup.string().default('').required(),
  phoneNumber: Yup.string().default('').required(),
  emailNotifications: Yup.boolean().default(true),
});

export interface OnboardingInfoFormValue {
  username: string;
  fullName: string;
  phoneNumber: string;
  emailNotifications: boolean;
}
export interface OnboardingInfoFormProps extends FormComponentProps<OnboardingInfoFormValue> {}

const OnboardingInfoForm = React.forwardRef<FormRef<OnboardingInfoFormValue>, OnboardingInfoFormProps>(
  (props: OnboardingInfoFormProps, ref) => {
    const { handleSubmit, control, errors, formState } = useForm<OnboardingInfoFormValue>({
      resolver: yupResolver(ValidationSchema),
      mode: 'onTouched',
      defaultValues: props.initialValues,
    });

    const onSubmit = (data: OnboardingInfoFormValue) => {
      props.onCompleted(data);
    };
    const submitHandler = handleSubmit(onSubmit);
    useImperativeHandle(
      ref,
      () => ({
        formState,
        submit: submitHandler,
      }),
      [formState, submitHandler]
    );

    return (
      <form onSubmit={submitHandler}>
        <TextField
          type="text"
          name="username"
          label="EMAIL"
          placeholder="Your email"
          defaultValue=""
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="xs" />

        <TextField
          type="text"
          name="fullName"
          label="FULL NAME"
          placeholder="Your Name"
          defaultValue=""
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="xs" />

        <TextField
          type="text"
          name="phoneNumber"
          label="PHONE"
          placeholder="Your Phone"
          defaultValue=""
          control={control}
          errors={errors}
        />
      </form>
    );
  }
);

export default OnboardingInfoForm;
