import { gql, useQuery } from '@apollo/client';

import { PostMetric } from './inventoryByLocationId';

export enum PostTypeEnum {
  OFFER = 'offer',
  VEHICLE = 'vehicle',
  LOCATION_CONTENT = 'locationContent',
}

export interface GetPostByLocationIdResponse {
  getPostsByLocationId: PostMetric[];
}
export interface GetPostByLocationParams {
  locationId: string;
  type: PostTypeEnum;
  search?: string;
}

export const GET_POST_BY_LOCATIONID = gql`
  query getPostsByLocationId($locationId: String!, $type: String!, $search: String) {
    getPostsByLocationId(locationId: $locationId, type: $type, search: $search) {
      postId
      inventoryId
      title
      source
      date
      clicks
      views
      imageUrl
      status
      statusMessage
    }
  }
`;

export function useGetPostByLocationId(params: GetPostByLocationParams) {
  const { data, error, loading, refetch } = useQuery<GetPostByLocationIdResponse, GetPostByLocationParams>(
    GET_POST_BY_LOCATIONID,
    {
      variables: params,
      fetchPolicy: 'cache-and-network',
    }
  );

  return {
    refetch,
    posts: data?.getPostsByLocationId,
    error,
    loading,
  };
}
