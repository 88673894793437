import React from 'react';

import styled from 'styled-components';

export interface StepState {
  state?: 'active' | 'done' | 'inactive';
  title?: string;
}

export const StyledStep = styled.div<StepState>`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  .step-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    &:before {
      content: ' ';
      background-color: white;
      position: relative;
      left: 0;
      top: 20px;
      height: 40px;
      width: 40px;
      z-index: 3;
    }
  }
  .step-content .step-text {
    font-family: ${(props) => props.theme.fonts.header};
    font-weight: ${(props) => (props.state === 'active' ? '600' : '400')};
    font-size: 12px;
    z-index: 4;
  }
  .step-content .step-icon {
    border: 2px solid;
    border-radius: 50%;
    border-color: ${(props) =>
      props.state === 'active' || props.state === 'done' ? props.theme.colors.purpleNavi.plus1 : '#DADADA'};
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.purpleNavi.plus1};
    z-index: 4;
  }
`;

export const Step: React.FC<StepState> = ({ state, title }) => {
  return (
    <StyledStep state={state}>
      <div className="step-content">
        <div className="step-icon">{state === 'done' && <i className="icon misc-done-mini" />}</div>
        <div className="step-text">{title}</div>
      </div>
    </StyledStep>
  );
};

export default Step;
