import React, { useState } from 'react';
import { Empty } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import moment from 'moment';
import styled from 'styled-components';
import NoImage from '../../../../assets/images/noimage.png';
import { useGetTopPost } from '../../../../graphql/query/getTopPosts';
import { PostMetric } from '../../../../graphql/query/inventoryByLocationId';
import { DashboardFilter } from '../../../../pages/Dashboard/hooks/useFilters';
import { intToString } from '../../../utils/convert.utils';
import ItemInfoCard from '../../ItemInfoCard/ItemInfoCard';
import { RadioGroupOption } from '../../RadioGroup/RadioGroup';
import { Spacer } from '../../Spacer';
import { LabelB, LabelBodyB } from '../../Text/Text.styled';
import { SocialAccount } from '../../VehicleColumn/modeViews/VehicleSocialAccounts';
import { VehicleCardStyle } from './Components.styles';
import { VerticalRadioGroup } from './VerticalRadioGroup';

interface PostElementProps {
  position: number;
  postMetric: PostMetric;
  type?: 'sold' | 'popular';
  value?: number;
}
export const PopularPostELement: React.FC<PostElementProps> = ({ position, postMetric, type = 'popular', value }) => {
  const { soldAt, views, title, imageUrl, owner, link } = postMetric;
  let label = type === 'sold' ? moment(soldAt).fromNow() : intToString(views ?? 0);
  if (value) label = `${value}`;

  return (
    <ElementContainer as={link ? 'a' : 'div'} href={link} target={link ? '_blank' : undefined}>
      <LeftSide>
        <Order>{position + 1}</Order>
        <Spacer type="inline" size="xs" />
        <ItemInfoCard item={title} avatar={imageUrl} subtitle={owner} imgError={NoImage} />
      </LeftSide>
      <LabelB>{label}</LabelB>
    </ElementContainer>
  );
};

interface PopularPostProps {
  dashboardFilters: DashboardFilter;
}

export const PopularPost: React.FC<PopularPostProps> = ({ dashboardFilters }) => {
  const [account, setAccount] = useState<SocialAccount>(SocialAccount.FacebookMarketplace);
  const { getTopPosts } = useGetTopPost({
    topPostRequest: {
      organizationIds: dashboardFilters.organizationIds,
      startDate: dashboardFilters.dateRange.startDate,
      endDate: dashboardFilters.dateRange.endDate,
      platforms: [account],
      locationIds: dashboardFilters.locationIds,
      postTypes: dashboardFilters.postTypes,
    },
  });
  const handleChangeCondition = ({ target: { value } }: RadioChangeEvent) => {
    setAccount(value);
  };

  const options: RadioGroupOption[] = [
    { label: 'Marketplace', value: 'facebook_marketplace' },
    { label: 'Facebook', value: 'facebook' },
    { label: 'Instagram', value: 'instagram' },
    { label: 'TikTok', value: 'tik_tok' },
  ];

  return (
    <VehicleCardStyle>
      <PopularPostWrapper>
        <PopularHeading>
          <LabelBodyB>Most Popular Post</LabelBodyB>
          <VerticalRadioGroup
            options={options}
            onChange={handleChangeCondition}
            defaultValue={account}
            selected={account}
          />
        </PopularHeading>
        <Spacer type="stack" size="s" />
        <Label>#Views</Label>
      </PopularPostWrapper>
      {getTopPosts.map((post, pos) => {
        return <PopularPostELement position={pos} postMetric={post} />;
      })}
      {getTopPosts?.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
    </VehicleCardStyle>
  );
};

const PopularPostWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const PopularHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;
const Label = styled(LabelB)`
  align-self: flex-end;
`;
const Order = styled(LabelB)`
  width: 16px;
  height: 16px;
`;
const ElementContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #f4f3f6;
  padding: 8px;
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
`;
