import React, { useRef } from 'react';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import { FormRef } from '../../../../models/form';
import SideDrawer from '../../SideDrawer';
import { snackbar } from '../../Snackbar';
import { LabelBodyB } from '../../Text/Text.styled';
import { EditPartnerFormValue } from './EditPartnerForm';
import { Partner } from '../../../../graphql/query/partners';
import {
  EDIT_PARTNER,
  EditPartnerParams,
  EditPartnerRequest,
  EditPartnerResponse,
} from '../../../../graphql/mutations/editPartner';
import EditPartnerForm from './EditPartnerForm';

interface EditPartnerDrawerProps {
  isSideDrawerVisible: boolean;
  toggleSideDrawer: (value?: boolean) => void;
  updateObject: Partner;
}

const FormContainer = styled.section`
  margin: 16px;
  width: 100%;
`;
const SectionTitleContainer = styled.div`
  margin-bottom: 16px;
`;

const EditPartnerDrawer: React.FC<EditPartnerDrawerProps> = ({
  isSideDrawerVisible,
  toggleSideDrawer,
  updateObject,
}) => {
  const formRef = useRef<FormRef<EditPartnerFormValue>>(null);
  const initialValues: EditPartnerFormValue = {
    name: updateObject.name,
    type: updateObject.type,
  };

  const [editPartner] = useMutation<EditPartnerResponse, EditPartnerParams>(EDIT_PARTNER);

  const handleUpdatePartner = async (formData: EditPartnerFormValue) => {
    try {
      const partner: EditPartnerRequest = {
        ...formData,
        id: updateObject.id,
      };
      await editPartner({
        variables: { data: partner },
      });
      snackbar.success({
        message: 'Partner updated Successfully',
      });
      toggleSideDrawer();
    } catch (error) {
      Sentry.captureException(error);
      snackbar.error({
        message: 'Unable to update partner',
      });
    }
  };
  return (
    <SideDrawer
      heading="Partner"
      isDrawerVisible={isSideDrawerVisible}
      toggleDrawerVisible={toggleSideDrawer}
      ctaButtons={[
        {
          label: 'EDIT PARTNER',
          onClick: async () => {
            formRef.current?.submit();
          },
          isDisabled: false,
        },
      ]}
    >
      <FormContainer>
        <SectionTitleContainer>
          <LabelBodyB>Partner Details</LabelBodyB>
        </SectionTitleContainer>
        <EditPartnerForm ref={formRef} onCompleted={handleUpdatePartner} initialValues={initialValues} />
      </FormContainer>
    </SideDrawer>
  );
};

export default EditPartnerDrawer;
