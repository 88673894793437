import { gql, useQuery } from '@apollo/client';

import { SocialAccount } from '../../components/ui/VehicleColumn/modeViews/VehicleSocialAccounts';

interface CampaignMetricsRequest {
  platform: string;
  organizationIds: string[];
  locationIds: string[];
  startDate: Date;
  endDate: Date;
}

export interface CampaignMetrics {
  platform: SocialAccount;
  impressions?: string;
  clicks?: string;
  spend?: string;
  costPerClick?: string;
  leads?: string;
  costPerLead?: string;
}

interface CampaignMetricsResponse {
  getCampaignMetrics: CampaignMetricsResponse[];
}

interface CampaignMetricsParams {
  campaignMetricsRequest: CampaignMetricsRequest;
}

const GET_CAMPAIGN_METRICS = gql`
  query getCampaignMetrics($campaignMetricsRequest: CampaignMetricsRequest!) {
    getCampaignMetrics(input: $campaignMetricsRequest) {
      platform
      impressions
      clicks
      spend
      costPerClick
      leads
      costPerLead
    }
  }
`;

export function useGetCampaignMetrics(params: CampaignMetricsParams) {
  const { data, error, loading } = useQuery<CampaignMetricsResponse, CampaignMetricsParams>(GET_CAMPAIGN_METRICS, {
    variables: params,
    fetchPolicy: 'cache-and-network',
  });
  return {
    campaignMetrics: data?.getCampaignMetrics || [],
    error,
    loading,
  };
}
