import React, { useEffect, useRef } from 'react';

import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

import styled from 'styled-components';

import { LabelR } from '../Text/Text.styled';

const CompactMenuItemWrapper = styled.div`
  width: 200px;
  height: 48px;
  padding: 16px;
  margin: -12px -16px;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.colors.dimGray.plus3};
  }
  :active {
    background-color: ${(props) => props.theme.colors.purpleNavi.plus2};
    .option-label {
      font-weight: bold;
      color: white;
    }
  }
`;

export interface MenuOption {
  label: string;
  active: boolean;
  onClick: () => void;
}
interface MenuCompactProps {
  options: MenuOption[];
  placement?: TooltipPlacement;
  visible?: boolean;
  setShowOptions?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CompactMenuItem: React.FC<MenuOption> = ({ label, onClick }) => (
  <CompactMenuItemWrapper onClick={onClick}>
    <LabelR className="option-label">{label}</LabelR>
  </CompactMenuItemWrapper>
);

export const MenuCompact: React.FC<MenuCompactProps> = ({
  children,
  options,
  setShowOptions,
  placement = 'bottomRight',
  ...props
}) => {
  const popoverContainer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popoverContainer.current && !popoverContainer.current?.contains(event.target)) {
        setShowOptions && setShowOptions(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [setShowOptions]);
  return (
    <div ref={popoverContainer}>
      <Popover
        placement={placement}
        content={
          <>
            {options
              .filter((o) => o.active)
              .map((opt, index) => (
                <CompactMenuItem key={index} {...opt} />
              ))}
          </>
        }
        trigger="click"
        {...props}
      >
        {children}
      </Popover>
    </div>
  );
};
