import React from 'react';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UPLOAD_USER_AVATAR, UploadAvatarRequest, UploadAvatarResponse } from '../../../graphql/mutations/uploadAvatar';
import { contentTypes } from './UploadButton';
import { useMutation } from '@apollo/client';
import axios, { AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/react';

const { Dragger } = Upload;

type Props = {
  onUploadSuccess?: (file: RcFile) => void;
  onRemoveFile?: (file: RcFile) => void;
};

const UploadFiles: React.FC<Props> = ({ onUploadSuccess, onRemoveFile }: Props) => {
  const [uploadAvatar] = useMutation<UploadAvatarResponse, UploadAvatarRequest>(UPLOAD_USER_AVATAR);
  const handleUploadError = (error: Error) => {
    message.error(`Error uploading file. ${error.message}`);
  };

  const handleUploadSuccess = (file: RcFile) => {
    message.success('File uploaded.');
    onUploadSuccess && onUploadSuccess(file);
  };

  const handleUpload = async ({ file, onSuccess }: any) => {
    const { name, type } = file;
    let typen = type as keyof typeof contentTypes;

    const { data } = await uploadAvatar({
      variables: {
        data: {
          contentType: contentTypes[typen],
          ext: name.split('.').pop()?.toUpperCase()!,
          filename: name.split('.').slice(0, -1).join('.'),
        },
      },
    });

    const signedUrl = data?.uploadUserAvatar.signedUrl!;
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': file.type,
      },
    };

    try {
      const result = await axios.put(signedUrl, file, config);
      const { url } = result.config;
      const path = url!.slice(0, url?.indexOf('?X-Goog-Algorithm='));
      onSuccess({ ...file, url: path, thumbUrl: path });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    customRequest: handleUpload,
    action: '',
    onRemove: (file: any) => {
      onRemoveFile && onRemoveFile(file);
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        handleUploadSuccess(info.file as RcFile);
      } else if (status === 'error') {
        handleUploadError(info.file.error as Error);
      }
    },
  };

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
    </Dragger>
  );
};

export default UploadFiles;
