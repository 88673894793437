import { createGlobalStyle } from 'styled-components';

export const AntTooltipStyles = createGlobalStyle`
  .ant-tooltip-inner {
    min-height: 24px;
    padding: 4px 8px;
    border-radius: 4px;
    opacity:0.8;
    background: ${(props) => props.theme.colors.dimGray.minus2};
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color:${(props) => props.theme.colors.white};
  }
  .ant-tooltip-arrow-content {
    opacity:0.8;
    background: ${(props) => props.theme.colors.dimGray.minus2};
  }
`;
