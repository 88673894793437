import { gql } from '@apollo/client';

export interface TogglePartnerStatusResponse {
  id: string;
  isActive: boolean;
}

export interface TogglePartnerStatusRequest {
  partnerId: string;
}

export const TOGGLE_PARTNER_STATUS = gql`
  mutation ($partnerId: String!) {
    togglePartnerStatus(partnerId: $partnerId) {
      id
      isActive
    }
  }
`;
