import { RcFile } from 'antd/lib/upload';
import axios, { AxiosRequestConfig } from 'axios';

const createAxiosClient = () => {
  return axios.create({
    withCredentials: true,
  });
};

export const FacebookSync = async () => {
  await createAxiosClient().put('/api/fb/sync');
};

export const GoogleSync = async () => {
  await createAxiosClient().put('/api/google/sync');
};

export const LesaSync = async () => {
  await createAxiosClient().post('/api/v1/partners/lesa/sync');
};

export const sendGoogleReport = async (file: RcFile, config: AxiosRequestConfig) => {
  let formData = new FormData();
  formData.append('file', file);
  const response = await createAxiosClient().post(`/api/google/sync/carsForSale`, formData, config);
  return response;
};
