import React from 'react';
import { Empty } from 'antd';
import styled from 'styled-components';
import { PostTypeEnum } from '../../../../graphql/query/postHistoryByLocationId';
import { DashboardFilter } from '../../../../pages/Dashboard/hooks/useFilters';
import { LabelB, LabelBodyB } from '../../Text/Text.styled';
import { SocialAccount } from '../../VehicleColumn/modeViews/VehicleSocialAccounts';
import { VehicleCardStyle } from './Components.styles';
import { PopularPostELement } from './PopularPost';
import { useGetTopMarketplaceLeads } from '../../../../graphql/query/getTopMarketplaceLeads';
import { Spacer } from '../../Spacer';

interface TopMarketplaceLeadsProps {
  dashboardFilters: DashboardFilter;
}

export const TopMarketplaceLeads: React.FC<TopMarketplaceLeadsProps> = ({ dashboardFilters }) => {
  const { topMarketplaceCars } = useGetTopMarketplaceLeads({
    topMarketplaceLeadsRequest: {
      organizationIds: dashboardFilters.organizationIds,
      startDate: dashboardFilters.dateRange.startDate,
      endDate: dashboardFilters.dateRange.endDate,
      platforms: [SocialAccount.FacebookMarketplace],
      locationIds: dashboardFilters.locationIds,
      postTypes: [PostTypeEnum.VEHICLE],
    },
  });

  if (topMarketplaceCars?.length === 0) {
    return null;
  }

  return (
    <VehicleCardStyle>
      <PopularPostWrapper>
        <PopularHeading>
          <LabelBodyB>Top Marketplace Performers</LabelBodyB>
        </PopularHeading>
        <Spacer type="stack" size="s" />
        <Label>#of Leads</Label>
      </PopularPostWrapper>
      <VehicleList>
        {topMarketplaceCars.map((post, pos) => {
          return <PopularPostELement value={post.leads} position={pos} postMetric={post} />;
        })}
        {topMarketplaceCars?.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </VehicleList>
    </VehicleCardStyle>
  );
};

export const VehicleList = styled.div``;
const PopularPostWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const PopularHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;
const Label = styled(LabelB)`
  align-self: flex-end;
`;
