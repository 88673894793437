import React from 'react';

import { Control, Controller, FieldError, FieldErrors, FieldValues } from 'react-hook-form';

import HelperMessage from '../HelperMessage';

interface ControllerWithInputProps<FormValue extends FieldValues> {
  name: keyof FormValue & string;
  label?: string;
  type?: string;
  defaultValue?: string | number;
  placeholder?: string;
  control: Control<Record<string, any>>;
  errors: FieldErrors<FormValue>;
  disabled?: boolean;
  readOnly?: boolean;
  input: any;
}

function ControllerWithInput<FormValue extends FieldValues = FieldValues>(
  props: ControllerWithInputProps<FormValue>
): React.ReactElement<ControllerWithInputProps<FormValue>> {
  const { name, defaultValue, control, type, label, errors, placeholder, disabled, input, readOnly } = props;
  return (
    <Controller
      readOnly={readOnly}
      disabled={disabled}
      as={input}
      type={type}
      name={name}
      control={control}
      topLabel={label}
      defaultValue={defaultValue}
      placeholder={placeholder}
      helperText={
        (errors?.[name] && <HelperMessage type="danger" message={(errors[name] as FieldError)?.message} />) || null
      }
    />
  );
}

ControllerWithInput.defaultProps = {
  label: '',
  type: 'text',
};

export default ControllerWithInput;
