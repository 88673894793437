import React from 'react';

import Text from 'antd/lib/typography/Text';

export interface HelperMessageProps {
  message?: string | null;
  type: 'danger' | 'secondary' | 'success' | 'warning';
}
const HelperMessage: React.FC<HelperMessageProps> = ({ message, type }) =>
  message ? <Text type={type}>{message}</Text> : null;

export default HelperMessage;
