import { gql } from '@apollo/client';

import { UserFieldsFragment, UsersResponseItem } from '../query/usersByOrganizationId';
import { MutationParam } from './';

export interface UpsertUserToOrganizationRequest {
  organizationId: string;
  email: string;
  fullName: string;
  phoneNumber: string;
  role: string;
  title?: string;
  newPassword?: string;
  avatarId?: number;
  userId?: string;
  locationIds?: string[];
  partnerId?: string;
}

export interface UpsertUserToOrganizationParams extends MutationParam<UpsertUserToOrganizationRequest> {}

export interface UpsertUserToOrganizationResponse extends UsersResponseItem {}

export const UPSERT_USER_TO_ORGANIZATION = gql`
  mutation upsertUser($data: UpsertUserRequest!) {
    upsertUserToOrganization(data: $data) {
      ...UserFields
    }
  }

  ${UserFieldsFragment}
`;
