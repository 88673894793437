import React, { useEffect, useState } from 'react';

import { Alert, Modal, Select } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';

import styled from 'styled-components';

import { ReviewLayout } from '../../components/Layout/ReviewLayout';
import { displayGqlErrors } from '../../components/ui/ErrorList';
import { PostCard } from '../../components/ui/PostCard';
import { snackbar } from '../../components/ui/Snackbar';
import { Spacer } from '../../components/ui/Spacer';
import { DELETE_GOOGLE_POST, DeleteGooglePostRequest } from '../../graphql/mutations/deleteGooglePost';
import { useGoogleLocationsByOrgIdQuery } from '../../graphql/query/googleLocationByOrgId';
import {
  GOOGLE_LOCATION_POSTS,
  PostsByGoogleLocationIdParams,
  PostsByGoogleLocationIdResponse,
} from '../../graphql/query/googleLocationPosts';
import { useParams } from 'react-router-dom';
import { SelectOption } from '../../components/ui/FormSelectField/FormSelectField';

const PageContent = styled.div`
  padding: 0px 30px;
  align-items: flex-start;
`;
const { confirm } = Modal;
export const GooglePosts: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [locationId, setLocationId] = useState<string>('');
  const [locationName, setLocationName] = useState<string>('');
  const selectedAccountKey = `google-${organizationId}`;
  const selectedAccount = localStorage.getItem(selectedAccountKey);
  const { googleLocations } = useGoogleLocationsByOrgIdQuery(organizationId!);
  const { data } = useQuery<PostsByGoogleLocationIdResponse, PostsByGoogleLocationIdParams>(GOOGLE_LOCATION_POSTS, {
    variables: {
      locationId: locationId,
    },
  });
  const [deleteGooglePost] = useMutation<any, DeleteGooglePostRequest>(DELETE_GOOGLE_POST);

  useEffect(() => {
    if (googleLocations?.length > 0) {
      const firstLocation = googleLocations[0];
      if (selectedAccount) {
        const selected = googleLocations.find((location) => location.id === selectedAccount);
        if (selected) {
          setLocationId(selected.id);
          setLocationName(selected.locationName);
        } else {
          setLocationId(firstLocation.id);
          setLocationName(firstLocation.locationName);
          localStorage.setItem(selectedAccountKey, firstLocation.locationId!);
        }
      } else {
        setLocationId(googleLocations[0].id);
        setLocationName(googleLocations[0].locationName);
        localStorage.setItem(selectedAccountKey, firstLocation.locationId!);
      }
    }
  }, [googleLocations, selectedAccount, selectedAccountKey]);

  const locationOptions: SelectOption[] = googleLocations.map<SelectOption>((location) => ({
    label: location.locationName,
    value: location.id,
  }));

  const deletePost = async (nameOfPosts: string) => {
    try {
      const success = await deleteGooglePost({
        variables: {
          nameOfPost: nameOfPosts,
          googleLocationId: locationId,
        },
      });
      if (success) {
        snackbar.success({
          message: 'Google Post deleted',
        });
      } else {
        snackbar.error({
          message: 'Google Post not deleted',
        });
      }
    } catch (er) {
      Sentry.captureException(er);
      displayGqlErrors(er as ApolloError, 'Unable to deleted');
    }
  };

  const handleDeletePost = async (nameOfPosts: string) => {
    confirm({
      title: `Delete Google Post`,
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: `YES, delete`,
      content: `Are you sure you want to delete this Post`,
      onOk() {
        deletePost(nameOfPosts);
      },
    });
  };

  return (
    <ReviewLayout
      pageName={locationName}
      filterColumn={
        <>
          <Select
            options={locationOptions}
            value={locationId}
            onChange={(val) => {
              setLocationId(val.toString());
              setLocationName(googleLocations.find((item) => item.id === val)?.locationName || '');
              localStorage.setItem(selectedAccountKey, val.toString());
            }}
          />
          <Spacer type="stack" size="s" />{' '}
        </>
      }
      reviewColumn={
        <PageContent>
          {data?.getGooglePostByGoogleLocationId.length ? (
            data?.getGooglePostByGoogleLocationId.map((post) => (
              <>
                <PostCard posts={post} deletePost={handleDeletePost} />
                <Spacer type="stack" size="l" />
              </>
            ))
          ) : (
            <Alert type="info" message={`No list of items to show`} showIcon />
          )}
        </PageContent>
      }
    />
  );
};
