import React from 'react';
import { Button, Progress, Table } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SearchInput } from '../../../components/ui/TextInput/SearchInput.styled';
import { createSortProps } from '../../../utils/table.utils';
import { LocationOnboardingInfo, LOCATIONS_IN_ONBOARDING_NAME } from '../../../graphql/query/locationsInOnboarding';
import { match } from 'ts-pattern';
import { LocationOnboardingStage } from '../OnboardingDashboard';
import { useMutation } from '@apollo/client';
import { MARK_LOCATION_ONBOARDED } from '../../../graphql/mutations/markLocationOnboarded';
import { snackbar } from '../../../components/ui/Snackbar';
import { displayGqlErrors } from '../../../components/ui/ErrorList';
import * as Sentry from '@sentry/react';

const { Column } = Table;
const PageContent = styled.div`
  padding: 10px 10px;
  width: 100%;
  align-items: flex-start;
`;
const PageHeader = styled.div`
  width: 100%;
  height: 56px;
  padding: 12px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  display: flex;
  align-items: center;

  justify-content: space-between;
`;
interface OnboardingColumnParams {
  locations: LocationOnboardingInfo[];
  handleSearch: (value: string) => void;
}

const percentDoneForStage = (stage: string) => {
  return match(stage as LocationOnboardingStage)
    .with(LocationOnboardingStage.AccountSetup, () => 25)
    .with(LocationOnboardingStage.UserSetup, () => 50)
    .with(LocationOnboardingStage.InventorySetup, () => 75)
    .with(LocationOnboardingStage.OnboardingConfirmation, () => 100)
    .with(LocationOnboardingStage.OnboardingCompleted, () => 100)
    .exhaustive();
};

export const OnboardingColumn: React.FC<OnboardingColumnParams> = ({ locations, handleSearch }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (locations) {
      handleSearch(value);
    }
  };

  const [markLocationOnboarded] = useMutation<boolean>(MARK_LOCATION_ONBOARDED, {
    refetchQueries: [LOCATIONS_IN_ONBOARDING_NAME],
  });

  const onConfirmOnboarded = async (locationId: string) => {
    try {
      const success = await markLocationOnboarded({
        variables: {
          locationId,
        },
      });
      if (success) {
        snackbar.success({ message: 'Location marked as onboarded!' });
      } else {
        snackbar.error({ message: 'Failed to update location' });
      }
    } catch (error) {
      Sentry.captureException(error);
      displayGqlErrors(error, `Unable to update location`);
    }
  };

  return (
    <>
      <PageHeader>
        <SearchInput placeholder="Search by location or organization" onChange={handleChange} removeBorder />
      </PageHeader>
      <PageContent>
        <Table pagination={{ pageSize: 50 }} className="wide-table" dataSource={locations} rowKey="id">
          <Column<LocationOnboardingInfo>
            title="Organization"
            width="160px"
            dataIndex="organization"
            key="organization"
            render={(organization) => organization.name}
            {...createSortProps<LocationOnboardingInfo>({
              type: 'string',
              field: 'organizationName',
              multiple: 1,
            })}
          />
          <Column<LocationOnboardingInfo>
            title="Location"
            width="240px"
            dataIndex="name"
            key="name"
            //@ts-ignore
            render={(name, row) => <Link to={`/app/organization/${row.organization.id}`}>{name}</Link>}
          />
          <Column<LocationOnboardingInfo>
            title="Stage"
            width="160px"
            dataIndex="onboardingStage"
            key="onboardingStage"
            render={(onboardingStage, record) => {
              if (onboardingStage === LocationOnboardingStage.OnboardingConfirmation) {
                return (
                  <Button onClick={() => onConfirmOnboarded(record.id)} type="primary" size="small">
                    Confirm Onboarding Completed
                  </Button>
                );
              }

              return <div>{onboardingStage}</div>;
            }}
            {...createSortProps<LocationOnboardingInfo>({
              type: 'string',
              field: 'onboardingStage',
              multiple: 1,
            })}
          />
          <Column<LocationOnboardingInfo>
            title="Progress"
            width="160px"
            dataIndex="onboardingStage"
            key="onboardingStage"
            render={(onboardingStage) => {
              const percentDone = percentDoneForStage(onboardingStage);
              return <Progress percent={percentDone} style={{ width: '35%' }} />;
            }}
            {...createSortProps<LocationOnboardingInfo>({
              type: 'string',
              field: 'onboardingStage',
              multiple: 1,
            })}
          />
        </Table>
      </PageContent>
    </>
  );
};
