import React from 'react';

import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

import styled from 'styled-components';

import { ActionType } from '../../../../graphql/mutations/createPost';
import { RadioGroup, RadioGroupOption } from '../../RadioGroup/RadioGroup';
import { Spacer } from '../../Spacer';
import { TagLabel } from '../../TagLabel';
import { LabelB } from '../../Text/Text.styled';
import { KeywordsTypes } from '../helpers';
import ImageSelection from './ImageSelection';

export const KeywordsSuggestionContainer = styled.div`
  display: flex;
  padding: 0px;
`;

export const KeywordTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 24px;
`;

const StyledTagContainer = styled.div`
  margin: 4px 2px;
`;

export const Section = styled.div``;

export const KeywordSection = styled.div`
  flex-wrap: wrap;
`;
interface KeywordsProps {
  setKeywordSelect: React.Dispatch<React.SetStateAction<string>>;
  keywordsState: KeywordsTypes[];
}

const KeywordsComponent: React.FC<KeywordsProps> = ({ setKeywordSelect, keywordsState }) => {
  return (
    <React.Fragment>
      {keywordsState?.map((KeywordObj) => {
        const { keyword, isFound } = KeywordObj;
        return (
          <StyledTagContainer key={keyword}>
            <TagLabel
              onClick={() => setKeywordSelect(keyword)}
              {...(isFound ? { color: 'dimgray' } : { color: 'default' })}
            >
              {keyword}
            </TagLabel>
          </StyledTagContainer>
        );
      })}
    </React.Fragment>
  );
};

export interface CallToActionProps {
  handleChangeCondition: (e: RadioChangeEvent) => void;
  condition: ActionType;
  actionTypeOptions: RadioGroupOption[];
}

export const CallToAction: React.FC<CallToActionProps> = ({ handleChangeCondition, condition, actionTypeOptions }) => {
  return (
    <Section>
      <Spacer type="stack" size="xs" />
      <LabelB>Call to Action</LabelB>
      <Spacer type="stack" size="xxs" />
      <RadioGroup
        style={{ width: 'fit-content' }}
        onChange={handleChangeCondition}
        defaultValue={condition}
        options={actionTypeOptions}
      ></RadioGroup>
      <Spacer type="stack" size="xs" />
    </Section>
  );
};

export interface KeywordsSectionProps {
  keywords: KeywordsTypes[];
  keywordsState: KeywordsTypes[];
  setKeywordSelect: React.Dispatch<React.SetStateAction<string>>;
}

export const KeywordsSection: React.FC<KeywordsSectionProps> = ({ keywordsState, setKeywordSelect, keywords }) => {
  return (
    <KeywordSection>
      <Spacer type="stack" size="xs" />
      <LabelB>Add Keywords to your post</LabelB>
      <Spacer type="stack" size="xxs" />
      {!!keywords?.length && (
        <KeywordsSuggestionContainer id="keywordContainer">
          <KeywordTagsContainer>
            <KeywordsComponent keywordsState={keywordsState} setKeywordSelect={setKeywordSelect} />
          </KeywordTagsContainer>
        </KeywordsSuggestionContainer>
      )}
      <Spacer type="stack" size="xs" />
    </KeywordSection>
  );
};

export interface ImageSectionProps {
  image: string;
}

export const ImageSection: React.FC<ImageSectionProps> = ({ image }) => {
  return (
    <Section>
      <Spacer type="stack" size="xs" />
      <LabelB>Media Post</LabelB>
      <Spacer type="stack" size="xxs" />
      <ImageSelection image={image} />
      <Spacer type="stack" size="xs" />
    </Section>
  );
};

export interface CheckBoxSectionProps {
  setSourceOption: (source: string) => void;
  mediaOption: string;
  options: RadioGroupOption[];
}

export const CheckBoxSection: React.FC<CheckBoxSectionProps> = ({
  setSourceOption,
  mediaOption: sourceOption,
  options,
}) => {
  const onChange = (e: RadioChangeEvent) => {
    setSourceOption(e.target.value);
  };

  return (
    <Section>
      <Spacer type="stack" size="xs" />
      <LabelB>Media Post</LabelB>
      <Spacer type="stack" size="xxs" />
      <Radio.Group
        className="noStyles"
        onChange={onChange}
        value={sourceOption}
        style={{
          borderStyle: 'none',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {options.map((opt) => (
          //@ts-ignore
          <Radio key={opt.value} value={opt.value}>
            {opt.label}
          </Radio>
        ))}
      </Radio.Group>
      <Spacer type="stack" size="xxs" />
    </Section>
  );
};
