import { ReactText, useState } from 'react';

export function useRowSelection<RecordType = unknown>(fieldRestricted?: string, valueRestricted?: string) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<ReactText[]>([]);
  const [selected, setSelected] = useState(false);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[], _selectedRows: RecordType[]) => {
      setSelectedRowKeys(selectedRowKeys);
      if (selectedRowKeys.length > 0) {
        setSelected(true);
      } else {
        setSelected(false);
      }
    },
    getCheckboxProps: (record: RecordType) =>
      fieldRestricted
        ? {
            disabled: Object(record)[fieldRestricted!] === valueRestricted,
          }
        : {},
  };
  return {
    selectedRowKeys,
    setSelectedRowKeys,
    rowSelection,
    selected,
    setSelected,
  };
}
