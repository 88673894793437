import React from 'react';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import UsersLayout from '../components/Layout/UsersLayout';
import { UserList } from '../pages/UserList';
import { useQuery } from '@apollo/client';
import { USERS_BY_ORG_ID, UsersByOrgIdParams } from '../graphql/query/usersByOrganizationId';
import { USERS_BY_PARTNER_ID, UsersByPartnerIdParams } from '../graphql/query/usersByPartnerId';

const UserRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  const { organizationId, partnerId } = useParams<{ partnerId: string; organizationId: string }>();

  let variables: UsersByOrgIdParams | UsersByPartnerIdParams = {
    organizationId,
    onlyActive: true,
  };
  let queryName = USERS_BY_ORG_ID;

  if (partnerId) {
    variables = {
      partnerId,
      onlyActive: true,
    };
    queryName = USERS_BY_PARTNER_ID;
  }

  const { data, refetch } = useQuery(queryName, {
    variables,
  });

  const users = data?.usersByOrgId || data?.usersByPartnerId;

  return (
    <UsersLayout>
      <Switch>
        <Route exact path={`${path}`}>
          <UserList users={users} refetch={refetch} />
        </Route>
        <Redirect to={`${path}`} />
      </Switch>
    </UsersLayout>
  );
};

export default UserRoutes;
