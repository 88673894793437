import React from 'react';

import { ApolloError, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { useHistory } from 'react-router-dom';

import { displayGqlErrors } from '../../../components/ui/ErrorList';
import { snackbar } from '../../../components/ui/Snackbar';
import { GqlMutationError } from '../../../graphql/gql-error';
import {
  CONNECT_GOOGLE_LOCATIONS,
  ConnectGoogleLocationParams,
} from '../../../graphql/mutations/connectGoogleLocation';
import { useGoogleAccountsQuery } from '../../../graphql/query/googleAccounts';
import { UserProfileResponse } from '../../../graphql/query/userProfile';
import { AccountsColumn, SocialMedia } from './AccountColumn';
import { ConnectPageLocationParam } from './AccountColumn/LocationInfo';
import { OnboardingAccount } from './UserSettings';

interface ManageGoogleAccountProps {
  data: UserProfileResponse;
  refetch: () => void;
}
export const ManageGoogleAccount: React.FC<ManageGoogleAccountProps> = ({ data, refetch }) => {
  const history = useHistory();
  const [list, setList] = React.useState<SocialMedia[]>([]);
  const onConnectToGoogle = async () => {
    history.push('/auth/google/user');
  };
  const { accounts, loading, error } = useGoogleAccountsQuery();
  const locations = data.myInfo.isConnectedToGoogle.list;
  const [connectGoogleLocationMutation] = useMutation<boolean, ConnectGoogleLocationParams>(CONNECT_GOOGLE_LOCATIONS);

  const connectLocation = async (connectPageLocationParam: ConnectPageLocationParam) => {
    try {
      snackbar.info({ message: 'Connecting location', autoClose: 1500 });
      await connectGoogleLocationMutation({
        variables: {
          resourcename: connectPageLocationParam.id,
          accountName: connectPageLocationParam.account!,
        },
      });
      snackbar.success({ message: 'Google Location Connected' });
      refetch();
    } catch (error) {
      Sentry.captureException(error);
      displayGqlErrors(error as ApolloError);
    }
  };

  React.useEffect(() => {
    if (error) {
      displayGqlErrors(error as unknown as GqlMutationError);
    }
  }, [error]);

  React.useEffect(() => {
    if (!loading) {
      const list = accounts.map<SocialMedia>((account) => {
        return {
          name: account.locationName,
          description: account.address,
          avatar: '',
          id: account.id,
          groupId: account.groupId || '',
        };
      });
      setList(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <AccountsColumn
      loading={loading}
      socialMediaList={list}
      onConnectAccount={onConnectToGoogle}
      isConnected={data.myInfo.isConnectedToGoogle.isActive}
      account={OnboardingAccount.GOOGLE}
      data={data}
      connectedPrev={locations}
      connectLocationPage={connectLocation}
    />
  );
};
