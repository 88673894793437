import { gql } from '@apollo/client';

export interface CreateMerchantCenterRequest {
  locationId: string;
}
export interface CreateMerchantCenterResponse {
  createGoogleMerchantCenter: {
    id: string;
  };
}

export const CREATE_MERCHANT_CENTER = gql`
  mutation createGoogleMerchantCenter($locationId: String!) {
    createGoogleMerchantCenter(locationId: $locationId) {
      id
    }
  }
`;
