import { gql } from '@apollo/client';

export interface ToggleMerchantCenterFeedStatusRequest {
  locationId: string;
}
export interface ToggleMerchantCenterFeedStatusResponse {
  toggleGoogleMerchantCenterFeedStatus: {
    id: string;
    feedApproved: boolean;
  };
}

export const TOGGLE_MERCHANT_CENTER_FEED_STATUS = gql`
  mutation toggleGoogleMerchantCenterFeedStatus($locationId: String!) {
    toggleGoogleMerchantCenterFeedStatus(locationId: $locationId) {
      id
      feedApproved
    }
  }
`;
