import React from 'react';

import { Button, message, Upload } from 'antd';
//@ts-ignore
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import { AxiosRequestConfig } from 'axios';

import { UploadOutlined } from '@ant-design/icons';

import styled from 'styled-components';

import { sendGoogleReport } from '../../../api/syncLocations.api';
import { SecondaryProps } from '../Buttons/Buttons.styled';

const UploadWrapper = styled(Upload)`
  .anticon svg {
    display: none;
  }
`;

const UploadButton = styled(Button)`
  ${SecondaryProps}
  &:hover {
    background-color: ${(props) => props.theme.colors.purpleNavi.plus2};
    color: ${(props) => props.theme.colors.white};
    border: 1px solid transparent;
  }
`;

const FileUploader = () => {
  const handleUpload = async ({ onSuccess, onError, file }: RcCustomRequestOptions) => {
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': file.type,
      },
    };
    sendGoogleReport(file, config)
      .then((result) => {
        onSuccess(result, file);
        message.success(`${file.name} file uploaded successfully`);
      })
      .catch((error) => {
        onError(error, file);
        message.success(`${error?.message}`);
      });
  };
  return (
    <UploadWrapper customRequest={(e) => handleUpload(e)} multiple={false}>
      <UploadButton icon={<UploadOutlined />}>Click to Upload</UploadButton>
    </UploadWrapper>
  );
};

export default FileUploader;
