import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { NavigationTabItem, NavigationTabItemProps } from '../../ui/NavigationTabItem/NavigationTabItem';
import { HasPermission } from '../../ui/Security/HasPermission';
import { Spacer } from '../../ui/Spacer';

export interface NavigationLinkProps extends NavigationTabItemProps {
  href?: string;
  allowedRoles?: string[];
  forbiddenRoles?: string[];
  alert?: boolean;
}
const NavigationLink: React.FC<NavigationLinkProps> = ({
  href = '',
  allowedRoles,
  forbiddenRoles,
  alert,
  ...props
}) => {
  const router = useLocation();
  let isActive = false;
  if (router.pathname.startsWith(href)) {
    isActive = true;
  }
  return (
    <HasPermission allowedRoles={allowedRoles} forbiddenRoles={forbiddenRoles}>
      <Link to={href || '/'}>
        <NavigationTabItem {...props} active={props.active || isActive} alert={alert} />
      </Link>
      <Spacer type="stack" size="xs" />
    </HasPermission>
  );
};
NavigationLink.defaultProps = {
  href: '#',
};

export default NavigationLink;
