import React, { useEffect, useRef, useState } from 'react';

import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import styled from 'styled-components';

import { usePostStateContext } from '../../../context/PostStateProvider';
import { PUBLISH_VIDEO, PublishVideoRequest } from '../../../graphql/mutations/publishVideo';
import { useFindMusicQuery } from '../../../graphql/query/findMusic';
import {
  GENERATE_VIDEO_CONTENT,
  GenerateVideoRequest,
  GenerateVideoResponse,
} from '../../../graphql/query/generateVideoContent';
import { GetLocationAccountInfoResponse } from '../../../graphql/query/getLocationAccountsInfoInfo';
import { InventoryItem } from '../../../graphql/query/inventoryByLocationId';
import { LocationProductEnum } from '../../../graphql/query/locationsByOrgId';
import { formatVehicleField } from '../../../utils/formatText.utils';
import { generateKeywordsText } from '../../../utils/formatTextPost.utils';
import { hasProduct } from '../../utils/product.utils';
import AudioList from '../AudioList';
import { Button } from '../Buttons/Button';
import { AccountItem } from '../CreatePostModal';
import { AccountCheckBox } from '../CreatePostModal/components/AccountsCheckbox';
import { GridImages } from '../GridImages/GridImages';
import { snackbar } from '../Snackbar';
import { Spacer } from '../Spacer';
import { LabelB } from '../Text/Text.styled';
import { TextAreaInput } from '../TextInput/TextInput.styled';
import { createCompletion } from '../../../utils/openai';

const DEFAULT_HASHTAGS = `#carsofinstagram #automotive #cars #car #auto #carsforsale #fyp #cartok #auto`;
const CAPTION_LIMIT = 150;

const VideoPostWrapper = styled.div`
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  padding: 0;
  height: 760px;
`;

const Input = styled.input`
  background: ${(props) => props.theme.layoutColors.background};
  cursor: text;
  font-family: ${(props) => props.theme.fonts.body};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`;

const InputSection = styled.div`
  padding-right: 24px;
  height: 200px;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

const FirstColumn = styled.div`
  float: left;
  width: 50%;
  border-right: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  padding-right: 16px;
  height: 100%;
`;

const SecondColumn = styled.div`
  float: left;
  padding-left: 24px;
  width: 50%;
  position: relative;
  padding-bottom: 8px;
`;

const ColumnImage = styled.div`
  display: flex;
  width: 100%;
  align-content: center;
`;

export enum FacebookSource {
  LINK = 'link',
  VIDEO = 'video',
}

export enum InstagramSource {
  IMAGE = 'image',
  VIDEO = 'video',
}

interface VideoPostModalProps {
  locationAccountsInfo: GetLocationAccountInfoResponse;
  setShowModal: (show: boolean) => void;
  item: InventoryItem;
}

const extractAccounts = (info?: GetLocationAccountInfoResponse): AccountItem[] => {
  if (!info) return [];
  const tikTokCreator: AccountItem = {
    name: info.location.tikTokCreator?.name || '',
    enable: hasProduct(info.location.products, LocationProductEnum.TikTok),
    id: 'tiktok',
    imgUrl: '',
  };

  return tikTokCreator.enable ? [tikTokCreator] : [];
};

export const VideoPostModal: React.FC<VideoPostModalProps> = ({ item, locationAccountsInfo, setShowModal }) => {
  const { location } = locationAccountsInfo;
  const postState = usePostStateContext();

  const accounts = extractAccounts(locationAccountsInfo);
  const [imagesSelected, setImageSelected] = useState<string[]>([]);
  const [vehName, setVehName] = useState<string>(item.vehicle ?? formatVehicleField(item));
  const [audioUrl, setAudioUrl] = useState<string>('');
  const [selectedAccounts, setSelectedAccounts] = React.useState<string[]>(accounts.map((account) => account.id));
  const [disabled, setDisabled] = useState<boolean>(true);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [caption, setCaption] = useState<string>('Generating caption...');
  const handleChange = (checkedValue: CheckboxValueType[] = []) => {
    setSelectedAccounts(checkedValue as string[]);
  };

  const [generateVideo] = useMutation<GenerateVideoResponse, GenerateVideoRequest>(GENERATE_VIDEO_CONTENT);
  const { audioTracks, loading: loadingTracks } = useFindMusicQuery();
  const [publishVideo] = useMutation<boolean, PublishVideoRequest>(PUBLISH_VIDEO);
  const videoRef = useRef<HTMLVideoElement>(null);
  const handlePublishVideo = async () => {
    try {
      if (!videoUrl) throw new Error('No Video available');
      await snackbar.info({
        message: `Posting video ...`,
      });
      await publishVideo({
        variables: {
          input: {
            inventoryId: item.id,
            vin: item.vin,
            caption: caption,
            locationId: location.id,
            videoUrl: videoUrl,
          },
        },
      });
      setDisabled(true);
      await snackbar.info({
        message: `Video posted ...`,
      });
      setShowModal(false);
      setDisabled(false);
    } catch (error) {
      Sentry.captureException(error);
      const err = error as Error;
      snackbar.error({
        message: err?.message,
      });
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (audioTracks?.length > 0) {
      setAudioUrl(audioTracks[0].url);
    }
  }, [audioTracks]);

  useEffect(() => {
    videoRef.current?.load();
    setDisabled(false);
  }, [videoUrl]);

  useEffect(() => {
    const generateCaption = async () => {
      const generatedHashtags = generateKeywordsText({
        location,
        item,
        keywords: postState?.keywords,
      });
      const prompt = `Write me a one sentence caption in the form of the question about a ${vehName}`;
      const generatedPrompt = await createCompletion({ maxTokens: CAPTION_LIMIT - 50, prompt });
      const finalCaption = `${generatedPrompt}${generatedHashtags} ${DEFAULT_HASHTAGS}`
        .slice(0, CAPTION_LIMIT)
        .replace(/(?:\s)(#[^\s]*)$/, '')
        .replace(/"/g, '');
      setCaption(finalCaption);
      console.log(finalCaption);
    };

    generateCaption();

    return () => {};
  }, [setCaption, vehName, item, location, postState?.keywords]);

  const handleGenerateVideo = async () => {
    try {
      await snackbar.info({
        message: `Generating video ...`,
      });
      const response = await generateVideo({
        variables: {
          input: {
            audioUrl: audioUrl,
            imageCaption: vehName,
            imagesSelected: imagesSelected,
            locationId: location.id,
          },
        },
      });
      await snackbar.success({
        message: `Video generated ...`,
      });
      if (response.data) {
        setVideoUrl(response.data.generateVideo);
      }
    } catch (error) {
      Sentry.captureException(error);
      const err = error as Error;
      snackbar.error({
        message: err?.message,
      });
    }
  };
  return (
    <>
      <VideoPostWrapper>
        <FirstColumn>
          <LabelB>Placements</LabelB>
          <Spacer type="stack" size="xxs" />
          <AccountCheckBox accounts={accounts} selectedAccounts={selectedAccounts} onChange={handleChange} />
          <Spacer type="stack" size="s" />
          <LabelB>Vehicle Name</LabelB>
          <Input
            className="ant-input"
            onChange={(e) => setVehName(e.currentTarget.value)}
            value={vehName.toUpperCase()}
          />
          <Spacer type="stack" size="s" />
          <LabelB>Select Images</LabelB>
          <ColumnImage>
            <GridImages
              preSelect
              images={item.images ?? []}
              imagesSelected={imagesSelected}
              setImagesSelected={setImageSelected}
            />
          </ColumnImage>
          <Spacer type="stack" size="s" />
          <LabelB>Select Music</LabelB>
          <Spacer type="stack" size="xxs" />
          <AudioList setAudioUrl={setAudioUrl} loading={loadingTracks} tracks={audioTracks} />
          <Spacer type="stack" size="s" />
          <Button id="generateVideoPost" btnType="primary" onClick={handleGenerateVideo}>
            Generate video
          </Button>
        </FirstColumn>
        <SecondColumn>
          <LabelB>Video Preview</LabelB>
          <Spacer type="stack" size="xxs" />
          <video ref={videoRef} width="400" controls>
            <source src={videoUrl} type="video/mp4" />
          </video>
          <Spacer type="stack" size="s" />
          <LabelB>{`Caption - ${caption.length}/${CAPTION_LIMIT}`}</LabelB>
          <Spacer type="stack" size="xxs" />

          <InputSection>
            <TextAreaInput
              {...{
                size: 'large',
                rows: 12,
                maxLength: CAPTION_LIMIT,
                defaultValue: caption,
                value: caption,
                onChange: (val) => {
                  console.log(val);
                  setCaption(val.target.value);
                },
              }}
            />
          </InputSection>
        </SecondColumn>
      </VideoPostWrapper>
      <StyledButtonsContainer>
        <Button disabled={disabled} id="createVideoPost" btnType="primary" onClick={handlePublishVideo}>
          Post to Tiktok
        </Button>
      </StyledButtonsContainer>
    </>
  );
};
