import React from 'react';

import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { SideMenuLayout } from '../../components/Layout/SideMenuLayout';
import { LabelB } from '../../components/ui/Text/Text.styled';
import { PostTypeEnum } from '../../graphql/query/postHistoryByLocationId';
import PostHistoryTable from './components';
import { Button } from 'antd';

const SideColumnWrapper = styled.div`
  padding: 0px 16px;
`;
const PostHistory: React.FC = () => {
  const history = useHistory();
  let { locationId, mode } = useParams<{ mode: PostTypeEnum; locationId: string }>();
  return (
    <SideMenuLayout
      title={
        <Button onClick={() => history.goBack()}>
          <i className={`icon icon-small-arrows-arrow-left`} />
        </Button>
      }
      sideColumn={
        <SideColumnWrapper>
          <LabelB>Post History</LabelB>
        </SideColumnWrapper>
      }
      contentColumn={<PostHistoryTable locationId={locationId} typePost={mode} />}
    />
  );
};

export default PostHistory;
