import React from 'react';

import { RadioChangeEvent } from 'antd/lib/radio';

import { PostFrequency, PostPriority, RepostCar } from '../../../../graphql/mutations/upsertLocationSettings';
import { RadioGroupOption, RadioVerticalGroup } from '../../RadioGroup/RadioGroup';
import { Spacer } from '../../Spacer';
import { LabelBodyB, LabelBodyR } from '../../Text/Text.styled';

export interface LocationSettingsFormProps {
  setPostFrequency: (frequency: PostFrequency) => void;
  setPostPriority: (priority: PostPriority) => void;
  setRepost: (repost: boolean) => void;
  postFrequency: PostFrequency;
  postPriority: PostPriority;
  repost: boolean;
}

const LocationSettingsForm: React.FC<LocationSettingsFormProps> = ({
  setPostFrequency,
  setPostPriority,
  setRepost,
  postFrequency,
  postPriority,
  repost,
}) => {
  const handleChangeFrequency = (element: RadioChangeEvent) => {
    setPostFrequency(element.target.value);
  };
  const handleChangePriority = (element: RadioChangeEvent) => {
    setPostPriority(element.target.value);
  };
  const handleChangeRepost = (element: RadioChangeEvent) => {
    setRepost(element.target.value === RepostCar.Yes);
  };

  const postFrequencyOptions: RadioGroupOption[] = [
    {
      label: 'Daily',
      value: PostFrequency.Daily,
    },
    {
      label: 'Twice a week',
      value: PostFrequency.Twice,
    },
    {
      label: 'Once a week',
      value: PostFrequency.Once,
    },
  ];

  const postPriorityOptions: RadioGroupOption[] = [
    {
      label: 'Used inventory first.',
      value: PostPriority.Used,
    },
    {
      label: 'New inventory first.',
      value: PostPriority.New,
    },
    {
      label: 'Most time on lot first.',
      value: PostPriority.LotLongest,
    },
    {
      label: 'Least time on lot first.',
      value: PostPriority.LotLeast,
    },
    {
      label: 'New cars only',
      value: PostPriority.NewOnly,
    },
    {
      label: 'Used cars only',
      value: PostPriority.UsedOnly,
    },
  ];

  const postRepostOptions: RadioGroupOption[] = [
    {
      label: 'Repost cars',
      value: RepostCar.Yes,
    },
    {
      label: 'Don’t post until there is new inventory.',
      value: RepostCar.No,
    },
  ];

  return (
    <form>
      <LabelBodyB>Posting Frequency</LabelBodyB>
      <Spacer type="stack" size="xxs" />
      <LabelBodyR>
        If you have fewer cars, we recommend posting 1-2 times a week so that you post new content while getting the
        page rank benefits.
      </LabelBodyR>
      <Spacer type="stack" size="m" />
      <RadioVerticalGroup
        onChange={handleChangeFrequency}
        options={postFrequencyOptions}
        defaultValue={postFrequency}
      ></RadioVerticalGroup>
      <Spacer type="stack" size="s" />

      <LabelBodyB>Post Priority</LabelBodyB>
      <Spacer type="stack" size="xxs" />
      <LabelBodyR>How do you want us to prioritize posting your cars?</LabelBodyR>
      <Spacer type="stack" size="m" />
      <RadioVerticalGroup
        onChange={handleChangePriority}
        options={postPriorityOptions}
        defaultValue={postPriority}
      ></RadioVerticalGroup>
      <Spacer type="stack" size="s" />

      <LabelBodyB>Repost Cars</LabelBodyB>
      <Spacer type="stack" size="xxs" />
      <LabelBodyR>After posting all the cars in your lot, what would you like us to do?</LabelBodyR>
      <Spacer type="stack" size="m" />
      <RadioVerticalGroup
        defaultValue={repost ? RepostCar.Yes : RepostCar.No}
        onChange={handleChangeRepost}
        options={postRepostOptions}
      ></RadioVerticalGroup>
    </form>
  );
};

export default LocationSettingsForm;
