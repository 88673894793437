import React, { useCallback, useEffect } from 'react';
import { message } from 'antd';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { loginRequest } from '../../api/auth.api';
import LoginLayout from '../../components/Layout/LoginLayout';
import {
  DisclaimerText,
  LoginFormContainer,
  MainContentLoginLayout,
} from '../../components/Layout/MainContentLoginLayout';
import { displayAxiosErrors } from '../../components/ui/ErrorList';
import { Heading2 } from '../../components/ui/Headings/Headings.styled';
import LeftContent from '../../components/ui/LeftLoginContent';
import { hasAllowedRoles } from '../../components/ui/Security';
import { Spacer } from '../../components/ui/Spacer';
import { getTokenInfo } from '../../context/appReducer';
import { useSession } from '../../context/AppStateProvider';
import { RoleEnum } from '../../models/session';
import EnhancedLoginForm, { LoginFormValue } from './LoginForm';
import { useQueryParam } from 'use-query-params';

const LinkStyled = styled.a.attrs({
  class: 'text-link',
})`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.purpleNavi.plus1};
`;
const Title = styled(Heading2)`
  text-align: center;
`;

export function getDefaultHome(roles?: RoleEnum[]) {
  if (!roles) {
    return '/login';
  }

  if (hasAllowedRoles([RoleEnum.SuperAdmin, RoleEnum.FacebookTest], roles)) {
    return '/app/organization';
  }

  if (hasAllowedRoles([RoleEnum.Partner], roles)) {
    return '/app/partner';
  }

  if (hasAllowedRoles([RoleEnum.Admin], roles)) {
    return '/app/dashboard';
  }

  return '/app/profile';
}

export async function handleLoginResponse(
  response: { access_token: string },
  loadSession: ({ accessToken, ...rest }: any) => void
) {
  const token = response.access_token;
  localStorage.setItem('rpro.token', token);
  const tokenInfo = getTokenInfo(token);
  loadSession({
    ...tokenInfo,
    accessToken: token,
  });

  return tokenInfo;
}

const MainContent: React.FC = () => {
  const history = useHistory();
  const [redirect] = useQueryParam('redirect');
  const { loadSession, session } = useSession();

  const handleFormSubmit = useCallback(
    async (data: LoginFormValue) => {
      try {
        const response = await loginRequest({
          username: data.email,
          password: data.password,
        });
        const tokenInfo = await handleLoginResponse(response, loadSession);
        if (redirect) {
          history.push(redirect);
        } else {
          const path = getDefaultHome(tokenInfo.roles);
          history.push(path);
        }
      } catch (err) {
        Sentry.captureException(err);
        const error = displayAxiosErrors(err);
        if (error?.response?.data.statusCode === 403) message.error(error?.response.data.message);
        else message.error('Invalid user or password');
      }
    },
    [redirect]
  );

  useEffect(() => {
    if (!redirect) {
      const path = getDefaultHome(session?.roles);
      history.push(path);
    }
  }, [redirect, session]);

  return (
    <MainContentLoginLayout>
      <LoginFormContainer>
        <Spacer type="stack" size="s" />
        <Title>Log in to Local Shift</Title>
        <Spacer type="stack" size="l" />
        <EnhancedLoginForm onCompleted={handleFormSubmit} />
      </LoginFormContainer>
      <Spacer type="stack" size="s" />
      <DisclaimerText>
        By Clicking Sign in, I agree to{' '}
        <LinkStyled
          href="https://app.termly.io/document/terms-of-service/26df03e9-1af7-43d7-b17b-2a4d0cb6287b"
          target="_blank"
        >
          LocalShift’s
        </LinkStyled>{' '}
        Terms, including the payment terms and{' '}
        <LinkStyled
          href="https://app.termly.io/document/privacy-policy/d91c18e1-9438-4325-a248-37c26738c731"
          target="_blank"
        >
          Privacy Policy
        </LinkStyled>
      </DisclaimerText>
    </MainContentLoginLayout>
  );
};

export const LoginByLocalShift = () => (
  <LoginLayout leftContent={<LeftContent />} mainContent={<MainContent />}></LoginLayout>
);
