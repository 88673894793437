import 'antd/dist/antd.css';

import React, { useEffect } from 'react';

import styled from 'styled-components';

import { Button } from '../Buttons/Button';
import { GridImages } from '../GridImages/GridImages';
import { Spacer } from '../Spacer';
import UploadButton from '../UploadButton';

const ImgSelected = styled.img`
  max-width: 100%;
  max-height: 216px;
  object-fit: cover;
  border-radius: 8px;
  align-self: center;
  margin-bottom: 24px;
`;

const ImageSelectedContainer = styled.div`
  border-radius: 16px;
  width: 400px;
  height: 304px;
  box-shadow: ${(props) => props.theme.shadows.l};
  border: solid 1px rgba(0, 0, 0, 0.07);
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  align-items: center;
`;

export interface SelectOrUploadImageProps {
  images: string[];
  imageSelected: string[];
  setImagesSelected: React.Dispatch<React.SetStateAction<string[]>>;
  setImagePath: (image: string) => void;
}

export const SelectOrUploadImage: React.FC<SelectOrUploadImageProps> = ({
  images = [],
  imageSelected,
  setImagesSelected,
  setImagePath,
}) => {
  useEffect(() => {
    setImagesSelected(() => (images.length === 1 ? [images[0]] : []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  return (
    <>
      {imageSelected.length === 1 || images.length === 0 ? (
        <ImageSelectedContainer>
          <ImgSelected src={imageSelected[0]} />
          <Spacer type="stack" size="s" />
          {images.length > 0 && (
            <Button disabled={images.length === 1} btnType="text" size="small" onClick={() => setImagesSelected([])}>
              Show Grid
            </Button>
          )}
          <Spacer type="stack" size="l" />
          <UploadButton
            size="xLarge"
            onChange={() => {}}
            setExternalImage={setImagePath}
            radious={'0'}
            customSize={images.length ? { width: '304px', height: '24px' } : { width: '304px', height: '240px' }}
          />
          <Spacer type="stack" size="s" />
        </ImageSelectedContainer>
      ) : (
        <GridImages images={images} imagesSelected={imageSelected} setImagesSelected={setImagesSelected} />
      )}
    </>
  );
};
