import { gql } from '@apollo/client';

export interface ToggleMarketplaceLicenseStatusRequest {
  marketplaceToken: string;
}
export interface ToggleMarketplaceLicenseStatusResponse {
  toggleMarketplaceLicenseStatus: boolean;
}

export const TOGGLE_MARKETPLACE_LICENSE_STATUS = gql`
  mutation toggleMarketplaceLicenseStatus($marketplaceToken: String!) {
    toggleMarketplaceLicenseStatus(marketplaceToken: $marketplaceToken)
  }
`;
