import React from 'react';

import { message } from 'antd';

import * as Sentry from '@sentry/react';

import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { passwordResetRequest } from '../../api/auth.api';
import LoginLayout from '../../components/Layout/LoginLayout';
import { LoginFormContainer, MainContentLoginLayout } from '../../components/Layout/MainContentLoginLayout';
import { Heading2 } from '../../components/ui/Headings/Headings.styled';
import LeftContent from '../../components/ui/LeftLoginContent';
import { snackbar } from '../../components/ui/Snackbar';
import { Spacer } from '../../components/ui/Spacer';
import EnhancedLoginForm, { ResetPasswordFormValue } from './ResetPasswordForm';
import { useSession } from '../../context/AppStateProvider';
import { getDefaultHome, handleLoginResponse } from '../Login';

const Title = styled(Heading2)`
  text-align: center;
`;

const MainContent: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { loadSession } = useSession();
  const code = search.replace('?code=', '');
  const handleFormSubmit = async (data: ResetPasswordFormValue) => {
    try {
      const response = await passwordResetRequest({
        id: code,
        password: data.password,
        confirmPassword: data.confirmPassword,
      });
      if (response.data.access_token) {
        snackbar.success({
          message: 'Password Reset Successfully',
        });
        const tokenInfo = await handleLoginResponse(response.data, loadSession);
        const path = getDefaultHome(tokenInfo.roles);
        history.push(path);
      } else {
        snackbar.info({
          message: response.data.errorMessage ?? 'Error when tried reset password',
        });
      }
    } catch (error: any) {
      Sentry.captureException(error);
      if (error.response.data.statusCode === 403) message.error(error.response.data.message);
      else message.error('Error when tried reset password, try again later');
    }
  };

  return (
    <MainContentLoginLayout>
      <LoginFormContainer>
        <Spacer type="stack" size="s" />
        <Title>Reset Password</Title>
        <Spacer type="stack" size="l" />
        <EnhancedLoginForm onCompleted={handleFormSubmit} />
      </LoginFormContainer>
      <Spacer type="stack" size="xs" />
    </MainContentLoginLayout>
  );
};

const ResetPassword = () => <LoginLayout leftContent={<LeftContent />} mainContent={<MainContent />}></LoginLayout>;

export default ResetPassword;
