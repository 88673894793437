import React from 'react';

import { toast } from 'react-toastify';

import { AlertType, Snackbar, SnackbarProps } from './Snackbar';

interface SnackbarOptions extends SnackbarProps {
  autoClose?: number | false;
}
type AlertSnackbarOptions = Omit<SnackbarOptions, 'alertType'>;

export function snackbar(options: SnackbarOptions) {
  return toast(<Snackbar {...options} />, {
    autoClose: options.autoClose || 5000,
    closeButton: false,
    position: toast.POSITION.BOTTOM_RIGHT,
    closeOnClick: false,
  });
}

snackbar.success = function (options: AlertSnackbarOptions) {
  return snackbar({
    autoClose: 3000,
    ...options,
    alertType: AlertType.Success,
  });
};

snackbar.warning = function (options: AlertSnackbarOptions) {
  return snackbar({
    ...options,
    alertType: AlertType.Warning,
  });
};
snackbar.danger = function (options: AlertSnackbarOptions) {
  return snackbar({
    ...options,
    alertType: AlertType.Danger,
  });
};

snackbar.error = snackbar.danger;

snackbar.info = function (options: AlertSnackbarOptions) {
  return snackbar({
    ...options,
    alertType: AlertType.Info,
  });
};
