import React, { useRef } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { FormRef } from '../../../../models/form';
import { displayGqlErrors } from '../../ErrorList';
import SideDrawer from '../../SideDrawer';
import { snackbar } from '../../Snackbar';
import { LabelBodyB } from '../../Text/Text.styled';
import NewPartnerForm, { NewPartnerFormValue } from './NewPartnerForm';
import { NEW_PARTNER, NewPartnerParams, NewPartnerResponse } from '../../../../graphql/mutations/newPartner';

interface NewPartnerDrawerProps {
  isSideDrawerVisible: boolean;
  toggleSideDrawer: (value?: boolean) => void;
}

const FormContainer = styled.section`
  margin: 16px;
  width: 100%;
`;
const SectionTitleContainer = styled.div`
  margin-bottom: 16px;
`;

const NewPartnerDrawer: React.FC<NewPartnerDrawerProps> = ({ isSideDrawerVisible, toggleSideDrawer }) => {
  const formRef = useRef<FormRef<NewPartnerFormValue>>(null);
  const [createPartner] = useMutation<NewPartnerResponse, NewPartnerParams>(NEW_PARTNER, {});

  const handleNewPartnerSubmit = async (formData: NewPartnerFormValue) => {
    try {
      await createPartner({
        variables: { data: formData },
      });
      snackbar.success({ message: 'Partner created' });
      toggleSideDrawer();
    } catch (err) {
      Sentry.captureException(err);
      displayGqlErrors(err as ApolloError);
    }
  };

  return (
    <SideDrawer
      heading="Partner"
      isDrawerVisible={isSideDrawerVisible}
      toggleDrawerVisible={toggleSideDrawer}
      ctaButtons={[
        {
          label: 'CREATE PARTNER',
          onClick: async () => {
            formRef.current?.submit();
          },
          isDisabled: false,
        },
      ]}
    >
      <FormContainer>
        <SectionTitleContainer>
          <LabelBodyB>Partner details</LabelBodyB>
        </SectionTitleContainer>
        <NewPartnerForm ref={formRef} onCompleted={handleNewPartnerSubmit} />
      </FormContainer>
    </SideDrawer>
  );
};

export default NewPartnerDrawer;
