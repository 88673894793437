import 'draft-js-mention-plugin/lib/plugin.css';

import React, { useState } from 'react';

import { convertToRaw, EditorState, Modifier } from 'draft-js';
import createMentionPlugin, { defaultSuggestionsFilter, MentionData, MentionPlugin } from 'draft-js-mention-plugin';
import Editor, { EditorPlugin } from 'draft-js-plugins-editor';

import styled, { css } from 'styled-components';

import { PostInputStatusEnum } from '../CreatePostModal/components/GeneralPost';
import { MentionEntry, MentionTag, MentionValueEntry, MentionValueTag } from './MentionEntry';
import { createMentionStateFromText } from './mentions.utils';
import mentionStyles from './mentionStyles.module.css';

const EditorWrapper = styled.div<{ $addBorder?: boolean; $height?: string; $width?: string }>`
  box-sizing: border-box;
  width: '100%';
  max-width: ${(props) => props.$width || '100%'};
  height: ${(props) => props.$height || 'auto'};
  overflow: auto;
  border-radius: 4px;
  ${({ theme, $addBorder }) =>
    $addBorder &&
    css`
      border: 1px solid ${theme.colors.dimGray.plus2};
      padding: 16px;
      min-height: 140px;
    `}
  background: ${(props) => props.theme.layoutColors.background};
  cursor: text;
  font-family: ${(props) => props.theme.fonts.body};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  .public-DraftEditor-content {
    width: '100%';
    max-width: ${(props) => props.$width || '100%'};
    text-align: left;
  }
`;

const getCurrentBlock = (editorState: EditorState) => {
  return editorState.getCurrentContent().getPlainText();
};

export type MentionSearchParams = { value: string };
export type MentionSearchFn<TMention extends MentionData = MentionData> = (
  search: MentionSearchParams
) => Promise<TMention[]>;

interface MentionInputProps<TMention extends MentionData> {
  inputId?: string;
  defaultValue?: string;
  selectedValue?: string;
  mentions?: TMention[];
  postStatus?: PostInputStatusEnum;
  addBorder?: boolean;
  height?: string;
  width?: string;
  setPostStatus?: (newPostStatus: PostInputStatusEnum) => void;
  onSearch?: MentionSearchFn<TMention>;
  onChange?: (editorState: string) => void;
  keyword?: string;
  addHashtags?: boolean;
  addOffset?: boolean;
  type?: 'mentionUser' | 'mentionValue';
  onStateChange?: (editorState: EditorState) => void;
}

interface MentionInputState<TMention extends MentionData = MentionData> {
  editorState: EditorState;
  suggestions: TMention[];
}

/**
 * @deprecated
 * This is quite possibly the dumbest component I've ever seen. Aside from the fact that it's written
 * in old React, I hate so many other things about it.
 */
export default class MentionInput<TMention extends MentionData = MentionData> extends React.Component<
  MentionInputProps<TMention>,
  MentionInputState<TMention>
> {
  mentionPlugin: MentionPlugin;
  plugins: EditorPlugin[];
  editorRef: React.RefObject<Editor>;
  state: MentionInputState<TMention> = {
    editorState: EditorState.createEmpty(),
    suggestions: [],
  };

  constructor(props: MentionInputProps<TMention>) {
    super(props);
    this.editorRef = React.createRef<Editor>();
    const defaultValue = props.defaultValue || '';
    const mentions = props.mentions || [];
    const editorState = EditorState.createWithContent(createMentionStateFromText(defaultValue, mentions));
    //initialize editor plugins
    this.mentionPlugin = createMentionPlugin({
      mentionPrefix: '@',
      supportWhitespace: true,
      mentionComponent: this.props?.type === 'mentionValue' ? MentionValueTag : MentionTag,
      theme: mentionStyles,
    });
    this.plugins = [this.mentionPlugin];
    this.state = { editorState, suggestions: props.mentions || [] };
  }

  componentDidUpdate(prevProps: MentionInputProps<TMention>) {
    const { inputId, postStatus, defaultValue, keyword, mentions, setPostStatus, selectedValue: value } = this.props;

    const newInput = prevProps.inputId !== inputId;
    if (newInput || (prevProps.postStatus !== postStatus && postStatus === PostInputStatusEnum.reverted)) {
      const editorState = EditorState.createWithContent(createMentionStateFromText(defaultValue || '', mentions || []));
      this.setState({ editorState });
      setPostStatus?.(PostInputStatusEnum.suggested);
    }
    if (prevProps.selectedValue !== value) {
      const editorState = EditorState.createWithContent(createMentionStateFromText(value || '', mentions || []));
      this.setState({ editorState });
    }
    if (prevProps.keyword !== keyword) {
      if (keyword) this.onInsert(keyword);
    }
  }

  onInsert = (keyword: string) => {
    const contentState = this.state.editorState.getCurrentContent();
    const targetRange = this.state.editorState.getSelection();
    const newContentState = Modifier.replaceText(
      contentState,
      targetRange,
      this.props.addHashtags ? `#${keyword}` : keyword
    );
    const editorState = EditorState.push(this.state.editorState, newContentState, 'insert-characters');
    this.onChange(editorState);
  };

  onChange = (editorState: EditorState) => {
    this.setState({ editorState });
    this.props.onChange?.(getCurrentBlock(editorState));
    this.props.onStateChange?.(editorState);
  };

  defaultSearch = async (search: MentionSearchParams): Promise<TMention[]> => {
    return defaultSuggestionsFilter(search.value, this.props.mentions || []) as TMention[];
  };

  onSearchChange = async (searchParams: MentionSearchParams) => {
    const searchFn: MentionSearchFn<TMention> = this.props.onSearch || this.defaultSearch;
    const newSuggestions = await searchFn(searchParams);
    this.setState({ suggestions: newSuggestions });
  };

  focus = () => {
    this.editorRef.current?.focus();
  };

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const { addBorder, height, width } = this.props;
    const { editorState, suggestions } = this.state;
    return (
      <EditorWrapper onClick={this.focus} $addBorder={addBorder} $height={height} $width={width}>
        {/* 
          // @ts-ignore */}
        <Editor editorState={editorState} onChange={this.onChange} plugins={this.plugins} ref={this.editorRef} />
        {/* 
// @ts-ignore */}
        <MentionSuggestions
          onSearchChange={this.onSearchChange}
          suggestions={suggestions}
          entryComponent={this.props?.type === 'mentionValue' ? MentionValueEntry : MentionEntry}
        />
      </EditorWrapper>
    );
  }
}

export function DebugMentionInput<TMention extends MentionData = MentionData>(props: MentionInputProps<TMention>) {
  const [rawJson, setRawJson] = useState('');
  const renderContentAsRawJson = (editorState: EditorState) => {
    const contentState = editorState.getCurrentContent();
    const raw = convertToRaw(contentState);

    setRawJson(JSON.stringify(raw, null, 2));
  };
  return (
    <>
      <MentionInput {...props} onStateChange={renderContentAsRawJson} />
      <br />
      <h3>editor content as json</h3>
      <pre id="raw-display" style={{ textAlign: 'left' }}>
        {rawJson}
      </pre>
    </>
  );
}
