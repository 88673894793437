import { createGlobalStyle } from 'styled-components';

export const AntCheckBoxStyles = createGlobalStyle`
.ant-checkbox-inner {
  border: solid 2px ${(props) => props.theme.colors.dimGray.plus2};
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: white;
  border-color: ${(props) => props.theme.colors.purpleNavi.plus2};
  &:after {
    font-family: 'ResponsePro';
    content: var(--icon-Icon_Medium_Misc_Done_mini, 'X');
    border: none;
    display: block;
    top: -1px;
    left: 0px;
    width: 6px;
    height: 5px;
    font-size: 18px;
    transform: translate(-50%, -50%);
    color: ${(props) => props.theme.colors.purpleNavi.base};
  }

}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  border-color: ${(props) => props.theme.colors.purpleNavi.plus2};

  &::after {
    height: 2px;
    background-color: ${(props) => props.theme.colors.purpleNavi.base};
  }
}
.ant-checkbox-checked.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: transparent;
}
.ant-checkbox-disabled,
.ant-checkbox-disabled .ant-checkbox-inner::after {
  opacity: 0.4;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-disabled.ant-checkbox-indeterminate .ant-checkbox-inner {
  border: solid 2px ${(props) => props.theme.colors.purpleNavi.plus2} !important;
}`;
