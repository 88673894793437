export function intToString(num: number) {
  const parsed = num.toString().replace(/[^0-9.]/g, '');
  if (num < 1000) {
    return parsed;
  }
  const limits = [
    { limit: 1e3, letter: 'K' },
    { limit: 1e6, letter: 'M' },
  ];
  let index;
  for (index = limits.length - 1; index > 0; index--) {
    if (num >= limits[index].limit) {
      break;
    }
  }
  return (num / limits[index].limit).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + limits[index].letter;
}

export function stringToJson<T>(config: string): T {
  const parsed = JSON.parse(config) as T;
  return parsed;
}
