import { gql } from '@apollo/client';

export interface SyncMerchantCenterFeedRequest {
  locationId: string;
}
export interface SyncMerchantCenterFeedResponse {
  syncGoogleMerchantCenterFeed: {
    id: string;
  };
}

export const SYNC_MERCHANT_CENTER_FEED = gql`
  mutation syncGoogleMerchantCenterFeed($locationId: String!) {
    syncGoogleMerchantCenterFeed(locationId: $locationId) {
      id
    }
  }
`;
