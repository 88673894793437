import React, { useContext, useEffect, useReducer } from 'react';

import { useHistory } from 'react-router-dom';

import { SessionData } from '../models/session';
import { ActionTypes, AppReducer as appReducer, AppState, Dispatch, initialAppState } from './appReducer';

export const AppStateContext = React.createContext<AppState | null>(null);
export const AppSateDispatchContext = React.createContext<Dispatch | null>(null);

export const AppStateProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [state, dispatch] = useReducer(appReducer, initialAppState);
  useEffect(() => {
    window.addEventListener('logout', function () {
      dispatch({ type: ActionTypes.CleanSession });
      const currentLocation = history.location;
      if (currentLocation.pathname.includes('login')) {
        return;
      } else {
        history.push(`/login/?redirect=${currentLocation.pathname}`);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppStateContext.Provider value={state}>
      <AppSateDispatchContext.Provider value={dispatch}>{children}</AppSateDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

export function useAppState() {
  const context = useContext(AppStateContext);
  if (context === null) {
    throw new Error('useAppState must be used within a AppStateProvider');
  }
  return context;
}
export function useAppStateDispatch() {
  const context = useContext(AppSateDispatchContext);
  if (context === null) {
    throw new Error('useAppStateDispatch must be used within a AppStateProvider');
  }
  return context;
}

export function useSession() {
  const state = useAppState();
  const dispatch = useAppStateDispatch();
  return {
    session: state.session,
    loadSession(session: SessionData) {
      dispatch({ type: ActionTypes.LoadSession, payload: session });
      //@ts-ignore
      dispatch({ type: ActionTypes.SelectOrganization, payload: { organizationId: session.organizationId } });
    },
    cleanSession() {
      dispatch({ type: ActionTypes.CleanSession });
    },
  };
}
