export type SourceType = 'primary' | 'yelp' | 'edmunds' | 'facebook' | 'google' | 'dealer rater' | 'cars.com';

export enum CRM {
  VinSolutions = 'VinSolutions',
  eLeads = 'eLeads',
  DriveCentric = 'DriveCentric',
  Byrider = 'Byrider',
  Other = 'Other',
}

export enum LeadRevivalCampaign {
  SimilarInventory = 'SimilarInventory',
  PriceDrop = 'PriceDrop',
  Service = 'Service',
  Lease = 'Lease',
}

export type NameId = {
  id: string;
  name: string;
};

export enum FacebookCampaignObjective {
  Traffic = 'OUTCOME_TRAFFIC',
  Leads = 'OUTCOME_LEADS',
}

export enum CountryCode {
  US = 'US',
  CA = 'CA',
}
