import { gql } from '@apollo/client';

export interface createOfferPostForTemplateRequest {
  offerPostTemplateId: string;
}
export interface createOfferPostForTemplateResponse {
  createOfferPostForTemplate: {
    account: string;
    response: string;
  };
}

export const CREATE_OFFER_POST_FOR_TEMPLATE = gql`
  mutation createOfferPostForTemplate($offerPostTemplateId: String!) {
    createOfferPostForTemplate(offerPostTemplateId: $offerPostTemplateId) {
      account
      response
    }
  }
`;
