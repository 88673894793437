import React from 'react';

import { Control, Controller, FieldErrors, FieldValues } from 'react-hook-form';
import styled from 'styled-components';

import HelperMessage from '../HelperMessage';
import { RadioGroup, RadioGroupProps } from '../RadioGroup/RadioGroup';
import { LabelBold } from '../Text/Text.styled';

const ControlWrapper = styled.div``;

const ControlContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  &.with-label {
    padding-top: 32px;
  }
`;

const LabelBoldStyled = styled(LabelBold)`
  margin-right: 24px;
`;

export interface RadioGroupFieldProps<FormValue extends FieldValues> extends RadioGroupProps {
  name: keyof FormValue & string;
  label?: string;
  subLabel?: string;
  defaultValue?: string | number;
  control: Control<Record<string, any>>;
  errors: FieldErrors<FormValue>;
}

function RadioGroupField<FormValue extends FieldValues = any>(
  props: RadioGroupFieldProps<FormValue>
): React.ReactElement<RadioGroupFieldProps<FormValue>> {
  const { name, defaultValue, control, label, subLabel, errors, options } = props;
  return (
    <ControlWrapper>
      <ControlContainer>
        {label && <LabelBoldStyled>{label?.toUpperCase()}</LabelBoldStyled>}
        {subLabel && <LabelBoldStyled>{subLabel}</LabelBoldStyled>}
        <Controller
          render={({ name, onChange, value }) => (
            <RadioGroup name={name} value={value} onChange={(e) => onChange(e.target.value)} options={options} />
          )}
          name={name}
          control={control}
          defaultValue={defaultValue}
        />
      </ControlContainer>
      {(errors?.[name] && <HelperMessage type="danger" message={(errors[name] as any)?.message} />) || null}
    </ControlWrapper>
  );
}

RadioGroupField.defaultProps = {
  label: '',
  type: 'text',
};

export default RadioGroupField;
