import { gql, useQuery } from '@apollo/client';

import { FacebookPage, FbPageFragment } from '../fragments/fbPageFragments';

export interface FacebookPagesForCurrentUserResponse {
  facebookPagesForCurrentUser: FacebookPage[];
}

export const FACEBOOK_PAGES_FOR_CURRENT_USER = gql`
  query facebookPagesForCurrentUser {
    facebookPagesForCurrentUser {
      ...FbPage
    }
  }
  ${FbPageFragment}
`;

export function useFacebookPagesForCurrentUserQuery() {
  const { data, loading, error } = useQuery<FacebookPagesForCurrentUserResponse>(FACEBOOK_PAGES_FOR_CURRENT_USER, {});
  return {
    facebookPages: data?.facebookPagesForCurrentUser || [],
    loading,
    error,
  };
}
