import React from 'react';

import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Button as ButtonStyle } from '../../../components/ui/Buttons/Button';
import { StyledForm, StyledSubmitWrapper } from '../../../components/ui/FormLogin';
import { Spacer } from '../../../components/ui/Spacer';
import TextField from '../../../components/ui/TextField';
import { FormComponentProps } from '../../../models/form';

const Button = styled(ButtonStyle)`
  width: 100%;
`;

const ValidationSchema = Yup.object().shape({
  email: Yup.string().default('').required('Email required.'),
});

export interface ForgotPasswordFormValue {
  email: string;
}

interface ForgotPasswordProps extends FormComponentProps<ForgotPasswordFormValue> {}

const ForgotPasswordForm: React.FC<ForgotPasswordProps> = (props) => {
  const { handleSubmit, control, errors, formState } = useForm<ForgotPasswordFormValue>({
    resolver: yupResolver(ValidationSchema),
    mode: 'onChange',
  });
  const onSubmit = (data: ForgotPasswordFormValue) => props.onCompleted(data);
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <TextField type="email" placeholder="Email" name="email" control={control} defaultValue="" errors={errors} />
      <Spacer type="stack" size="xs" />
      <StyledSubmitWrapper>
        <Button btnType="primary" type="submit" disabled={formState.touched && !formState.isValid}>
          {props.actionButtonName}
        </Button>
      </StyledSubmitWrapper>
    </StyledForm>
  );
};

export default ForgotPasswordForm;
