import { gql } from '@apollo/client';

export interface MarkDeletedOnMarketplaceParams {
  vin: string;
  locationId: string;
}

export const MARK_DELETED_ON_MARKETPLACE = gql`
  mutation markDeletedOnMarketplace($vin: String!, $locationId: String!) {
    markDeletedOnMarketplace(vin: $vin, locationId: $locationId)
  }
`;
