import { gql } from '@apollo/client';

import { OrganizationItem } from '../query/organizationList';

export interface NewOrganizationRequest {
  name: string;
  website: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  fullName: string;
  phoneNumber: string;
  email: string;
  partnerId?: string;
}

export interface NewOrganizationParams {
  data: NewOrganizationRequest;
}

export interface NewOrganizationResponse {
  createOrganization: OrganizationItem;
}

export const NEW_ORGANIZATION = gql`
  mutation ($data: NewOrganizationRequest!) {
    createOrganization(data: $data) {
      id
      name
      headquarters
      domain
      accountOwnerId
      partnerId
    }
  }
`;
