// Create customFetch function for handling re-authorization
export const customFetch = async (uri: string, options: RequestInit) => {
  let refreshingPromise = null;

  const initialRequest = fetch(uri, options);
  const response = await initialRequest;
  const json = await response.clone().json();

  // check that we do and look for errors from the GraphQL server
  // If it has the error 'Unauthorized' we execute the next steps in the re-auth flow
  if (json && json.errors && json.errors[0] && json.errors[0].message === 'Unauthorized') {
    if (!refreshingPromise) {
      const refreshTokenUrl = `/api/auth/refresh`;
      // Execute the re-authorization request and set the promise returned to this.refreshingPromise
      refreshingPromise = fetch(refreshTokenUrl, { method: 'GET', credentials: 'include' }).then(
        (refresh_token_repsonse) => {
          if (refresh_token_repsonse.ok) {
            return refresh_token_repsonse.json().then((refreshJSON) => {
              // Return the new access token as a result of the promise
              return refreshJSON.access_token;
            });
          } else {
            window.dispatchEvent(new Event('logout'));
          }
        }
      );
    }
    return refreshingPromise.then(() => {
      // Now that the refreshing promise has been executed, set it to null
      refreshingPromise = null;
      // Return the promise from the new fetch (which should now have used an active access token)
      // If the initialRequest had errors, this fetch that is returned below is the final result.
      return fetch(uri, options);
    });
  }

  return response.clone();
};
