import { createGlobalStyle } from 'styled-components';

export const AntRadioGroupStyles = createGlobalStyle`
  .ant-radio-group {
    display: flex;
    height: 32px;
    flex-grow: 0;
    border: 1px solid ${(props) => props.theme.colors.purpleNavi.plus2};
    border-radius: 24px;
    &.borderless {
      border: none;
    }
    .ant-radio-button {
      height: 32px;
      padding: 4px 8px;
    }
    .ant-radio-button-wrapper {
      top: -1px;
      border: none;
      border-radius: 24px;
      background: transparent;
      text-align: center;
      &:before {
        display: none;
      }
      &:hover {
        color: ${(props) => props.theme.colors.dimGray.minus1};
      }
    }
    .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
      background: ${(props) => props.theme.colors.purpleNavi.base};
      color: ${(props) => props.theme.colors.white};
    }

    /** Sytles for small version */
    &.ant-radio-group-small {
      height: 24px;
      .ant-radio-button {
        height: 24px;
        padding: 4px 16px;
      }
    }
    
    &.ant-radio-group-middle {
      .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:nth-child(even) {
        width:65%;
        margin-left: auto;
      }
      .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:nth-child(odd) {
        width:65%;
        margin-right: auto;
      }
    }
    
  }
`;
