import React from 'react';
import { InputProps } from 'antd/lib/input';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';
import { TextInput, TextInputProps } from './TextInput.styled';

export interface SearchInputProps extends TextInputProps {
  removeBorder?: boolean;
}

const borderStyle = (
  // @ts-ignore
  props: ThemedStyledProps<InputProps & React.RefAttributes<Input> & SearchInputProps, DefaultTheme>
) => (props.removeBorder ? 'none' : `2px solid ${props.theme.colors.purpleNavi.plus2}`);

export const SearchInput = styled(({ removeBorder, ...props }) => <TextInput {...props} />)<SearchInputProps>`
  border-radius: 48px;
  background-color: ${(props) => props.theme.colors.white};
  ${(props) => (props.removeBorder ? 'border:none' : '')};
  box-shadow: none;
  .ant-input {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.dimGray.base};
    &::placeholder {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .ant-input-prefix {
    margin-right: 16px;
  }
  .ant-input-prefix .icon {
    color: ${(props) => props.theme.colors.dimGray.base};
  }
  .ant-input:focus {
    border: none;
  }
  &:hover {
    box-shadow: none;

    border: ${(props) => borderStyle(props)};
    .ant-input::placeholder {
      color: ${(props) => props.theme.colors.dimGray.base};
    }
  }
`;
SearchInput.defaultProps = {
  prefix: <span className="icon misc-search-left"></span>,
  removeBorder: false,
};
