import { gql, useQuery } from '@apollo/client';

import { GoogleLocation, GoogleLocationFieldsFragment } from '../fragments/googleLocationFragments';

export interface GoogleLocationsByOrgIdParams {
  organizationId: string;
}

export interface GoogleLocationsByOrgIdResponse {
  googleLocationsByOrgId: GoogleLocation[];
}

export const GOOGLE_LOCATION_BY_ORGID = gql`
  query googleLocationsByOrgId($organizationId: String!) {
    googleLocationsByOrgId(organizationId: $organizationId) {
      ...GoogleLocationFields
    }
  }
  ${GoogleLocationFieldsFragment}
`;

export function useGoogleLocationsByOrgIdQuery(organizationId: string) {
  const { data, loading, error } = useQuery<GoogleLocationsByOrgIdResponse, GoogleLocationsByOrgIdParams>(
    GOOGLE_LOCATION_BY_ORGID,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        organizationId,
      },
    }
  );
  return {
    googleLocations: data?.googleLocationsByOrgId || [],
    loading,
    error,
  };
}
