import React, { useEffect, useState } from 'react';

import { Modal, Table } from 'antd';
import moment from 'moment';

import { InfoCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import styled from 'styled-components';

import { SearchInput } from '../../../components/ui/TextInput/SearchInput.styled';
import { ARCHIVE_OFFER_POST_TEMPLATE } from '../../../graphql/mutations/archiveOfferPostTemplate';
import {
  CREATE_OFFER_POST_DEFAULT,
  createOfferPostDefaultParams,
} from '../../../graphql/mutations/createOfferPostDefault';
import {
  CREATE_OFFER_POST_FOR_TEMPLATE,
  createOfferPostForTemplateRequest,
  createOfferPostForTemplateResponse,
} from '../../../graphql/mutations/createOfferPostForTemplate';
import {
  GET_OFFER_TEMPLATES_BY_LOCATION,
  GetOfferPostTemplatesParams,
  GetOfferPostTemplatesResponse,
  OfferPostTemplate,
  useGetOfferPostTemplates,
} from '../../../graphql/query/getOfferPostTemplatesByLocationId';
import { useRowSelection } from '../../../hooks/useRowSelection';
import { RoleEnum } from '../../../models/session';
import { capitalizeOnlyFirstLetter } from '../../../utils/formatText.utils';
import { Button } from '../Buttons/Button';
import { DefaultModal as CreateOfferTemplateModal } from '../DefaultModal';
import { UpsertOfferForm } from '../Forms/UpsertOfferForm';
import { PostPreview } from '../Forms/UpsertOfferForm/components/PostPreview';
import ItemInfoCard from '../ItemInfoCard/ItemInfoCard';
import { CustomActions, ModalWithFooter as DeleteOfferTemplateModal } from '../ModalWithFooter';
import { HasPermission } from '../Security';
import { snackbar } from '../Snackbar';
import { Spacer } from '../Spacer';
import { LabelBodyR } from '../Text/Text.styled';
import { useParams } from 'react-router-dom';

const { Column } = Table;
const { confirm } = Modal;
const ActionsOfferTemplate = styled(DeleteOfferTemplateModal)`
  top: '120px';
  .ant-modal-body {
    padding: 16px 64px;
  }
`;
const PageContent = styled.div`
  padding: 10px 10px;
  width: 100%;
  align-items: flex-start;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PageHeader = styled.div`
  width: 100%;
  height: 56px;
  padding: 12px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  display: flex;
  align-items: center;

  justify-content: space-between;
`;
const DeleteButton = styled.div`
  margin-right: 18px;
  display: flex;
  font-size: 20px;
  .icon {
    color: ${(props) => props.theme.colors.red};
  }
  &:hover {
    cursor: pointer;
  }
`;

interface ServiceColumnProps {
  cadence: string;
}
const ServiceColumn: React.FC<ServiceColumnProps> = ({ cadence }) => {
  const { locationId } = useParams<{ locationId: string }>();
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const [showCreateUpsertModal, setShowCreateUpsertModal] = useState<boolean>(false);
  const [showActionsModal, setShowActionsModal] = useState<boolean>(false);
  const [offerTemplateSelected, setOfferTemplateSelected] = useState<OfferPostTemplate | undefined>(undefined);
  const [createOfferPostTemplate] = useMutation<createOfferPostForTemplateResponse, createOfferPostForTemplateRequest>(
    CREATE_OFFER_POST_FOR_TEMPLATE
  );
  const [createDefaultOffers] = useMutation<any, createOfferPostDefaultParams>(CREATE_OFFER_POST_DEFAULT);
  const { offerPostTemplates, refetch } = useGetOfferPostTemplates({
    locationId: locationId,
    cadence: cadence,
  });
  const [isDisableDefaultOffer, setIsDisableDefaultOffer] = useState<boolean>(!locationId);

  const reload = () => {
    refetch({
      locationId: locationId,
      cadence: cadence,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (offerPostTemplates) {
      refetch({
        locationId: locationId,
        search: value,
        cadence: cadence,
      });
    }
  };
  const [archiveOfferPosts] = useMutation(ARCHIVE_OFFER_POST_TEMPLATE, {
    update: (cache, { data }) => {
      const queryVariables: GetOfferPostTemplatesParams = {
        locationId: locationId,
        cadence: cadence,
      };
      const querydata = cache.readQuery<GetOfferPostTemplatesResponse, GetOfferPostTemplatesParams>({
        query: GET_OFFER_TEMPLATES_BY_LOCATION,
        variables: queryVariables,
      }) || { getOfferPostTemplatesByLocationId: [] };
      const mergedData: GetOfferPostTemplatesResponse = {
        getOfferPostTemplatesByLocationId: [...querydata.getOfferPostTemplatesByLocationId, data!],
      };
      cache.writeQuery<GetOfferPostTemplatesResponse, GetOfferPostTemplatesParams>({
        query: GET_OFFER_TEMPLATES_BY_LOCATION,
        variables: queryVariables,
        data: mergedData,
      });
    },
  });

  const offerPosts = offerPostTemplates || [];

  const { rowSelection, selectedRowKeys, selected, setSelectedRowKeys, setSelected } =
    useRowSelection<OfferPostTemplate>();

  const handleDelete = async () => {
    try {
      await archiveOfferPosts({
        variables: { data: selectedRowKeys },
      });
      setSelectedRowKeys([]);
      setSelected(false);
      snackbar.success({
        message: selectedRowKeys.length > 1 ? 'Offer Posts archived Successfully' : 'Offer Post archived Succesfully',
      });
    } catch (error) {
      Sentry.captureException(error);
      snackbar.error({
        message: selectedRowKeys.length > 1 ? 'Unable to archived Offer Posts' : 'Unable to archived Offer Post',
      });
    }
  };

  const onCreateNewOfferPostTemplate = async () => {
    setOfferTemplateSelected(undefined);
    setShowCreateUpsertModal(true);
  };

  const onCreateDefaultOfferPost = async () => {
    const result = await createDefaultOffers({
      variables: { locationId: locationId },
    });
    if (result) {
      refetch();
    } else {
      await snackbar.warning({
        message: `Failed on creating default services`,
      });
    }
  };

  useEffect(() => {
    setIsDisableDefaultOffer(!!offerPostTemplates.length || !locationId);
  }, [offerPostTemplates, locationId]);

  const handleCreateDefaultPosts = async () => {
    confirm({
      title: `Create Default Posts`,
      icon: <InfoCircleOutlined />,
      okType: 'primary',
      okText: `YES, create`,
      content: `This action creates five default offer post templates `,
      onOk() {
        onCreateDefaultOfferPost();
      },
    });
  };

  const onOkArchive = async () => {
    await handleDelete();
    setShowArchiveModal(false);
  };
  const onSelectTemplate = (template: OfferPostTemplate) => {
    setOfferTemplateSelected(template);
    setShowActionsModal(true);
  };

  const onEditTemplate = () => {
    setShowCreateUpsertModal(true);
    setShowActionsModal(false);
  };

  const onPostTemplate = async () => {
    if (offerTemplateSelected) {
      const result = await createOfferPostTemplate({
        variables: { offerPostTemplateId: offerTemplateSelected.id },
      });
      await snackbar.info({
        message: `Posting template ...`,
      });
      await snackbar.info({
        message: `${capitalizeOnlyFirstLetter(result.data?.createOfferPostForTemplate?.account)}: ${
          result.data?.createOfferPostForTemplate?.response
        }`,
      });
      setShowActionsModal(false);
    }
  };

  const actions: CustomActions = {
    primaryButtonAction: onPostTemplate,
    secondaryButtonAction: onEditTemplate,
  };

  return (
    <>
      <CreateOfferTemplateModal
        width={1200}
        visible={showCreateUpsertModal}
        onCancel={() => setShowCreateUpsertModal(false)}
        title={'Create Service Post'}
        okText={'Save'}
        maskClosable={false}
        footer={null}
        children={
          <UpsertOfferForm
            refetch={reload}
            locationId={locationId}
            setShowModal={setShowCreateUpsertModal}
            updatedObject={offerTemplateSelected}
          />
        }
      />
      <ActionsOfferTemplate
        visible={showActionsModal}
        onCancel={() => {
          setShowActionsModal(false);
        }}
        width={520}
        centered={false}
        actions={actions}
        title={'Service Post Preview'}
        okText={'Post'}
        cancelText={'Edit'}
      >
        <PostPreview template={offerTemplateSelected} imageSelected={offerTemplateSelected?.imageUrl} />
      </ActionsOfferTemplate>
      <DeleteOfferTemplateModal
        visible={showArchiveModal}
        onCancel={() => setShowArchiveModal(false)}
        onOk={onOkArchive}
        title={'Delete Offer Post Template'}
        okText={'Delete'}
      >
        <div>
          <LabelBodyR>
            {`Are you sure you want to delete those offer post templates? You can’t undo this action.`}
          </LabelBodyR>
        </div>
      </DeleteOfferTemplateModal>
      <PageHeader>
        <SearchInput placeholder="Search by Offer title or Coupon code" onChange={handleChange} removeBorder />

        <ActionsWrapper>
          {selected && (
            <DeleteButton
              onClick={() => {
                setShowArchiveModal(true);
              }}
            >
              <i className="icon misc-delete" />
            </DeleteButton>
          )}
        </ActionsWrapper>
        <HasPermission allowedRoles={[RoleEnum.Admin, RoleEnum.SuperAdmin, RoleEnum.FacebookTest, RoleEnum.Partner]}>
          <Button btnType="secondary" disabled={isDisableDefaultOffer} size="small" onClick={handleCreateDefaultPosts}>
            CREATE DEFAULT OFFERS
          </Button>
          <Spacer size="xs" type="inline" />
          <Button btnType="primary" size="small" onClick={onCreateNewOfferPostTemplate} disabled={!locationId}>
            CREATE SERVICE POST
          </Button>
        </HasPermission>
      </PageHeader>
      <PageContent>
        <Table className="wide-table" dataSource={offerPosts} rowKey="id" rowSelection={rowSelection}>
          <Column<OfferPostTemplate>
            title="Offer Title"
            width="240px"
            dataIndex="title"
            key="title"
            render={(title, row) => <ItemInfoCard item={title} avatar={row.imageUrl} imgError={''} />}
          />
          <Column<OfferPostTemplate>
            title="Coupon Code"
            width="160px"
            dataIndex="couponCode"
            key="couponCode"
            render={(couponCode) => <div>{couponCode}</div>}
          />

          <Column<OfferPostTemplate>
            title="Last Edited"
            width="160px"
            dataIndex="updatedAt"
            key="updatedAt"
            render={(updatedAt) => <div>{moment(updatedAt).format('MMMM DD')}</div>}
          />
          <Column<OfferPostTemplate>
            title="Cadence"
            width="160px"
            dataIndex="cadence"
            key="cadence"
            render={(cadence) => <div>{cadence}</div>}
          />
          <Column<OfferPostTemplate>
            title=""
            width="160px"
            dataIndex="id"
            key="id"
            render={(_field, record) => (
              <Button
                btnType="secondary"
                onClick={() => {
                  onSelectTemplate(record);
                }}
                size="small"
              >
                POST
              </Button>
            )}
          />
        </Table>
      </PageContent>
    </>
  );
};

export default ServiceColumn;
