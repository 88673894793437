import React, { useEffect } from 'react';
import { RadioChangeEvent } from 'antd/lib/radio';
import styled from 'styled-components';
import { useLocationByOrgIdQuery } from '../../../graphql/query/locationsByOrgId';
import { PostTypeEnum } from '../../../graphql/query/postHistoryByLocationId';
import { ServiceFilters } from '../../../pages/Inventory/hooks/useServiceFilters';
import { VehicleFilters } from '../../../pages/Inventory/hooks/useVehicleFilters';
import { Radio, Select } from 'antd';
import { Spacer } from '../../ui/Spacer';
import { ServiceMenu } from './ServiceMenu';
import { VehicleMenu } from './VehicleMenu';
import { useHistory, useParams } from 'react-router-dom';
import { match } from 'ts-pattern';
import { SelectOption } from '../../ui/FormSelectField/FormSelectField';
import { useHasPermission, Permission } from '../../../hooks/useHasPermission';

const AccountMenuWrapper = styled.div`
  padding: 24px 16px;
  width: 200px;
`;
export const RadioOption = styled.div`
  display: flex;
  flex-direction: column;
`;
export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  align-items: flex-start;
`;

const RadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

interface InventoryMenuProps {
  vehicleFilters: VehicleFilters;
  serviceFilters: ServiceFilters;
  setView: (view: PostTypeEnum) => void;
  view: PostTypeEnum;
}
export const InventoryMenu: React.FC<InventoryMenuProps> = ({ setView, view, vehicleFilters, serviceFilters }) => {
  let { organizationId, locationId } = useParams<{ organizationId: string; locationId?: string }>();
  const history = useHistory();
  const { locations } = useLocationByOrgIdQuery(organizationId!);
  const locationOptions: SelectOption[] = locations.map<SelectOption>((location) => ({
    label: location.name,
    value: location.id,
  }));

  let modeViewOptions: { label: string; value: string }[] = [];
  const canSeeAllInventoryView = useHasPermission(Permission.SeeAllInventoryView);
  if (canSeeAllInventoryView) {
    modeViewOptions = [
      { label: 'Vehicles', value: PostTypeEnum.VEHICLE },
      { label: 'Services', value: PostTypeEnum.OFFER },
      { label: 'Media', value: PostTypeEnum.LOCATION_CONTENT },
    ];
  } else {
    modeViewOptions = [{ label: 'Vehicles', value: PostTypeEnum.VEHICLE }];
  }

  const handleChangeView = ({ target: { value } }: RadioChangeEvent) => {
    setView(value);
  };

  useEffect(() => {
    if (locations?.length > 0) {
      const firstLocation = locations[0];
      if (locationId) {
        const selected = locations.find((location) => location.id === locationId);
        if (selected) {
          history.replace(`/app/inventory/${organizationId}/${selected.id}`);
        } else {
          history.replace(`/app/inventory/${organizationId}/${firstLocation.id}`);
        }
      } else {
        history.replace(`/app/inventory/${organizationId}/${firstLocation.id}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, locationId]);
  const hasShopId = locations.some((location) => location.id === locationId && location.shopId);
  const hasVehicleCatalog = locations.some((location) => location.id === locationId && location.catalogId);

  if (!locationId) return null;

  const vehicleMenu = (
    <VehicleMenu
      hasVehicleCatalog={hasVehicleCatalog}
      hasShopId={hasShopId}
      locationId={locationId}
      VehicleFilters={vehicleFilters}
    />
  );
  const serviceMenu = <ServiceMenu serviceFilters={serviceFilters} />;
  const contentMenu = <div />;

  const menu = match(view)
    .with(PostTypeEnum.OFFER, () => serviceMenu)
    .with(PostTypeEnum.VEHICLE, () => vehicleMenu)
    .with(PostTypeEnum.LOCATION_CONTENT, () => contentMenu)
    .exhaustive();

  return (
    <AccountMenuWrapper>
      <OptionsWrapper>
        <Select
          options={locationOptions}
          value={locationId}
          onChange={(val) => {
            history.push(`/app/inventory/${organizationId}/${val}`);
          }}
        />
        <Spacer type="stack" size="s" />
        <RadioGroup
          defaultValue={PostTypeEnum.VEHICLE}
          options={modeViewOptions}
          onChange={handleChangeView}
          className={'borderless'}
        ></RadioGroup>
      </OptionsWrapper>
      <Spacer type="stack" size="s" />
      {menu}
    </AccountMenuWrapper>
  );
};
