import { useState } from 'react';

export function useSideDrawerToggle() {
  const [isSideDrawerVisible, setIsSideDrawerVisible] = useState<Record<string, boolean>>({});

  const toggleSideDrawer = (drawerName: string) => {
    setIsSideDrawerVisible({
      ...isSideDrawerVisible,
      [drawerName]: !isSideDrawerVisible[drawerName],
    });
  };
  return { isSideDrawerVisible, setIsSideDrawerVisible, toggleSideDrawer };
}
