import { gql } from '@apollo/client';

import { OfferPostTemplate } from '../query/getOfferPostTemplatesByLocationId';

export interface createOfferPostDefaultResponse {
  createOfferPostDefault: OfferPostTemplate[];
}

export interface createOfferPostDefaultParams {
  locationId: string;
}

export const CREATE_OFFER_POST_DEFAULT = gql`
  mutation createOfferPostDefault($locationId: String!) {
    createOfferPostDefault(locationId: $locationId) {
      id
      locationId
      archivedAt
      cadence
      couponCode
      termsAndConditions
      link
      updatedAt
      title
      details
      imageUrl
    }
  }
`;
