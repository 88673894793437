import React from 'react';

import { Card } from 'antd';

import styled from 'styled-components';

import mainTheme from '../../StyleGuide/themes/mainTheme';
import { Heading4 } from '../Headings/Headings.styled';
import IconButtonSimple from '../IconButtonSimple';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 24px 16px 0;
`;
export interface SimpleCardProps extends React.HTMLAttributes<HTMLDivElement> {
  topHeader?: string;
  topIconButton?: {
    icon: 'edit' | 'plus';
    onClick: () => void;
  };
}

const SimpleCard: React.FC<SimpleCardProps> = ({ topHeader, topIconButton, children, ...props }) => (
  <div {...props}>
    {topHeader?.length && (
      <HeaderContainer>
        <Heading4>{topHeader}</Heading4>
        {topIconButton && (
          <IconButtonSimple icon={topIconButton.icon} onClick={topIconButton.onClick}></IconButtonSimple>
        )}
      </HeaderContainer>
    )}
    <Card
      className="card"
      bodyStyle={{ padding: 0 }}
      style={{
        borderRadius: '16px',
        boxShadow: mainTheme.shadows.l,
        border: 'solid 1px rgba(0, 0, 0, 0.07)',
      }}
    >
      {children}
    </Card>
  </div>
);

export default SimpleCard;
