import React from 'react';

import styled from 'styled-components';

import { LogoColor } from '../../../pages/Onboarding';

const MainWrapper = styled.section`
  height: 100%;
  width: 100%;
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const MainContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  .button {
    width: 100%;
  }
`;

export const LoginFormContainer = styled.div`
  width: 100%;
  text-align: left;
`;
export const DisclaimerText = styled.p`
  width: 65%;
  color: ${(props) => props.theme.colors.dimGray.minus1};
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  font-weight: normal;
`;

export const MainContentLoginLayout: React.FC = ({ children }) => {
  return (
    <MainWrapper>
      <MainContainer>
        <LogoColor />
        {children}
      </MainContainer>
    </MainWrapper>
  );
};
