import React from 'react';

import styled from 'styled-components';
import { OnboardingStep } from '../../../pages/Onboarding/OnboardingStepProvider';

import Step from './Step';

export interface StepperProps {
  steps: OnboardingStep[];
  activeStep: OnboardingStep;
}

export const StyledStepper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: inehrit;
  background-color: transparent;
`;

export const StyledWrapper = styled.div`
  width: 450px; //auto
  &:after {
    display: block;
    height: 1px;
    position: relative;
    content: ' ';
    width: 90%;
    z-indexs: 2;
    background-color: ${(props) => props.theme.colors.dimGray.plus3};
    left: 10px;
    top: -36px;
  }
`;

export const Stepper: React.FC<StepperProps> = ({ steps, activeStep }) => {
  return (
    <StyledWrapper>
      <StyledStepper>
        {steps.map((step) => {
          if (step.order > activeStep.order) return <Step title={step.label} state={'inactive'} key={step.label} />;
          else if (step.order < activeStep.order) return <Step title={step.label} state={'done'} key={step.label} />;
          else return <Step title={step.label} state={'active'} key={step.label} />;
        })}
      </StyledStepper>
    </StyledWrapper>
  );
};

export default Stepper;
