import React, { useImperativeHandle } from 'react';

import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { validateEmail } from '../../../../api/auth.api';
import { FormComponentProps, FormRef } from '../../../../models/form';
import { Spacer } from '../../Spacer';
import { LabelBodyB } from '../../Text/Text.styled';
import TextField from '../../TextField';
import { NewPartnerRequest } from '../../../../graphql/mutations/newPartner';

const ValidationSchema = Yup.object().shape({
  name: Yup.string().default('').required(),
  fullName: Yup.string().default('').required(),
  email: Yup.string()
    .default('')
    .required()
    .test('is-not-new-user', 'Email already exists', async (value) => {
      if (value) {
        const existsUser = await validateEmail({ email: value || '' });
        return !existsUser;
      }

      return false;
    }),
  phoneNumber: Yup.string().default('').required(),
  password: Yup.string().default('').required(),
});

export interface NewPartnerFormValue extends NewPartnerRequest {}
export interface NewPartnerFormProps extends FormComponentProps<NewPartnerFormValue> {}

const NewPartnerForm = React.forwardRef<FormRef<NewPartnerFormValue>, NewPartnerFormProps>(
  (props: NewPartnerFormProps, ref) => {
    const { handleSubmit, control, errors, formState } = useForm<NewPartnerFormValue>({
      resolver: yupResolver(ValidationSchema),
      mode: 'onTouched',
    });

    const onSubmit = (data: NewPartnerFormValue) => {
      props.onCompleted(data);
    };
    const submitHandler = handleSubmit(onSubmit);
    useImperativeHandle(
      ref,
      () => ({
        formState,
        submit: submitHandler,
      }),
      [formState, submitHandler]
    );
    return (
      <form onSubmit={submitHandler}>
        <TextField type="text" name="name" label="NAME" placeholder="Partner Name" control={control} errors={errors} />
        <Spacer type="stack" size="l" />
        <LabelBodyB>User details</LabelBodyB>
        <Spacer type="stack" size={'m'} />
        <TextField
          type="text"
          name="fullName"
          label="FULL NAME"
          placeholder="John Doe"
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size={'m'} />
        <TextField
          type="text"
          name="email"
          label="EMAIL"
          placeholder="johndoe@mail.com"
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size={'m'} />
        <TextField
          type="text"
          name="phoneNumber"
          label="PHONE"
          placeholder="(555) 555-1234"
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="m" />
        <TextField type="text" name="password" label="PASSWORD" control={control} errors={errors} />
        <Spacer type="stack" size="m" />
      </form>
    );
  }
);

export default NewPartnerForm;
