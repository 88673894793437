import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import IconButtonSimple from '../../../components/ui/IconButtonSimple';
import { LabelB } from '../../../components/ui/Text/Text.styled';
import { PostTypeEnum } from '../../../graphql/query/postHistoryByLocationId';

const FooterSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

interface FooterSectionProps {
  mode: PostTypeEnum;
  locationId: string;
}

const FooterSection: React.FC<FooterSectionProps> = ({ mode, locationId }) => {
  return (
    <FooterSectionWrapper>
      <LabelB>Post history</LabelB>
      <Link to={`/app/inventory/history/${mode}/${locationId}`}>
        <IconButtonSimple icon="next" size="small" />
      </Link>
    </FooterSectionWrapper>
  );
};

export default FooterSection;
