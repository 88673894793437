import React, { useState } from 'react';

import { SideMenuLayout } from '../../components/Layout/SideMenuLayout';
import { InventoryMenu } from '../../components/Menu/InventoryMenu';
import ServiceColumn from '../../components/ui/ServiceColumn/ServiceColumn';
import { VehicleColumn } from '../../components/ui/VehicleColumn';
import { PostStateProvider } from '../../context/PostStateProvider';
import { PostTypeEnum } from '../../graphql/query/postHistoryByLocationId';
import FooterSection from './components/FooterSection';
import { useServiceFilters } from './hooks/useServiceFilters';
import { useVehicleFilters } from './hooks/useVehicleFilters';
import LocationContentColumn from '../../components/ui/LocationContentColumn/LocationContentColumn';
import { match } from 'ts-pattern';
import { useParams } from 'react-router-dom';

const Inventory: React.FC = () => {
  const vehicleFilters = useVehicleFilters();
  const serviceFilters = useServiceFilters();
  const { locationId } = useParams<{ locationId: string }>();
  const [view, setView] = useState<PostTypeEnum>(PostTypeEnum.VEHICLE);

  const VehicleContent = (
    <PostStateProvider>
      <VehicleColumn vehicleFilters={vehicleFilters} />
    </PostStateProvider>
  );

  const ServiceContent = <ServiceColumn cadence={serviceFilters.cadence} />;

  const LocationContent = <LocationContentColumn />;

  const contentColumn = match(view)
    .with(PostTypeEnum.VEHICLE, () => VehicleContent)
    .with(PostTypeEnum.OFFER, () => ServiceContent)
    .with(PostTypeEnum.LOCATION_CONTENT, () => LocationContent)
    .exhaustive();

  return (
    <SideMenuLayout
      title="Inventory"
      sideColumn={
        <InventoryMenu serviceFilters={serviceFilters} vehicleFilters={vehicleFilters} setView={setView} view={view} />
      }
      sideFooter={<FooterSection mode={view} locationId={locationId} />}
      contentColumn={contentColumn}
    />
  );
};

export default Inventory;
