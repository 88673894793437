import React from 'react';

import { ModalProps } from 'antd/lib/modal';

import styled from 'styled-components';

import { Button } from '../Buttons/Button';
import { DefaultModal } from '../DefaultModal';

export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;
interface ModalWithFooterProps extends ModalProps {
  actions?: CustomActions;
  hasSecondaryAction?: boolean;
  hasPrimaryAction?: boolean;
}

export interface CustomActions {
  primaryButtonAction?: () => void;
  secondaryButtonAction?: () => void;
  disablePrimaryAction?: boolean;
  disableSecondaryAction?: boolean;
}

export const ModalWithFooter: React.FC<ModalWithFooterProps> = ({
  hasSecondaryAction = true,
  hasPrimaryAction = true,
  actions,
  title,
  ...props
}) => {
  const footer = (
    <StyledButtonsContainer>
      {hasSecondaryAction && (
        <Button
          btnType="secondary"
          onClick={actions?.secondaryButtonAction || props.onCancel}
          disabled={actions?.disableSecondaryAction}
        >
          {props.cancelText || 'CANCEL'}
        </Button>
      )}
      {hasPrimaryAction && (
        <Button
          btnType="primary"
          onClick={actions?.primaryButtonAction || props.onOk}
          disabled={actions?.disablePrimaryAction}
        >
          {props.okText || 'OK'}
        </Button>
      )}
    </StyledButtonsContainer>
  );

  return <DefaultModal title={title} centered footer={footer} {...props} />;
};
