import { match } from 'ts-pattern';

import { InventoryItem } from '../../../../graphql/query/inventoryByLocationId';

type FilterOption = 'Posted' | 'Not posted' | 'Ineligible for Marketplace' | 'Eligible for Marketplace';
export const filtersOptions: { id: number; name: FilterOption }[] = [
  { id: 1, name: 'Posted' },
  { id: 2, name: 'Not posted' },
  { id: 3, name: 'Eligible for Marketplace' },
  { id: 4, name: 'Ineligible for Marketplace' },
];

export const applyFilters = (item: InventoryItem, filters: number[]): boolean => {
  const hasPosts = item?.postMetric?.length > 0;
  const options = filtersOptions.filter((filter) => filters.includes(filter.id)).map((x) => x.name);
  const alreadyPostedToMarketplace = item?.marketplaceIneligibleReasons?.includes('already posted');
  const conditions = options.reduce((previousValue, currentValue) => {
    const condition = match(currentValue)
      .with('Posted', () => hasPosts || options.includes('Not posted'))
      .with('Not posted', () => !hasPosts || options.includes('Posted'))
      .with(
        'Ineligible for Marketplace',
        () => item.marketplaceIneligibleReasons?.length && !alreadyPostedToMarketplace // Don't show "already posted" as an ineligible reason
      )
      .with('Eligible for Marketplace', () => !item.marketplaceIneligibleReasons?.length)
      .exhaustive();
    return previousValue && Boolean(condition);
  }, true);
  return conditions;
};
