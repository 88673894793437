import React from 'react';

import { Spacer } from '../../ui/Spacer';
import NavigationLink, { NavigationLinkProps } from './NavigationLink';
import styled from 'styled-components';

const SideNavigationWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-top: 16px;
  margin-left: 8px;
`;

export const SideNavigation: React.FC<{ config: NavigationLinkProps[] }> = ({ config }) => {
  return (
    <SideNavigationWrapper>
      {config.map((itemProps, index) => (
        <React.Fragment key={index}>
          <NavigationLink key={index} {...itemProps} />
          <Spacer type="stack" size="xs" />
        </React.Fragment>
      ))}
    </SideNavigationWrapper>
  );
};
