import React from 'react';

import { Control, Controller, FieldError, FieldErrors, FieldValues } from 'react-hook-form';

import HelperMessage from '../HelperMessage';
import { LabelBold } from '../Text/Text.styled';
import { Select } from 'antd';
import { SelectProps, SelectValue } from 'antd/lib/select';

export type SelectOption = {
  label: string;
  value: string;
  disabled?: boolean;
};

export interface FormSelectFieldProps<FormValue extends FieldValues> extends SelectProps<SelectValue> {
  name: keyof FormValue & string;
  label?: string;
  type?: string;
  defaultValue?: string | number | null;
  placeholder?: string;
  prefixIconClass?: string;
  control: Control<Record<string, any>>;
  errors: FieldErrors<FormValue>;
  options: SelectOption[];
}

function FormSelectField<FormValue extends FieldValues = FieldValues>(
  props: FormSelectFieldProps<FormValue>
): React.ReactElement<FormSelectFieldProps<FormValue>> {
  const { name, defaultValue, control, label, errors, placeholder, options, onChange: overrideOnChange } = props;

  const helperText =
    (errors?.[name] && <HelperMessage type="danger" message={(errors[name] as FieldError)?.message} />) || null;

  return (
    <>
      <LabelBold>{label}</LabelBold>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange }) => {
          return (
            <>
              <Select
                style={{ width: '100%' }}
                placeholder={placeholder}
                onChange={overrideOnChange ?? onChange}
                optionFilterProp="label"
                allowClear
                defaultValue={defaultValue}
                options={options}
                showSearch
                filterOption={true}
              ></Select>
              {helperText}
            </>
          );
        }}
      />
    </>
  );
}

export default FormSelectField;
