import React from 'react';
import styled from 'styled-components';
import { displayRange } from '../../../utils/formatText.utils';
import AvatarImage from '../AvatarImage';
import { Label, LabelBold } from '../Text/Text.styled';
import { VerticalTabItemWrapper } from '../VerticalTabItem/VerticalTabItem.styled';
import { Partner } from '../../../graphql/query/partners';
import { Tag } from 'antd';
import { colors } from '../../StyleGuide/themes/mainTheme';

const TextContainer = styled.div`
  flex-direction: column;
  line-break: after;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

const VerticalTabItemWrapperStyled = styled(VerticalTabItemWrapper)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
`;
export interface OrganizationTabProps {
  active?: boolean;
  isNew?: boolean;
  imageSrc?: string;
  orgName?: string;
  orgCityState?: string;
  organizationId?: string;
  isActive?: boolean;
  onClick?: () => void;
  partner?: Partner;
}

const OrganizationTab: React.FC<OrganizationTabProps> = ({
  active,
  imageSrc,
  orgName,
  orgCityState,
  onClick,
  partner,
}) => {
  return (
    <VerticalTabItemWrapperStyled style={{ height: '64px' }} active={active || false} onClick={onClick}>
      <AvatarImage src={imageSrc} nameAvatar={orgName} active={active} size={'medium'} />
      <TextContainer>
        <LabelBold>{displayRange(28, orgName!) || ''}</LabelBold>
        <Label>{displayRange(28, orgCityState!) || ''}</Label>
        {partner && (
          <Tag color={colors.viridianGreen.base} style={{ display: 'flex' }}>
            {displayRange(28, partner.name)}
          </Tag>
        )}
      </TextContainer>
      <i className={`icon tab-icon `} />
    </VerticalTabItemWrapperStyled>
  );
};

OrganizationTab.defaultProps = {
  onClick: () => {},
};
export default OrganizationTab;
