import { gql, useQuery } from '@apollo/client';

import { InventoryItem } from './inventoryByLocationId';

export interface PostInQueueElement {
  id: string;
  postedDate: string;
  locationId: string;
  inventoryId: string;
  dateAdded: Date;
  dateSent: Date | null;
  socialMedia: string[];
  inventory: InventoryItem;
}

export interface GetLocationsWithQueueResponse {
  getQueueByLocation: PostInQueueElement[];
}

export interface GetLocationsWithQueueParams {
  name?: string;
  locationId: string;
}

export const GET_QUEUE_BY_LOCATION = gql`
  query getQueueByLocation($locationId: String!, $name: String) {
    getQueueByLocation(locationId: $locationId, name: $name) {
      id
      postedDate
      locationId
      inventoryId
      dateAdded
      dateSent
      socialMedia
      inventory {
        condition
        age
        imgSource
        price
        status
        vehicle
      }
    }
  }
`;

export function useQueueByLocation(params: GetLocationsWithQueueParams) {
  const { data, error, loading, refetch } = useQuery<GetLocationsWithQueueResponse, GetLocationsWithQueueParams>(
    GET_QUEUE_BY_LOCATION,
    {
      variables: params,
      fetchPolicy: 'cache-and-network',
    }
  );

  return {
    refetch,
    queueList: data?.getQueueByLocation || [],
    error,
    loading,
  };
}
