import React from 'react';

import styled from 'styled-components';

import DashboardLayout from '../../components/Layout/DashboardLayout';
import DashboardContent from '../../components/ui/DashboardContent';
import DashboardFilters from '../../components/ui/DashboardFilters/DashboardFilters';
import { H3 } from '../../components/ui/Text/Text.styled';
import { useDashboardFilters } from './hooks/useFilters';

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

const Dashboard: React.FC = () => {
  const dashboardFilters = useDashboardFilters();

  return (
    <DashboardLayout
      menu={
        <MenuWrapper>
          <H3>Accounts</H3>
          <DashboardFilters dashboardFilters={dashboardFilters} />
        </MenuWrapper>
      }
      mainContent={<DashboardContent dashboardFilters={dashboardFilters} />}
    />
  );
};

export default Dashboard;
