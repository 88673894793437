import { gql } from '@apollo/client';
import { Partner, PartnerFieldsFragment } from '../query/partners';
import { EditPartnerFormValue } from '../../components/ui/Forms/EditPartnerForm/EditPartnerForm';

export interface EditPartnerRequest extends EditPartnerFormValue {
  id: string;
  name: string;
}

export interface EditPartnerParams {
  data: EditPartnerRequest;
}

export interface EditPartnerResponse {
  updatedPartner: Partner;
}

export const EDIT_PARTNER = gql`
  mutation ($data: UpdatePartnerRequest!) {
    updatePartner(data: $data) {
      ...PartnerFields
    }
  }
  ${PartnerFieldsFragment}
`;
