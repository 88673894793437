import React from 'react';

import { Table } from 'antd';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { TagLabel } from '../../../components/ui/TagLabel';
import { SearchInput } from '../../../components/ui/TextInput/SearchInput.styled';
import { LocationWithQueue, useGetLocationWithQueue } from '../../../graphql/query/getLocationWithQueue';
import { createSortProps } from '../../../utils/table.utils';
import { FilterOptionsMode } from '../QueuePage';

const { Column } = Table;
const PageContent = styled.div`
  padding: 10px 10px;
  width: 100%;
  align-items: flex-start;
`;
const PageHeader = styled.div`
  width: 100%;
  height: 56px;
  padding: 12px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  display: flex;
  align-items: center;

  justify-content: space-between;
`;
interface QueuePageColumnParams {
  mode: FilterOptionsMode;
}
export const QueuePageColumn: React.FC<QueuePageColumnParams> = ({ mode }) => {
  const { queue, refetch } = useGetLocationWithQueue({
    filter: mode === 'Priority',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (queue) {
      refetch({
        filter: mode === 'Priority',
        name: value,
      });
    }
  };
  const queueData = queue?.nodes || [];
  return (
    <>
      <PageHeader>
        <SearchInput placeholder="Search by Location or Organization" onChange={handleChange} removeBorder />
      </PageHeader>
      <PageContent>
        <Table className="wide-table" dataSource={queueData} rowKey="id">
          <Column<LocationWithQueue>
            title="Location"
            width="240px"
            dataIndex="locationName"
            key="locationName"
            render={(locationName, row) => <Link to={`/app/queue/location/${row.locationId}`}>{locationName}</Link>}
          />
          <Column<LocationWithQueue>
            title="Vehicle in Queue"
            width="160px"
            dataIndex="queueItemsCount"
            key="queueItemsCount"
            render={(queueItemsCount) => (
              <TagLabel color={queueItemsCount > 4 ? 'default' : 'redVariant'}>{queueItemsCount}</TagLabel>
            )}
            {...createSortProps<LocationWithQueue>({
              type: 'number',
              field: 'queueItemsCount',
              multiple: 1,
            })}
          />
          <Column<LocationWithQueue>
            title="Organization"
            width="160px"
            dataIndex="organizationName"
            key="organizationName"
            render={(organizationName) => <div>{organizationName}</div>}
          />
        </Table>
      </PageContent>
    </>
  );
};
