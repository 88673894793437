import { Card } from 'antd';

import styled from 'styled-components';

export const CardStyled = styled(Card)`
  border-radius: 8px;
  width: 100%;
  box-shadow: ${(props) => props.theme.shadows.l};
  border: solid 1px rgba(0, 0, 0, 0.07);
`;

export const VehicleCardStyle = styled(CardStyled)`
  height: 43.7%;
  overflow-y: scroll;
`;
