import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Button as AntdAButton, Checkbox, message, Modal, Select, Tooltip } from 'antd';
import moment from 'moment';
import * as Yup from 'yup';
import { State } from 'country-state-city';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Sentry from '@sentry/react';
import { useForm, useFieldArray } from 'react-hook-form';
import styled from 'styled-components';
import { GqlMutationError } from '../../../../graphql/gql-error';
import {
  ARCHIVE_LOCATION,
  ArchiveLocationStatusRequest,
  UNARCHIVE_LOCATION,
} from '../../../../graphql/mutations/archiveLocation';
import {
  ARCHIVE_MARKETPLACE_LICENSE,
  ArchiveMarketplaceLicenseRequest,
  ArchiveMarketplaceLicenseResponse,
} from '../../../../graphql/mutations/archiveMarketplaceLicense';
import {
  CREATE_MARKETPLACE_LICENSE,
  CreateMarketplaceLicenseRequest,
  CreateMarketplaceLicenseResponse,
} from '../../../../graphql/mutations/createMarketplaceLicense';
import {
  CatalogTypes,
  CREATE_FB_CATALOG,
  CreateFbCatalogParams,
  CreateFbCatalogResponse,
} from '../../../../graphql/query/createFbCatalog';
import {
  CREATE_TIK_TOK_STORE,
  CreateTikTokStoreParams,
  CreateTikTokStoreResponse,
} from '../../../../graphql/query/createTikTokStore';
import {
  DealerType,
  InventorySource,
  LocationProductEnum,
  LOCATIONS_BY_ORG_ID_NAME,
  MarketplaceLicense,
  TagManagerStatus,
} from '../../../../graphql/query/locationsByOrgId';
import { FormComponentProps, FormRef } from '../../../../models/form';
import { copyTextToClipboard } from '../../../../utils/clipboard.utils';
import { toggleBoxOptions, ToggleBoxOptions } from '../../../utils/enums.utils';
import { vehicleMention, MarketplaceMention } from '../../../utils/vehiclePropsMention';
import { Button } from '../../Buttons/Button';
import { CheckBoxOption } from '../../CheckBoxOption/CheckBoxOption';
import { CollapseOption } from '../../Collapse/Collapse';
import DateField from '../../DateField';
import { displayGqlErrors } from '../../ErrorList';
import IconButtonSimple from '../../IconButtonSimple';
import MentionInput from '../../MentionInput/MentionInput';
import { RadioGroupOption } from '../../RadioGroup/RadioGroup';
import RadioGroupField from '../../RadioGroupField';
import FormSelectField, { SelectOption } from '../../FormSelectField/FormSelectField';
import { snackbar } from '../../Snackbar';
import { Spacer } from '../../Spacer';
import { LabelBodyB } from '../../Text/Text.styled';
import TextButtonActionField from '../../TextButtonField';
import TextField from '../../TextField';
import TextAreaField from '../../TextField/TextAreaField';
import { useAutofillGoogleFooter } from './hooks/useAutofillGoogleFooter';
import {
  SETUP_TAG_MANAGER,
  SetupTagManagerRequest,
  SetupTagManagerResponse,
} from '../../../../graphql/mutations/setupTagManager';
import ControllerWithInput from '../../ControllerWithInput/ControllerWithInput';
import {
  ResetMarketplaceLicenseRequest,
  ResetMarketplaceLicenseResponse,
  RESET_MARKETPLACE_LICENSE,
} from '../../../../graphql/mutations/resetMarketplaceLicense';
import { CountryCode, CRM, FacebookCampaignObjective, LeadRevivalCampaign } from '../../../utils/types';
import {
  CREATE_MERCHANT_CENTER,
  CreateMerchantCenterRequest,
  CreateMerchantCenterResponse,
} from '../../../../graphql/mutations/createMerchantCenter';
import {
  CREATE_FACEBOOK_CAMPAIGN,
  CreateFacebookCampaignRequest,
  CreateFacebookCampaignResponse,
} from '../../../../graphql/mutations/createFacebookCampaign';
import { noop } from 'lodash';
import {
  SEND_TEST_RETARGETING_EMAIL,
  SEND_TEST_RETARGETING_TEXT,
} from '../../../../graphql/mutations/sendTestRetargeting';
import { TextInput } from '../../TextInput/TextInput.styled';
import {
  SYNC_MERCHANT_CENTER_FEED,
  SyncMerchantCenterFeedRequest,
  SyncMerchantCenterFeedResponse,
} from '../../../../graphql/mutations/syncMerchantCenterFeed';
import {
  TOGGLE_MERCHANT_CENTER_FEED_STATUS,
  ToggleMerchantCenterFeedStatusRequest,
  ToggleMerchantCenterFeedStatusResponse,
} from '../../../../graphql/mutations/toggleMerchantCenterFeedStatus';
import { SEND_TEST_ADF_EMAIL } from '../../../../graphql/mutations/sendTestAdfLead';
import {
  TOGGLE_MARKETPLACE_LICENSE_STATUS,
  ToggleMarketplaceLicenseStatusRequest,
  ToggleMarketplaceLicenseStatusResponse,
} from '../../../../graphql/mutations/toggleMarketplaceLicenseStatus';
import { HasPermission } from '../../Security';
import { RoleEnum } from '../../../../models/session';
import {
  TOGGLE_MARKETPLACE_WARMUP_STATUS,
  ToggleMarketplaceWarmupStatusRequest,
  ToggleMarketplaceWarmupStatusResponse,
} from '../../../../graphql/mutations/toggleMarketplaceWarmupStatus';

const { confirm } = Modal;

const HorizontalContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TokenInput = styled.input`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.colors.dimGray.plus2};
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 16px;
  color: ${(props) => props.theme.colors.dimGray.minus1};
  outline: 0;
  width: 100%;
  height: 40px;
  cursor: pointer;
`;

const CheckBoxStyled = styled(CheckBoxOption)`
  top: 0px;
  font-size: 14px;
  left: 0;
`;

export enum LocationType {
  dealership = 'dealership',
  service_center = 'service_center',
}

const DEALER_TYPE_REQUIRED = [InventorySource.DEALERSPIKE, InventorySource.DX1];

export const locationTypeOptions: RadioGroupOption[] = [
  { label: 'Dealership', value: LocationType.dealership },
  { label: 'Service Center', value: LocationType.service_center },
];

const dealerTypeOptions: SelectOption[] = [
  {
    label: 'Motorcycle',
    value: DealerType.motorcycle,
  },
  {
    label: 'Powersports',
    value: DealerType.powersports,
  },
  {
    label: 'Heavy Vehicle',
    value: DealerType.heavy_vehicle,
  },
  {
    label: 'RV',
    value: DealerType.rv,
  },
  {
    label: 'Boat',
    value: DealerType.boat,
  },
];

export enum MarketplaceInventoryDistribution {
  SingleProfile = 'SingleProfile',
  AllProfiles = 'AllProfiles',
}

export const marketplaceInventoryDistributionOptions: RadioGroupOption[] = [
  { label: 'True', value: MarketplaceInventoryDistribution.AllProfiles },
  { label: 'False', value: MarketplaceInventoryDistribution.SingleProfile },
];

export interface UpsertLocationFormValue {
  // Required
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  products: LocationProductEnum[];
  // Optional
  id?: string;
  facebookPageId?: string;
  facebookAdAccountId?: string;
  googleLocationId?: string;
  tikTokStoreId?: string;
  tikTokPixelId?: string;
  facebookPixelId?: string;
  pocUserId?: string;
  tikTokBusinessCenterId?: string;
  tagManagerAccountId?: string;
  autoPostsEnabled?: ToggleBoxOptions;
  thirtyDayCallCompleted?: ToggleBoxOptions;
  unbrandedEnabled?: ToggleBoxOptions;
  crmReportsConnected?: ToggleBoxOptions;
  leadRevivalTextEnabled?: ToggleBoxOptions;
  website?: string;
  inventoryProvider?: string;
  keywords?: string;
  externalDealerId?: string;
  discardFirstXImages: number;
  resellerPrice?: number;
  syncNonVinListings: ToggleBoxOptions;
  syncOwnedListings: ToggleBoxOptions;
  serviceCenterUrl?: string;
  type: string;
  inventorySource?: InventorySource;
  dealerType?: DealerType;
  shopId?: string;
  catalogId?: string;
  dealerMessage?: string;
  fbFooter?: string;
  googleFooter?: string;
  marketplaceTemplate?: string;
  marketplaceFooter?: string;
  marketplaceLicense?: string;
  connectedMarketplace?: ToggleBoxOptions;
  archiveAt?: Date;
  archivedAt?: Date;
  carsForSaleEnabled?: ToggleBoxOptions;
  dailyPostNumber?: number;
  imageRequirement?: number;
  marketplaceInventoryDistribution?: MarketplaceInventoryDistribution;
  dealershipNumber?: string;
  fbMarketplaceLocation?: string;
  tikTokCatalogId?: string;
  includeImagesWithWords?: string;
  tikTokCreatorId: string;
  homenetId?: string;
  tagManagerStatus?: TagManagerStatus;
  marketplaceLicenses: MarketplaceLicense[];
  adfEmail?: string;
  crmUsername?: string;
  crmPassword?: string;
  crm?: string;
  crmFreeText?: string;
  contactUsUrl?: string;
  serviceUrl?: string;
  serviceNumber?: string;
  tradeInUrl?: string;
  leaseUrl?: string;
  marketingEmail?: string;
  replyToEmail?: string;
  merchantCenterId?: string;
  merchantCenterFeedApproved?: boolean;
  googleAdAccountId?: string;
}

interface UpsertLocationFormProps extends FormComponentProps<UpsertLocationFormValue> {
  fbPageOptions: SelectOption[];
  fbAdAccountOptions: SelectOption[];
  userOptions: SelectOption[];
  tikTokCreatorOptions: SelectOption[];
  googleLocationOptions: SelectOption[];
  tagManagerAccountOptions: SelectOption[];
  businessCenterOptions: SelectOption[];
  lesaOptions: SelectOption[];
  sourceInventoryOptions: SelectOption[];
  organizationId: string;
  locationId?: string;
  marketplaceLicenses?: MarketplaceLicense[];
  setProducts: (products: LocationProductEnum[]) => void;
}

const ValidationSchema = Yup.object().shape({
  // Required Fields
  name: Yup.string().default('').required(),
  address: Yup.string().default('').required(),
  city: Yup.string().default('').required(),
  state: Yup.string().default('').required(),
  website: Yup.string().default('').required(),
  zip: Yup.string().default('').required(),
  country: Yup.string().default('').required(),

  // Optional fields
  facebookPageId: Yup.string()
    .nullable()
    .notRequired()
    .test({
      name: 'facebookPageId-catalogId',
      message:
        'Facebook Page is required when catalog is provided. Remove catalog as well if you want to remove the page',
      test: function (facebookPageId) {
        const { catalogId } = this.parent;
        if (catalogId && !facebookPageId) return false;
        return true;
      },
    }),
  facebookAdAccountId: Yup.string().notRequired().nullable(),
  homenetId: Yup.string().nullable().notRequired(),
  googleLocationId: Yup.string().nullable().notRequired(),
  tagManagerAccountId: Yup.string().nullable().notRequired(),
  tikTokBusinessCenterId: Yup.string()
    .notRequired()
    .nullable()
    .test({
      name: 'tikTokBusinessCenterId-tikTokStoreId',
      message:
        'TikTok Business Center is required when TikTok store is provided. Remove store as well if you want to remove the business center',
      test: function (tikTokBusinessCenterId) {
        const { tikTokStoreId } = this.parent;
        if (tikTokStoreId && !tikTokBusinessCenterId) return false;
        return true;
      },
    }),
  autoPostsEnabled: Yup.number().nullable().default(ToggleBoxOptions.No),
  thirtyDayCallCompleted: Yup.number().nullable().default(ToggleBoxOptions.No),
  unbrandedEnabled: Yup.number().nullable().default(ToggleBoxOptions.Yes),
  crmReportsConnected: Yup.number().nullable().default(ToggleBoxOptions.No),
  leadRevivalTextEnabled: Yup.number().nullable().default(ToggleBoxOptions.No),
  externalDealerId: Yup.string().notRequired().nullable(),
  discardFirstXImages: Yup.number().nullable().default(0),
  resellerPrice: Yup.number().nullable().notRequired(),
  syncNonVinListings: Yup.number().nullable().default(ToggleBoxOptions.No),
  syncOwnedListings: Yup.number().nullable().default(ToggleBoxOptions.Yes),
  inventorySource: Yup.string()
    .notRequired()
    .ensure()
    .when('externalDealerId', (externalDealerId: string) => {
      if (externalDealerId) return Yup.string().required('Must select inventory source');
    }),
  dealerType: Yup.string()
    .notRequired()
    .when('inventorySource', (inventorySource: string) => {
      if (DEALER_TYPE_REQUIRED.includes(inventorySource as InventorySource)) {
        Yup.string().required(`Dealer type is required for ${inventorySource}`);
      }
    }),
  inventoryProvider: Yup.string().default('').required(),
  keywords: Yup.string().nullable().notRequired(),
  catalogId: Yup.string().notRequired().nullable(),
  tikTokStoreId: Yup.string().notRequired().nullable(),
  tikTokPixelId: Yup.string().notRequired().nullable(),
  facebookPixelId: Yup.string().notRequired().nullable(),
  shopId: Yup.string().nullable().notRequired(),
  dealerMessage: Yup.string().notRequired().nullable(),
  googleFooter: Yup.string().notRequired().nullable(),
  marketplaceTemplate: Yup.string().notRequired().nullable(),
  marketplaceFooter: Yup.string().notRequired().nullable(),
  fbFooter: Yup.string().notRequired().nullable(),
  serviceCenterUrl: Yup.string().nullable().notRequired(),
  type: Yup.string().nullable().required(),
  connectedMarketplace: Yup.number().nullable().default(ToggleBoxOptions.No),
  carsForSaleEnabled: Yup.number().nullable().default(ToggleBoxOptions.No),
  dailyPostNumber: Yup.number().nullable().required().typeError('Add a daily post number').default(3),
  imageRequirement: Yup.number().nullable().required().typeError('Add an image requirement').default(3),
  marketplaceInventoryDistribution: Yup.string()
    .nullable()
    .required()
    .default(MarketplaceInventoryDistribution.AllProfiles),
  dealershipNumber: Yup.string().nullable().notRequired(),
  fbMarketplaceLocation: Yup.string().nullable().notRequired(),
  tikTokCreatorId: Yup.string().notRequired().nullable(),
  archiveAt: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .notRequired(),
  includeImagesWithWords: Yup.string().notRequired().nullable(),
  crm: Yup.string().required(),
  crmFreeText: Yup.string()
    .notRequired()
    .when('crm', (crm: string) => {
      if (crm === CRM.Other) {
        Yup.string().required(`Must provide CRM name`);
      }
    }),
  crmUsername: Yup.string().notRequired().nullable(),
  crmPassword: Yup.string().notRequired().nullable(),
  contactUsUrl: Yup.string().notRequired().nullable(),
  serviceUrl: Yup.string().notRequired().nullable(),
  serviceNumber: Yup.string().notRequired().nullable(),
  tradeInUrl: Yup.string().notRequired().nullable(),
  leaseUrl: Yup.string().notRequired().nullable(),
  marketingEmail: Yup.string().notRequired().nullable(),
  replyToEmail: Yup.string().notRequired().nullable(),
  merchantCenterId: Yup.string().notRequired().nullable(),
  googleAdAccountId: Yup.string().notRequired().nullable(),
  adfEmail: Yup.string()
    .notRequired()
    .nullable()
    .when('leadRevivalTextEnabled', {
      is: ToggleBoxOptions.Yes,
      then: Yup.string().required('ADF email required when lead revival text is checked'),
    }),
  marketplaceLicenses: Yup.array()
    .notRequired()
    .nullable()
    .of(
      Yup.object()
        .nullable()
        .notRequired()
        .shape({
          id: Yup.string().required(),
          token: Yup.string().required(),
          user: Yup.object().nullable().notRequired().shape({
            id: Yup.string().notRequired().nullable(),
          }),
        })
        .notRequired()
        .nullable()
    ),
});

export const NewLocationForm = React.forwardRef<FormRef<UpsertLocationFormValue>, UpsertLocationFormProps>(
  (props: UpsertLocationFormProps, ref) => {
    const [createCatalog, { data: response, error }] = useLazyQuery<CreateFbCatalogResponse, CreateFbCatalogParams>(
      CREATE_FB_CATALOG
    );

    const [createTikTokStore, { data: tikTokStoreResponse, error: tikTokStoreError }] = useLazyQuery<
      CreateTikTokStoreResponse,
      CreateTikTokStoreParams
    >(CREATE_TIK_TOK_STORE);
    const archived = Boolean(props.initialValues?.archivedAt);
    const [sendTestAdfLead] = useMutation<boolean>(SEND_TEST_ADF_EMAIL);
    const [archiveLocation] = useMutation<boolean, ArchiveLocationStatusRequest>(ARCHIVE_LOCATION, {
      refetchQueries: [LOCATIONS_BY_ORG_ID_NAME],
    });
    const [unarchiveLocation] = useMutation<boolean, ArchiveLocationStatusRequest>(UNARCHIVE_LOCATION, {
      refetchQueries: [LOCATIONS_BY_ORG_ID_NAME],
    });
    const [createMarketplaceLicense] = useMutation<CreateMarketplaceLicenseResponse, CreateMarketplaceLicenseRequest>(
      CREATE_MARKETPLACE_LICENSE
    );
    const [createFacebookCampaign, { loading: creatingFacebookCampaign }] = useMutation<
      CreateFacebookCampaignResponse,
      CreateFacebookCampaignRequest
    >(CREATE_FACEBOOK_CAMPAIGN);
    const [setupTagManager] = useMutation<SetupTagManagerResponse, SetupTagManagerRequest>(SETUP_TAG_MANAGER);
    const [archiveMarketplaceLicense] = useMutation<
      ArchiveMarketplaceLicenseResponse,
      ArchiveMarketplaceLicenseRequest
    >(ARCHIVE_MARKETPLACE_LICENSE);
    const [createMerchantCenter] = useMutation<CreateMerchantCenterResponse, CreateMerchantCenterRequest>(
      CREATE_MERCHANT_CENTER
    );
    const [syncMerchantCenterFeed] = useMutation<SyncMerchantCenterFeedResponse, SyncMerchantCenterFeedRequest>(
      SYNC_MERCHANT_CENTER_FEED
    );

    const [toggleMerchantCenterFeedStatus] = useMutation<
      ToggleMerchantCenterFeedStatusResponse,
      ToggleMerchantCenterFeedStatusRequest
    >(TOGGLE_MERCHANT_CENTER_FEED_STATUS);

    const [resetMarketplaceLicense] = useMutation<ResetMarketplaceLicenseResponse, ResetMarketplaceLicenseRequest>(
      RESET_MARKETPLACE_LICENSE
    );

    const [toggleMarketplaceLicenseStatus] = useMutation<
      ToggleMarketplaceLicenseStatusResponse,
      ToggleMarketplaceLicenseStatusRequest
    >(TOGGLE_MARKETPLACE_LICENSE_STATUS);

    const [toggleMarketplaceWarmupStatus] = useMutation<
      ToggleMarketplaceWarmupStatusResponse,
      ToggleMarketplaceWarmupStatusRequest
    >(TOGGLE_MARKETPLACE_WARMUP_STATUS);

    const [toggledLicenses, setToggledLicenses] = useState<string[]>([]);
    const [skipWarmupLicenses, setSkipWarmupLicenses] = useState<string[]>([]);
    const [retargetEmailTest, setRetargetEmailTest] = useState<string | null>(null);
    const [retargetTextTest, setRetargetTextTest] = useState<string | null>(null);
    const [testCampaign, setTestCampaign] = useState<LeadRevivalCampaign>(LeadRevivalCampaign.SimilarInventory);
    const [sendTestRetargetingEmail] = useMutation<boolean>(SEND_TEST_RETARGETING_EMAIL);
    const [sendTestRetargetingText] = useMutation<boolean>(SEND_TEST_RETARGETING_TEXT);
    const onTestRetargetEmail = useCallback(async () => {
      if (!retargetEmailTest) return;

      try {
        const success = await sendTestRetargetingEmail({
          variables: {
            locationId: props.initialValues!.id!,
            email: retargetEmailTest,
            campaignType: testCampaign,
          },
        });
        if (success) {
          snackbar.success({ message: 'Email sent successfully' });
        } else {
          snackbar.error({ message: 'Failed to send email' });
        }
      } catch (error) {
        Sentry.captureException(error);
        displayGqlErrors(error, `Unable to send test`);
      }
    }, [retargetEmailTest, sendTestRetargetingEmail, testCampaign, props.initialValues]);

    const onTestRetargetText = useCallback(async () => {
      if (!retargetTextTest) return;

      try {
        const success = await sendTestRetargetingText({
          variables: {
            locationId: props.initialValues!.id!,
            phone: retargetTextTest,
            campaignType: testCampaign,
          },
        });
        if (success) {
          snackbar.success({ message: 'Text sent successfully' });
        } else {
          snackbar.error({ message: 'Failed to send text' });
        }
      } catch (error) {
        Sentry.captureException(error);
        displayGqlErrors(error, `Unable to send test`);
      }
    }, [retargetTextTest, testCampaign, sendTestRetargetingText, props.initialValues]);

    const [checkedProducts, setCheckedProducts] = useState(props.initialValues?.products ?? []);
    const [disabledMerchantCenter, setDisabledMerchantCenter] = useState<boolean>(false);
    const [disabledVCatalog, setDisabledVCatalog] = useState<boolean>(false);
    const [disabledTikTokStore, setDisabledTikTokStore] = useState<boolean>(false);
    const [tikTokTagsInstalled, setTikTokTagsInstalled] = useState(
      props.initialValues?.tagManagerStatus?.tik_tok ?? false
    );
    const [merchantCenterFeedApproved, setMerchantCenterFeedApproved] = useState(
      props.initialValues?.merchantCenterFeedApproved ?? false
    );

    const { handleSubmit, control, formState, getValues, setValue, register, trigger } =
      useForm<UpsertLocationFormValue>({
        resolver: yupResolver(ValidationSchema),
        mode: 'onChange',
        defaultValues: {
          name: props.initialValues?.name,
          address: props.initialValues?.address,
          city: props.initialValues?.city,
          state: props.initialValues?.state,
          zip: props.initialValues?.zip,
          country: props.initialValues?.country,
          facebookPageId: props.initialValues?.facebookPageId,
          facebookAdAccountId: props.initialValues?.facebookAdAccountId,
          googleLocationId: props.initialValues?.googleLocationId,
          tagManagerAccountId: props.initialValues?.tagManagerAccountId,
          tikTokBusinessCenterId: props.initialValues?.tikTokBusinessCenterId,
          tikTokStoreId: props.initialValues?.tikTokStoreId,
          tikTokPixelId: props.initialValues?.tikTokPixelId,
          facebookPixelId: props.initialValues?.facebookPixelId,
          externalDealerId: props.initialValues?.externalDealerId,
          discardFirstXImages: props.initialValues?.discardFirstXImages,
          resellerPrice: props.initialValues?.resellerPrice,
          autoPostsEnabled: props.initialValues?.autoPostsEnabled,
          thirtyDayCallCompleted: props.initialValues?.thirtyDayCallCompleted,
          unbrandedEnabled: props.initialValues?.unbrandedEnabled,
          crmReportsConnected: props.initialValues?.crmReportsConnected,
          leadRevivalTextEnabled: props.initialValues?.leadRevivalTextEnabled,
          inventoryProvider: props.initialValues?.inventoryProvider,
          keywords: props.initialValues?.keywords,
          inventorySource: props.initialValues?.inventorySource,
          dealerType: props.initialValues?.dealerType,
          catalogId: props.initialValues?.catalogId,
          shopId: props.initialValues?.shopId,
          dealerMessage: props.initialValues?.dealerMessage,
          website: props.initialValues?.website,
          fbFooter: props.initialValues?.fbFooter,
          googleFooter: props.initialValues?.googleFooter,
          marketplaceFooter: props.initialValues?.marketplaceFooter,
          marketplaceTemplate: props.initialValues?.marketplaceTemplate,
          syncNonVinListings: props.initialValues?.syncNonVinListings,
          tikTokCreatorId: props.initialValues?.tikTokCreatorId,
          syncOwnedListings: props.initialValues?.syncOwnedListings,
          connectedMarketplace: props.initialValues?.connectedMarketplace,
          serviceCenterUrl: props.initialValues?.serviceCenterUrl,
          type: props.initialValues?.type,
          archiveAt: props.initialValues?.archiveAt ? moment(props.initialValues.archiveAt) : undefined,
          carsForSaleEnabled: props.initialValues?.carsForSaleEnabled,
          dailyPostNumber: props.initialValues?.dailyPostNumber,
          imageRequirement: props.initialValues?.imageRequirement ?? 3,
          marketplaceInventoryDistribution: props.initialValues?.marketplaceInventoryDistribution,
          dealershipNumber: props.initialValues?.dealershipNumber,
          fbMarketplaceLocation: props.initialValues?.fbMarketplaceLocation,
          homenetId: props.initialValues?.homenetId,
          includeImagesWithWords: '',
          marketplaceLicenses: props.initialValues?.marketplaceLicenses ?? [],
          adfEmail: props.initialValues?.adfEmail,
          crmUsername: props.initialValues?.crmUsername,
          crmPassword: props.initialValues?.crmPassword,
          crm: props.initialValues?.crm,
          crmFreeText: props.initialValues?.crmFreeText,
          contactUsUrl: props.initialValues?.contactUsUrl,
          serviceUrl: props.initialValues?.serviceUrl,
          serviceNumber: props.initialValues?.serviceNumber,
          tradeInUrl: props.initialValues?.tradeInUrl,
          leaseUrl: props.initialValues?.leaseUrl,
          marketingEmail: props.initialValues?.marketingEmail,
          replyToEmail: props.initialValues?.replyToEmail,
          merchantCenterId: props.initialValues?.merchantCenterId,
          googleAdAccountId: props.initialValues?.googleAdAccountId,
        },
      });

    const crmOptions = Object.values(CRM).map((crm) => ({ label: crm, value: crm }));
    const campaignOptions = Object.values(LeadRevivalCampaign).map((campaign) => ({
      label: campaign,
      value: campaign,
    }));

    const { fields, append, remove } = useFieldArray({
      keyName: 'key',
      name: 'marketplaceLicenses',
      control,
    });

    const { errors } = formState;

    const onTestAdf = async (crm: string | undefined, adfEmail: string | undefined) => {
      if (!adfEmail || !crm) return;

      try {
        const success = await sendTestAdfLead({
          variables: {
            data: {
              locationId: props.initialValues!.id!,
              adfEmail,
              crm,
            },
          },
        });
        if (success) {
          snackbar.success({ message: 'Email sent successfully' });
        } else {
          snackbar.error({ message: 'Failed to send email' });
        }
      } catch (error) {
        Sentry.captureException(error);
        displayGqlErrors(error, `Unable to archive location`);
      }
    };

    const handleCreateCatalog = async (typeOfCatalog: CatalogTypes) => {
      await createCatalog({
        variables: {
          locationId: props.initialValues!.id!,
          typeOfCatalog,
        },
      });
    };

    const handleCreateMerchantCenter = useCallback(async () => {
      try {
        const response = await createMerchantCenter({
          variables: {
            locationId: props.initialValues!.id!,
          },
        });

        const merchantCenterId = response.data?.createGoogleMerchantCenter.id;
        if (merchantCenterId) {
          setValue('merchantCenterId', response.data?.createGoogleMerchantCenter.id);
          setDisabledMerchantCenter(true);
        }
      } catch (error) {
        Sentry.captureException(error);
        displayGqlErrors(error, `Unable to create merchant center`);
      }
    }, [setValue, setDisabledMerchantCenter, createMerchantCenter, props.initialValues]);

    const handleSyncMerchantCenterFeed = useCallback(async () => {
      try {
        const response = await syncMerchantCenterFeed({
          variables: {
            locationId: props.initialValues!.id!,
          },
        });

        if (response.data?.syncGoogleMerchantCenterFeed.id) {
          snackbar.success({ message: 'Merchant center feed synced' });
        }
      } catch (error) {
        Sentry.captureException(error);
        displayGqlErrors(error, `Unable to sync merchant center feed`);
      }
    }, [syncMerchantCenterFeed, props.initialValues]);

    const handleToggleMerchantCenterFeedStatus = useCallback(async () => {
      try {
        const response = await toggleMerchantCenterFeedStatus({
          variables: {
            locationId: props.initialValues!.id!,
          },
        });

        if (response.data) {
          setMerchantCenterFeedApproved(response.data?.toggleGoogleMerchantCenterFeedStatus.feedApproved);
          if (response.data?.toggleGoogleMerchantCenterFeedStatus.id) {
            snackbar.success({ message: 'Merchant center feed status updated' });
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        displayGqlErrors(error, `Unable to update merchant center feed status`);
      }
    }, [toggleMerchantCenterFeedStatus, props.initialValues]);

    const handleCreateTikTokStore = async () => {
      await createTikTokStore({
        variables: {
          locationId: props.initialValues!.id!,
        },
      });
    };

    const handleCreateFacebookCampaign = async (objective: FacebookCampaignObjective) => {
      try {
        const response = await createFacebookCampaign({
          variables: {
            locationId: props.initialValues!.id!,
            objective,
          },
        });

        if (response.data?.success) {
          snackbar.success({ message: 'Facebook campaign created' });
        }
      } catch (error) {
        displayGqlErrors(error, `Unable to create facebook campaign`);
      }
    };

    const onConfirmArchiveLocation = async (
      action: (params: { variables: ArchiveLocationStatusRequest }) => any,
      title: string
    ) => {
      const { locationId } = props;
      if (locationId) {
        try {
          const success = await action({
            variables: { locationId },
          });
          if (success) {
            snackbar.success({
              message: `${title} successful`,
            });
          } else {
            snackbar.error({
              message: `${title} failed`,
            });
          }
        } catch (error) {
          Sentry.captureException(error);
          displayGqlErrors(error, `${title} failed`);
        }
      }
    };

    const onConfirmToggleLicenseStatus = useCallback(
      async (marketplaceToken: string) => {
        try {
          const success = await toggleMarketplaceLicenseStatus({
            variables: { marketplaceToken },
          });
          if (success) {
            snackbar.success({
              message: `Success`,
            });

            /**
             * This absolute cluster is bc whoever the fuck set up the side drawer
             * is a fucking dumbass who doesn't know how the fuck a context is supposed to work
             * and it would take more time than I have to go back and fix rn.
             */
            if (toggledLicenses.includes(marketplaceToken)) {
              setToggledLicenses((prev) => prev.filter((token) => token !== marketplaceToken));
            } else {
              setToggledLicenses((prev) => [...prev, marketplaceToken]);
            }
          } else {
            snackbar.error({
              message: `Failed`,
            });
          }
        } catch (error) {
          Sentry.captureException(error);
          displayGqlErrors(error, `Unable to update token status`);
        }
      },
      [toggledLicenses, setToggledLicenses, toggleMarketplaceLicenseStatus]
    );

    const onConfirmToggleWarmupStatus = useCallback(
      async (marketplaceToken: string) => {
        try {
          const success = await toggleMarketplaceWarmupStatus({
            variables: { marketplaceToken },
          });
          if (success) {
            snackbar.success({
              message: `Success`,
            });

            if (toggledLicenses.includes(marketplaceToken)) {
              setSkipWarmupLicenses((prev) => prev.filter((token) => token !== marketplaceToken));
            } else {
              setSkipWarmupLicenses((prev) => [...prev, marketplaceToken]);
            }
          } else {
            snackbar.error({
              message: `Failed`,
            });
          }
        } catch (error) {
          Sentry.captureException(error);
          displayGqlErrors(error, `Unable to update token status`);
        }
      },
      [skipWarmupLicenses, setSkipWarmupLicenses, toggleMarketplaceLicenseStatus]
    );

    const onConfirmResetLicense = async (marketplaceToken: string) => {
      try {
        const success = await resetMarketplaceLicense({
          variables: { marketplaceToken },
        });
        if (success) {
          snackbar.success({
            message: `Marketplace Posts reset`,
          });
        } else {
          snackbar.error({
            message: `Token reset failed`,
          });
        }
      } catch (error) {
        Sentry.captureException(error);
        displayGqlErrors(error, `Unable to reset token`);
      }
    };

    const handleArchiveLocation = useCallback(async () => {
      const title = archived ? 'Unarchive Location' : 'Archive Location';
      const content = archived
        ? 'Are you sure you want to unarchive this location?'
        : 'Are you sure you want to archive this location?';
      const action = archived ? unarchiveLocation : archiveLocation;

      confirm({
        title,
        icon: <ExclamationCircleOutlined />,
        okType: 'danger',
        okText: `Yes`,
        content,
        onOk() {
          onConfirmArchiveLocation(action, title);
        },
      });
    }, [archived, onConfirmArchiveLocation]);

    const handleCreateLicenseToken = async () => {
      if (props.locationId) {
        const marketplaceResponse = await createMarketplaceLicense({
          variables: {
            locationId: props.locationId!,
          },
        });
        const newLicense = marketplaceResponse?.data?.createMarketplaceLicense
          ? marketplaceResponse.data.createMarketplaceLicense
          : null;

        if (newLicense) {
          append(newLicense);
        }
        snackbar.success({
          message: 'Marketplace token created successfully',
        });
      }
    };

    const handleSetupTikTokTags = async () => {
      if (props.locationId) {
        const response = await setupTagManager({
          variables: {
            locationId: props.locationId!,
            platform: 'tik_tok',
          },
        });
        const info = response?.data?.setupTagManager;
        const success = info?.success ?? false;
        const message = info?.message ?? '';

        setTikTokTagsInstalled(success);
        if (!success) {
          snackbar.error({ message: `Tag Manager setup failed: ${message}` });
        } else {
          snackbar.success({
            message: 'Tag Manager set up successfully',
          });
        }
      }
    };

    const handleToggleLicenseStatus = (token: string, paused: boolean) => {
      const action = paused ? 'Unpause' : 'Pause';
      confirm({
        title: `${action} License`,
        icon: <ExclamationCircleOutlined />,
        okType: 'danger',
        okText: `Yes, ${action}`,
        content: `Are you sure you want to ${action} this license?`,
        onOk() {
          onConfirmToggleLicenseStatus(token);
        },
      });
    };

    const handleToggleWarmupStatus = (token: string, skipWarmup: boolean) => {
      const action = skipWarmup ? 'Skip Warmup' : 'Enforce Warmup';
      confirm({
        title: `${action} for License`,
        icon: <ExclamationCircleOutlined />,
        okType: 'danger',
        okText: `Yes, ${action}`,
        content: `Are you sure you want to ${action} for this license?`,
        onOk() {
          onConfirmToggleWarmupStatus(token);
        },
      });
    };

    const handleResetLicense = (token: string) => {
      confirm({
        title: `Reset License`,
        icon: <ExclamationCircleOutlined />,
        okType: 'danger',
        okText: `Yes, reset`,
        content: `Are you sure you want to reset the posts on this license? This will re-post any vehicles this token has previously posted if they are still available.`,
        onOk() {
          onConfirmResetLicense(token);
        },
      });
    };

    const handleArchiveLicenseToken = async (index: number, token: string) => {
      const marketplaceResponse = await archiveMarketplaceLicense({
        variables: {
          marketplaceToken: token,
        },
      });
      const removed = marketplaceResponse.data?.archiveMarketplaceLicense ?? false;
      if (removed) {
        remove(index);
        snackbar.success({
          message: 'Marketplace token archived successfully',
        });
      } else {
        snackbar.error({
          message: `Marketplace token can't be archived`,
        });
      }
    };

    const setGoogleFooter = useCallback((value: string, success: boolean) => {
      if (success) {
        navigator.clipboard.writeText(value);
        snackbar.success({
          message: 'Copied to clipboard',
        });
      } else {
        snackbar.error({
          message: value,
        });
      }
    }, []);

    const onVehicleCatalog = () => {
      setValue('catalogId', response?.createFbCatalog!.catalogId!);
      setDisabledVCatalog(true);
    };
    const onTikTokStore = () => {
      setValue('tikTokStoreId', tikTokStoreResponse?.createTikTokStore!.storeId!);
      setDisabledTikTokStore(true);
    };

    useEffect(() => {
      if (!props.initialValues?.tikTokStoreId) {
        if (tikTokStoreResponse) {
          if (tikTokStoreResponse.createTikTokStore.storeId) {
            onTikTokStore();
            snackbar.success({
              message: 'TikTok store created successfully',
            });
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tikTokStoreResponse]);

    useEffect(() => {
      if (!props.initialValues?.catalogId) {
        if (response) {
          response.createFbCatalog.typeOfCatalog === CatalogTypes.VEHICLE ? onVehicleCatalog() : noop();
          snackbar.success({
            message: 'Catalog created successfully',
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    const toggleProductActive = useCallback(
      (active, product: LocationProductEnum) => {
        let enabledProducts = [];
        if (active) {
          enabledProducts = [...checkedProducts, product];
        } else {
          enabledProducts = checkedProducts.filter((p) => p !== product);
        }

        setCheckedProducts(enabledProducts);
        props.setProducts(enabledProducts);
      },
      [checkedProducts, setCheckedProducts, props]
    );

    const productIsChecked = useCallback(
      (product: LocationProductEnum) => {
        return checkedProducts.includes(product);
      },
      [checkedProducts]
    );

    const checkedGoogle = productIsChecked(LocationProductEnum.Google);
    const checkedFacebook = productIsChecked(LocationProductEnum.Facebook);
    const checkedMarketplace = productIsChecked(LocationProductEnum.FbMarketplace);
    const checkedInstagram = productIsChecked(LocationProductEnum.Instagram);
    const checkedTikTok = productIsChecked(LocationProductEnum.TikTok);
    const checkedFacebookAds = productIsChecked(LocationProductEnum.FacebookAds);
    const checkedGoogleAds = productIsChecked(LocationProductEnum.GoogleAds);
    const checkedLeadRevival = productIsChecked(LocationProductEnum.LeadRevival);

    useEffect(() => {
      const err = error ?? tikTokStoreError;
      if (err) {
        displayGqlErrors(err as ApolloError | GqlMutationError, `${err?.message}`);
      }
    }, [error, tikTokStoreError]);

    const onSubmit = (data: UpsertLocationFormValue) => {
      props.onCompleted(data);
    };
    const submitHandler = handleSubmit(onSubmit);
    useImperativeHandle(
      ref,
      () => ({
        formState,
        submit: submitHandler,
      }),
      [formState, submitHandler]
    );

    const name = getValues('name');
    const address = getValues('address');
    const city = getValues('city');
    const state = getValues('state');
    const { contactUsUrl, serviceUrl, tradeInUrl, marketingEmail, replyToEmail, crm, adfEmail, inventorySource } =
      getValues([
        'contactUsUrl',
        'serviceUrl',
        'tradeInUrl',
        'marketingEmail',
        'replyToEmail',
        'crm',
        'adfEmail',
        'inventorySource',
      ]);
    const autoFillGoogleFooterDisabled = !(name && address && city && state);

    const stateOptions: SelectOption[] = [];
    const countryOptions = Object.values(CountryCode).map((country) => {
      const states = State.getStatesOfCountry(country);
      for (const state of states) {
        stateOptions.push({ value: state.name, label: state.name });
      }
      return { value: country, label: country };
    });

    const autofillGoogleFooter = useAutofillGoogleFooter({ locationName: name, address, city, state, setGoogleFooter });

    return (
      <form onSubmit={submitHandler}>
        <FormSelectField
          name="pocUserId"
          label="POINT OF CONTACT"
          placeholder="Select the POC for the location"
          defaultValue={props.initialValues?.pocUserId}
          options={props.userOptions}
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="m" />
        <RadioGroupField
          control={control}
          name="type"
          label="Location Type"
          defaultValue={props?.initialValues?.type ?? LocationType.dealership}
          errors={errors}
          options={locationTypeOptions}
        />
        <Spacer type="stack" size="m" />
        <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
          <RadioGroupField
            name="thirtyDayCallCompleted"
            label="30 Day Call Completed?"
            control={control}
            errors={errors}
            options={toggleBoxOptions}
          />
          <Spacer type="stack" size="m" />
        </HasPermission>
        <TextField
          type="number"
          name="resellerPrice"
          label="Reseller Price"
          placeholder=""
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="m" />
        <TextField type="text" name="name" label="NAME" placeholder="Location Name" control={control} errors={errors} />
        <Spacer type="stack" size="m" />
        <TextField
          type="text"
          name="address"
          label="ADDRESS"
          placeholder="Location Address"
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="m" />
        <TextField type="text" name="city" label="CITY" placeholder="City" control={control} errors={errors} />
        <Spacer type="stack" size="m" />
        <FormSelectField
          name="state"
          label="STATE"
          placeholder="Select state"
          defaultValue={props?.initialValues?.state}
          options={stateOptions}
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="m" />
        <TextField type="text" name="zip" label="ZIP" placeholder="00000 - 0000" control={control} errors={errors} />
        <Spacer type="stack" size="m" />
        <FormSelectField
          name="country"
          label="COUNTRY"
          placeholder="Select the country"
          defaultValue={props?.initialValues?.country}
          options={countryOptions}
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="m" />
        <TextField
          type="text"
          name="website"
          label="WEBSITE"
          placeholder="Website Link"
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="m" />
        <TextField
          type="text"
          name="dealershipNumber"
          label="Dealership Number"
          placeholder=""
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="m" />
        <FormSelectField
          name="crm"
          label="CRM"
          placeholder="Select the CRM"
          options={crmOptions}
          onChange={(value) => {
            setValue('crm', value ?? null);
            trigger(['crm', 'crmFreeText']);
          }}
          defaultValue={props?.initialValues?.crm}
          control={control}
          errors={errors}
        />
        {crm === CRM.Other && (
          <>
            <Spacer type="stack" size="m" />
            <TextField
              name="crmFreeText"
              label="CRM Name"
              placeholder="CRM name"
              defaultValue={props?.initialValues?.crmFreeText}
              control={control}
              errors={errors}
            />
          </>
        )}
        <Spacer type="stack" size="m" />
        <CollapseOption header={<LabelBodyB>Unbranded Mode</LabelBodyB>}>
          <RadioGroupField
            control={control}
            name="unbrandedEnabled"
            label="Unbranded Mode Enabled"
            errors={errors}
            options={toggleBoxOptions}
          />
          <Spacer type="stack" size="m" />
          <TextField
            type="number"
            name="discardFirstXImages"
            label="Discard First X Images"
            placeholder=""
            control={control}
            errors={errors}
          />
        </CollapseOption>
        <Spacer type="stack" size="m" />
        <CollapseOption header={<LabelBodyB>CRM Configuration</LabelBodyB>}>
          <TextField
            type="text"
            name="crmUsername"
            label="CRM Username"
            placeholder=""
            control={control}
            errors={errors}
          />
          <Spacer type="stack" size="m" />
          <TextField
            type="text"
            name="crmPassword"
            label="CRM Password"
            placeholder=""
            control={control}
            errors={errors}
          />
          <Spacer type="stack" size="m" />
          <RadioGroupField
            name="crmReportsConnected"
            label="CRM Reports Setup?"
            subLabel="Do not check unless the reports are set up in the CRM"
            control={control}
            errors={errors}
            options={toggleBoxOptions}
          />
          <Spacer type="stack" size="m" />
          <TextField type="text" name="adfEmail" label="ADF Email" placeholder="" control={control} errors={errors} />
          <Spacer type="stack" size="m" />
          <Button
            disabled={!(crm && adfEmail)}
            btnType="secondary"
            size="small"
            onClick={() => onTestAdf(crm, adfEmail)}
          >
            Send Test Lead
          </Button>
        </CollapseOption>
        <Spacer type="stack" size="m" />
        <LabelBodyB>Products</LabelBodyB>
        <Spacer type="stack" size="m" />
        <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
          <CollapseOption
            active={checkedGoogle}
            header={
              <CheckBoxStyled
                checked={checkedGoogle}
                setChecked={(event) => toggleProductActive(event, LocationProductEnum.Google)}
                content={<LabelBodyB>Google</LabelBodyB>}
              />
            }
          >
            <FormSelectField
              name="googleLocationId"
              label="GOOGLE LOCATION"
              placeholder="Select the associated Google Location"
              control={control}
              onChange={(value) => setValue('googleLocationId', value ?? null)}
              options={props.googleLocationOptions}
              defaultValue={props?.initialValues?.googleLocationId}
              errors={errors}
            />
            <Spacer type="stack" size="m" />
            <RadioGroupField
              control={control}
              name="carsForSaleEnabled"
              label="Cars for Sale"
              errors={errors}
              options={toggleBoxOptions}
            />
          </CollapseOption>
          <Spacer type="stack" size="m" />
        </HasPermission>

        <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
          <CollapseOption
            active={checkedFacebook}
            header={
              <CheckBoxStyled
                checked={checkedFacebook}
                setChecked={(event) => toggleProductActive(event, LocationProductEnum.Facebook)}
                content={<LabelBodyB>Facebook</LabelBodyB>}
              />
            }
          >
            <FormSelectField
              name="facebookPageId"
              label="FACEBOOK PAGE"
              placeholder="Select the associated Facebook Page"
              control={control}
              onChange={(value) => setValue('facebookPageId', value ?? null)}
              options={props.fbPageOptions}
              defaultValue={props?.initialValues?.facebookPageId}
              errors={errors}
            />
          </CollapseOption>
          <Spacer type="stack" size="m" />
        </HasPermission>

        <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
          <CollapseOption
            active={checkedInstagram}
            header={
              <CheckBoxStyled
                checked={checkedInstagram}
                setChecked={(event) => toggleProductActive(event, LocationProductEnum.Instagram)}
                content={<LabelBodyB>Instagram</LabelBodyB>}
              />
            }
          >
            <FormSelectField
              name="facebookPageId"
              label="FACEBOOK PAGE"
              placeholder="Select the associated Facebook Page for the IG account"
              control={control}
              onChange={(value) => setValue('facebookPageId', value ?? null)}
              defaultValue={props?.initialValues?.facebookPageId}
              options={props.fbPageOptions}
              errors={errors}
            />
          </CollapseOption>
          <Spacer type="stack" size="m" />
        </HasPermission>

        <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
          <CollapseOption
            active={checkedTikTok}
            header={
              <CheckBoxStyled
                checked={checkedTikTok}
                setChecked={(event) => toggleProductActive(event, LocationProductEnum.TikTok)}
                content={<LabelBodyB>TikTok</LabelBodyB>}
              />
            }
          >
            <>
              <FormSelectField
                name="tikTokBusinessCenterId"
                label="TikTok Business Center"
                placeholder="Select the associated Business Center"
                control={control}
                defaultValue={props?.initialValues?.tikTokBusinessCenterId}
                onChange={(value) => setValue('tikTokBusinessCenterId', value ?? null)}
                options={props.businessCenterOptions}
                errors={errors}
              />
              <Spacer type="stack" size="m" />

              <TextButtonActionField
                type="text"
                name="tikTokPixelId"
                label="TikTok Pixel Id"
                control={control}
                errors={errors}
                disableAction={false}
              />
              <Spacer type="stack" size="m" />

              <Button size="small" disabled={tikTokTagsInstalled} onClick={handleSetupTikTokTags}>
                {'Setup Google Tag Manager'}
              </Button>
              <Spacer type="stack" size="m" />

              <TextButtonActionField
                type="text"
                name="tikTokStoreId"
                label="TikTok Store Id"
                control={control}
                errors={errors}
                disableAction={
                  !props.initialValues?.tikTokBusinessCenterId ||
                  !!props.initialValues?.tikTokStoreId ||
                  disabledTikTokStore
                }
                textAction="Create TikTok Store"
                action={() => {
                  handleCreateTikTokStore();
                }}
              />
              <Spacer type="stack" size="m" />
              <FormSelectField
                name="tikTokCreatorId"
                label="TikTok Creators"
                placeholder="Select user"
                control={control}
                defaultValue={props?.initialValues?.tikTokCreatorId}
                onChange={(value) => setValue('tikTokCreatorId', value ?? null)}
                options={props.tikTokCreatorOptions}
                errors={errors}
              />
            </>
          </CollapseOption>
          <Spacer type="stack" size="m" />
        </HasPermission>

        <HasPermission allowedRoles={[RoleEnum.SuperAdmin, RoleEnum.Partner]}>
          <CollapseOption
            active={checkedMarketplace}
            header={
              <CheckBoxStyled
                checked={checkedMarketplace}
                setChecked={(event) => toggleProductActive(event, LocationProductEnum.FbMarketplace)}
                content={<LabelBodyB>Facebook Marketplace</LabelBodyB>}
              />
            }
          >
            <div>
              {fields.map((license, index) => {
                const fieldName = `marketplaceLicenses.${index}`;
                const paused = toggledLicenses.includes(license.token) ? !license.paused : license.paused;
                const skipWarmup = skipWarmupLicenses.includes(license.token)
                  ? !license.skipWarmupPeriod
                  : license.skipWarmupPeriod;

                return (
                  <div key={license.id}>
                    <HorizontalContainer>
                      <ControllerWithInput
                        type="hidden"
                        defaultValue={license.id}
                        //@ts-ignore
                        name={`${fieldName}.id`}
                        control={control}
                        errors={errors}
                        input={<input />}
                      />
                      <ControllerWithInput
                        readOnly={true}
                        onClick={() => {
                          copyTextToClipboard(license.token);
                          message.info('License copied to clipboard');
                        }}
                        type="text"
                        //@ts-ignore
                        name={`${fieldName}.token`}
                        label=""
                        control={control}
                        errors={errors}
                        defaultValue={license.token}
                        disabled={paused}
                        input={<TokenInput />}
                      />
                      <Spacer type="inline" size="s" />
                      <FormSelectField
                        //@ts-ignore
                        name={`${fieldName}.user.id`}
                        placeholder="Select user"
                        defaultValue={license.user?.id ?? null}
                        options={props.userOptions}
                        disabled={paused}
                        control={control}
                        errors={errors}
                      />
                      <Spacer type="inline" size="s" />
                      <Tooltip placement="top" title={'Skip Warmup Period'}>
                        <Checkbox
                          checked={skipWarmup}
                          onChange={(e: any) => handleToggleWarmupStatus(license.token, e.target.checked)}
                        />
                      </Tooltip>
                      <Spacer type="inline" size="s" />
                      <Tooltip placement="top" title={`${paused ? 'Unpause' : 'Pause'} Token`}>
                        <IconButtonSimple
                          icon={paused ? 'play' : 'pause'}
                          size="small"
                          type="button"
                          onClick={() => {
                            handleToggleLicenseStatus(license.token, paused);
                          }}
                        />
                      </Tooltip>
                      <Spacer type="inline" size="s" />
                      <Tooltip placement="top" title={'Reset License Posts'}>
                        <IconButtonSimple
                          icon="reload"
                          size="small"
                          type="button"
                          onClick={() => {
                            handleResetLicense(license.token);
                          }}
                        />
                      </Tooltip>
                      <Spacer type="inline" size="s" />
                      <Tooltip placement="top" title={'Archive License'}>
                        <IconButtonSimple
                          icon="delete"
                          size="small"
                          type="button"
                          onClick={() => {
                            handleArchiveLicenseToken(index, license.token);
                          }}
                        />
                      </Tooltip>
                    </HorizontalContainer>
                    <Spacer type="stack" size="m" />
                  </div>
                );
              })}
            </div>
            <Button size="small" disabled={!props.locationId} onClick={handleCreateLicenseToken}>
              {'Create Token'}
            </Button>
            <Spacer type="stack" size="m" />
            <TextField
              type="number"
              name="dailyPostNumber"
              label="Daily Post Number"
              control={control}
              errors={errors}
            />
            <Spacer type="stack" size="m" />
            <TextField
              type="number"
              name="imageRequirement"
              label="Minimum Image Requirement"
              control={control}
              errors={errors}
            />
            <Spacer type="stack" size="m" />
            <TextField
              type="text"
              name="fbMarketplaceLocation"
              label="FACEBOOK MARKETPLACE LOCATION"
              placeholder=""
              control={control}
              errors={errors}
            />
            <Spacer type="stack" size="m" />
            <RadioGroupField
              control={control}
              name="marketplaceInventoryDistribution"
              label="List all inventory for each user?"
              errors={errors}
              defaultValue={
                props?.initialValues?.marketplaceInventoryDistribution ?? MarketplaceInventoryDistribution.AllProfiles
              }
              options={marketplaceInventoryDistributionOptions}
            />
            <Spacer type="stack" size="m" />

            <RadioGroupField
              control={control}
              name="connectedMarketplace"
              label="Connected Account"
              errors={errors}
              options={toggleBoxOptions}
            />
          </CollapseOption>
          <Spacer type="stack" size="m" />
        </HasPermission>

        <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
          <CollapseOption
            active={checkedFacebookAds}
            header={
              <CheckBoxStyled
                checked={checkedFacebookAds}
                setChecked={(event) => toggleProductActive(event, LocationProductEnum.FacebookAds)}
                content={<LabelBodyB>Facebook Ads</LabelBodyB>}
              />
            }
          >
            <FormSelectField
              name="facebookAdAccountId"
              label="FACEBOOK AD ACCOUNT"
              placeholder="Select the associated Ad Account"
              control={control}
              defaultValue={props?.initialValues?.facebookAdAccountId}
              onChange={(value) => setValue('facebookAdAccountId', value ?? null)}
              options={props.fbAdAccountOptions}
              errors={errors}
            />
            {props.initialValues?.facebookAdAccountId && (
              <AntdAButton
                type="link"
                style={{ padding: 0 }}
                disabled={!props.initialValues?.merchantCenterId}
                target="_blank"
                rel="noreferrer"
                href={`https://adsmanager.facebook.com/adsmanager/manage/campaigns?act=${props.initialValues.facebookAdAccountId}`}
              >
                Visit Facebook Ad Manager
              </AntdAButton>
            )}
            <Spacer type="stack" size="m" />
            <TextButtonActionField
              type="text"
              name="facebookPixelId"
              label="Facebook Pixel Id"
              control={control}
              errors={errors}
              disableAction={false}
            />
            <Spacer type="stack" size="m" />
            <TextButtonActionField
              type="text"
              name="catalogId"
              label="Vehicle Catalog Id"
              control={control}
              errors={errors}
              disableAction={!!props.initialValues?.catalogId || disabledVCatalog}
              textAction="Create vehicle catalog"
              action={() => {
                handleCreateCatalog(CatalogTypes.VEHICLE);
              }}
            />
            <Spacer type="stack" size="m" />
            <AntdAButton
              loading={creatingFacebookCampaign}
              block
              onClick={() => handleCreateFacebookCampaign(FacebookCampaignObjective.Traffic)}
            >
              CREATE TRAFFIC CAMPAIGN
            </AntdAButton>
            <Spacer type="stack" size="m" />
            <AntdAButton
              loading={creatingFacebookCampaign}
              block
              onClick={() => handleCreateFacebookCampaign(FacebookCampaignObjective.Leads)}
            >
              CREATE LEAD CAMPAIGN
            </AntdAButton>
          </CollapseOption>
          <Spacer type="stack" size="m" />
        </HasPermission>

        <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
          <CollapseOption
            active={checkedGoogleAds}
            header={
              <CheckBoxStyled
                checked={checkedGoogleAds}
                setChecked={(event) => toggleProductActive(event, LocationProductEnum.GoogleAds)}
                content={<LabelBodyB>Google Ads</LabelBodyB>}
              />
            }
          >
            <FormSelectField
              name="googleLocationId"
              label="GOOGLE LOCATION"
              placeholder="Select the associated Google Location"
              control={control}
              onChange={(value) => setValue('googleLocationId', value ?? null)}
              options={props.googleLocationOptions}
              defaultValue={props?.initialValues?.googleLocationId}
              errors={errors}
            />
            <Spacer type="stack" size="m" />
            <TextField
              type="text"
              name="googleAdAccountId"
              label="Google Ad Account Id"
              placeholder="Google Ad Account Id"
              control={control}
              errors={errors}
            />
            <AntdAButton
              type="link"
              target="_blank"
              rel="noreferrer"
              href={`https://ads.google.com/aw/overview?ocid=1223800461`}
            >
              Open Google Ads
            </AntdAButton>
            <Spacer type="stack" size="m" />
            <TextButtonActionField
              type="text"
              name="merchantCenterId"
              label="Merchant Center Id"
              control={control}
              errors={errors}
              disabled={!props.initialValues?.googleAdAccountId}
              disableAction={
                !props.initialValues?.googleAdAccountId ||
                !!props.initialValues?.merchantCenterId ||
                disabledMerchantCenter
              }
              textAction="Create merchant center"
              action={() => {
                handleCreateMerchantCenter();
              }}
            />
            <Spacer type="stack" size="m" />
            <AntdAButton disabled={!props.initialValues?.merchantCenterId} onClick={handleSyncMerchantCenterFeed}>
              Sync Merchant Center Feed
            </AntdAButton>
            <Spacer type="stack" size="m" />
            <AntdAButton
              //@ts-ignore
              type={merchantCenterFeedApproved ? 'danger' : 'primary'}
              disabled={!props.initialValues?.merchantCenterId}
              onClick={handleToggleMerchantCenterFeedStatus}
            >
              {merchantCenterFeedApproved ? 'Mark Feed NOT Approved' : 'Mark Feed Approved'}
            </AntdAButton>
            <Spacer type="stack" size="m" />
            <AntdAButton
              type="link"
              disabled={!props.initialValues?.merchantCenterId}
              target="_blank"
              rel="noreferrer"
              href={`https://merchants.google.com/mc/merchantprofile/businessinfo?a=${props?.initialValues?.merchantCenterId}`}
            >
              Verify Phone Number
            </AntdAButton>
            <Spacer type="stack" size="m" />
            <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
              <FormSelectField
                name="tagManagerAccountId"
                label="TAG MANAGER ACCOUNT"
                placeholder="Select the associated Tag Manager Account"
                control={control}
                onChange={(value) => setValue('tagManagerAccountId', value ?? null)}
                defaultValue={props?.initialValues?.tagManagerAccountId}
                options={props.tagManagerAccountOptions}
                errors={errors}
              />
            </HasPermission>
          </CollapseOption>
          <Spacer type="stack" size="m" />
        </HasPermission>

        <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
          <CollapseOption
            active={checkedLeadRevival}
            header={
              <CheckBoxStyled
                checked={checkedLeadRevival}
                setChecked={(event) => toggleProductActive(event, LocationProductEnum.LeadRevival)}
                content={<LabelBodyB>Lead Revival</LabelBodyB>}
              />
            }
          >
            <TextField
              type="text"
              name="contactUsUrl"
              label="Contact Us Url"
              placeholder=""
              control={control}
              errors={errors}
            />
            <Spacer type="stack" size="m" />
            <TextField
              type="text"
              name="serviceUrl"
              label="Service Url"
              placeholder=""
              control={control}
              errors={errors}
            />
            <Spacer type="stack" size="m" />
            <TextField
              type="text"
              name="serviceNumber"
              label="Service Number"
              placeholder=""
              control={control}
              errors={errors}
            />
            <Spacer type="stack" size="m" />
            <TextField
              type="text"
              name="tradeInUrl"
              label="Trade In Url"
              placeholder=""
              control={control}
              errors={errors}
            />
            <Spacer type="stack" size="m" />
            <TextField type="text" name="leaseUrl" label="Lease Url" placeholder="" control={control} errors={errors} />
            <Spacer type="stack" size="m" />
            <TextField
              type="text"
              name="marketingEmail"
              label="LS Marketing Email"
              placeholder=""
              control={control}
              errors={errors}
            />
            <Spacer type="stack" size="m" />
            <TextField
              type="text"
              name="replyToEmail"
              label="Reply To Email"
              placeholder=""
              control={control}
              errors={errors}
            />
            <Spacer type="stack" size="m" />
            <RadioGroupField
              name="leadRevivalTextEnabled"
              label="Text Messages Enabled"
              control={control}
              errors={errors}
              options={toggleBoxOptions}
            />
            <Spacer type="stack" size="m" />
            <TextInput
              topLabel={'Send Email To'}
              className="ant-input"
              onChange={(e) => setRetargetEmailTest(e.target.value)}
              value={retargetEmailTest ?? ''}
            />
            <Spacer type="stack" size="m" />
            <Button
              disabled={
                !(contactUsUrl && serviceUrl && tradeInUrl && marketingEmail && retargetEmailTest && replyToEmail)
              }
              btnType="secondary"
              size="small"
              onClick={onTestRetargetEmail}
            >
              Send Test Email
            </Button>
            <Spacer type="stack" size="m" />
            <TextInput
              topLabel={'Send Text To'}
              className="ant-input"
              onChange={(e) => setRetargetTextTest(e.target.value)}
              value={retargetTextTest ?? ''}
            />
            <Spacer type="stack" size="m" />
            <Select
              style={{ width: '100%' }}
              placeholder={'Select a campaign type'}
              onChange={(option) => setTestCampaign(option)}
              options={campaignOptions}
            />
            <Spacer type="stack" size="m" />
            <Button disabled={!retargetTextTest} btnType="secondary" size="small" onClick={onTestRetargetText}>
              Send Test Text
            </Button>
            <Spacer type="stack" size="m" />
          </CollapseOption>
          <Spacer type="stack" size="l" />
        </HasPermission>

        <LabelBodyB>Inventory Configuration</LabelBodyB>
        <HasPermission allowedRoles={[RoleEnum.SuperAdmin, RoleEnum.Partner]}>
          <Spacer type="stack" size="m" />
          <TextField
            type="text"
            name="inventoryProvider"
            label="Inventory Provider"
            defaultValue={props?.initialValues?.inventoryProvider}
            placeholder=""
            control={control}
            errors={errors}
          />
        </HasPermission>
        <Spacer type="stack" size="m" />
        <TextField type="text" name="homenetId" label="Homenet Onboarding" control={control} errors={errors} />
        <Spacer type="stack" size="m" />
        <FormSelectField
          name="inventorySource"
          label="INVENTORY SOURCE"
          placeholder="Select the inventory source"
          prefixIconClass="module-inventory"
          onChange={(value) => {
            setValue('inventorySource', value ?? null);
            trigger(['inventorySource', 'dealerType', 'externalDealerId']);
          }}
          defaultValue={props?.initialValues?.inventorySource}
          options={props.sourceInventoryOptions}
          control={control}
          errors={errors}
        />
        {DEALER_TYPE_REQUIRED.includes(inventorySource as InventorySource) && (
          <>
            <Spacer type="stack" size="m" />
            <FormSelectField
              name="dealerType"
              label="Dealer Type"
              placeholder="Select dealer type"
              defaultValue={props?.initialValues?.dealerType}
              options={dealerTypeOptions}
              onChange={(value) => setValue('dealerType', value ?? null)}
              control={control}
              errors={errors}
            />
          </>
        )}
        <Spacer type="stack" size="m" />
        {inventorySource === InventorySource.LESA ? (
          <FormSelectField
            name="externalDealerId"
            label="Dealer ID"
            placeholder="Select dealer ID"
            defaultValue={props?.initialValues?.externalDealerId}
            options={props.lesaOptions}
            control={control}
            errors={errors}
          />
        ) : (
          <TextField type="text" name="externalDealerId" label="Url/ID from dealer" control={control} errors={errors} />
        )}

        <Spacer type="stack" size="m" />
        <RadioGroupField
          control={control}
          name="syncNonVinListings"
          label="Sync non VIN vehicles?"
          errors={errors}
          options={toggleBoxOptions}
        />
        <Spacer type="stack" size="m" />
        <RadioGroupField
          control={control}
          name="syncOwnedListings"
          label="Only sync owned vehicles?"
          errors={errors}
          options={toggleBoxOptions}
        />
        <Spacer type="stack" size="m" />
        <CollapseOption header={<LabelBodyB>Post Template Configuration</LabelBodyB>}>
          <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
            <TextAreaField
              //@ts-ignore
              {...register('dealerMessage')}
              name="dealerMessage"
              size="large"
              rows={6}
              label="Custom Post Template"
              control={control}
              errors={errors}
              hidden={true}
            />
            <MentionInput
              {...{
                defaultValue: props.initialValues?.dealerMessage,
                type: 'mentionValue',
                onChange: (value) => setValue('dealerMessage', value),
                addBorder: true,
                width: '500px',
                mentions: vehicleMention,
              }}
            />
            <Spacer type="stack" size="s" />
            <TextAreaField
              //@ts-ignore
              {...register('googleFooter')}
              name="googleFooter"
              size="large"
              rows={2}
              label="Google Footer Description"
              control={control}
              errors={errors}
            />
            <Spacer type="stack" size="s" />
            <TextAreaField
              //@ts-ignore
              {...register('fbFooter')}
              name="fbFooter"
              size="large"
              rows={2}
              label="Facebook Footer Description"
              control={control}
              errors={errors}
            />
            <Spacer type="stack" size="s" />
          </HasPermission>
          <TextAreaField
            //@ts-ignore
            {...register('marketplaceTemplate')}
            name="marketplaceTemplate"
            size="large"
            rows={6}
            label="Marketplace Template"
            control={control}
            errors={errors}
            hidden={true}
          />
          <MentionInput
            {...{
              defaultValue: props.initialValues?.marketplaceTemplate,
              type: 'mentionValue',
              onChange: (value) => setValue('marketplaceTemplate', value),
              addBorder: true,
              width: '500px',
              mentions: MarketplaceMention,
            }}
          />
          <Spacer type="stack" size="s" />
          <TextAreaField
            //@ts-ignore
            {...register('marketplaceFooter')}
            name="marketplaceFooter"
            size="large"
            rows={2}
            label="Marketplace Footer Description"
            control={control}
            errors={errors}
          />
          <Spacer type="stack" size="s" />
          <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
            <Button size="small" onClick={autofillGoogleFooter} disabled={autoFillGoogleFooterDisabled}>
              Copy default footer to clipboard
            </Button>
          </HasPermission>
        </CollapseOption>
        <Spacer type="stack" size="m" />
        <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
          <Spacer type="stack" size="m" />
          <RadioGroupField
            name="autoPostsEnabled"
            label="Enabled Automatic Posting?"
            control={control}
            errors={errors}
            options={toggleBoxOptions}
          />
          <Spacer type="stack" size="m" />
        </HasPermission>
        <LabelBodyB>Archive Configuration</LabelBodyB>
        <Spacer type="stack" size="m" />
        <DateField name="archiveAt" label="Archive on" errors={errors} control={control} size="middle" />
        <Spacer type="stack" size="m" />
        <AntdAButton block danger onClick={handleArchiveLocation}>
          {archived ? 'ACTIVATE LOCATION' : 'ARCHIVE LOCATION'}
        </AntdAButton>
        <Spacer type="stack" size="m" />
      </form>
    );
  }
);
