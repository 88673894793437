import React, { useRef } from 'react';

import { ApolloError, useMutation } from '@apollo/client';

import styled from 'styled-components';
import * as Sentry from '@sentry/react';

import {
  NEW_ORGANIZATION,
  NewOrganizationParams,
  NewOrganizationResponse,
} from '../../../../graphql/mutations/newOrganization';
import { ORGANIZATION_LIST } from '../../../../graphql/query/organizationList';
import { FormRef } from '../../../../models/form';
import { displayGqlErrors } from '../../ErrorList';
import SideDrawer from '../../SideDrawer';
import { snackbar } from '../../Snackbar';
import { LabelBodyB } from '../../Text/Text.styled';
import NewOrganizationForm, { NewOrganizationFormValue } from './NewOrganizationForm';
import { usePartnerQuery } from '../../../../graphql/query/partners';

interface NewOrganizationDrawerProps {
  isSideDrawerVisible: boolean;
  toggleSideDrawer: (value?: boolean) => void;
}

const FormContainer = styled.section`
  margin: 16px;
  width: 100%;
`;
const SectionTitleContainer = styled.div`
  margin-bottom: 16px;
`;

const NewOrganizationDrawer: React.FC<NewOrganizationDrawerProps> = ({ isSideDrawerVisible, toggleSideDrawer }) => {
  const formRef = useRef<FormRef<NewOrganizationFormValue>>(null);
  const [createOrganization] = useMutation<NewOrganizationResponse, NewOrganizationParams>(NEW_ORGANIZATION, {
    refetchQueries: [ORGANIZATION_LIST],
  });
  const { partners } = usePartnerQuery();
  const partnerOptions = partners.map((partner) => ({
    label: partner.name,
    value: partner.id,
  }));

  const handleNewOrganizationSubmit = async (formData: NewOrganizationFormValue) => {
    try {
      await createOrganization({
        variables: { data: formData },
      });
      snackbar.success({ message: 'Organization created' });
      toggleSideDrawer();
    } catch (err) {
      Sentry.captureException(err);
      displayGqlErrors(err as ApolloError);
    }
  };

  return (
    <SideDrawer
      heading="Organization"
      isDrawerVisible={isSideDrawerVisible}
      toggleDrawerVisible={toggleSideDrawer}
      ctaButtons={[
        {
          label: 'CREATE ORGANIZATION',
          onClick: async () => {
            formRef.current?.submit();
          },
          isDisabled: false,
        },
      ]}
    >
      <FormContainer>
        <SectionTitleContainer>
          <LabelBodyB>Organization details</LabelBodyB>
        </SectionTitleContainer>
        <NewOrganizationForm partnerOptions={partnerOptions} ref={formRef} onCompleted={handleNewOrganizationSubmit} />
      </FormContainer>
    </SideDrawer>
  );
};

export default NewOrganizationDrawer;
