import React from 'react';

import { createGlobalStyle } from 'styled-components';

import { AntCardStyles } from './AntCard.styled';
import { AntCarouselStyles } from './AntCarousel.styed';
import { AntCheckBoxStyles } from './AntCheckBox.styled';
import { AntDropdownStyles } from './AntDropdown.styled';
import { AntRadioGroupStyles } from './AntRadioGroup.styled';
import { AntRadioStyles } from './AntRadioStyles.styled';
import { AntSelectStyles } from './AntSelectStyles.styled';
import { AntSpinnerStyles } from './AntSpinner.styled';
import { AntTextInputStyles } from './AntTextInput.styled';
import { AntTooltipStyles } from './AntTooltip.styled';
import { TableStyles } from './TableStyles.styled';
import { ToastifyStyles } from './Toastify.styled';
import { AntCalendarStyles } from './AntCalendar.styled';

const BaseGlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.fonts.body};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.layoutColors.bodyText};
    background-color: ${(props) => props.theme.layoutColors.background}
  }

  .ant-layout {
    background-color: white;
  }

  .ant-table-thead > tr >th{
    color: ${(props) => props.theme.colors.dimGray.base};
  }

  a.text-link {
    font-weight:600;
    color: ${(props) => props.theme.colors.purpleNavi.base};
  }

  form {
    text-align: left;
  }

  .icon-info{
    color: ${(props) => props.theme.colors.purpleNavi.plus2};
  }

  .icon-success{
    color: ${(props) => props.theme.colors.aquamarine};
  }

  .icon-warning{
    color: ${(props) => props.theme.colors.gold};
  }

  .icon-danger{
    color: ${(props) => props.theme.colors.red};
  }


`;
export const GlobalStyle = () => (
  <>
    <BaseGlobalStyle />
    <AntCalendarStyles />
    <AntSelectStyles />
    <AntRadioStyles />
    <AntSpinnerStyles />
    <AntRadioGroupStyles />
    <AntCheckBoxStyles />
    <TableStyles />
    <AntTextInputStyles />
    <AntDropdownStyles />
    <AntCardStyles />
    <AntTooltipStyles />
    <ToastifyStyles />
    <AntCarouselStyles />
  </>
);
