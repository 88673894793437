import { gql } from '@apollo/client';

export interface SendDashboardInviteEmailRequest {
  userId: string;
}

export const SEND_DASHBOARD_INVITE_EMAIL = gql`
  mutation ($userId: String!) {
    sendDashboardInviteEmail(userId: $userId)
  }
`;
