import { gql } from '@apollo/client';

export interface RefreshInventoryResponse {
  updateInventory: boolean;
}

export interface RefreshInventoryRequest {
  locationId: string;
}

export const REFRESH_INVENTORY = gql`
  mutation ($locationId: String!) {
    updateInventory(locationId: $locationId)
  }
`;
