import { gql } from '@apollo/client';

export interface ToggleUserStatusResponse {
  id: string;
  isActive: boolean;
}

export interface ToggleUserStatusRequest {
  userId: string;
}

export const TOGGLE_USER_STATUS = gql`
  mutation ($userId: String!) {
    toggleUserStatus(userId: $userId) {
      id
      isActive
    }
  }
`;
