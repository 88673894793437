import React, { useState } from 'react';

import styled, { css } from 'styled-components';

import NoImage from '../../../assets/images/noimage.png';
import { GetLocationAccountInfoResponse } from '../../../graphql/query/getLocationAccountsInfoInfo';
import { Condition, InventoryItem, Status } from '../../../graphql/query/inventoryByLocationId';
import { RoleEnum } from '../../../models/session';
import { decodeEntities } from '../../../utils/formatText.utils';
import { Button } from '../Buttons/Button';
import { CreatePostModal } from '../CreatePostModal';
import { DefaultModal } from '../DefaultModal';
import { Heading4 } from '../Headings/Headings.styled';
import { HasPermission } from '../Security';
import SimpleCard from '../SimpleCard';
import { Spacer } from '../Spacer';
import { TagLabel } from '../TagLabel';
import { VehicleSocialAccounts } from '../VehicleColumn/modeViews/VehicleSocialAccounts';

const StyledSimpleCard = styled(SimpleCard)<{ disabled?: boolean }>`
  width: 300px;
  &:hover {
    transform: scale(1.05);
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

const VehicleCardWrapper = styled.div<{ active?: boolean }>`
  height: 400px;
  flex-direction: column;
  display: flex;
  ${(props) =>
    props.active &&
    css`
      border: solid 3px ${(props) => props.theme.colors.purpleNavi.plus2};
      border-radius: 16px;
    `}
  align-content: center;
  align-items: center;

  padding: 20px 20px;
`;
const ColumnInfo = styled.div`
  width: 100%;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  margin-right: 40px;
  align-content: flex-start;
  align-items: flex-start;
`;

const TitleCar = styled.div`
  height: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ColumnImage = styled.div`
  display: flex;
  align-content: center;
`;

const Img = styled.img`
  width: 100%;
  height: 170px;
  border-radius: 4px;
  align-self: center;
`;

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 8px 4px;
`;

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: left;
  padding: 8px 4px;
`;

const PriceText = styled.p`
  font-weight: bold;
`;
const DaysText = styled.p`
  margin-left: 3px;
`;

const LeftContent = styled.div`
  flex-grow: 3;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
`;

const RightContent = styled.div`
  flex-grow: 1;
  align-self: flex-start;
  margin-left: 50px;
`;

interface VehicleCardProps {
  item: InventoryItem;
  locationAccountsInfo: GetLocationAccountInfoResponse;
}

export const VehicleCard: React.FC<VehicleCardProps> = ({ item, locationAccountsInfo }) => {
  const { price, vehicle, status, age, condition, imgSource, postMetric } = item;
  const handleErrorImage = (e: React.ChangeEvent<HTMLImageElement>) => {
    e.target.src = NoImage;
  };
  const [showPostModal, setShowPostModal] = useState(false);

  const onClickPost = () => {
    setShowPostModal(true);
  };
  return (
    <StyledSimpleCard>
      <VehicleCardWrapper>
        <ColumnImage>
          <Img alt="" src={imgSource || NoImage} onError={handleErrorImage} />
        </ColumnImage>
        <ColumnInfo>
          <Spacer size="xxxs" type="stack" />
          <TitleCar>
            <RowContainer>
              <LeftContent>
                <Heading4>{decodeEntities(vehicle!)}</Heading4>
              </LeftContent>
              <RightContent>
                <TagLabel color="default">{condition === Condition.New ? 'new' : 'pre-owned'}</TagLabel>
              </RightContent>
            </RowContainer>
          </TitleCar>
          <InfoContainer>
            <PriceText>
              {price === 0
                ? 'No price yet'
                : price.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
            </PriceText>
            <DaysText> - {age} Days</DaysText>
          </InfoContainer>
          <RowContainer>
            <LeftContent>
              <VehicleSocialAccounts postMetrics={postMetric} />
            </LeftContent>
            <RightContent>
              <HasPermission
                allowedRoles={[RoleEnum.Admin, RoleEnum.SuperAdmin, RoleEnum.FacebookTest, RoleEnum.Partner]}
              >
                <Button
                  disabled={status === Status.SOLD}
                  btnType="secondary"
                  onClick={() => onClickPost()}
                  size="small"
                >
                  POST
                </Button>
              </HasPermission>
            </RightContent>
          </RowContainer>
        </ColumnInfo>
      </VehicleCardWrapper>
      <DefaultModal
        visible={showPostModal}
        onCancel={() => setShowPostModal(false)}
        title={'Create Post'}
        width={1380}
        footer={null}
      >
        <CreatePostModal item={item} locationAccountsInfo={locationAccountsInfo} setShowModal={setShowPostModal} />
      </DefaultModal>
    </StyledSimpleCard>
  );
};
