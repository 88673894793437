import React, { useContext } from 'react';
import styled from 'styled-components';

import { SideDrawerContext } from '../../../context/SideDrawerProvider';
import { UsersResponseItem } from '../../../graphql/query/usersByOrganizationId';
import { Heading4 } from '../Headings/Headings.styled';
import IconButtonSimple from '../IconButtonSimple';
import UserListItem from './UserListItem';
import { Spacer } from '../Spacer';

const UserListWrapper = styled.section`
  width: 100%;
`;
const UserListTitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const UserListContainer = styled.div`
  width: 100%;
  align-content: center;
  align-self: center;
  align-items: center;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(322px, 49%));
  justify-content: space-between;
  margin-top: 16px;
`;
export interface OrganizationUserListProps {
  users: UsersResponseItem[];
  isActive: boolean;
}

const UsersSection: React.FC<{ users: UsersResponseItem[]; title: string; isActive: boolean; newUserHandler: any }> = ({
  users,
  title,
  isActive,
  newUserHandler,
}) => {
  return (
    <>
      <UserListTitleRow>
        <Heading4>{title}</Heading4>
        <IconButtonSimple disabled={!isActive} icon="plus" size="medium" onClick={newUserHandler} />
      </UserListTitleRow>
      <UserListContainer>
        {users.map((user) => (
          <UserListItem key={user.id} user={user} />
        ))}
      </UserListContainer>
    </>
  );
};

const OrganizationUserList: React.FC<OrganizationUserListProps> = ({ users, isActive }) => {
  const sideDrawerContext = useContext(SideDrawerContext);
  const newUserHandler = () => sideDrawerContext.toggleSideDrawer(sideDrawerContext.sideDrawerNames.upsertUser);
  const nonMarketplaceUsers = users.filter((user) => !user.marketplaceLicense);
  const marketplaceUsers = users.filter((user) => user.marketplaceLicense);

  return (
    <UserListWrapper>
      <UsersSection users={nonMarketplaceUsers} title="Users" isActive={isActive} newUserHandler={newUserHandler} />
      <Spacer type="stack" size="m" />
      <UsersSection
        users={marketplaceUsers}
        title="Posting Users"
        isActive={isActive}
        newUserHandler={newUserHandler}
      />
    </UserListWrapper>
  );
};

export default OrganizationUserList;
