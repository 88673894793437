import { gql } from '@apollo/client';

import { OrganizationItem } from '../query/organizationList';

export interface EditOrganizationRequest {
  id: string;
  avatarId?: number;
  avatarImg?: string;
  name: string;
  website: string;
  address: string;
  pocUserId?: string;
  partnerId?: string;
}

export interface EditOrganizationParams {
  data: EditOrganizationRequest;
}

export interface EditOrganizationResponse {
  updateOrganization: OrganizationItem;
}

export const EDIT_ORGANIZATION = gql`
  mutation ($data: UpdateOrganizationRequest!) {
    updateOrganization(data: $data) {
      id
      name
      headquarters
      domain
      accountOwnerId
      pocUserId
      partnerId
      avatar {
        id
        signedUrl
      }
    }
  }
`;
