import { useEffect, useState } from 'react';

import { Condition } from '../../../graphql/query/inventoryByLocationId';

export interface VehicleFilters {
  setCondition: (condition: Condition) => void;
  setMode: (mode: FilterOptionsMode) => void;
  setSelectedFilters: (filters: number[]) => void;
  mode: FilterOptionsMode;
  condition: Condition;
  selectedFilters: number[];
}

export type FilterOptionsMode = 'Table' | 'Grid';
export function useVehicleFilters(): VehicleFilters {
  const [condition, setCondition] = useState<Condition>(Condition.All);
  const [selectedFilters, setSelectedFilters] = useState<number[]>([]);
  const [mode, setMode] = useState<FilterOptionsMode>('Table');

  useEffect(() => {
    setMode('Table');
    setCondition(Condition.All);
    setSelectedFilters([]);
  }, []);

  return { mode, setMode, condition, setCondition, selectedFilters, setSelectedFilters };
}
