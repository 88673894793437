import React from 'react';

import { PauseCircleOutlined, PhoneOutlined, PlayCircleOutlined, RollbackOutlined } from '@ant-design/icons';

import styled, { css } from 'styled-components';
import { match } from 'ts-pattern';

const ButtonWrapper = styled.button<{ noColor?: boolean }>`
  padding: 0px;
  display: flex;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  ${({ noColor }) => {
    if (noColor) {
      return css`
        color: ${({ theme }) => theme.colors.dimGray.plus1};
      `;
    }
    return css`
      color: ${({ theme }) => theme.colors.purpleNavi.plus2};
      &:hover {
        color: ${({ theme }) => theme.colors.purpleNavi.base};
      }
      &:disabled {
        color: ${({ theme }) => theme.colors.dimGray.plus1};
      }
    `;
  }}
  &:disabled {
    cursor: not-allowed;
  }
`;
type ButtonSize = 'small' | 'medium';

const iconFontSize: Record<ButtonSize, string> = {
  small: '16px',
  medium: '24px',
};

const IconStyled = styled.i<{ size?: ButtonSize; color?: boolean }>`
  font-size: ${({ size }) => iconFontSize[size!]};
`;

IconStyled.defaultProps = {
  size: 'medium',
};

export const icons = {
  edit: 'misc-edit',
  plus: 'misc-plus-circle',
  close: 'misc-close',
  email: 'icon-small-misc-email',
  doneCircle: 'misc-done-circle',
  done: 'misc-done-mini',
  ellipsis: 'icon-small-misc-menu-option',
  folder: 'icon-small-misc-folder',
  search: 'icon-small-misc-search-left-small',
  back: 'icon-small-arrows-arrow-left',
  clipboard: 'icon-small-misc-clipboard',
  view: 'icon-small-misc-view-simple',
  click: 'icon-small-misc-click',
  reload: 'ic ic_arrow_reload',
  next: 'icon-small-arrows-arrow-right',
  delete: 'icon-small-misc-delete',
  restore: '',
  phone: '',
  play: '',
  pause: '',
};

interface IconButtonSimpleProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: keyof typeof icons;
  size?: ButtonSize;
  noColor?: boolean;
}

const IconButtonSimple: React.FC<IconButtonSimpleProps> = ({ icon, size, ...props }) => {
  const antIcon = match(icon)
    .with('phone', () => (
      <PhoneOutlined style={{ width: iconFontSize[size ?? 'small'], height: iconFontSize[size ?? 'small'] }} />
    ))
    .with('pause', () => (
      <PauseCircleOutlined style={{ width: iconFontSize[size ?? 'small'], height: iconFontSize[size ?? 'small'] }} />
    ))
    .with('play', () => (
      <PlayCircleOutlined style={{ width: iconFontSize[size ?? 'small'], height: iconFontSize[size ?? 'small'] }} />
    ))
    .with('restore', () => (
      <RollbackOutlined style={{ width: iconFontSize[size ?? 'small'], height: iconFontSize[size ?? 'small'] }} />
    ))
    .otherwise(() => null);

  return (
    <ButtonWrapper {...props}>
      {antIcon ? <>{antIcon}</> : <IconStyled size={size} className={`icon ${icon?.length && icons[icon]}`} />}
    </ButtonWrapper>
  );
};

export default IconButtonSimple;
