import React, { useState } from 'react';

import EditOrganizationDrawer from '../components/ui/Forms/EditOrganizationForm/EditOrganizationDrawer';
import EditUserAccountDrawer from '../components/ui/Forms/EditUserAccountForm/EditUserAccountSideDrawer';
import LocationSettingsDrawer from '../components/ui/Forms/LocationSettingsForm/LocationSettingsDrawer';
import NewLocationSideDrawer from '../components/ui/Forms/NewLocationForm/NewLocationSideDrawer';
import NewOrganizationDrawer from '../components/ui/Forms/NewOrganizationForm/NewOrganizationDrawer';
import UpdateUserSideDrawer from '../components/ui/Forms/UpsertUserForm/UpsertUserSideDrawer';
import { LocationItem } from '../graphql/query/locationsByOrgId';
import { OrganizationItem } from '../graphql/query/organizationList';
import { UserProfileResponse } from '../graphql/query/userProfile';
import { UsersResponseItem } from '../graphql/query/usersByOrganizationId';
import { useSideDrawerToggle } from '../hooks/useSideDrawerToggle';
import NewPartnerDrawer from '../components/ui/Forms/NewPartnerForm/NewPartnerDrawer';
import EditPartnerDrawer from '../components/ui/Forms/EditPartnerForm/EditPartnerDrawer';

export const sideDrawerNames = {
  newOrganization: 'newOrganization',
  newPartner: 'newPartner',
  editPartner: 'editPartner',
  upsertUser: 'upsertUser',
  newLocation: 'newLocation',
  editUserAccount: 'editUserAccount',
  editOrganization: 'editOrganization',
  locationSettings: 'locationSettings',
} as const;

export interface SideDrawerContextValue {
  sideDrawerNames: typeof sideDrawerNames;
  toggleSideDrawer: (name: string, updateObject?: unknown, date?: string) => void;
}

export const SideDrawerContext = React.createContext<SideDrawerContextValue>({
  sideDrawerNames,
  toggleSideDrawer(_drawerName: string, _updateObject?: unknown, _date?: string) {},
});

export const SideDrawerProvider: React.FC = ({ children }) => {
  const { isSideDrawerVisible, toggleSideDrawer } = useSideDrawerToggle();
  const [updateObject, setUpdateObject] = useState<
    UserProfileResponse | UsersResponseItem | LocationItem | OrganizationItem | any
  >();
  return (
    <SideDrawerContext.Provider
      value={{
        sideDrawerNames,
        toggleSideDrawer(drawerName, updateObject) {
          toggleSideDrawer(drawerName);
          setUpdateObject(updateObject);
        },
      }}
    >
      {isSideDrawerVisible[sideDrawerNames.newOrganization] && (
        <NewOrganizationDrawer
          isSideDrawerVisible={isSideDrawerVisible[sideDrawerNames.newOrganization]}
          toggleSideDrawer={() => toggleSideDrawer(sideDrawerNames.newOrganization)}
        />
      )}
      {isSideDrawerVisible[sideDrawerNames.newPartner] && (
        <NewPartnerDrawer
          isSideDrawerVisible={isSideDrawerVisible[sideDrawerNames.newPartner]}
          toggleSideDrawer={() => toggleSideDrawer(sideDrawerNames.newPartner)}
        />
      )}
      {isSideDrawerVisible[sideDrawerNames.editPartner] && (
        <EditPartnerDrawer
          isSideDrawerVisible={isSideDrawerVisible[sideDrawerNames.editPartner]}
          toggleSideDrawer={() => toggleSideDrawer(sideDrawerNames.editPartner)}
          updateObject={updateObject}
        />
      )}
      {isSideDrawerVisible[sideDrawerNames.editOrganization] && (
        <EditOrganizationDrawer
          isSideDrawerVisible={isSideDrawerVisible[sideDrawerNames.editOrganization]}
          toggleSideDrawer={() => toggleSideDrawer(sideDrawerNames.editOrganization)}
          updateObject={updateObject}
        />
      )}
      {isSideDrawerVisible[sideDrawerNames.upsertUser] && (
        <UpdateUserSideDrawer
          isSideDrawerVisible={isSideDrawerVisible[sideDrawerNames.upsertUser]}
          toggleSideDrawer={() => toggleSideDrawer(sideDrawerNames.upsertUser)}
          updateObject={updateObject}
        />
      )}
      {isSideDrawerVisible[sideDrawerNames.newLocation] && (
        <NewLocationSideDrawer
          isSideDrawerVisible={isSideDrawerVisible[sideDrawerNames.newLocation]}
          toggleSideDrawer={() => toggleSideDrawer(sideDrawerNames.newLocation)}
          updateObject={updateObject}
        />
      )}
      {isSideDrawerVisible[sideDrawerNames.locationSettings] && (
        <LocationSettingsDrawer
          isSideDrawerVisible={isSideDrawerVisible[sideDrawerNames.locationSettings]}
          toggleSideDrawer={() => toggleSideDrawer(sideDrawerNames.locationSettings)}
          updateObject={updateObject}
        />
      )}
      {isSideDrawerVisible[sideDrawerNames.editUserAccount] && (
        <EditUserAccountDrawer
          isSideDrawerVisible={isSideDrawerVisible[sideDrawerNames.editUserAccount]}
          toggleSideDrawer={() => toggleSideDrawer(sideDrawerNames.editUserAccount)}
          updateObject={updateObject}
        />
      )}
      {children}
    </SideDrawerContext.Provider>
  );
};
