import React from 'react';

import { Layout } from 'antd';

import styled from 'styled-components';

import { UserSideNavigation } from '../../Menu/SideNavigation/SideUserNavigation';

const SideNavStyled = styled(Layout.Sider)`
  width: 64px !important;
  max-width: 64px !important;
  min-width: 64px !important;
  background-color: ${(props) => props.theme.layoutColors.background};
  border-right: 1px solid ${(props) => props.theme.colors.dimGray.plus3};

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;
const ContentStyles = styled(Layout.Content)`
  background-color: ${(props) => props.theme.layoutColors.background};
`;
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
`;

export const MainLayout: React.FC = ({ children }) => {
  return (
    <StyledWrapper>
      <Layout
        style={{
          height: '100%',
          display: 'flex',
        }}
      >
        <SideNavStyled
          style={{
            width: '64px',
            maxWidth: '64px',
            minWidth: '64px',
          }}
          collapsed
        >
          <UserSideNavigation />
        </SideNavStyled>
        <ContentStyles>{children}</ContentStyles>
      </Layout>
    </StyledWrapper>
  );
};
