import React from 'react';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../components/ui/Buttons/Button';
import { Heading2, Heading4 } from '../../components/ui/Headings/Headings.styled';
import { Spacer } from '../../components/ui/Spacer';

const MainWrapper = styled.section`
  height: 100%;
  width: 100%;
  padding-top: 31.77vh;
  display: flex;
  justify-content: center;
`;

const MainContainer = styled.div`
  width: 461px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorRedirect: React.FC = () => {
  const history = useHistory();
  const onConnectToFacebook = async () => {
    history.push('/auth/facebook/retry');
  };
  return (
    <MainWrapper>
      <MainContainer>
        <Heading2>We're sorry</Heading2>
        <Spacer type="stack" size="m" />
        <Heading4>
          Oops, sorry, we must have access to important data of your account to be able to make a correct
          synchronization, if you wish you can try again or you can contact the Localshift technical team to give you
          more information
        </Heading4>
        <Spacer type="stack" size="m" />
        <Button btnType="primary" size="medium" onClick={onConnectToFacebook}>
          TRY TO CONNECT AGAIN
        </Button>
      </MainContainer>
    </MainWrapper>
  );
};

export default ErrorRedirect;
