import React from 'react';

import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';

import styled from 'styled-components';

import { Heading4 } from '../Headings/Headings.styled';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px;
    .ant-modal-close .icon {
      color: ${(props) => props.theme.colors.dimGray.base};
    }
  }
  .ant-modal-footer {
    height: 64px;
  }
  .ant-modal-header {
    height: 64px;
    display: flex;
    align-items: center;
    padding: 20px 0 20px 16px;
  }
`;

export const DefaultModal: React.FC<ModalProps> = ({ visible, children, title, ...props }) => {
  if (!visible) {
    return null;
  }
  return (
    <StyledModal
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, .4)' }}
      title={<Heading4>{title}</Heading4>}
      closeIcon={<i className="icon misc-close" style={{ fontSize: 20 }} />}
      closable
      centered={props.centered || true}
      visible={visible}
      {...props}
      footer={props.footer}
    >
      {children}
    </StyledModal>
  );
};
