import React from 'react';

import { Control, Controller, FieldError, FieldErrors, FieldValues } from 'react-hook-form';

import HelperMessage from '../HelperMessage';
import { PasswordInput, TextInput } from '../TextInput/TextInput.styled';

interface TextFieldProps<FormValue extends FieldValues> {
  name: keyof FormValue & string;
  label?: string;
  type?: string;
  defaultValue?: string | number;
  placeholder?: string;
  control: Control<Record<string, any>>;
  errors: FieldErrors<FormValue>;
  disabled?: boolean;
}

function TextField<FormValue extends FieldValues = FieldValues>(
  props: TextFieldProps<FormValue>
): React.ReactElement<TextFieldProps<FormValue>> {
  const { name, defaultValue, control, type, label, errors, placeholder, disabled } = props;
  return (
    <Controller
      disabled={disabled}
      as={type === 'password' ? PasswordInput : TextInput}
      type={type}
      name={name}
      control={control}
      topLabel={label}
      defaultValue={defaultValue}
      placeholder={placeholder}
      autoComplete="new-password"
      helperText={
        (errors?.[name] && <HelperMessage type="danger" message={(errors[name] as FieldError)?.message} />) || null
      }
    />
  );
}

TextField.defaultProps = {
  label: '',
  type: 'text',
};

export default TextField;
