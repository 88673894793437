import React from 'react';

import styled from 'styled-components';

import { AccountItem } from '../';
import { InventoryItem } from '../../../../graphql/query/inventoryByLocationId';
import { capitalizeOnlyFirstLetter } from '../../../../utils/formatText.utils';
import { AccountCard } from '../../AccountCard';
import { Spacer } from '../../Spacer';
import { LabelB, LabelRText } from '../../Text/Text.styled';
import { KeywordsTypes } from '../helpers';
import { PostInfo } from '../hooks/usePostInfo';
import { SocialAccountType } from './GeneralPost';
import SocialCustomForm from './SocialCustomForm';

const SocialPostWrapper = styled.div`
  float: left;
  width: 33.33%;
  border-right: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  height: 100%;
  position: relative;
  padding-left: 24px;
`;

const Contrast = styled.b`
  color: ${(props) => props.theme.colors.purpleNavi.plus2};
  margin-right: 3px;
  margin-left: 3px;
`;

const AccountsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

interface CustomPostParams {
  defaultText: string;
  item: InventoryItem;
  selectedAccounts: string[];
  accounts: AccountItem[];
  keywords: KeywordsTypes[];
  postInfo: PostInfo;
  images: string[];
  currentAccount: SocialAccountType;
  setCurrentAccount: (actual: SocialAccountType) => void;
  setInstagramSource: (source: string) => void;
  setFacebookSource: (source: string) => void;
  facebookSource: string;
  instagramSource: string;
  hashtags: string;
}

export const CustomPost: React.FC<CustomPostParams> = ({
  item,
  defaultText,
  accounts,
  images,
  selectedAccounts,
  postInfo,
  keywords,
  currentAccount,
  setCurrentAccount,
  hashtags,
  setInstagramSource,
  setFacebookSource,
  facebookSource,
  instagramSource,
}) => {
  const title = capitalizeOnlyFirstLetter(currentAccount);
  return (
    <SocialPostWrapper>
      <LabelB>Custom Media Post</LabelB>
      <Spacer type="stack" size="xs" />
      <AccountsWrapper>
        {accounts.map((account) => {
          return (
            <React.Fragment key={account.id}>
              <AccountCard
                selectedItems={selectedAccounts}
                currentSelected={currentAccount}
                setCurrentSelected={setCurrentAccount}
                name={account.id}
              />
            </React.Fragment>
          );
        })}
      </AccountsWrapper>
      <Spacer type="stack" size="xs" />
      <LabelB>Custom your {title} Post</LabelB>
      <Spacer type="stack" size="xxxs" />
      <LabelRText>
        These features and text will only appear in your <Contrast>{title}</Contrast> Post.
      </LabelRText>
      {selectedAccounts.length > 0 ? (
        <div>
          {currentAccount === 'facebook' && (
            <SocialCustomForm
              title={title}
              account={currentAccount}
              condition={postInfo.fbActionButton}
              generalPost={defaultText}
              hashtags={hashtags}
              setCondition={postInfo.setFbActionButton}
              input={postInfo.fbSummary}
              setInput={postInfo.setFbSummary}
              setEdit={postInfo.setIsFbEdited}
              imageSelected={postInfo.imageSelected[0] ?? ''}
              setMediaSource={setFacebookSource}
              mediaSource={facebookSource}
              images={images}
              item={item}
              keywords={keywords}
            />
          )}
          {currentAccount === 'google' && (
            <SocialCustomForm
              title={title}
              account={currentAccount}
              generalPost={defaultText}
              condition={postInfo.googleActionButton}
              setCondition={postInfo.setGoogleActionButton}
              input={postInfo.googleSummary}
              setInput={postInfo.setGoogleSummary}
              setEdit={postInfo.setIsGoogleEdited}
              imageSelected={postInfo.imageSelected[0] ?? ''}
              mediaSource={'image'}
              hashtags={''}
              images={images}
              item={item}
              keywords={keywords}
            />
          )}
          {currentAccount === 'instagram' && (
            <SocialCustomForm
              title={title}
              account={currentAccount}
              generalPost={defaultText}
              hashtags={hashtags}
              condition={postInfo.fbActionButton}
              setCondition={postInfo.setFbActionButton}
              input={postInfo.instagramSummary}
              setInput={postInfo.setInstagramSummary}
              setEdit={postInfo.setIsInstagramEdited}
              imageSelected={postInfo.imageSelected[0] ?? ''}
              setMediaSource={setInstagramSource}
              mediaSource={instagramSource}
              images={images}
              item={item}
              keywords={keywords}
            />
          )}
        </div>
      ) : null}
    </SocialPostWrapper>
  );
};

export default CustomPost;
