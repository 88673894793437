import React from 'react';

import { ToastProps } from 'react-toastify';
import styled from 'styled-components';

import { Button } from '../Buttons/Button';
import { Spacer } from '../Spacer';

const SnackbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const SnackbarContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
const AlertIcon = styled.div`
  font-size: 24px;
`;
export enum AlertType {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
}

const iconForAlert = {
  [AlertType.Info]: 'icon-small-misc-information icon-info',
  [AlertType.Success]: 'icon-small-misc-done-fill-circle icon-success',
  [AlertType.Warning]: '.misc-warning icon-warning',
  [AlertType.Danger]: 'icon.misc-error-circlee icon-danger',
};

export interface SnackbarProps {
  alertType?: AlertType;
  message?: string;
}

export const Snackbar: React.FC<SnackbarProps & Partial<ToastProps>> = ({ alertType, message, closeToast }) => {
  return (
    <SnackbarWrapper>
      <SnackbarContent>
        <AlertIcon className={`icon ${iconForAlert[alertType || AlertType.Info]}`}></AlertIcon>
        <Spacer type="inline" size="xxxs" />
        {message}
      </SnackbarContent>
      <Button type="button" btnType="text" onClick={closeToast}>
        DISMISS
      </Button>
    </SnackbarWrapper>
  );
};
