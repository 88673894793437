import { gql, useQuery } from '@apollo/client';

import { GoogleLocation, GoogleLocationFieldsFragment } from '../fragments/googleLocationFragments';

export interface GoogleLocationsForAccountResponse {
  googleAccounts: GoogleLocation[];
}

export const GOOGLE_ACCOUNTS = gql`
  query googleAccounts {
    googleAccounts {
      ...GoogleLocationFields
    }
  }
  ${GoogleLocationFieldsFragment}
`;

export function useGoogleAccountsQuery() {
  const { data, error, loading } = useQuery<GoogleLocationsForAccountResponse>(GOOGLE_ACCOUNTS);
  return {
    accounts: data?.googleAccounts || [],
    error,
    loading,
  };
}
