import { useState, useEffect, useCallback } from 'react';

interface UseButtonTimeout {
  isDisabled: boolean;
  onClick: () => void;
}

/**
 * Custom hook to disable a button for a given amount of time, with state persistence across page refreshes.
 * @param timeoutMinutes Number of minutes to disable the button.
 * @param identifier Unique identifier for the component instance.
 * @returns {UseButtonTimeout} Object containing isDisabled state and onClick handler.
 */
export function useButtonTimeout(timeoutMinutes: number, identifier: string): UseButtonTimeout {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem(`buttonTimeout-${identifier}`);
    if (storedData) {
      const { expiresAt } = JSON.parse(storedData);
      const remainingTime = expiresAt - Date.now();

      if (remainingTime > 0) {
        setIsDisabled(true);
        const timeoutId = setTimeout(() => setIsDisabled(false), remainingTime);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [identifier]);

  const onClick = useCallback(() => {
    const expiresAt = Date.now() + timeoutMinutes * 60 * 1000;
    localStorage.setItem(`buttonTimeout-${identifier}`, JSON.stringify({ expiresAt }));
    setIsDisabled(true);
    const timeoutId = setTimeout(() => setIsDisabled(false), timeoutMinutes * 60 * 1000);
    return () => clearTimeout(timeoutId);
  }, [identifier, timeoutMinutes]);

  console.log({ isDisabled });
  return { isDisabled, onClick };
}
