import React from 'react';

import styled from 'styled-components';

import { LabelBodyB } from '../../ui/Text/Text.styled';

const SideMenuLayoutWrapper = styled.section`
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const SideContent = styled.aside`
  height: 100%;
  width: 200px;
  border-right: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SideFooter = styled.div`
  display: flex;
  align-items: start;
  padding: 24px 16px 32px;
  border-top: 1px solid ${({ theme }) => theme.colors.dimGray.plus3};
  position: fixed;
  bottom: 0px;
  width: 200px;
`;
const LayoutContent = styled.section`
  height: 100%;
  flex-grow: 1;
  margin-left: 200px;
`;

const SideContentTitle = styled.div`
  display: flex;
  align-items: start;
  margin: 16px 0 16px 16px;
`;

const TopSideContent = styled.div``;
interface SideMenuLayoutProps {
  sideColumn?: React.ReactNode;
  contentColumn?: React.ReactNode;
  title: string | React.ReactNode;
  sideFooter?: React.ReactNode;
}
export const SideMenuLayout: React.FC<SideMenuLayoutProps> = ({ sideColumn, title, contentColumn, sideFooter }) => (
  <SideMenuLayoutWrapper>
    <SideContent>
      <TopSideContent>
        <SideContentTitle>
          <LabelBodyB>{title}</LabelBodyB>
        </SideContentTitle>
        {sideColumn}
      </TopSideContent>
      <SideFooter>{sideFooter}</SideFooter>
    </SideContent>
    <LayoutContent id="contentColumn">{contentColumn}</LayoutContent>
  </SideMenuLayoutWrapper>
);
