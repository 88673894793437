import React from 'react';

import { Alert } from 'antd';

import styled from 'styled-components';

import { GetLocationAccountInfoResponse } from '../../../../graphql/query/getLocationAccountsInfoInfo';
import { InventoryItem } from '../../../../graphql/query/inventoryByLocationId';
import { VehicleCard } from '../../VehicleCard';

const PageContent = styled.div`
  width: 100%;
  align-content: center;
  align-self: center;
  align-items: center;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(322px, 322px));
`;

const VehicleGrid = styled.div`
  padding: 24px 24px;
`;

export const VehicleGridView: React.FC<{
  vehicles: InventoryItem[];
  locationAccountsInfo: GetLocationAccountInfoResponse;
}> = ({ vehicles: items, locationAccountsInfo }) => {
  return (
    <PageContent>
      {items.length ? (
        items.map((item) => (
          <VehicleGrid key={item.id}>
            <VehicleCard item={item} locationAccountsInfo={locationAccountsInfo} />
          </VehicleGrid>
        ))
      ) : (
        <Alert type="info" message={`No list of items to show`} showIcon />
      )}
    </PageContent>
  );
};
