import React, { useCallback } from 'react';
import { DatePicker } from 'antd';
import { CheckboxOptionType } from 'antd/lib/checkbox/Group';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { useLocationByOrgIdsQuery } from '../../../graphql/query/locationByOrgIds';
import {
  ORGANIZATION_LIST,
  OrganizationListRequest,
  OrganizationListResponse,
} from '../../../graphql/query/organizationList';
import { PostTypeEnum } from '../../../graphql/query/postHistoryByLocationId';
import { DashboardFilter } from '../../../pages/Dashboard/hooks/useFilters';
import FilterWithCheckBox from '../FilterWithCheckBox';
import { Spacer } from '../Spacer';

const { RangePicker } = DatePicker;
const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SINGLE_OPTION = 1;
const setTitle = (selected: string[], options: CheckboxOptionType[], label: string) => {
  if (options.length === 1) return options[0]?.label?.toString();

  return selected.length === 1
    ? options.find((org) => org.value === selected[0])?.label?.toString()
    : selected.length === options.length || selected.length === 0
    ? `All ${label}`
    : `Selected ${selected.length} ${label}`;
};
interface DashBoardFilterProps {
  dashboardFilters: DashboardFilter;
}
const DashboardFilters: React.FC<DashBoardFilterProps> = ({ dashboardFilters }) => {
  const { data, refetch } = useQuery<OrganizationListResponse, OrganizationListRequest>(ORGANIZATION_LIST, {
    variables: { onlyActive: true },
  });
  const organizationOptions =
    data?.organizations.map<CheckboxOptionType>((organization) => {
      return { label: organization.name, value: organization.id };
    }) || [];

  const { locations, refetch: refetchLoc } = useLocationByOrgIdsQuery(dashboardFilters.organizationIds);
  const postOptions: CheckboxOptionType[] = [
    {
      label: 'Vehicle Post',
      value: PostTypeEnum.VEHICLE,
    },
    {
      label: 'Service Post',
      value: PostTypeEnum.OFFER,
    },
  ];

  const searchOrg = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (data) {
      refetch({
        name: value,
      });
    }
  };

  const searchLoc = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (data) {
      refetchLoc({
        organizationIds: dashboardFilters.organizationIds,
        name: value,
      });
    }
  };

  const onDateRangeChange: RangePickerProps['onChange'] = (dates) => {
    if (dates?.length) {
      dashboardFilters.setDateRange({ startDate: dates[0]?.toDate()!, endDate: dates[1]?.toDate()! });
    }
  };

  const locationOptions =
    locations.map<CheckboxOptionType>((location) => {
      return { label: location.name, value: location.id };
    }) || [];

  const isSingleOrganization = organizationOptions.length === SINGLE_OPTION;
  const isSingleLocation = locationOptions.length === SINGLE_OPTION;

  if (isSingleOrganization) {
    const organizationIds = [organizationOptions[0].value?.toString()];
    dashboardFilters.setOrganizationIds(organizationIds);
  }

  const resetLocations = useCallback(
    async (organizationIds: string[]) => {
      dashboardFilters.setLocationIds([]);
      await refetchLoc({ organizationIds: organizationIds });
    },
    [dashboardFilters, refetchLoc]
  );

  const organizationTitle = setTitle(dashboardFilters.organizationIds, organizationOptions, 'Organizations');
  const locationTitle = setTitle(dashboardFilters.locationIds, locationOptions, 'Locations');
  return (
    <FilterWrapper>
      <FilterWithCheckBox
        title={'All Posts'}
        options={postOptions}
        selected={dashboardFilters.postTypes}
        setSelect={dashboardFilters.setPostTypes}
      />
      <Spacer type="inline" size="s" />
      <FilterWithCheckBox
        title={organizationTitle ?? ''}
        options={organizationOptions}
        searchText={'Search Organization'}
        dividerLabel="ORGANIZATIONS"
        showSearch
        disabled={isSingleOrganization}
        searchFunction={searchOrg}
        setSelect={(args) => {
          dashboardFilters.setOrganizationIds(args);
          resetLocations(args);
        }}
        selected={dashboardFilters.organizationIds}
      />
      <Spacer type="inline" size="s" />
      <FilterWithCheckBox
        title={locationTitle ?? ''}
        options={locationOptions}
        disabled={Boolean(isSingleLocation)}
        searchFunction={searchLoc}
        searchText={'Search Location'}
        dividerLabel="LOCATIONS"
        showSearch
        setSelect={dashboardFilters.setLocationIds}
        selected={dashboardFilters.locationIds}
      />
      <Spacer type="inline" size="s" />
      {/* 
      // @ts-ignore */}
      <RangePicker
        defaultValue={
          dashboardFilters.dateRange.startDate && dashboardFilters.dateRange.endDate
            ? [moment(dashboardFilters.dateRange.startDate), moment(dashboardFilters.dateRange.endDate)]
            : undefined
        }
        ranges={{
          'From the Beginning': [moment(0), moment()],
          'Last Quarter': [moment().subtract(1, 'Q').startOf('Q'), moment().subtract(1, 'Q').endOf('Q')],
          'Last Month': [moment().subtract(1, 'M').startOf('month'), moment().subtract(1, 'M').endOf('month')],
          'This Month': [moment().startOf('month'), moment()],
        }}
        onChange={onDateRangeChange}
      />
    </FilterWrapper>
  );
};

export default DashboardFilters;
