import { gql } from '@apollo/client';

export interface SetupTagManagerRequest {
  locationId: string;
  platform: string;
}
export interface SetupTagManagerResponse {
  setupTagManager: {
    success: boolean;
    message?: string;
  };
}

export const SETUP_TAG_MANAGER = gql`
  mutation setupTagManager($locationId: String!, $platform: String!) {
    setupTagManager(locationId: $locationId, platform: $platform) {
      success
      message
    }
  }
`;
