import React from 'react';

import { EntryComponentProps, SubMentionComponentProps } from 'draft-js-mention-plugin';

import styled from 'styled-components';

import AvatarImage from '../AvatarImage';
import { Spacer } from '../Spacer';
import { LabelB } from '../Text/Text.styled';
import { EmployeeMention } from './mentions';

const MentionWrapper = styled.span`
  font-weight: 600;
  color: ${(props) => props.theme.colors.dimGray.minus1};
  cursor: pointer;
`;

const EntryWrapper = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  &:hover,
  &[class*='Focused'] {
    background-color: ${(props) => props.theme.colors.dimGray.plus3};
    box-shadow: none;
  }
  &:focus,
  &:active {
    background-color: ${(props) => props.theme.colors.purpleNavi.plus2};
    color: ${(props) => props.theme.colors.white};
  }
`;
export const MentionTag: React.FC<SubMentionComponentProps> = (props) => {
  const mention = props.mention as EmployeeMention;
  return (
    <MentionWrapper key={mention.id}>
      {mention.fullName} - {mention.position} - {mention.phone}
    </MentionWrapper>
  );
};

export const MentionEntry: React.FC<EntryComponentProps> = (props) => {
  const {
    theme,
    mention: mentionData,
    searchValue, // eslint-disable-line no-unused-vars
    isFocused, // eslint-disable-line no-unused-vars
    ...parentProps
  } = props;
  const mention = mentionData as EmployeeMention;
  return (
    <EntryWrapper {...parentProps}>
      <AvatarImage src={mention.avatar} size="small" />
      <Spacer type="inline" size="xs" />
      <div>
        <LabelB> {mention.fullName}</LabelB>
      </div>
    </EntryWrapper>
  );
};

export const MentionValueTag: React.FC<SubMentionComponentProps> = (props) => {
  const mention = props.mention;
  return <MentionWrapper key={mention.id}>{mention.name}</MentionWrapper>;
};

export const MentionValueEntry: React.FC<EntryComponentProps> = (props) => {
  const {
    theme,
    mention: mentionData,
    searchValue, // eslint-disable-line no-unused-vars
    isFocused, // eslint-disable-line no-unused-vars
    ...parentProps
  } = props;
  const mention = mentionData as EmployeeMention;
  return (
    <EntryWrapper {...parentProps}>
      <LabelB> {mention.name}</LabelB>
    </EntryWrapper>
  );
};
