import { gql } from '@apollo/client';

export const SEND_TEST_RETARGETING_EMAIL = gql`
  mutation ($locationId: String!, $email: String!, $campaignType: String!) {
    sendTestRetargetingEmail(locationId: $locationId, email: $email, campaignType: $campaignType)
  }
`;

export const SEND_TEST_RETARGETING_TEXT = gql`
  mutation ($locationId: String!, $phone: String!, $campaignType: String!) {
    sendTestRetargetingText(locationId: $locationId, phone: $phone, campaignType: $campaignType)
  }
`;
