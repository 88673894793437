import { SessionData, TokenData } from '../models/session';

export interface AppState {
  session?: SessionData;
}

export enum ActionTypes {
  SelectOrganization = '[Organization] Select',
  ClearOrganization = '[Organization] Clear',
  LoadSession = '[Session] Load',
  CleanSession = '[Session] Clean',
  SelectOrganizationData = '[Organization] Data',
  SetSelectedOrganizationTab = '[OrganizationTab] Set',
  EnableManagementMode = '[ManagementMode] Enable',
  DisableManagementMode = '[ManagementMode] Disable',
  ChangeOrganizationMode = '[Organization Mode] Change',
}

export function getTokenInfo(token: string): TokenData {
  return JSON.parse(atob(token.split('.')[1]));
}

export function loadInitialState(): AppState {
  const defaultState: AppState = {};
  const rawData = localStorage.getItem('rpro.token');
  if (!rawData) return defaultState;

  const tokenData: TokenData = getTokenInfo(rawData);
  const state: AppState = {
    session: {
      ...tokenData,
      accessToken: rawData,
    },
  };
  return state;
}

export const initialAppState: AppState = loadInitialState();

export interface Action<TAction extends string = string, TPayload = unknown> {
  type: TAction;
  payload?: TPayload;
}

export interface LoadSessionAction extends Action<ActionTypes.LoadSession> {
  payload: SessionData;
}

export interface CleanSessionAction extends Action<ActionTypes.CleanSession> {}

export type AppReducerActions = LoadSessionAction | CleanSessionAction;

export type Dispatch = (action: AppReducerActions) => void;

export function AppReducer(state: AppState = initialAppState, action: AppReducerActions): AppState {
  switch (action.type) {
    case ActionTypes.LoadSession: {
      return {
        ...state,
        session: action.payload,
      };
    }
    case ActionTypes.CleanSession: {
      return initialAppState;
    }

    default: {
      return state;
    }
  }
}
