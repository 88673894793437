interface VehicleMention {
  id: string;
  name: string;
}
export const vehicleMention: VehicleMention[] = [
  // vehicle props
  { id: 'vin', name: 'vin' },
  { id: 'stock', name: 'stock' },
  { id: 'vehicle', name: 'vehicle' },
  { id: 'condition', name: 'condition' },
  { id: 'price', name: 'price' },
  { id: 'model', name: 'model' },
  { id: 'body', name: 'body' },
  { id: 'transmission', name: 'transmission' },
  { id: 'driveType', name: 'driveType' },
  { id: 'intColor', name: 'intColor' },
  { id: 'extColor', name: 'extColor' },
  { id: 'engine', name: 'engine' },
  { id: 'year', name: 'year' },
  { id: 'mileage', name: 'mileage' },
  { id: 'make', name: 'make' },
  { id: 'address', name: 'address' },
  { id: 'modelNumber', name: 'modelNumber' },
  //location props
  { id: 'locationName', name: 'locationName' },
  //default propsl
  { id: 'defaultBody', name: 'defaultBody' },
  { id: 'defaultTitle', name: 'defaultTitle' },
];

export const MarketplaceMention: VehicleMention[] = [
  // vehicle props
  { id: 'vin', name: 'vin' },
  { id: 'stock', name: 'stock' },
  { id: 'vehicle', name: 'vehicle' },
  { id: 'condition', name: 'condition' },
  { id: 'price', name: 'price' },
  { id: 'model', name: 'model' },
  { id: 'body', name: 'body' },
  { id: 'trim', name: 'trim' },
  { id: 'transmission', name: 'transmission' },
  { id: 'driveType', name: 'driveType' },
  { id: 'intColor', name: 'intColor' },
  { id: 'extColor', name: 'extColor' },
  { id: 'engine', name: 'engine' },
  { id: 'engineHours', name: 'engineHours' },
  { id: 'year', name: 'year' },
  { id: 'mileage', name: 'mileage' },
  { id: 'make', name: 'make' },
  { id: 'modelNumber', name: 'modelNumber' },
  { id: 'vehDescription', name: 'vehDescription' },
  //location props
  { id: 'address', name: 'address' },
  { id: 'dealershipNumber', name: 'dealershipNumber' },
  { id: 'locationName', name: 'locationName' },
];
