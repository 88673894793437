import React from 'react';

import { Card } from 'antd';
import moment from 'moment';

import styled from 'styled-components';

import { OfferPostTemplate } from '../../../../../graphql/query/getOfferPostTemplatesByLocationId';
import AccountInfoCard from '../../../AccountInfoCard/AccountInfoCard';
import IconButtonSimple from '../../../IconButtonSimple';
import { Spacer } from '../../../Spacer';
import { LabelB, LabelBodyB, LabelR } from '../../../Text/Text.styled';

const PostOfferCard = styled(Card)`
  width: 100%;
  border-radius: '16px';
  box-shadow: ${(props) => props.theme.shadows.l};
  border: 'solid 1px rgba(0, 0, 0, 0.07)';
  .ant-card-body {
    padding: 0px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;
const HeaderPreview = styled.div`
  display: flex;
  padding: 16px;
  flex-orientation: row;
  justify-content: space-between;
`;
const ImageContainer = styled.div`
  background-color: ${(props) => props.theme.colors.dimGray.plus2};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  min-height: 186.67px;
`;

const ServiceImage = styled.img`
  max-width: 100%;
  max-height: 240px;
  object-fit: cover;
  align-self: center;
  margin-botton: 24px;
`;
interface PostPreviewProps {
  template?: OfferPostTemplate;
  googleName?: string;
  imageSelected?: string;
}

export const PostPreview: React.FC<PostPreviewProps> = ({ template, googleName, imageSelected }) => {
  const today = moment();
  const openInNewTab = () => {
    window.open(template?.link, '_blank', 'noopener,noreferrer');
  };
  return (
    <PostOfferCard className="card">
      <HeaderPreview>
        <AccountInfoCard accountName={googleName || 'No Google Location found'} detail={'Just Now'} avatar={''} />
        <IconButtonSimple noColor {...{ icon: 'ellipsis', size: 'small' }} style={{ transform: 'rotate(90deg)' }} />
      </HeaderPreview>
      <ImageContainer>{imageSelected && <ServiceImage src={imageSelected} alt="service-image" />}</ImageContainer>
      <CardContainer>
        <LabelBodyB>{template?.title || 'Title'}</LabelBodyB>
        <LabelB>
          Valid {today.startOf('week').format('MMM DD')} - {today.endOf('week').format('MMM DD')}
        </LabelB>
        <Spacer size="xxs" type="stack" />
        <LabelR>{template?.details || 'More info'}</LabelR>
        <Spacer size="xxs" type="stack" />
        <LabelB color="#427EEE" onClick={openInNewTab} style={{ cursor: 'pointer' }}>
          View offer
        </LabelB>
      </CardContainer>
    </PostOfferCard>
  );
};
