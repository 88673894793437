import React, { useImperativeHandle } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormComponentProps, FormRef } from '../../../../models/form';
import TextField from '../../TextField';
import { Spacer } from '../../Spacer';
import { HasPermission } from '../../Security';
import { RoleEnum } from '../../../../models/session';
import FormSelectField, { SelectOption } from '../../FormSelectField/FormSelectField';
import { PartnerType } from '../../../../graphql/query/partners';

const typeOptions: SelectOption[] = [
  {
    label: 'LocalShift Ownership',
    value: PartnerType.LocalShiftOwnership,
  },
  {
    label: 'Reseller Owned',
    value: PartnerType.ResellerOwned,
  },
  {
    label: 'Referral',
    value: PartnerType.Referral,
  },
];

const ValidationSchema = Yup.object().shape({
  name: Yup.string().default('').required(),
  type: Yup.string().nullable(),
});

export interface EditPartnerFormValue {
  name: string;
  type?: PartnerType;
}
export interface EditPartnerFormProps extends FormComponentProps<EditPartnerFormValue> {}

const EditPartnerForm = React.forwardRef<FormRef<EditPartnerFormValue>, EditPartnerFormProps>(
  (props: EditPartnerFormProps, ref) => {
    const { handleSubmit, control, errors, formState, setValue } = useForm<EditPartnerFormValue>({
      resolver: yupResolver(ValidationSchema),
      mode: 'onChange',
      defaultValues: props.initialValues,
    });

    const onSubmit = (data: EditPartnerFormValue) => {
      props.onCompleted(data);
    };

    const submitHandler = handleSubmit(onSubmit);
    useImperativeHandle(
      ref,
      () => ({
        formState,
        submit: submitHandler,
      }),
      [formState, submitHandler]
    );
    return (
      <form onSubmit={submitHandler}>
        <TextField type="text" name="name" label="NAME" placeholder="Partner Name" control={control} errors={errors} />
        <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
          <Spacer type="stack" size="m" />
          <FormSelectField
            name="type"
            label="Partner Type"
            placeholder="Select partner type"
            defaultValue={props?.initialValues?.type}
            options={typeOptions}
            onChange={(value) => setValue('type', value ?? null)}
            control={control}
            errors={errors}
          />
        </HasPermission>
      </form>
    );
  }
);

export default EditPartnerForm;
