import React from 'react';

import { formatDistance } from 'date-fns';

import styled from 'styled-components';

import { Posts } from '../../../graphql/query/googleLocationPosts';
import { capitalizeOnlyFirstLetter } from '../../../utils/formatText.utils';
import { Button } from '../Buttons/Button';
import IconButtonSimple from '../IconButtonSimple';
import { MenuCompact } from '../MenuCompact';
import SimpleCard from '../SimpleCard';
import { Label, LabelBoldSmall } from '../Text/Text.styled';

const StyledSimpleCard = styled(SimpleCard)`
  min-width: 700px;
  max-width: 700px;
`;
const CardContent = styled.div`
  flex-direction: row;
  display: flex;
`;
const ColumnInfo = styled.div`
  display: flex;
  max-width: 500px;
  flex-direction: column;
  padding: 30px 30px;
  margin-right: 40px;
`;
const ColumnImage = styled.div`
  display: flex;
  align-content: center;
  padding: 20px 20px;
`;
const Img = styled.img`
  height: 200px;
  width: 270px;
  border-radius: 15px;
  object-fit: cover;
`;

const Content = styled.div`
  padding: 0px 20px;
`;
const ButtonSection = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
`;
const CardRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const RowInfo = styled.div`
  display: flex;
  text-align: left;
  align-content: flex-start;
`;
const RowContainer = styled.div`
  padding-right: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
`;

const TopHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  padding-top: 20px;
  padding-right: 20px;
`;
const IconSection = styled.div`
  padding-top: 20px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const Text = styled.div`
  margin-left: 10px;
`;
interface PostCardProps {
  posts: Posts;
  deletePost: (nameOfGooglePost: string) => void;
}

export const PostCard: React.FC<PostCardProps> = ({ posts, deletePost }) => {
  const formatDate = formatDistance(new Date(posts.updateTime), new Date(), { addSuffix: true });
  const handleClick = () => {
    window.open(posts.urlLink, '_blank');
  };

  return (
    <StyledSimpleCard>
      <CardContent>
        <ColumnImage>
          <Img alt="" src={posts.mediaImgUrl} />
        </ColumnImage>
        <CardRight>
          <TopHeader>
            <MenuCompact
              options={[
                {
                  label: 'Delete',
                  active: true,
                  onClick: () => deletePost(posts.name),
                },
              ]}
            >
              <IconButtonSimple
                noColor
                {...{ icon: 'ellipsis', size: 'small' }}
                style={{ transform: 'rotate(90deg)' }}
              />
            </MenuCompact>
          </TopHeader>
          <ColumnInfo>
            <RowInfo>
              <Label>
                <h2>{posts.title || 'No Title'}</h2>
              </Label>
            </RowInfo>
            <RowInfo>
              <Label>{capitalizeOnlyFirstLetter(formatDate)}</Label>
            </RowInfo>
          </ColumnInfo>
        </CardRight>
      </CardContent>
      <Content>
        <RowInfo>
          <Label>{posts.summary} </Label>
        </RowInfo>
      </Content>
      <IconSection>
        <RowContainer>
          <IconButtonSimple
            icon={'view'}
            size={'small'}
            // @ts-ignore
            css={'margin-right: 100px'}
          />
          <Text>
            <LabelBoldSmall>{posts.views + ' views'}</LabelBoldSmall>
          </Text>
        </RowContainer>
        <RowContainer>
          <IconButtonSimple
            icon={'click'}
            size={'small'}
            //@ts-ignore
            css={'margin-right: 100px'}
          />
          <Text>
            <LabelBoldSmall>{posts.clicks + ' clicks'}</LabelBoldSmall>
          </Text>
        </RowContainer>
      </IconSection>
      <ButtonSection>
        {posts.actionType && (
          <Button size={'small'} btnType={'primary'} onClick={handleClick}>
            {posts.actionType.replace('_', ' ')}
          </Button>
        )}
      </ButtonSection>
    </StyledSimpleCard>
  );
};
