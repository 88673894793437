import { gql } from '@apollo/client';

import { OnboardingAccount } from '../../pages/SettingsPage/UserSettings/UserSettings';

export interface DisconnectUserAccountRequest {
  platform: OnboardingAccount;
}

export interface DisconnectUserAccountResponse {
  disconnectAccount: boolean;
}

export const DISCONNECT_USER_ACCOUNT = gql`
  mutation ($platform: String!) {
    disconnectAccount(platform: $platform)
  }
`;
