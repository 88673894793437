import React from 'react';

import { Dropdown, Radio, Space } from 'antd';
import { RadioGroupProps as AntRadioGroupProps } from 'antd/lib/radio';

import { DownOutlined } from '@ant-design/icons';

import styled from 'styled-components';

import { RadioGroupOption } from '../../RadioGroup/RadioGroup';
import { Spacer } from '../../Spacer';
import { SocialAccount } from '../../VehicleColumn/modeViews/VehicleSocialAccounts';

const RadioContent = styled.div`
  border-right: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  box-shadow: ${({ theme }) => theme.shadows.l};
  background: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

const ActionWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.dimGray.plus3};
  border: 4px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  &:hover {
    cursor: pointer;
  }
`;

const Menu = styled.div`
  width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

interface SocialAccountRadioGroupProp extends AntRadioGroupProps {
  selected: SocialAccount;
  options: RadioGroupOption[];
}

const AccountRadioGroup = React.forwardRef<HTMLDivElement, SocialAccountRadioGroupProp>(
  ({ selected, options, ...props }, ref) => (
    <RadioContent>
      <Radio.Group ref={ref} {...props} className={'borderless'}>
        <Space direction="vertical">
          {options.map((opt) => (
            //@ts-ignore
            <Radio key={opt.value} value={opt.value}>
              {opt.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </RadioContent>
  )
);
export const VerticalRadioGroup: React.FC<SocialAccountRadioGroupProp> = ({ selected, ...prop }) => {
  const radioOptions = <AccountRadioGroup selected={selected} {...prop} />;
  const title = prop.options.find((option) => option.value === selected);
  return (
    <Dropdown overlay={radioOptions}>
      <ActionWrapper>
        <Menu>
          {title?.label || 'Google'}
          <Spacer type="inline" size="xs" />
          {<DownOutlined />}
        </Menu>
      </ActionWrapper>
    </Dropdown>
  );
};

export default VerticalRadioGroup;
