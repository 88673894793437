import React, { useEffect } from 'react';

import { ApolloError, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { useHistory } from 'react-router-dom';

import { displayGqlErrors } from '../../../components/ui/ErrorList';
import { snackbar } from '../../../components/ui/Snackbar';
import { GqlMutationError } from '../../../graphql/gql-error';
import {
  CONNECT_FACEBOOK_PAGES,
  ConnectFacebookPagesParams,
  ConnectFacebookPagesResponse,
} from '../../../graphql/mutations/connectFacebookPages';
import { useFacebookPagesForCurrentUserQuery } from '../../../graphql/query/facebookPagesForCurrentUser';
import { UserProfileResponse } from '../../../graphql/query/userProfile';
import { AccountsColumn, SocialMedia } from './AccountColumn';
import { ConnectPageLocationParam } from './AccountColumn/LocationInfo';
import { OnboardingAccount } from './UserSettings';

interface ManageFbAccountProps {
  data: UserProfileResponse;
  refetch: () => void;
}

export const ManageFbAccount: React.FC<ManageFbAccountProps> = ({ data, refetch }) => {
  const history = useHistory();
  const onConnectToFacebook = async () => {
    history.push('/auth/facebook/user');
  };
  const { facebookPages, loading, error } = useFacebookPagesForCurrentUserQuery();
  const [connectFacebookPagesMutation] = useMutation<ConnectFacebookPagesResponse, ConnectFacebookPagesParams>(
    CONNECT_FACEBOOK_PAGES
  );
  const [list, setList] = React.useState<SocialMedia[]>([]);
  const pages = data.myInfo.isConnectedToFacebook.list;

  const connectPage = async (connectPageLocationParam: ConnectPageLocationParam) => {
    try {
      snackbar.info({ message: 'Connecting page', autoClose: 1500 });
      await connectFacebookPagesMutation({
        variables: {
          pageIds: [connectPageLocationParam.id],
        },
      });
      snackbar.success({ message: 'Page Connected' });
      refetch();
    } catch (error) {
      Sentry.captureException(error);
      displayGqlErrors(error as ApolloError);
    }
  };

  useEffect(() => {
    if (error) {
      displayGqlErrors(error as unknown as GqlMutationError);
    }
  }, [error]);

  React.useEffect(() => {
    if (!loading) {
      const list = facebookPages.map((page) => {
        return {
          name: page.name,
          description: page.category || '',
          avatar: page.avatar || '',
          id: page.pageId,
          groupId: page.pageId,
        };
      });
      setList(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <AccountsColumn
      isConnected={data.myInfo.isConnectedToFacebook.isActive}
      onConnectAccount={onConnectToFacebook}
      account={OnboardingAccount.FACEBOOK}
      data={data}
      socialMediaList={list}
      loading={loading}
      connectedPrev={pages}
      connectLocationPage={connectPage}
    />
  );
};
