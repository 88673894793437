import { gql } from '@apollo/client';
import { CRM } from '../../components/utils/types';

export interface SendTestAdfLeadRequest {
  locationId: string;
  adfEmail: string;
  crm: CRM;
}

export const SEND_TEST_ADF_EMAIL = gql`
  mutation ($data: SendTestAdfLeadRequest!) {
    sendTestAdfLead(data: $data)
  }
`;
