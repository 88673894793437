import { gql } from '@apollo/client';

export interface GoogleLocation {
  id: string;
  resourceName: string;
  locationName: string;
  address: string;
  isActive: boolean;
  locationId: string | null;
  groupId: string | null;
}

export const GoogleLocationFieldsFragment = gql`
  fragment GoogleLocationFields on GoogleLocation {
    id
    locationName
    locationId
    primaryPhone
    address
    isActive
    requestId
    resourceName
    groupId
  }
`;
