import React from 'react';

import styled from 'styled-components';

import { LabelBodyB, LabelR } from '../../ui/Text/Text.styled';

const InventoryLayoutWrapper = styled.section`
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const SideContent = styled.aside`
  height: 100%;
  width: 200px;
  border-right: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  position: fixed;
`;
const LayoutContent = styled.section`
  height: 100%;
  flex-grow: 1;
  margin-left: 200px;
`;

const SideContentTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: start;

  margin: 16px 0 16px 16px;
`;
interface ConnectAccountsLayoutProps {
  accountsColumn: React.ReactNode;
  userAccountColumn?: React.ReactNode;
}

export const ConnectAccountsLayout: React.FC<ConnectAccountsLayoutProps> = ({ accountsColumn, userAccountColumn }) => (
  <InventoryLayoutWrapper>
    <SideContent>
      <SideContentTitle>
        <LabelBodyB>Connected Accounts</LabelBodyB>
        <LabelR>Manage your social networks</LabelR>
      </SideContentTitle>
      {accountsColumn}
    </SideContent>
    <LayoutContent>{userAccountColumn}</LayoutContent>
  </InventoryLayoutWrapper>
);
