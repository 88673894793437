import React from 'react';

import { Switch, useRouteMatch } from 'react-router-dom';

import { RestrictedRoute } from '../components/ui/Security';
import { RoleEnum } from '../models/session';
import { SystemSettings } from '../pages/SettingsPage/SystemSettings';
import { UserSettings } from '../pages/SettingsPage/UserSettings';

const SettingsRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <RestrictedRoute
        allowedRoles={[RoleEnum.SuperAdmin, RoleEnum.Partner]}
        path={`${path}`}
        component={SystemSettings}
        exact
      />
      <RestrictedRoute
        forbiddenRoles={[RoleEnum.SuperAdmin, RoleEnum.Partner]}
        path={`${path}/user/:account`}
        component={UserSettings}
      />
    </Switch>
  );
};

export default SettingsRoutes;
