import React from 'react';

import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import { RestrictedRoute } from '../components/ui/Security';
import Inventory from '../pages/Inventory/Inventory';
import PostHistory from '../pages/PostHistory';

const InventoryRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <RestrictedRoute path={`${path}/:organizationId?/:locationId?`} component={Inventory} exact />
      <RestrictedRoute path={`${path}/history/:mode/:locationId`} component={PostHistory} />
      <Redirect to={`${path}`} />
    </Switch>
  );
};

export default InventoryRoutes;
