import React from 'react';

import { Checkbox } from 'antd';

import styled from 'styled-components';

import { AvatarWithLabel } from '../../AvatarImage/AvatarWithLabel';
import { Spacer } from '../../Spacer';
import { LabelR } from '../../Text/Text.styled';
import { AccountItem } from '../CreatePostModal';

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  max-height: 16px;
  padding: 8px;
  margin-bottom: 16px;
`;
export interface AccountCheckBoxProps {
  selectedAccounts: string[];
  accounts: AccountItem[];
  onChange: (checkedValue: string[]) => void;
  title?: string;
}
export const AccountCheckBox: React.FC<AccountCheckBoxProps> = ({ onChange, selectedAccounts, accounts }) => {
  return (
    <>
      <Spacer type="stack" size="xs" />
      <Checkbox.Group value={selectedAccounts} onChange={(val) => onChange(val as string[])}>
        {accounts.map((account, index) =>
          account.enable ? (
            <React.Fragment key={account.id}>
              <CheckBoxWrapper key={account.id}>
                <Checkbox value={account.id} />
                <Spacer type="inline" size="xxs" />
                <AvatarWithLabel imgUrl={account.imgUrl!} name={account.name!} label={account.id}>
                  <LabelR>{account.name}</LabelR>
                </AvatarWithLabel>
              </CheckBoxWrapper>
              {index !== accounts.length - 1 && <Spacer type="stack" size="xxs" />}
            </React.Fragment>
          ) : (
            ''
          )
        )}
      </Checkbox.Group>
    </>
  );
};
