import styled, { css } from 'styled-components';

export interface NavigationTabItemWrapperProps {
  active?: boolean;
}

export const NavigationTabItemWrapper = styled.div<NavigationTabItemWrapperProps>`
  display: flex;
  justify-content: flex-end;
  width: 50px;
  height: 40px;
  padding: 10px;
  border-radius: 0px 8px 8px 0px;
  font-size: 20px;
  cursor: pointer;
  color: ${(props) => (props.active ? props.theme.colors.white : props.theme.colors.dimGray.plus2)};
  background-color: ${(props) => (props.active ? props.theme.colors.purpleNavi.base : props.theme.colors.white)};
  transition: all 0.2s;
  ${(props) =>
    props.active &&
    css`
      box-shadow: ${props.theme.shadows.interaction};
    `}

  ${({ active }) =>
    !active &&
    css`
      &:hover {
        color: ${(props) => props.theme.colors.dimGray.plus1};
      }
    `}
`;
