import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { MainLayout } from '../components/Layout/MainLayout';
import ProtectedRoute from '../components/ui/Security/ProtectedRoute';
import ErrorRedirect from '../pages/ErrorRedirect/ErrorRedirect';
import ForgotPassword from '../pages/ForgotPassword';
import { LoginByLocalShift } from '../pages/Login';
import { LoginByCode } from '../pages/Login/LoginByCode';
import OnboardingByEmail, { GoogleOnboarding, Onboarding } from '../pages/Onboarding';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import AppRoutes from './AppRoutes';

const RootRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/login" component={LoginByLocalShift} />
      <Route exact path="/facebook/oauth/error" component={ErrorRedirect} />
      <Route exact path="/login-localshift" component={LoginByLocalShift} />
      <Route exact path="/dashboard-access" component={LoginByCode} />
      <Route exact path="/onboarding" component={Onboarding} />
      <Route exact path="/onboarding/email" component={OnboardingByEmail} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <ProtectedRoute authenticationPath="/login" path="/app">
        <MainLayout>
          <AppRoutes />
        </MainLayout>
      </ProtectedRoute>
      <Route
        exact
        path="/auth/google"
        component={() => {
          window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/google`;
          return null;
        }}
      />
      <Route
        exact
        path="/auth/google/user"
        component={() => {
          window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/google/user`;
          return null;
        }}
      />
      <Route
        exact
        path="/auth/tiktok/user"
        component={() => {
          window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/tik-tok/user`;
          return null;
        }}
      />
      <Route
        exact
        path="/auth/tiktok/creator"
        component={() => {
          window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/tik-tok/creator`;
          return null;
        }}
      />
      <Route
        exact
        path="/auth/google/login"
        component={() => {
          window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/google/login`;
          return null;
        }}
      />
      <Route exact path="/auth/google/onboarding">
        <GoogleOnboarding />
      </Route>

      <Route
        exact
        path="/auth/facebook/user"
        component={() => {
          window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/facebook/user`;
          return null;
        }}
      />
      <Route
        exact
        path="/auth/facebook/retry"
        component={() => {
          window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/facebook/user?retry=true`;
          return null;
        }}
      />
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
};
export default RootRoutes;
