import { gql, useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { snackbar } from '../../components/ui/Snackbar';

export const TOGGLE_CAN_LIST_TO_MARKETPLACE = gql`
  mutation toggleCanListToMarketplace($inventoryId: String!) {
    toggleCanListToMarketplace(inventoryId: $inventoryId) {
      id
      canListToMarketplace
    }
  }
`;

const useToggleCanListToMarketplace = (inventoryId: string, initialValue: boolean) => {
  const [canListToMarketplace, setCanListToMarketplace] = useState(initialValue);
  const [toggleCanListToMarketplaceMutation, { data, loading, error }] = useMutation(TOGGLE_CAN_LIST_TO_MARKETPLACE);

  const toggleCanListToMarketplace = useCallback(async () => {
    try {
      const result = await toggleCanListToMarketplaceMutation({
        variables: { inventoryId },
      });
      setCanListToMarketplace(result.data.toggleCanListToMarketplace.canListToMarketplace);
      snackbar.success({
        message: `Vehicle updated successfully`,
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }, [inventoryId]);

  useEffect(() => {
    if (data) {
      setCanListToMarketplace(data.toggleCanListToMarketplace.canListToMarketplace);
    }
  }, [data]);

  return { toggleCanListToMarketplace, canListToMarketplace, loading, error };
};

export default useToggleCanListToMarketplace;
