import styled, { css } from 'styled-components';

import mainTheme from '../../StyleGuide/themes/mainTheme';

export interface TagLabelStyledProps {
  fontColor?: string;
  backgroundColor?: string;
}

export const TagLabelStyled = styled.div<TagLabelStyledProps>`
  display: inline-block;
  min-height: 24px;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  border-radius: 16px;
  color: ${(props) => props.fontColor || props.theme.colors.dimGray.base};
  background-color: ${(props) => props.backgroundColor || props.theme.colors.dimGray.plus3};
  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
`;

export const tagLabelColors: Record<string, TagLabelStyledProps> = {
  default: {
    backgroundColor: mainTheme.colors.dimGray.plus3,
    fontColor: mainTheme.colors.dimGray.base,
  },
  purpleLight: {
    backgroundColor: mainTheme.colors.purpleNavi.plus2,
    fontColor: mainTheme.colors.white,
  },
  purpleDark: {
    backgroundColor: mainTheme.colors.purpleNavi.base,
    fontColor: mainTheme.colors.white,
  },
  greenLight: {
    backgroundColor: mainTheme.colors.viridianGreen.plus2,
    fontColor: mainTheme.colors.viridianGreen.minus2,
  },
  greenMedium: {
    backgroundColor: mainTheme.colors.viridianGreen.plus1,
    fontColor: mainTheme.colors.viridianGreen.minus2,
  },
  greenDark: {
    backgroundColor: mainTheme.colors.viridianGreen.minus1,
    fontColor: mainTheme.colors.white,
  },
  aquamarine: {
    backgroundColor: mainTheme.colors.aquamarine,
    fontColor: mainTheme.colors.white,
  },
  red: {
    backgroundColor: mainTheme.colors.red,
    fontColor: mainTheme.colors.white,
  },
  dimgray: {
    backgroundColor: mainTheme.colors.dimGray.plus1,
    fontColor: mainTheme.colors.white,
  },
  viridianGreen: {
    backgroundColor: mainTheme.colors.viridianGreen.plus2,
    fontColor: mainTheme.colors.viridianGreen.minus1,
  },
  redVariant: {
    backgroundColor: mainTheme.colors.redminus,
    fontColor: mainTheme.colors.red,
  },
};
