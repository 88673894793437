import React, { useState } from 'react';

import { Table } from 'antd';
import Column from 'antd/lib/table/Column';
import moment from 'moment';

import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import styled from 'styled-components';

import { ARCHIVE_LOCATION_CONTENT } from '../../../graphql/mutations/archiveLocationContent';
import {
  GET_CONTENT_BY_LOCATION_NAME,
  LocationContent,
  useGetContentByLocationId,
} from '../../../graphql/query/getContentByLocationId';
import { useRowSelection } from '../../../hooks/useRowSelection';
import { RoleEnum } from '../../../models/session';
import { Button } from '../Buttons/Button';
import { DefaultModal } from '../DefaultModal';
import ItemInfoCard from '../ItemInfoCard/ItemInfoCard';
import { CustomActions, ModalWithFooter as DeleteModal } from '../ModalWithFooter';
import { HasPermission } from '../Security';
import { snackbar } from '../Snackbar';
import { UpsertLocationContentForm } from '../Forms/UpsertLocationContentForm/UpsertLocationContentForm';
import { createSortProps } from '../../../utils/table.utils';
import { VehicleSocialAccounts } from '../VehicleColumn/modeViews/VehicleSocialAccounts';
import { useParams } from 'react-router-dom';

const ActionsStyled = styled(DeleteModal)`
  top: '120px';
  .ant-modal-body {
    padding: 16px 64px;
  }
`;

const PageHeader = styled.div`
  width: 100%;
  height: 56px;
  padding: 12px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const PageContent = styled.div`
  padding: 10px 10px;
  width: 100%;
  align-items: flex-start;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DeleteButton = styled.div`
  margin-right: 18px;
  display: flex;
  font-size: 20px;
  .icon {
    color: ${(props) => props.theme.colors.red};
  }
  &:hover {
    cursor: pointer;
  }
`;

const LocationContentColumn: React.FC = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const { rowSelection, selectedRowKeys, selected, setSelectedRowKeys, setSelected } = useRowSelection<any>();
  const [showUpsertModal, setShowUpsertModal] = useState<boolean>(false);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const [showActionsModal, setShowActionsModal] = useState<boolean>(false);
  const [locationContentSelected, setLocationContentSelected] = useState<LocationContent | undefined>(undefined);

  const { locationContent, refetch } = useGetContentByLocationId({
    locationId: locationId,
  });
  const [archiveLocationContent] = useMutation(ARCHIVE_LOCATION_CONTENT, {
    refetchQueries: [GET_CONTENT_BY_LOCATION_NAME],
  });

  const reload = () => {
    refetch({
      locationId: locationId,
    });
  };
  const onCreateNewLocationContent = async () => {
    setLocationContentSelected(undefined);
    setShowUpsertModal(true);
  };

  const handleDelete = async () => {
    try {
      await archiveLocationContent({
        variables: { data: selectedRowKeys },
      });
      setSelectedRowKeys([]);
      setSelected(false);
      snackbar.success({ message: `Location content archived successfully` });
    } catch (error) {
      Sentry.captureException(error);
      snackbar.error({
        message: `Unable to archived location content`,
      });
    }
  };
  const onOkArchive = async () => {
    await handleDelete();
    setShowArchiveModal(false);
  };
  const onEditLocationContent = () => {
    setShowUpsertModal(true);
    setShowActionsModal(false);
  };

  const actions: CustomActions = {
    primaryButtonAction: onEditLocationContent,
  };
  return (
    <>
      <DefaultModal
        width={600}
        visible={showUpsertModal}
        onCancel={() => setShowUpsertModal(false)}
        title={'Upload Media'}
        okText={'Save'}
        maskClosable={false}
        footer={null}
        children={
          <UpsertLocationContentForm
            refetch={reload}
            locationId={locationId}
            setShowModal={setShowUpsertModal}
            updatedObject={locationContentSelected}
          />
        }
      />
      <DeleteModal
        visible={showArchiveModal}
        onCancel={() => setShowArchiveModal(false)}
        onOk={onOkArchive}
        title={'Delete selected content?'}
        okText={'DELETE'}
      ></DeleteModal>
      <ActionsStyled
        visible={showActionsModal}
        onCancel={() => {
          setShowActionsModal(false);
        }}
        width={520}
        centered={false}
        actions={actions}
        title={'Location Content Preview'}
        okText={'Post'}
        cancelText={'Edit'}
      ></ActionsStyled>
      <PageHeader>
        <ActionsWrapper>
          {selected && (
            <DeleteButton
              onClick={() => {
                setShowArchiveModal(true);
              }}
            >
              <i className="icon misc-delete" />
            </DeleteButton>
          )}
        </ActionsWrapper>
        <HasPermission allowedRoles={[RoleEnum.Admin, RoleEnum.SuperAdmin, RoleEnum.FacebookTest]}>
          <Button btnType="primary" size="small" onClick={onCreateNewLocationContent} disabled={!locationId}>
            Upload Content
          </Button>
        </HasPermission>
      </PageHeader>
      <PageContent>
        <Table className="wide-table" dataSource={locationContent} rowKey="id" rowSelection={rowSelection}>
          <Column<LocationContent>
            title="Description"
            width="240px"
            dataIndex="description"
            key="description"
            render={(description, row) => (
              <ItemInfoCard item={description.slice(0, 140)} avatar={row.mediaUrls[0]} imgError={''} />
            )}
          />
          <Column<LocationContent>
            title="Last Edited"
            width="80px"
            dataIndex="updatedAt"
            key="updatedAt"
            render={(updatedAt) => <div>{moment(updatedAt).format('MMMM DD')}</div>}
          />
          <Column<LocationContent>
            title="Posted to"
            width="80px"
            dataIndex="postMetric"
            key="postMetric"
            render={(postMetric) => <VehicleSocialAccounts postMetrics={postMetric} />}
            {...createSortProps<LocationContent>({
              type: 'array',
              field: 'postMetric',
              multiple: 1,
            })}
          />
        </Table>
      </PageContent>
    </>
  );
};

export default LocationContentColumn;
