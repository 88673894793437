import { useSession } from '../context/AppStateProvider';
import { RoleEnum } from '../models/session';

export enum Permission {
  SeeAllInventoryView = 'SeeAllInventoryView',
  ExportInventory = 'ExportInventory',
  CanUseInventoryActions = 'CanUseInventoryActions',
}

const permissions: Record<Permission, Set<RoleEnum>> = {
  [Permission.SeeAllInventoryView]: new Set([RoleEnum.SuperAdmin]),
  [Permission.ExportInventory]: new Set([RoleEnum.SuperAdmin]),
  [Permission.CanUseInventoryActions]: new Set([RoleEnum.SuperAdmin]),
};

function hasPermission(roles: RoleEnum[] | undefined, permission: Permission): Boolean {
  if (!roles) return false;

  for (const role of roles) {
    if (permissions[permission].has(role)) return true;
  }

  return false;
}

export const useHasPermission = (permission: Permission) => {
  const { session } = useSession();
  return hasPermission(session?.roles, permission);
};
