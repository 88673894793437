import { gql } from '@apollo/client';

export interface ArchiveMarketplaceLicenseRequest {
  marketplaceToken: string;
}
export interface ArchiveMarketplaceLicenseResponse {
  archiveMarketplaceLicense: boolean;
}

export const ARCHIVE_MARKETPLACE_LICENSE = gql`
  mutation archiveMarketplaceLicense($marketplaceToken: String!) {
    archiveMarketplaceLicense(marketplaceToken: $marketplaceToken)
  }
`;
