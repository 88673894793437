import React from 'react';

import styled from 'styled-components';

import logoPattern from '../../../assets/images/purple-logo-pattern.png';

const LoginLayoutWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
`;
const SideContentWrapper = styled.div`
  height: 100%;
  width: 504px;
  background-color: ${(props) => props.theme.colors.purpleNavi.base};
  background-image: url(${logoPattern});
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
`;

const MainContentWrapper = styled.main`
  height: 100%;
  flex-grow: 1;
`;
export interface LoginLayoutProps {
  leftContent: JSX.Element;
  mainContent: JSX.Element;
}

const LoginLayout: React.FC<LoginLayoutProps> = ({ leftContent, mainContent }) => (
  <LoginLayoutWrapper>
    <SideContentWrapper>{leftContent}</SideContentWrapper>
    <MainContentWrapper>{mainContent}</MainContentWrapper>
  </LoginLayoutWrapper>
);

export default LoginLayout;
