import React, { useState } from 'react';

import { RadioChangeEvent } from 'antd/lib/radio';

import styled from 'styled-components';

import { SideMenuLayout } from '../../components/Layout/SideMenuLayout';
import { RadioGroup, RadioGroupOption } from '../../components/ui/RadioGroup/RadioGroup';
import { Spacer } from '../../components/ui/Spacer';
import { LabelB } from '../../components/ui/Text/Text.styled';
import { QueuePageColumn } from './QueuePageColumn/QueuePageColumn';

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  width: 100%;
  align-items: center;
`;
const QueueMenuWrapper = styled.div`
  padding: 16px 16px;
  width: 100%;
`;
const Label = styled(LabelB)`
  text-align: left;
  width: 100%;
`;

export type FilterOptionsMode = 'Priority' | 'All';
const QueuePage: React.FC = () => {
  const [mode, setMode] = useState<FilterOptionsMode>('Priority');
  const modeGroupOptions: RadioGroupOption[] = [
    { label: '< 5', value: 'Priority' },
    { label: 'All', value: 'All' },
  ];

  const handleChangeMode = ({ target: { value } }: RadioChangeEvent) => {
    setMode(value);
  };

  return (
    <SideMenuLayout
      title="Queue"
      sideColumn={
        <QueueMenuWrapper>
          <OptionsWrapper>
            <Label>Show locations</Label>
            <Spacer type="stack" size="xs" />
            <RadioGroup
              style={{ width: '100%' }}
              size="middle"
              defaultValue={mode}
              onChange={handleChangeMode}
              options={modeGroupOptions}
            ></RadioGroup>
          </OptionsWrapper>
        </QueueMenuWrapper>
      }
      contentColumn={<QueuePageColumn mode={mode} />}
    />
  );
};

export default QueuePage;
