import { gql } from '@apollo/client';

export enum ActionType {
  ACTION_TYPE_UNSPECIFIED = 'ACTION_TYPE_UNSPECIFIED',
  BOOK = 'BOOK',
  ORDER = 'ORDER',
  SHOP = 'SHOP',
  LEARN_MORE = 'LEARN_MORE',
  SIGN_UP = 'SIGN_UP',
  GET_OFFER = 'GET_OFFER',
  CALL = 'CALL',
  MESSAGE_PAGE = 'MESSAGE_PAGE',
}

export enum PostButtonOptions {
  POST_NOW = 'POST_NOW',
  ADD_TO_QUEUE = 'ADD_TO_QUEUE',
}

export interface CreatePostRequest {
  fbSummary: string;
  fbActionButton?: string;

  googleSummary: string;
  googleActionButton?: string;
  googleImage: string;

  instagramSummary: string;
  instagramImage?: string;

  locationId: string;
  inventoryId: string;
  vin: string;
  accounts: string;
  vdpUrl?: string;

  fbVideoUrl?: string;
  instagramVideoUrl?: string;

  action: PostButtonOptions;
}

export interface CreatePostResponse {
  createPost: {
    account: string;
    response: string;
    failed: boolean;
  }[];
}

export const CREATE_POST = gql`
  mutation ($data: CreatePostRequest!) {
    createPost(createPostRequest: $data) {
      account
      response
      failed
    }
  }
`;
