import { gql } from '@apollo/client';
import { MutationParam } from './';
export enum PostFrequency {
  Daily = 'daily',
  Twice = 'twice',
  Once = 'once',
}

export enum PostPriority {
  New = 'newestFirst',
  Used = 'oldestFirst',
  LotLongest = 'onLotLongestFirst',
  LotLeast = 'onLotShortestFirst',
  NewOnly = 'newOnly',
  UsedOnly = 'usedOnly',
}

export enum RepostCar {
  Yes = 'yes',
  No = 'no',
}

export interface UpsertLocationSettingsRequest {
  postFrequency: PostFrequency;
  postPriority: PostPriority;
  repost: boolean;
  locationId: string;
}

export interface UpsertLocationSettingsParams extends MutationParam<UpsertLocationSettingsRequest> {}

export const UPSERT_LOCATION_SETTINGS = gql`
  mutation ($data: UpsertLocationSettingsRequest!) {
    upsertLocationPostSettings(data: $data) {
      id
      postSettings
    }
  }
`;
