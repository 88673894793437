import React from 'react';

import notification from 'antd/lib/notification';
import { AxiosError } from 'axios';

import { ApolloError } from '@apollo/client';

import { extractGqlErrorMessages, GqlMutationError } from '../../../graphql/gql-error';
import { snackbar } from '../Snackbar';

interface ErrorListProps {
  messages: string[];
}

const ErrorList: React.FC<ErrorListProps> = ({ messages }) => {
  return (
    <ul style={{ padding: 0 }}>
      {messages.map((message) => {
        return <li>{message}</li>;
      })}
    </ul>
  );
};

export function displayGqlErrors(err: GqlMutationError | ApolloError | any, badReqMessage?: string) {
  const exception = err as unknown as GqlMutationError;
  if (exception?.graphQLErrors?.length > 0) {
    const errorMessage = extractGqlErrorMessages(exception);
    if (errorMessage?.length > 0) {
      notification.error({ message: 'Errors', description: <ErrorList messages={errorMessage} />, duration: 10 });
    }
  }
  const errorMessage =
    exception.message === 'Bad Request Exception' ? badReqMessage || 'Data Validation errors' : exception.message;
  snackbar.error({ message: errorMessage });
}

interface ErrorRequest {
  statusCode: number;
  message: string;
}

export function displayAxiosErrors(err: AxiosError | unknown) {
  const exception = err as unknown as AxiosError<ErrorRequest>;
  return exception;
}
