import { gql } from '@apollo/client';

export interface SendMarketplaceOnboardingEmailRequest {
  userId: string;
}

export const SEND_MARKETPLACE_ONBOARDING_EMAIL = gql`
  mutation ($userId: String!) {
    sendMarketplaceOnboardingEmail(userId: $userId)
  }
`;
