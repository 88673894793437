import React from 'react';

import { useHistory } from 'react-router-dom';

import { displayGqlErrors } from '../../../components/ui/ErrorList';
import { GqlMutationError } from '../../../graphql/gql-error';
import { useTikTokCreatorQuery } from '../../../graphql/query/tikTokCreator';
import { UserProfileResponse } from '../../../graphql/query/userProfile';
import { AccountsColumn, SocialMedia } from './AccountColumn';
import { OnboardingAccount } from './UserSettings';

interface ManageTikTokAccountProps {
  data: UserProfileResponse;
  refetch: () => void;
}
export const ManageTikTokCreator: React.FC<ManageTikTokAccountProps> = ({ data }) => {
  const history = useHistory();
  const [list, setList] = React.useState<SocialMedia[]>([]);
  const onConnectTiktok = async () => {
    history.push('/auth/tikTok/creator');
  };
  const creators = data.myInfo.isConnectedToTikTokCreator.list;
  const { tikTokCreators, loading, error } = useTikTokCreatorQuery();
  const connectAccount = async () => {};

  React.useEffect(() => {
    if (error) {
      displayGqlErrors(error as unknown as GqlMutationError);
    }
  }, [error]);

  React.useEffect(() => {
    if (!loading) {
      const list = tikTokCreators.map<SocialMedia>((tikTokCreator) => {
        return {
          name: tikTokCreator.name,
          description: '',
          avatar: '',
          id: tikTokCreator.id,
          groupId: '',
        };
      });
      setList(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  return (
    <AccountsColumn
      loading={false}
      socialMediaList={list}
      onConnectAccount={onConnectTiktok}
      isConnected={data.myInfo.isConnectedToTikTokCreator.isActive}
      account={OnboardingAccount.TIKTOK_CREATOR}
      data={data}
      connectedPrev={creators}
      connectLocationPage={connectAccount}
    />
  );
};
