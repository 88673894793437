import 'antd/dist/antd.css';

import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import styled, { css } from 'styled-components';

import {
  calculateNextEnd,
  calculateNextStart,
  calculatePrevEnd,
  calculatePrevStart,
} from '../../utils/calculatePositions';
import { Spacer } from '../Spacer';
import CardImage from './CardImage';

const baseIconStyles = css`
  font-size: '16px';
  color: '#000';
  cursor: 'pointer';
`;

const RightOutlinedIcon = styled(RightOutlined)<{}>`
  ${baseIconStyles};
`;

const LeftOutlinedIcon = styled(LeftOutlined)<{}>`
  ${baseIconStyles};
`;

const GridContainer = styled.div`
  border-radius: 16px;
  width: 400px;
  height: 304px;
  box-shadow: ${(props) => props.theme.shadows.l};
  border: solid 1px rgba(0, 0, 0, 0.07);
  padding: 16px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

const GridWrapper = styled.div`
  height: 100%;
`;

export interface GridImagesProps {
  images: string[];
  imagesSelected: string[];
  setImagesSelected: React.Dispatch<React.SetStateAction<string[]>>;
  preSelect?: boolean;
}

export const GridImages: React.FC<GridImagesProps> = ({
  images = [],
  setImagesSelected,
  imagesSelected,
  preSelect,
}) => {
  const [posDisplayed, setPosDisplayed] = useState<{ start: number; end: number | undefined }>({ start: 0, end: 9 });
  const [groupDisplayed, setGroupDisplayed] = useState<string[]>([]);

  useEffect(() => {
    preSelect && setImagesSelected(images.slice(0, 10));
    setGroupDisplayed(images.slice(posDisplayed.start, posDisplayed.end));
    setPosDisplayed({ start: 0, end: 9 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    setGroupDisplayed(images.slice(posDisplayed.start, posDisplayed.end));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posDisplayed]);

  const rows = generateRowCols(groupDisplayed, setImagesSelected, imagesSelected);
  const handlePrev = () => {
    const start = calculatePrevStart(posDisplayed.start, images.length, 9);
    const end = calculatePrevEnd(posDisplayed.start);
    setPosDisplayed({ start, end });
  };
  const handleNext = () => {
    const start = calculateNextStart(posDisplayed.end);
    const end = calculateNextEnd(posDisplayed.end, images.length, 9);
    setPosDisplayed({ start, end });
  };

  return (
    <GridContainer id="gridContainer">
      <Spacer type="inline" size="xxs" />
      <LeftOutlinedIcon onClick={handlePrev} hidden={images.length < 9} />
      <Spacer type="inline" size="xs" />
      <GridWrapper>{rows}</GridWrapper>
      <Spacer type="inline" size="xs" />
      <RightOutlinedIcon onClick={handleNext} hidden={images.length < 9} />
      <Spacer type="inline" size="xxs" />
    </GridContainer>
  );
};

function generateRowCols(
  images: string[],
  setImagesSelected: React.Dispatch<React.SetStateAction<string[]>>,
  imagesSelected: string[]
) {
  let numberOfCols = images.length > 3 ? 3 : images.length % 3;
  const isMultipleOfThree = images.length % 3 === 0;
  const numberOfRows = numberOfCols > 1 ? images.length / 3 : 1;
  const endOfCols = numberOfCols % 3;
  const rows = [];
  for (let i = 0; i < numberOfRows; i++) {
    const row = [];
    if (i + 1 === numberOfRows) {
      numberOfCols = isMultipleOfThree ? 3 : endOfCols;
    }
    for (let j = 0; j < numberOfCols; j++) {
      const imageSource = images[3 * i + j];
      row.push(
        <Col span={24 / numberOfCols}>
          {imageSource && (
            <CardImage src={imageSource} setImagesSelected={setImagesSelected} imagesSelected={imagesSelected} />
          )}
        </Col>
      );
    }
    rows.push(<Row gutter={[16, 16]}>{row}</Row>);
  }
  return rows;
}
