import { gql } from '@apollo/client';

export interface CreateTikTokStoreResponse {
  createTikTokStore: {
    storeId: string;
  };
}

export interface CreateTikTokStoreParams {
  locationId: string;
}

export const CREATE_TIK_TOK_STORE = gql`
  query createTikTokStore($locationId: String!) {
    createTikTokStore(locationId: $locationId) {
      storeId
    }
  }
`;
