import React from 'react';

import { Checkbox } from 'antd';

import styled from 'styled-components';

import { Spacer } from '../Spacer';
import { LabelB } from '../Text/Text.styled';
import { CategoryWrapper } from './Filters.styled';

interface OptionType<T> {
  id: T;
  name: string;
}
interface CategoryFilterProps<T> {
  selectedItems: T[];
  options: OptionType<T>[];
  onChange: (checkedValue: T[]) => void;
  title: string;
  showTitle?: boolean;
}

export const StyledLabelB = styled(LabelB)`
  color: ${(props) => props.theme.colors.dimGray.base};
`;

export const FilterByNumber: React.FC<CategoryFilterProps<number | string>> = ({
  onChange,
  selectedItems: selectedFilters,
  options,
  title,
  showTitle = true,
}) => {
  return (
    <>
      {showTitle && (
        <>
          <LabelB>{title}</LabelB> <Spacer type="stack" size="xs" />
        </>
      )}
      <Checkbox.Group value={selectedFilters} onChange={(val) => onChange(val as any[])}>
        {options.map((tag, index) => (
          <React.Fragment key={tag.id}>
            <CategoryWrapper key={tag.id}>
              <Checkbox value={tag.id} />
              <Spacer type="inline" size="xxs" />
              <StyledLabelB>{tag.name}</StyledLabelB>
            </CategoryWrapper>
            {index !== options.length - 1 && <Spacer type="stack" size="xxs" />}
          </React.Fragment>
        ))}
      </Checkbox.Group>
    </>
  );
};
