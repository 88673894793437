export interface GqlErrorLocation {
  line: number;
  column: number;
}

export interface GqlErrorResponse {
  statusCode: number;
  message: string[];
  error: string;
}

export interface GqlErrorException {
  response: GqlErrorResponse;
  status: number;
  message: string;
  stacktrace: string[];
}

export interface GqlErrorExtensions {
  code: string;
  exception: GqlErrorException;
  response: {
    message: string[];
  };
}

export interface GraphQLError {
  message: string;
  locations: GqlErrorLocation[];
  path: string[];
  extensions: GqlErrorExtensions;
}

export interface GqlMutationError {
  graphQLErrors: GraphQLError[];
  networkError?: any;
  message: string;
}

export function extractGqlErrorMessages(error: GqlMutationError): string[] {
  if (!error.graphQLErrors?.length) return [];
  const messages = error.graphQLErrors
    .reduce<string[][]>((acc, currentError) => {
      let currentMessages = currentError?.extensions?.exception?.response?.message || null;
      if (!currentMessages) {
        currentMessages = currentError?.extensions?.response?.message || [];
      }

      if (currentMessages.length) {
        acc.push(currentMessages);
      }
      return acc;
    }, [])
    .flat()
    .filter((x) => x !== undefined);
  return messages;
}

export function formatErrorMessages(messages: string[]): string {
  const messageList = messages.reduce<string>((acc, message) => {
    return `${acc}- ${message}\n<br>`;
  }, '');
  return `${messageList}`;
}

export function extractAndFormatGqlErrorMessages(error: GqlMutationError): string {
  return formatErrorMessages(extractGqlErrorMessages(error));
}
