export interface TokenData {
  id: string;
  username: string;
  organizationId: string;
  roles: RoleEnum[];
  iat: number;
  exp: number;
  partnerId?: string;
}

export interface SessionData extends TokenData {
  accessToken: string;
}

export enum RoleEnum {
  SuperAdmin = 'SuperAdmin',
  Partner = 'Partner',
  Member = 'Member',
  Admin = 'Admin',
  FacebookTest = 'FacebookTest',
}
