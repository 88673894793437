import { gql } from '@apollo/client';

export enum CatalogTypes {
  PRODUCT = 'products',
  VEHICLE = 'vehicles',
}

export interface Catalog {
  catalogId: string;
  typeOfCatalog: string;
}
export interface CreateFbCatalogResponse {
  createFbCatalog: Catalog;
}

export interface CreateFbCatalogParams {
  locationId: string;
  typeOfCatalog: CatalogTypes;
}

export const CREATE_FB_CATALOG = gql`
  query createFbCatalog($locationId: String!, $typeOfCatalog: String!) {
    createFbCatalog(locationId: $locationId, typeOfCatalog: $typeOfCatalog) {
      catalogId
      typeOfCatalog
    }
  }
`;
