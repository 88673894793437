export const colors = {
  gold: '#F2C94C',
  silver: '#BFBEC5',
  bronze: '#DF9E63',
  aquamarine: '#5DD39E',
  red: '#FF665E',
  white: '#FFFFFF',
  redminus: '#FF665E1F',
  dimGray: {
    plus3: '#F4F3F6',
    plus2: '#BFBEC5',
    plus1: '#94939F',
    base: '#6D6C7A',
    minus1: '#575661',
    minus2: '#3A3A41',
  },
  purpleNavi: {
    plus2: '#747ABD',
    plus1: '#5D63A4',
    base: '#4A4F89',
    minus1: '#404577',
    minus2: '#32365D',
  },
  viridianGreen: {
    plus2: '#C6EFF1',
    plus1: '#80DED9',
    base: '#43B6BB',
    minus1: '#1B98A2',
    minus2: '#2A7079',
  },
};

export const fonts = {
  header: "'Overpass', sans-serif",
  body: "'Source Sans Pro', sans-serif",
};

const squishSpacing = {
  xs: {
    x: '8px',
    y: '4px',
  },
  s: {
    x: '16px',
    y: '8px',
  },
  m: {
    x: '16px',
    y: '12px',
  },
  l: {
    x: '16px',
    y: '16px',
  },
  xl: {
    x: '24px',
    y: '8px',
  },
};

export const spacing = {
  sizes: {
    xxxs: '4px',
    xxs: '8px',
    xs: '16px',
    s: '24px',
    m: '32px',
    l: '40px',
    xl: '56px',
    xxl: '64px',
    xxxl: '80px',
  },
  squish: squishSpacing,
  stretch: {
    s: '16px',
    m: '24px',
    l: '32px',
    xl: '40px',
  },
};

export const avatar = {
  sizes: {
    small: '32px',
    medium: '40px',
    large: '64px',
    extraLarge: '120px',
  },
};

export const shadows = {
  s: '2px 2px 8px -2px rgba(87, 86, 97, 0.1), 2px 4px 16px rgba(87, 86, 97, 0.1)',
  m: '2px 4px 16px rgba(109, 108, 122, 0.2)',
  l: '4px 8px 24px rgba(87, 86, 97, 0.1)',
  interaction: '4px 4px 24px -4px rgba(93, 99, 164, 0.4)',
};

const mainTheme = {
  fonts,
  colors,
  spacing,
  shadows,
  avatar,
};

export default mainTheme;
