import { createGlobalStyle } from 'styled-components';

export const ToastifyStyles = createGlobalStyle`
.Toastify__toast-container,
  .Toastify__toast-container.Toastify__toast-container--bottom-right {
    width: calc(100% - 32px) !important;
    max-width: 1048px;
    min-height: 48px;
  }

  .Toastify__toast {
    min-height: 48px;
    padding: 12px 16px;
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast-body {
    width: 100%;
    padding: 0;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.dimGray.minus2};
  }
  .Toastify__progress-bar {
    height: 4px;
    background: ${(props) => props.theme.colors.purpleNavi.plus2}
  }
`;
