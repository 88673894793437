import React from 'react';

import { match } from 'ts-pattern';

import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { UserProfileResponse } from '../../../graphql/query/userProfile';
import { AccountTextLabels } from '../../../pages/SettingsPage/UserSettings/AccountColumn';
import { OnboardingAccount } from '../../../pages/SettingsPage/UserSettings/UserSettings';
import { Spacer } from '../../ui/Spacer';
import { AccountIcon } from '../AccountMenu/AccountMenuItem/AccountMenuItem.styled';
import { AccountSubItemWrapper } from '../AccountMenu/AccountSubItem/AccountSubItem.styled';

const ConnectedAccountsMenuWrapper = styled.div`
  padding: 10px 10px;
  width: 200px;
`;

interface LabelAccountProps {
  numberOfAccounts?: number;
  isConnected?: boolean;
  account: OnboardingAccount;
  select: OnboardingAccount;
  iconClass: string;
}

const LabelAccount: React.FC<LabelAccountProps> = ({ numberOfAccounts, isConnected, account, select, iconClass }) => {
  const { path } = useRouteMatch();
  return (
    <Link to={`${path.replace(':account', '')}${account}`}>
      <AccountSubItemWrapper active={account === select} adjustHoverleft>
        <AccountIcon className={`ic ${iconClass}`} />
        <Spacer type="inline" size="xs" />
        <div className="label">{AccountTextLabels[account].shortName}</div>
        <Spacer type="inline" size="xs" />

        {isConnected ? (
          <div className="notification-count">{numberOfAccounts}</div>
        ) : (
          <AccountIcon className={`ic ${'ic_warning_filled'}`} />
        )}
      </AccountSubItemWrapper>
    </Link>
  );
};

interface ConnectedAccountsMenuProps {
  selected: OnboardingAccount;
  data: UserProfileResponse;
}

export const ConnectedAccountsMenu: React.FC<ConnectedAccountsMenuProps> = ({ data, selected }) => {
  const userInfo = (label: OnboardingAccount) =>
    match(label)
      .with(OnboardingAccount.FACEBOOK, () => data.myInfo.isConnectedToFacebook)
      .with(OnboardingAccount.GOOGLE, () => data.myInfo.isConnectedToGoogle)
      .with(OnboardingAccount.TIKTOK, () => data.myInfo.isConnectedToTikTok)
      .with(OnboardingAccount.TIKTOK_CREATOR, () => data.myInfo.isConnectedToTikTokCreator)
      .exhaustive();

  const socialAccounts = Object.values(OnboardingAccount);
  return (
    <ConnectedAccountsMenuWrapper>
      {socialAccounts.map((socialAccount) => {
        return (
          <LabelAccount
            numberOfAccounts={userInfo(socialAccount).numberAccounts}
            isConnected={userInfo(socialAccount).isActive}
            account={socialAccount}
            iconClass={AccountTextLabels[socialAccount].icon}
            select={selected}
          />
        );
      })}
    </ConnectedAccountsMenuWrapper>
  );
};
