import { gql, useQuery } from '@apollo/client';

export enum AccountType {
  GoogleLocation = 'GoogleLocation',
  TikTokBusinessCenter = 'TikTokBusinessCenter',
  FacebookPage = 'FacebookPage',
  GoogleTagManager = 'GoogleTagManager',
  FacebookAdAccount = 'FacebookAdAccount',
  LESA = 'Lesa',
}

export type AssignableAccount = {
  id: string;
  externalId?: string;
  name: string;
  type: AccountType;
};

export interface AssignableAccountsResponse {
  assignableAccounts: AssignableAccount[];
}

export const ASSIGNABLE_ACCOUNTS = gql`
  query assignableAccounts($locationId: String!, $type: String!) {
    assignableAccounts(locationId: $locationId, type: $type) {
      id
      externalId
      name
      type
    }
  }
`;

export function useAssignableAccountsQuery(locationId: string, type: AccountType) {
  const { data, loading, error } = useQuery<AssignableAccountsResponse>(ASSIGNABLE_ACCOUNTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      locationId,
      type,
    },
  });
  return {
    assignableAccounts: data?.assignableAccounts || [],
    loading,
    error,
  };
}
