import React, { useState } from 'react';

import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import { Spacer } from '../Spacer';
import { LabelB } from '../Text/Text.styled';
import { StyledLabelB } from './Filter';
import { CategoryWrapper } from './Filters.styled';

export enum PostStatusEnum {
  replied = 'replied',
  pending = 'pending',
  ignored = 'ignored',
}

const statusOptions = [
  { value: PostStatusEnum.replied, label: 'Responded' },
  { value: PostStatusEnum.pending, label: 'Pending' },
  { value: PostStatusEnum.ignored, label: 'Ignored' },
];

interface StatusFilterProps {
  selectedItems: string[];
  onChange: (checkedValue: string[]) => void;
}

export const StatusFilter: React.FC<StatusFilterProps> = ({ selectedItems, onChange }) => {
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const handleStatusChange = (checkedValue: CheckboxValueType[] = []) => {
    onChange(checkedValue as string[]);
    setIndeterminate(!!checkedValue.length && checkedValue.length < statusOptions.length);
    setCheckAll(checkedValue.length === statusOptions.length);
  };

  const onCheckAllStatusChange = (e: CheckboxChangeEvent) => {
    onChange(e.target.checked ? statusOptions.map((s) => s.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  return (
    <>
      <LabelB>Status</LabelB>
      <Spacer type="stack" size="xs" />
      <CategoryWrapper>
        <Checkbox checked={checkAll} indeterminate={indeterminate} onChange={onCheckAllStatusChange} />
        <Spacer type="inline" size="xxs" />
        <StyledLabelB>All</StyledLabelB>
      </CategoryWrapper>
      <Spacer type="stack" size="xxs" />
      <Checkbox.Group value={selectedItems} onChange={handleStatusChange}>
        {statusOptions.map((opt, index) => (
          <React.Fragment key={opt.value}>
            <CategoryWrapper key={opt.value}>
              <Checkbox value={opt.value} />
              <Spacer type="inline" size="xxs" />
              <StyledLabelB>{opt.label}</StyledLabelB>
            </CategoryWrapper>
            {index !== statusOptions.length - 1 && <Spacer type="stack" size="xxs" />}
          </React.Fragment>
        ))}
      </Checkbox.Group>
    </>
  );
};
