import { createGlobalStyle } from 'styled-components';

export const AntDropdownStyles = createGlobalStyle`
.dropdown-checkbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: auto;
  font-size: 12px;
  width: 135px;
}

.dropdown-users {
  display: flex;
  justify-content: flex-start;
  height: auto;
  font-size: 12px;
  width: 200px;
  align-items: center;
}

.dropdown-buttons{
  display:flex;
  justify-content: flex-start;
  height: auto;
  font-size: 16px;
  width:200px;
  align-items: flex-start;
}
`;
