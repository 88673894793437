import { createGlobalStyle } from 'styled-components';

export const AntRadioStyles = createGlobalStyle`
  // radio styles
  .ant-radio .ant-radio-inner,
  .ant-radio.ant-radio-disabled .ant-radio-inner {
      border-color: ${(props) => props.theme.colors.dimGray.plus2};

    }
  .ant-radio.ant-radio-checked,
  .ant-radio.ant-radio-disabled.ant-radio-checked {
    .ant-radio-inner {
      border-color: ${(props) => props.theme.colors.purpleNavi.plus2} !important;
      &:after {
        background-color: ${(props) => props.theme.colors.purpleNavi.base};
      }
    }
  }
  .ant-radio.ant-radio-disabled {
     opacity: 0.4;
     .ant-radio-inner {
       background-color: transparent;
     }
  }
`;
