import React from 'react';

import { Radio, Space } from 'antd';
import { RadioGroupProps as AntRadioGroupProps } from 'antd/lib/radio';

import { Spacer } from '../Spacer';

export interface RadioGroupOption {
  value: string | number | boolean;
  label: string;
}

export interface RadioGroupProps extends AntRadioGroupProps {
  options: RadioGroupOption[];
  removeBorders?: boolean;
}

export const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
  ({ options, removeBorders, ...props }, ref) => (
    <Radio.Group ref={ref} {...props} className={`${removeBorders ? 'borderless' : ''}`}>
      {options.map((opt) => (
        //@ts-ignore
        <Radio.Button key={opt.value} value={opt.value}>
          {opt.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  )
);

export const RadioVerticalGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(({ options, ...props }, ref) => (
  <Radio.Group style={{ height: '100%' }} ref={ref} {...props} className="borderless">
    <Space direction="vertical" style={{ width: '100%' }}>
      {options.map((opt) => (
        <>
          <Radio
            style={{ padding: '12px 16px', border: '1px solid #F4F3F6', borderRadius: '4px', width: '100%' }}
            //@ts-ignore
            key={opt.value}
            value={opt.value}
          >
            {opt.label}
          </Radio>
          <Spacer size="xxxs" type="stack" />
        </>
      ))}
    </Space>
  </Radio.Group>
));
