import styled, { css } from 'styled-components';

export interface VerticalTabItemWrapperProps {
  active: boolean;
}

export const VerticalTabItemWrapper = styled.div<VerticalTabItemWrapperProps>`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px 12px 16px;
  border-right: solid 4px ${(props) => (props.active ? props.theme.colors.purpleNavi.plus1 : 'transparent')};
  background-color: ${(props) => (props.active ? props.theme.colors.dimGray.plus3 : props.theme.colors.white)};
  cursor: pointer;

  .tab-text {
    flex-grow: 1;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    text-align: left;
    color: ${(props) => props.theme.colors.dimGray.plus1};
  }
  .tab-icon {
    font-size: 16px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.dimGray.plus2};
  }
  .tab-count {
    color: ${(props) => props.theme.colors.dimGray.plus1};
  }
  ${(props) =>
    props.active
      ? css`
          .tab-text {
            color: ${props.theme.colors.dimGray.minus2};
          }
          .tab-icon {
            color: ${props.theme.colors.purpleNavi.plus1};
          }
          .tab-count {
            color: ${props.theme.colors.dimGray.base};
          }
        `
      : css`
          :hover {
            .tab-text {
              color: ${(props) => props.theme.colors.dimGray.minus2};
            }
            .tab-icon {
              color: ${(props) => props.theme.colors.dimGray.plus1};
            }
          }
        `}
`;
