import { gql, useQuery } from '@apollo/client';

import { DashboardBaseInput } from './getTopPosts';
import { PostMetric } from './inventoryByLocationId';

interface TopMarketplaceLeadsRequest extends DashboardBaseInput {}
interface GetTopPostResponse {
  getTopMarketplaceLeads: PostMetric[];
}
interface GetTopMarketplaceLeadsParams {
  topMarketplaceLeadsRequest: TopMarketplaceLeadsRequest;
}

const GET_TOP_MARKETPLACE_LEADS = gql`
  query getTopMarketplaceLeads($topMarketplaceLeadsRequest: TopMarketplaceLeadsRequest!) {
    getTopMarketplaceLeads(input: $topMarketplaceLeadsRequest) {
      postId
      inventoryId
      source
      status
      statusMessage
      date
      clicks
      views
      leads
      imageUrl
      title
      soldAt
      owner
      link
    }
  }
`;

export function useGetTopMarketplaceLeads(params: GetTopMarketplaceLeadsParams) {
  const { data, error, loading } = useQuery<GetTopPostResponse, GetTopMarketplaceLeadsParams>(
    GET_TOP_MARKETPLACE_LEADS,
    {
      variables: params,
      fetchPolicy: 'cache-and-network',
    }
  );
  return {
    topMarketplaceCars: data?.getTopMarketplaceLeads || [],
    error,
    loading,
  };
}
