import React, { useState } from 'react';

import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import * as Yup from 'yup';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import {
  CREATE_OFFER_POST_FOR_TEMPLATE,
  createOfferPostForTemplateRequest,
  createOfferPostForTemplateResponse,
} from '../../../../graphql/mutations/createOfferPostForTemplate';
import {
  CadenceOptions,
  MAX_COUPON_CODE_LENGTH,
  UPSERT_OFFER_POST_TEMPLATE,
  UpsertOfferPostTemplateParams,
  UpsertOfferPostTemplateRequest,
} from '../../../../graphql/mutations/upsertOfferPostTemplate';
import { useLocationAccountsInfo } from '../../../../graphql/query/getLocationAccountsInfoInfo';
import { OfferPostTemplate } from '../../../../graphql/query/getOfferPostTemplatesByLocationId';
import { capitalizeOnlyFirstLetter } from '../../../../utils/formatText.utils';
import { Button } from '../../Buttons/Button';
import { ModalWithFooter } from '../../ModalWithFooter';
import SelectImageSource from '../../SelectImageSource';
import { snackbar } from '../../Snackbar';
import { Spacer } from '../../Spacer';
import { LabelBold } from '../../Text/Text.styled';
import TextField from '../../TextField';
import TextAreaField from '../../TextField/TextAreaField';
import { WhiteIcon } from '../../UploadButton/UploadButton.styled';
import { PostPreview } from './components/PostPreview';

const CreatePostWrapper = styled.div`
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  padding: 0;
  height: 500px;
  margin-bottom: 48px;
`;

const SelectImageModal = styled(ModalWithFooter)`
  top: '84px';
`;
const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  align-self: flex-end;
`;
const LeftSide = styled.div`
  float: left;
  width: 33.33%;
  border-right: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  padding-right: 16px;
  height: 100%;
`;

const MiddleSide = styled.div`
  float: left;
  width: 33.33%;
  border-right: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  padding-right: 16px;
  padding-left: 24px;
  height: 100%;
`;

const RightSide = styled.div`
  float: left;
  padding-left: 24px;
  width: 33.33%;
  height: 100%;
  position: relative;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

export const ServiceImage = styled.img`
  max-width: 100%;
  z-index: 1;
  max-height: 100%;
  object-fit: cover;
  display: block;
`;

export const ImageOption = styled.div`
  width: auto;
  height: 220px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: ${(props) => props.theme.colors.dimGray.plus3};
  border: 1px dashed ${(props) => props.theme.colors.dimGray.plus2};
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  &:hover {
    .actions {
      display: flex;
    }
  }
  .actions {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 220px;
    padding: 8px;
    display: none;
    background-color: ${(props) => props.theme.colors.dimGray.minus2};
    opacity: 0.8;
    align-content: flex-start;
    justify-content: center;
    align-items: center;
    .icon {
      &:hover {
        transform: scale(1.5);
        cursor: pointer;
      }
    }
  }
`;

const ValidationSchema = Yup.object().shape({
  couponCode: Yup.string()
    .default('')
    .optional()
    .nullable()
    .test('length', `Must be less than ${MAX_COUPON_CODE_LENGTH} characters`, (value) => {
      if (value) {
        return value.length <= MAX_COUPON_CODE_LENGTH;
      }

      return true;
    }),
  termsAndConditions: Yup.string().default('').required(),
  link: Yup.string().default('').required(),
  title: Yup.string().default('').required().max(58),
  details: Yup.string().default('').required(),
});

interface UpsertOfferFormValue {
  couponCode?: string;
  termsAndConditions: string;
  link: string;
  title: string;
  details: string;
  cadence: CadenceOptions;
}

interface UpsertOfferFormProps {
  setShowModal: (show: boolean) => void;
  updatedObject?: OfferPostTemplate;
  locationId: string;
  refetch: () => void;
}

export const UpsertOfferForm: React.FC<UpsertOfferFormProps> = ({
  setShowModal,
  updatedObject,
  locationId,
  refetch,
}) => {
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const { locationAccountsInfo } = useLocationAccountsInfo(locationId!);
  const [serviceImage, setServiceImage] = useState<string>(updatedObject?.imageUrl || '');
  const [cadenceOption, setCadenceOption] = useState<CadenceOptions>(updatedObject?.cadence || CadenceOptions.WEEKLY);
  const [upsertOfferPostTemplate] = useMutation<any, UpsertOfferPostTemplateParams>(UPSERT_OFFER_POST_TEMPLATE);
  const [createOfferPostTemplate] = useMutation<createOfferPostForTemplateResponse, createOfferPostForTemplateRequest>(
    CREATE_OFFER_POST_FOR_TEMPLATE
  );

  const { handleSubmit, control, errors, getValues, trigger } = useForm<UpsertOfferFormValue>({
    resolver: yupResolver(ValidationSchema),
    mode: 'onChange',
    defaultValues: {
      couponCode: updatedObject?.couponCode,
      termsAndConditions: updatedObject?.termsAndConditions,
      link: updatedObject?.link,
      title: updatedObject?.title,
      details: updatedObject?.details,
      cadence: updatedObject?.cadence ?? CadenceOptions.WEEKLY,
    },
  });

  const handleChangeCadence = ({ target: { value } }: RadioChangeEvent) => {
    setCadenceOption(value);
  };

  const onSubmit = async (data: UpsertOfferFormValue) => {
    const request: UpsertOfferPostTemplateRequest = {
      cadence: cadenceOption,
      title: data.title,
      id: updatedObject?.id || undefined,
      locationId: locationId,
      couponCode: data.couponCode,
      termsAndConditions: data.termsAndConditions,
      link: data.link,
      details: data.details,
      imageUrl: serviceImage,
    };
    await snackbar.info({
      message: `Saving template ...`,
    });
    const response = await upsertOfferPostTemplate({
      variables: { data: request },
    });
    if (response) {
      refetch();
      await snackbar.info({
        message: `Template was successfully saved`,
      });
      setShowModal(false);
    }
  };
  const onPostNow = async () => {
    const validation = await trigger(['details', 'link', 'termsAndConditions', 'title']);
    if (validation) {
      const data = getValues();
      const request: UpsertOfferPostTemplateRequest = {
        cadence: cadenceOption,
        title: data.title,
        id: updatedObject?.id || undefined,
        locationId: locationId,
        couponCode: data.couponCode,
        termsAndConditions: data.termsAndConditions,
        link: data.link,
        details: data.details,
        imageUrl: serviceImage,
      };
      await snackbar.info({
        message: `Saving template ...`,
      });
      const response = await upsertOfferPostTemplate({
        variables: { data: request },
      });
      if (response) {
        const result = await createOfferPostTemplate({
          variables: { offerPostTemplateId: response.data.upsertOfferPostTemplate?.id },
        });
        await snackbar.info({
          message: `Posting template ...`,
        });
        await snackbar.info({
          message: `${capitalizeOnlyFirstLetter(result.data?.createOfferPostForTemplate?.account)}: ${
            result.data?.createOfferPostForTemplate?.response
          }`,
        });
        refetch();
        setShowModal(false);
      }
    } else {
      await snackbar.warning({
        message: `All fields neeed to be completed`,
      });
    }
  };
  const isAbleToPostNow = !!serviceImage && !!locationAccountsInfo?.location.googleLocation?.locationName;

  const submitHandler = handleSubmit(onSubmit);

  return (
    <>
      <SelectImageModal
        width={800}
        visible={showImageModal}
        onCancel={() => {
          setShowImageModal(false);
        }}
        onOk={() => {
          setShowImageModal(false);
        }}
        title={'Select Image'}
        okText={'Select'}
      >
        <SelectImageSource image={serviceImage} setImage={setServiceImage} />
      </SelectImageModal>
      <form onSubmit={submitHandler}>
        <CreatePostWrapper>
          <LeftSide>
            <TextField
              type="text"
              name="title"
              label="Offer Title"
              placeholder="Post Media"
              control={control}
              errors={errors}
            />
            <Spacer type="stack" size="xs" />
            <LabelBold>POST MEDIA</LabelBold>
            <Spacer type="stack" size="xxxs" />

            <ImageOption>
              {serviceImage ? (
                <>
                  <div className="actions">
                    <WhiteIcon
                      className="icon misc-edit"
                      onClick={() => {
                        setShowImageModal(true);
                      }}
                    />
                    <Spacer type="inline" size="xs" />
                    <WhiteIcon className="icon misc-delete" onClick={() => setServiceImage('')} />
                  </div>
                  <ServiceImage src={serviceImage} alt="service" />
                </>
              ) : (
                <Button
                  size="small"
                  btnType="text"
                  onClick={() => {
                    setShowImageModal(true);
                  }}
                >
                  SELECT IMAGE
                </Button>
              )}
            </ImageOption>
            <Spacer type="stack" size="xs" />
            <TextAreaField
              name="details"
              size="large"
              rows={4}
              label="Offer Details"
              control={control}
              errors={errors}
            />
          </LeftSide>
          <MiddleSide>
            <TextField
              type="text"
              name="couponCode"
              label="Coupon Code"
              placeholder="Enter coupon code"
              control={control}
              errors={errors}
            />
            <Spacer type="stack" size="xs" />
            <TextField
              type="text"
              name="link"
              label="Offer Link"
              placeholder="Enter offer link"
              control={control}
              errors={errors}
            />
            <Spacer type="stack" size="xs" />
            <TextAreaField
              name="termsAndConditions"
              size="large"
              rows={4}
              label="Terms and Conditions"
              control={control}
              errors={errors}
            />
            <Spacer type="stack" size="xs" />
            <Radio.Group defaultValue={cadenceOption} onChange={handleChangeCadence} className={'borderless'}>
              <Radio value={CadenceOptions.WEEKLY}>Weekly</Radio>
              <Radio value={CadenceOptions.MONTHLY}>Monthly</Radio>
              <Radio value={CadenceOptions.DAILY}>Daily</Radio>
              <Radio value={CadenceOptions.BIWEEKLY}>Biweekly</Radio>
            </Radio.Group>
          </MiddleSide>
          <RightSide>
            <LabelBold>POST PREVIEW</LabelBold>
            <Spacer type="stack" size="xs" />
            <PostPreview
              googleName={locationAccountsInfo?.location.googleLocation?.locationName ?? 'Unknown'}
              imageSelected={serviceImage}
              template={updatedObject}
            />
            <Spacer type="stack" size="xl" />
          </RightSide>
        </CreatePostWrapper>
        <StyledButtonsContainer>
          <Button id="postNowButton" btnType="secondary" onClick={onPostNow} disabled={!isAbleToPostNow}>
            POST NOW
          </Button>
          <Spacer type="inline" size="s" />
          <Button id="CreateAndSaveButton" type="submit" btnType="primary" disabled={!serviceImage}>
            SAVE
          </Button>
        </StyledButtonsContainer>
      </form>
    </>
  );
};
