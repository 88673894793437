import React from 'react';

import { KeywordsTypes } from '../components/ui/CreatePostModal/helpers';

export interface PostState {
  locationId?: string;
  keywords?: KeywordsTypes[];
  actions: {
    changeLocationId: (locationId: string) => void;
    changeKeywords: (keywords: KeywordsTypes[]) => void;
  };
}

const PostStateContext = React.createContext<PostState | null>(null);
PostStateContext.displayName = 'PostContext';

const PostStateProvider: React.FC = ({ children }) => {
  const [locationId, setLocationId] = React.useState<string>('');
  const [keywords, setKeywords] = React.useState<KeywordsTypes[]>([]);

  return (
    <PostStateContext.Provider
      value={{
        actions: {
          changeKeywords: setKeywords,
          changeLocationId: setLocationId,
        },
        keywords: keywords,
        locationId,
      }}
    >
      {children}
    </PostStateContext.Provider>
  );
};

function usePostStateContext() {
  const context = React.useContext(PostStateContext);
  if (typeof context === 'undefined') {
    throw Error('The component must be in the provider to get the context');
  }
  return context;
}

export { PostStateProvider, usePostStateContext };
