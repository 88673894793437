import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import styled from 'styled-components';

import {
  PostFrequency,
  PostPriority,
  UPSERT_LOCATION_SETTINGS,
  UpsertLocationSettingsParams,
} from '../../../../graphql/mutations/upsertLocationSettings';
import { LocationItem } from '../../../../graphql/query/locationsByOrgId';
import { formatPostSettings } from '../../../utils/product.utils';
import { displayGqlErrors } from '../../ErrorList';
import SideDrawer from '../../SideDrawer';
import { snackbar } from '../../Snackbar';
import LocationSettingsForm from './LocationSettingsForm';

interface LocationSettingsSideDrawerProps {
  isSideDrawerVisible: boolean;
  toggleSideDrawer: (value?: boolean) => void;
  updateObject: LocationItem;
}

const FormContainer = styled.section`
  margin: 24px 16px 16px 16px;
  width: 100%;
`;

const LocationSettingsDrawer: React.FC<LocationSettingsSideDrawerProps> = ({
  isSideDrawerVisible,
  toggleSideDrawer,
  updateObject,
}) => {
  const postSettings = formatPostSettings(updateObject.postSettings);
  const [upsertLocationSettings] = useMutation<any, UpsertLocationSettingsParams>(UPSERT_LOCATION_SETTINGS);
  const [postFrequency, setPostFrequency] = useState<PostFrequency>(postSettings?.postFrequency ?? PostFrequency.Daily);
  const [postPriority, setPostPriority] = useState<PostPriority>(postSettings?.carPriority ?? PostPriority.Used);
  const [repost, setRepost] = useState<boolean>(postSettings?.repost ?? true);

  const handleFormCompletedEditUserAccount = async () => {
    try {
      await upsertLocationSettings({
        variables: {
          data: {
            postFrequency,
            postPriority,
            repost,
            locationId: updateObject.id,
          },
        },
      });
      snackbar.success({
        message: 'Location Settings updated Successfully',
      });
      toggleSideDrawer();
    } catch (error) {
      Sentry.captureException(error);
      displayGqlErrors(error, 'Unable to update location settings');
    }
  };

  return (
    <SideDrawer
      width={460}
      heading="Location Settings"
      isDrawerVisible={isSideDrawerVisible}
      toggleDrawerVisible={toggleSideDrawer}
      ctaButtons={[
        {
          label: 'SAVE',
          onClick: async () => {
            await handleFormCompletedEditUserAccount();
          },
          isDisabled: false,
        },
      ]}
    >
      <FormContainer>
        <LocationSettingsForm
          setPostFrequency={setPostFrequency}
          setPostPriority={setPostPriority}
          setRepost={setRepost}
          postFrequency={postFrequency}
          postPriority={postPriority}
          repost={repost}
        />
      </FormContainer>
    </SideDrawer>
  );
};

export default LocationSettingsDrawer;
