import { gql } from '@apollo/client';

export interface ToggleOrganizationStatusResponse {
  id: string;
  isActive: boolean;
}

export interface ToggleOrganizationStatusRequest {
  organizationId: string;
}

export const TOGGLE_ORGANIZATION_STATUS = gql`
  mutation ($organizationId: String!) {
    toggleOrganizationStatus(organizationId: $organizationId) {
      id
      isActive
    }
  }
`;
