import { createGlobalStyle } from 'styled-components';

export const AntTextInputStyles = createGlobalStyle`
background-color: ${(props) => props.theme.colors.white};
.ant-input,.ant-input-password {
  border: 1px solid ${(props) => props.theme.colors.dimGray.plus2};
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 16px;
  color: ${(props) => props.theme.colors.dimGray.minus1};
  outline: 0;
  &::placeholder {
    color: ${(props) => props.theme.colors.dimGray.plus1};
  }
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.purpleNavi.plus2};
    &::placeholder {
      color: ${(props) => props.theme.colors.dimGray.base};
    }
  }

  .ant-input:focus,
  .ant-input-focused,
  &.ant-input-affix-wrapper-focused,
  &.ant-input-affix-wrapper:focus,
  &:focus {
    outline: 0;
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.colors.purpleNavi.plus2};
  }

  &:disabled,
  &:disabled::placeholder {
    color: ${(props) => props.theme.colors.dimGray.plus2};
    background-color: ${(props) => props.theme.colors.white};
  }
  &:disabled {
    border: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  }
}

.ant-input-password{
  .ant-input:focus,
  .ant-input-focused{
    border:none;
  }
}
`;
