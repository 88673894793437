import React from 'react';

import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

import styled from 'styled-components';

import { ServiceFilters } from '../../../pages/Inventory/hooks/useServiceFilters';
import { Spacer } from '../../ui/Spacer';
import { LabelB } from '../../ui/Text/Text.styled';
import { CadenceOptions } from '../../../graphql/mutations/upsertOfferPostTemplate';

const Label = styled(LabelB)`
  text-align: left;
`;
const RadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
interface ServiceMenuProps {
  serviceFilters: ServiceFilters;
}
export const ServiceMenu: React.FC<ServiceMenuProps> = ({ serviceFilters }) => {
  const { setCadence } = serviceFilters;
  const handleChangeCadence = ({ target: { value } }: RadioChangeEvent) => {
    setCadence(value);
  };

  return (
    <>
      <Label>Posting Cadence</Label>
      <Spacer type="stack" size="xxs" />
      <RadioGroup onChange={handleChangeCadence} className={'borderless'}>
        <Radio value={CadenceOptions.WEEKLY}>Weekly</Radio>
        <Radio value={CadenceOptions.MONTHLY}>Monthly</Radio>
        <Radio value={CadenceOptions.DAILY}>Daily</Radio>
        <Radio value={CadenceOptions.BIWEEKLY}>Biweekly</Radio>
      </RadioGroup>
    </>
  );
};
