import React, { useState } from 'react';
import { List, Spin, Table } from 'antd';
import styled from 'styled-components';
import NoImage from '../../../../assets/images/noimage.png';
import { GetLocationAccountInfoResponse } from '../../../../graphql/query/getLocationAccountsInfoInfo';
import { Condition, InventoryItem } from '../../../../graphql/query/inventoryByLocationId';
import { LocationProductEnum } from '../../../../graphql/query/locationsByOrgId';
import { decodeEntities } from '../../../../utils/formatText.utils';
import { createSortProps } from '../../../../utils/table.utils';
import { hasProduct } from '../../../utils/product.utils';
import ItemInfoCard from '../../ItemInfoCard/ItemInfoCard';
import { TagLabel } from '../../TagLabel';
import { H3 } from '../../Text/Text.styled';
import { InventoryActionButtons } from './InventoryActionButtons';
import { VehicleSocialAccounts } from './VehicleSocialAccounts';
import { Spacer } from '../../Spacer';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Field, FieldSection } from '../../CreatePostModal/components/PostSummary';
import { LoadingWrapper } from '../../DashboardContent/components/MarketplaceLeaderboard';

const { Column } = Table;

const showVehicleInfo = (item?: InventoryItem) => {
  const priceField =
    item?.price === 0
      ? 'No price yet'
      : item?.price.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
  return (
    <FieldSection style={{ paddingLeft: '4%' }}>
      <Field rightText={item?.vin} leftText="VIN"></Field>
      <Field rightText={item?.vehicle} leftText="Name"></Field>
      <Field rightText={item?.stock} leftText="Stock"></Field>
      <Field rightText={item?.condition} leftText="Condition"></Field>
      <Field rightText={priceField} leftText="Price"></Field>
      <Field rightText={item?.year} leftText="Year"></Field>
      <Field rightText={item?.make} leftText="Make"></Field>
      <Field rightText={item?.model} leftText="Model"></Field>
      <Field rightText={item?.modelNumber} leftText="Model Number"></Field>
      <Field rightText={item?.trim} leftText="Trim"></Field>
      <Field rightText={item?.body} leftText="Body"></Field>
      <Field rightText={item?.transmission} leftText="Transmission"></Field>
      <Field rightText={item?.driveType} leftText="Drive Type"></Field>
      <Field rightText={item?.intColor} leftText="Interior Color"></Field>
      <Field rightText={item?.engine} leftText="Engine"></Field>
      <Field rightText={item?.extColor} leftText="Exterior Color"></Field>
      <Field rightText={item?.mileage} leftText="Mileage"></Field>
      <Field rightText={item?.vdp_url} leftText="VDP URL"></Field>
      <Field rightText={item?.numberOfImages} leftText="Number of Images"></Field>
      {item?.videoUrl && <Field rightText={item?.videoUrl} leftText="Video URL"></Field>}
      {item?.vehDescription && <Field rightText={item?.vehDescription} leftText="Description"></Field>}
    </FieldSection>
  );
};

const EmptyStateWrapper = styled.section`
  display: flex;
  justify-content: left;
`;

const emptyListReasons = ['Re-sync the inventory', 'Update the filter to include vehicles missing images'];

const EmptyState: React.FC<{}> = () => {
  return (
    <EmptyStateWrapper>
      <List
        size="large"
        header={<H3>Looks like there are no cars. Here are some thing you could try:</H3>}
        dataSource={emptyListReasons}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </EmptyStateWrapper>
  );
};

export const VehicleTableView: React.FC<{
  loadingInventory: boolean;
  vehicles: InventoryItem[];
  locationAccountsInfo: GetLocationAccountInfoResponse;
}> = ({ loadingInventory, vehicles: items, locationAccountsInfo }) => {
  const [itemsSelected, setItemsSelected] = useState<string[]>([]);

  const emptyState = loadingInventory ? (
    <LoadingWrapper>
      <Spin size="large" />
    </LoadingWrapper>
  ) : (
    <EmptyState />
  );

  const locale = {
    emptyText: emptyState,
  };

  if (!locationAccountsInfo?.location) return null;

  return (
    <>
      <Table
        expandable={{
          columnWidth: '3%',
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <RightOutlined onClick={(e) => onExpand(record, e)} />
            ),
          expandedRowRender: (record) => showVehicleInfo(record),
        }}
        locale={locale}
        className="wide-table"
        dataSource={items}
        rowKey="id"
      >
        <Column<InventoryItem>
          title="Vehicle"
          width="20%"
          dataIndex="vehicle"
          key="vehicle"
          render={(vehicle, row) => (
            <ItemInfoCard
              item={decodeEntities(vehicle)}
              avatar={row.imgSource || NoImage}
              videoUrl={row.videoUrl}
              imgError={NoImage}
            />
          )}
          {...createSortProps<InventoryItem>({
            type: 'string',
            field: 'vehicle',
            multiple: 1,
          })}
        />
        <Column<InventoryItem> title="VIN" width="10%" dataIndex="vin" key="vin" render={(vin) => <div>{vin}</div>} />
        <Column<InventoryItem>
          title="Price"
          width="10%"
          dataIndex="price"
          key="price"
          render={(price) => (
            <div>
              {price === 0
                ? 'No price yet '
                : price.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
            </div>
          )}
          {...createSortProps<InventoryItem>({
            type: 'number',
            field: 'price',
            multiple: 1,
          })}
        />
        <Column<InventoryItem>
          title="Condition"
          width="10%"
          dataIndex="condition"
          key="condition"
          render={(condition) => (
            <TagLabel color="default">{condition === Condition.New ? 'new' : 'pre-owned'}</TagLabel>
          )}
          {...createSortProps<InventoryItem>({
            type: 'string',
            field: 'condition',
            multiple: 1,
          })}
        />
        <Column<InventoryItem>
          title="Posted To"
          width="10%"
          dataIndex="postMetric"
          key="postMetric"
          render={(postMetric) => <VehicleSocialAccounts postMetrics={postMetric} />}
          {...createSortProps<InventoryItem>({
            type: 'array',
            field: 'postMetric',
            multiple: 1,
          })}
        />
        {hasProduct(locationAccountsInfo.location?.products ?? [], LocationProductEnum.FbMarketplace) && (
          <>
            <Column<InventoryItem>
              title="Marketplace Priority"
              width="10%"
              dataIndex="marketplacePriority"
              key="marketplacePriority"
              render={(marketplacePriority) => (
                <TagLabel color={marketplacePriority ? 'purpleLight' : 'redVariant'}>
                  {marketplacePriority ? 'Yes' : 'No'}
                </TagLabel>
              )}
            />
            <Column<InventoryItem>
              title="Should Post"
              width="10%"
              dataIndex="canListToMarketplace"
              key="canListToMarketplace"
              render={(canListToMarketplace) => (
                <TagLabel color={canListToMarketplace ? 'purpleLight' : 'redVariant'}>
                  {canListToMarketplace ? 'Yes' : 'No'}
                </TagLabel>
              )}
            />
            <Column<InventoryItem>
              title="Eligible for Marketplace"
              width="10%"
              dataIndex="marketplaceIneligibleReasons"
              key="marketplaceIneligibleReasons"
              render={(marketplaceIneligibleReasons) => {
                if (marketplaceIneligibleReasons?.length === 0) {
                  return <TagLabel color="greenLight">Yes</TagLabel>;
                }

                return (
                  <>
                    <TagLabel color="redVariant">No</TagLabel>
                    <Spacer type="stack" size="xxs" />
                    {marketplaceIneligibleReasons.map((reason: string) => (
                      <>
                        <TagLabel color="redVariant">{reason}</TagLabel>
                        <Spacer type="stack" size="xxs" />
                      </>
                    ))}
                  </>
                );
              }}
            />
          </>
        )}
        <Column<InventoryItem>
          title=""
          width="10%"
          dataIndex="id"
          key="id"
          render={(_id, record) => (
            <InventoryActionButtons
              vehicle={record}
              vehiclesSelected={itemsSelected}
              locationAccountsInfo={locationAccountsInfo}
              setItemsSelected={setItemsSelected}
            />
          )}
        />
      </Table>
    </>
  );
};
