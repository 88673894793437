import React from 'react';

import { Route, RouteProps } from 'react-router-dom';

import { useSession } from '../../../context/AppStateProvider';

export function hasAllowedRoles(allowedRoles: string[], roles: string[]) {
  return !allowedRoles.length || allowedRoles.some((role) => roles.includes(role));
}

function hasForbiddenRoles(forbiddenRoles: string[], roles: string[]) {
  return !!forbiddenRoles?.some((role) => roles.includes(role));
}
interface HasPermissionProps {
  allowedRoles?: string[];
  forbiddenRoles?: string[];
}

function useHasPermission(allowedRoles: string[] = [], forbiddenRoles: string[] = []): boolean {
  const { session } = useSession();
  const hasPermission =
    session && hasAllowedRoles(allowedRoles, session.roles) && !hasForbiddenRoles(forbiddenRoles, session.roles);
  return Boolean(hasPermission);
}

export const HasPermission: React.FC<HasPermissionProps> = ({ allowedRoles = [], forbiddenRoles = [], children }) => {
  const hasPermission = useHasPermission(allowedRoles, forbiddenRoles);
  return <>{hasPermission ? children : null}</>;
};

export interface PermissionRouteProps extends RouteProps {
  allowedRoles?: string[];
  forbiddenRoles?: string[];
}
export const RestrictedRoute: React.FC<PermissionRouteProps> = ({ allowedRoles, forbiddenRoles, ...props }) => {
  const hasPermission = useHasPermission(allowedRoles, forbiddenRoles);

  if (hasPermission) {
    return <Route {...props} />;
  } else {
    return <Route {...props} children={null} component={undefined} render={undefined} />;
  }
};
