import { gql } from '@apollo/client';

import { MutationParam } from './';

export interface AcceptInvitationRequest {
  confirmEmailId: string;
  confirmPassword: string;
  username: string;
  password: string;
  emailNotifications: boolean;
  fullName: string;
  phone: string;
  avatarId?: number | null;
}

export interface AcceptInvitationParams extends MutationParam<AcceptInvitationRequest> {}

export interface AcceptInvitationResponse {
  acceptInvitation: {
    id: string;
    uername: string;
  };
}

export const ACCEPT_INVITATION = gql`
  mutation acceptInvitation($data: AcceptInvitationRequest!) {
    acceptInvitation(data: $data) {
      id
      username
    }
  }
`;
