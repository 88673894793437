import { gql, useQuery } from '@apollo/client';

import { LocationProducts } from './locationsByOrgId';

export interface LocationAccountsInfo {
  id: string;
  city: string;
  state: string;
  name: string;
  dealerMessage?: string;
  marketplaceTemplate?: string;
  products: LocationProducts[];
  facebookPage?: {
    id: string;
    name: string;
    hasInstagramPage: boolean;
    avatar: string;
    instagramUsername: string;
    instagramProfileImg: string;
  };
  dealer?: {
    lastSync?: Date;
  };
  googleLocation?: {
    id: string;
    locationName: string;
  };
  organization: {
    name: string;
    id: string;
  };
  customTemplate: {
    googleFooter?: string;
    fbFooter?: string;
    marketplaceFooter?: string;
  };

  tikTokCreator?: {
    id: string;
    name: string;
  };
}

export interface GetLocationAccountInfoResponse {
  location: LocationAccountsInfo;
}

export interface GetLocationAccountInfoParams {
  id: string;
}

export const GET_LOCATION_ACCOUNTS_INFO = gql`
  query ($id: String!) {
    location(id: $id) {
      id
      city
      state
      name
      dealerMessage
      marketplaceTemplate
      dealer {
        lastSync
      }
      products {
        slug
        enabled
      }
      facebookPage {
        pageId
        avatar
        instagramUsername
        instagramProfileImg
        name
        hasInstagramPage
      }
      tikTokCreator {
        id
        name
      }

      customTemplate {
        fbFooter
        googleFooter
        marketplaceFooter
      }

      googleLocation {
        id
        locationName
      }
      organization {
        id
        name
      }
    }
  }
`;
export function useLocationAccountsInfo(id: string) {
  const { data, loading, error } = useQuery<GetLocationAccountInfoResponse, GetLocationAccountInfoParams>(
    GET_LOCATION_ACCOUNTS_INFO,
    {
      variables: {
        id,
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  return {
    locationAccountsInfo: data,
    loading,
    error,
  };
}
