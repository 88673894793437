import { gql } from '@apollo/client';

export interface Posts {
  actionType?: string;
  createTime: Date;
  updateTime: Date;
  mediaImgUrl: string;
  name: string;
  title: string;
  summary: string;
  views: string;
  clicks: string;
  urlLink: string;
}

export interface PostsByGoogleLocationIdResponse {
  getGooglePostByGoogleLocationId: Posts[];
}

export interface PostsByGoogleLocationIdParams {
  locationId: string;
}

export const GOOGLE_LOCATION_POSTS = gql`
  query getGooglePostByGoogleLocationId($locationId: String!) {
    getGooglePostByGoogleLocationId(locationId: $locationId) {
      actionType
      createTime
      updateTime
      name
      urlLink
      mediaImgUrl
      summary
      title
      views
      clicks
    }
  }
`;
