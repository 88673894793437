import { gql, useQuery } from '@apollo/client';

export interface TikTokCreator {
  id: string;
  name: string;
}

export interface TikTokCreatorForAccountResponse {
  tikTokCreator: TikTokCreator[];
}

export const TIKTOK_CREATOR = gql`
  query tikTokCreator {
    tikTokCreator {
      id
      name
    }
  }
`;

export function useTikTokCreatorQuery() {
  const { data, error, loading } = useQuery<TikTokCreatorForAccountResponse>(TIKTOK_CREATOR);
  return {
    tikTokCreators: data?.tikTokCreator || [],
    error,
    loading,
  };
}
