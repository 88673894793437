import { gql } from '@apollo/client';

import { LocationContent } from '../query/getContentByLocationId';

export interface UpsertLocationContentRequest {
  id?: string;
  locationId: string;
  description: string;
  mediaUrls: string;
}

export interface UpsertLocationContentResponse {
  upsertLocationContent: LocationContent;
}

export interface UpsertLocationContentParams {
  data: UpsertLocationContentRequest;
}

export const UPSERT_LOCATION_CONTENT = gql`
  mutation upsertLocationContent($data: UpsertLocationContentRequest!) {
    upsertLocationContent(data: $data) {
      id
      locationId
      archivedAt
      description
      updatedAt
      mediaUrls
    }
  }
`;
