import React, { useCallback } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import styled from 'styled-components';

import { CHANGE_NOTE, ChangeNoteRequest, ChangeNoteResponse } from '../../../graphql/mutations/changeCustomerNote';
import { GET_CUSTOMER_NOTE } from '../../../graphql/query/getCustomerNote';
import { Heading4 } from '../Headings/Headings.styled';
import NotesSection from '../Notes/NotesSection';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 24px 16px 0;
`;

interface CustomerNotesProps {
  organizationId: string;
}

export const CustomerNotes: React.FC<CustomerNotesProps> = ({ organizationId }) => {
  const { data, refetch } = useQuery(GET_CUSTOMER_NOTE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      organizationId: organizationId,
    },
  });
  const [changeNote] = useMutation<ChangeNoteResponse, ChangeNoteRequest>(CHANGE_NOTE);
  const note = data?.getCustomerNote;

  const onSaveNotes = useCallback(
    async (content: string) => {
      changeNote({
        variables: {
          organizationId: organizationId,
          customerNote: content,
        },
      });
      refetch();
    },
    [organizationId]
  );

  return (
    <div>
      <HeaderContainer>
        <Heading4>Notes</Heading4>
      </HeaderContainer>
      <NotesSection style={{ width: '100%' }} initialValue={note} onSave={onSaveNotes} />
    </div>
  );
};
