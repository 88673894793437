import { ILayoutTheme } from 'styled-components';

import mainTheme from './mainTheme';

const { colors } = mainTheme;

const darkTheme: ILayoutTheme = {
  ...mainTheme,
  layoutColors: {
    layout: colors.purpleNavi.plus1,
    interaction: colors.viridianGreen.plus1,
    background: colors.dimGray.minus2,
    altBackground: colors.dimGray.minus1,
    disabled: colors.dimGray.base,
    headings: colors.white,
    bodyText: colors.dimGray.plus3 || colors.dimGray.base,
  },
};

export default darkTheme;
