import { gql, useQuery } from '@apollo/client';
import { PostMetric } from './inventoryByLocationId';

export interface LocationContent {
  id: string;
  locationId: string;
  archivedAt: string;
  description: string;
  mediaUrls: string[];
  updatedAt: string;
  postMetric: PostMetric[];
}

export interface GetLocationContentResponse {
  getContentByLocationId: LocationContent[];
}

export interface GetLocationContentParams {
  locationId: string;
}

export const GET_CONTENT_BY_LOCATION_NAME = 'getContentByLocationId';

export const GET_CONTENT_BY_LOCATION = gql`
  query ${GET_CONTENT_BY_LOCATION_NAME}($locationId: String!) {
    ${GET_CONTENT_BY_LOCATION_NAME}(locationId: $locationId) {
      id
      locationId
      archivedAt
      description
      mediaUrls
      updatedAt
      postMetric {
        source
        date
      }
    }
  }
`;

export function useGetContentByLocationId(params: GetLocationContentParams) {
  const { data, error, loading, refetch } = useQuery<GetLocationContentResponse, GetLocationContentParams>(
    GET_CONTENT_BY_LOCATION,
    {
      variables: params,
      fetchPolicy: 'cache-and-network',
    }
  );

  return {
    refetch,
    locationContent: data?.getContentByLocationId || [],
    error,
    loading,
  };
}
