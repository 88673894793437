import React from 'react';

import { Link, useParams } from 'react-router-dom';

import { SideMenuLayout } from '../../components/Layout/SideMenuLayout';
import { QueueLocationMenu } from '../../components/Menu/QueueLocationMenu';
import { useLocationAccountsInfo } from '../../graphql/query/getLocationAccountsInfoInfo';
import { QueueLocationTable } from './QueueLocationTable/QueueLocationTable';

const QueueLocation: React.FC = () => {
  let { id } = useParams<{ id: string }>();

  const { locationAccountsInfo } = useLocationAccountsInfo(id!);
  return (
    <SideMenuLayout
      title={
        <Link to={'/app/queue'}>
          <i className={`icon icon-small-arrows-arrow-left`} />
        </Link>
      }
      sideColumn={
        <QueueLocationMenu
          locationName={locationAccountsInfo?.location.name!}
          locationId={id}
          organizationId={locationAccountsInfo?.location.organization.id!}
          organizationName={locationAccountsInfo?.location.organization.name!}
        />
      }
      contentColumn={<QueueLocationTable locationId={id} />}
    />
  );
};

export default QueueLocation;
