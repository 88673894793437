import React from 'react';

import { spacing } from '../../StyleGuide/themes/mainTheme';
import { SpacerProps } from './Spacer';

type SquishSpaceSize = 'xs' | 's' | 'm' | 'l' | 'xl';

type StretchSpaceSize = 's' | 'm' | 'l' | 'xl';

interface SquishSpaceProps extends SpacerProps {
  type: 'squish';
  size: SquishSpaceSize;
}

interface StretchSpaceProps extends SpacerProps {
  type: 'stretch';
  size: StretchSpaceSize;
}

export const InsetSpacer: React.FC<SquishSpaceProps | StretchSpaceProps> = (props) => {
  switch (props.type) {
    case 'squish':
      return (
        <div
          style={{
            padding: `${spacing.squish[props.size].y} ${spacing.squish[props.size].x}`,
          }}
        >
          {props.children}
        </div>
      );
    case 'stretch':
      return <div style={{ padding: `${spacing.stretch[props.size]}` }}>{props.children}</div>;
  }
};
