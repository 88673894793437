import { gql } from '@apollo/client';
import { MarketplaceLicense } from '../query/locationsByOrgId';

export interface CreateMarketplaceLicenseRequest {
  locationId: string;
}
export interface CreateMarketplaceLicenseResponse {
  createMarketplaceLicense: MarketplaceLicense;
}

export const CREATE_MARKETPLACE_LICENSE = gql`
  mutation createMarketplaceLicense($locationId: String!) {
    createMarketplaceLicense(locationId: $locationId) {
      id
      token
      user {
        id
        fullName
      }
    }
  }
`;
