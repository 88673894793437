import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import styled from 'styled-components';

import { usePostStateContext } from '../../../context/PostStateProvider';
import {
  ADD_CUSTOM_IMAGE,
  addCustomImageRequest,
  addCustomImageResponse,
} from '../../../graphql/mutations/addCustomImage';
import {
  CREATE_POST,
  CreatePostRequest,
  CreatePostResponse,
  PostButtonOptions,
} from '../../../graphql/mutations/createPost';
import { GetLocationAccountInfoResponse } from '../../../graphql/query/getLocationAccountsInfoInfo';
import { InventoryItem } from '../../../graphql/query/inventoryByLocationId';
import { LocationProductEnum } from '../../../graphql/query/locationsByOrgId';
import { capitalizeFirstLetter } from '../../../utils/formatText.utils';
import { formatItem, generateKeywordsText } from '../../../utils/formatTextPost.utils';
import { hasProduct } from '../../utils/product.utils';
import { Button } from '../Buttons/Button';
import { snackbar } from '../Snackbar';
import { Spacer } from '../Spacer';
import CustomPost from './components/CustomPost';
import DropdownButton from './components/DropdownButton';
import GeneralPost, { SocialAccountType } from './components/GeneralPost';
import PostSummaryColumn from './components/PostSummary';
import { usePostInfo } from './hooks/usePostInfo';

export interface AccountItem {
  id: SocialAccountType;
  name?: string;
  imgUrl?: string;
  enable?: boolean;
}

const CreatePostWrapper = styled.div`
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  padding: 0;
  height: 800px;
`;
const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

const extractAccounts = (info?: GetLocationAccountInfoResponse): AccountItem[] => {
  if (!info) return [];
  const facebook: AccountItem = {
    name: info.location.facebookPage?.name || '',
    id: 'facebook',
    enable: !!info.location.facebookPage?.name && hasProduct(info.location.products, LocationProductEnum.Facebook),
    imgUrl: info.location.facebookPage?.avatar,
  };
  const instagram: AccountItem = {
    name: info.location.facebookPage?.instagramUsername || '',
    enable:
      !!info.location.facebookPage?.instagramUsername &&
      hasProduct(info.location.products, LocationProductEnum.Instagram),
    id: 'instagram',
    imgUrl: info.location.facebookPage?.instagramProfileImg || '',
  };
  const google: AccountItem = {
    name: info.location.googleLocation?.locationName || '',
    enable:
      !!info.location.googleLocation?.locationName && hasProduct(info.location.products, LocationProductEnum.Google),
    id: 'google',
    imgUrl: '',
  };

  const accounts = [google, facebook, instagram].filter((account) => account.enable);
  return accounts;
};
const extractCustomTemplates = (
  info?: GetLocationAccountInfoResponse
): { fbFooter?: string; googleFooter?: string } => {
  const fbTemplate = info?.location?.customTemplate?.fbFooter ?? '';
  const googleTemplate = info?.location?.customTemplate?.googleFooter ?? '';
  const fbFooter = fbTemplate ? `\n\n${fbTemplate}` : '';
  const googleFooter = googleTemplate ? `\n\n${googleTemplate}` : '';
  return { fbFooter, googleFooter };
};

export enum FacebookSource {
  LINK = 'link',
  VIDEO = 'video',
}

export enum InstagramSource {
  IMAGE = 'image',
  VIDEO = 'video',
}

interface CreatePostModalProps {
  locationAccountsInfo: GetLocationAccountInfoResponse;
  setShowModal: (show: boolean) => void;
  item: InventoryItem;
}

export const CreatePostModal: React.FC<CreatePostModalProps> = ({ item, locationAccountsInfo, setShowModal }) => {
  const postState = usePostStateContext();
  const postInfo = usePostInfo();
  const accounts = extractAccounts(locationAccountsInfo);
  const { fbFooter, googleFooter } = extractCustomTemplates(locationAccountsInfo);
  const description = formatItem(item);
  const hashtags = generateKeywordsText({
    location: locationAccountsInfo.location,
    item,
    keywords: postState?.keywords,
  });

  const [defaultText, setDefaultText] = useState<string>(description);
  const [selectedAccounts, setSelectedAccounts] = React.useState<string[]>(accounts.map((account) => account.id));
  const [imagesCarrousel, setImagesCarrousel] = React.useState<string[]>(item.images!);

  const [facebookSource, setFacebookSource] = React.useState<string>(FacebookSource.LINK);
  const [instagramSource, setInstagramSource] = React.useState<string>(InstagramSource.IMAGE);

  const [createPost] = useMutation<CreatePostResponse>(CREATE_POST);
  const [currentAccount, setCurrentAccount] = useState<SocialAccountType>('');

  const [addNewImages] = useMutation<addCustomImageResponse, addCustomImageRequest>(ADD_CUSTOM_IMAGE);
  const [disabled, setDisabled] = React.useState<boolean>(!selectedAccounts.length);

  const [action, setAction] = useState<PostButtonOptions>(PostButtonOptions.ADD_TO_QUEUE);

  useEffect(() => {
    const hasImage = postInfo.imageSelected;
    const hasAccounts = selectedAccounts.length;
    const validInput = hasImage && hasAccounts;
    setDisabled(!validInput);
  }, [selectedAccounts, postInfo.imageSelected]);

  useEffect(() => {
    if (postInfo.imagePath) {
      addNewImages({
        variables: { inventoryId: item.id, imagePath: postInfo.imagePath },
      });
      setImagesCarrousel((prev) => [postInfo.imagePath, ...prev]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postInfo.imagePath]);

  useEffect(() => {
    setDefaultText(description);
    setCurrentAccount('');
  }, [description]);

  useEffect(() => {
    postInfo.setVdpUrl(item.vdp_url);
    postInfo.setImageSelected([]);
    setFacebookSource(FacebookSource.LINK);
    setInstagramSource(InstagramSource.IMAGE);
    setImagesCarrousel(item.images);
    setAction(PostButtonOptions.ADD_TO_QUEUE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    postInfo.setFbSummary(`${defaultText}${fbFooter}${hashtags}`);
    postInfo.setInstagramSummary(`${defaultText}${fbFooter}${hashtags}`);
    postInfo.setGoogleSummary(`${defaultText}${googleFooter}`);
    setImagesCarrousel(item.images);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !postInfo.isFbEdited && postInfo.setFbSummary(`${defaultText}${fbFooter}${hashtags}`);
    !postInfo.isInstagramEdited && postInfo.setInstagramSummary(`${defaultText}${fbFooter}${hashtags}`);
    !postInfo.isGoogleEdited && postInfo.setGoogleSummary(`${defaultText}${googleFooter}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultText]);

  const handleCreatePost = async () => {
    try {
      setDisabled(true);
      const createPostRequest: CreatePostRequest = {
        fbSummary: postInfo.fbSummary,
        fbActionButton: postInfo.fbActionButton,
        fbVideoUrl: facebookSource === FacebookSource.VIDEO ? item.videoUrl! : '',

        googleSummary: postInfo.googleSummary,
        googleActionButton: postInfo.googleActionButton,
        googleImage: postInfo.imageSelected[0] ?? '',

        instagramSummary: postInfo.instagramSummary,
        instagramImage: postInfo.imageSelected[0] ?? '',
        instagramVideoUrl: instagramSource === InstagramSource.VIDEO ? item.videoUrl! : '',

        locationId: postState?.locationId!,
        vdpUrl: postInfo.vdpUrl,
        inventoryId: item!.id,
        vin: item!.vin,
        accounts: selectedAccounts.join(','),
        action: action,
      };
      await snackbar.info({
        message: `Generating post ...`,
      });
      const sendPost = await createPost({
        variables: { data: createPostRequest },
      });
      const responses = sendPost.data?.createPost ?? [];
      for (const response of responses) {
        const message = `${capitalizeFirstLetter(response.account)}: ${response.response}`;
        if (response.failed) await snackbar.error({ message });
        else await snackbar.success({ message });
      }
      setShowModal(false);
      setDisabled(false);
    } catch (error) {
      Sentry.captureException(error);
      const err = error as Error;
      snackbar.error({
        message: err?.message,
      });
      setDisabled(false);
    }
  };

  return (
    <>
      <CreatePostWrapper>
        <GeneralPost
          vin={item.vin}
          accounts={accounts}
          defaultText={description}
          setDefaultText={setDefaultText}
          selectedAccounts={selectedAccounts}
          setSelectedAccounts={setSelectedAccounts}
          images={imagesCarrousel}
          setImageSelected={postInfo.setImageSelected}
          imageSelected={postInfo.imageSelected}
          setImagePath={postInfo.setImagePath}
        />
        <CustomPost
          keywords={postState!.keywords || []}
          currentAccount={currentAccount}
          setCurrentAccount={setCurrentAccount}
          accounts={accounts}
          hashtags={hashtags}
          item={item!}
          postInfo={postInfo}
          defaultText={defaultText}
          images={imagesCarrousel}
          selectedAccounts={selectedAccounts}
          setFacebookSource={setFacebookSource}
          setInstagramSource={setInstagramSource}
          facebookSource={facebookSource}
          instagramSource={instagramSource}
        />
        <PostSummaryColumn
          item={item}
          setVdpUrl={postInfo.setVdpUrl}
          vdp={postInfo.vdpUrl}
          videoUrl={item.videoUrl}
          mediaFacebook={facebookSource}
          mediaInstagram={instagramSource}
          source={currentAccount}
        />
      </CreatePostWrapper>
      <StyledButtonsContainer>
        <DropdownButton
          buttons={Object.values(PostButtonOptions) as unknown as PostButtonOptions[]}
          setSelectedButton={setAction}
        />
        <Spacer type="inline" size="xs" />
        <Button disabled={disabled} id="createPostButton" btnType="primary" onClick={handleCreatePost}>
          {action.replaceAll('_', ' ')}
        </Button>
      </StyledButtonsContainer>
    </>
  );
};
