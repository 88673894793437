import React, { useState, useEffect } from 'react';
import { Alert, Button, Tooltip, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const ExtensionBanner = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const afterDec6 = dayjs().isAfter('2024-12-07');
    const dismissed = localStorage.getItem('bannerDismissed');

    if (dismissed === 'true') {
      return;
    }

    if (afterDec6) {
      return;
    }

    // Show the banner for the first time or within 14 days
    setVisible(true);
  }, []);

  const handleDismiss = () => {
    setVisible(false);
    localStorage.setItem('bannerDismissed', 'true'); // Store dismissal in localStorage
  };

  const copyToClipboard = () => {
    const messageText = `
To make sure the LocalShift extension is active and working properly, please follow these steps:
1. Check that the LocalShift extension is still visible in your toolbar, or under the list of your extensions. Ensure that the extension is active.
2. If the extension is not active, or if you see a notice that it is disabled, please click the option to re-enable.
3. If you don't see a notice or option to re-enable the extension, please click this link: https://chromewebstore.google.com/detail/localshift/jboaoddjmknbcbdiomphdecaebmfcfli, and add the extension again.
    `;
    navigator.clipboard
      .writeText(messageText)
      .then(() => message.success('Instructions copied to clipboard!'))
      .catch(() => message.error('Failed to copy instructions'));
  };

  if (!visible) return null;

  return (
    <div style={{ width: '100%', padding: '5px' }}>
      <Alert
        message={
          <div style={{ textAlign: 'left', fontSize: '12px' }}>
            <strong>
              We're excited to announce we’ve added the option to list your inventory to approved Facebook groups!
            </strong>
            <p>
              This update requires extra permissions for the LocalShift Chrome extension which may have disabled the
              extension. If your users aren’t posting, try asking them to re-enable the extension. You can email them
              these instructions:
            </p>
            <div style={{ backgroundColor: '#f9f9f9', padding: '5px', marginBottom: '5px', fontSize: '11px' }}>
              <p>
                <strong>Instructions:</strong>
              </p>
              <pre style={{ backgroundColor: '#f9f9f9', fontSize: '11px', margin: '0' }}>
                <code>
                  {`
1. Check that the LocalShift extension is visible in your toolbar and active.
2. If it’s disabled, click to re-enable it.
3. If you don’t see that option, click this link: https://chromewebstore.google.com/detail/localshift/jboaoddjmknbcbdiomphdecaebmfcfli and add the extension again.
                `}
                </code>
              </pre>
              <Tooltip title="Copy instructions to clipboard">
                <Button
                  icon={<CopyOutlined />}
                  onClick={copyToClipboard}
                  type="primary"
                  shape="circle"
                  size="small"
                  style={{ marginTop: '5px' }}
                />
              </Tooltip>
            </div>
            <p>If you need help, please reach out.</p>
            <p style={{ marginBottom: 0 }}>
              <strong>Best regards,</strong>
              <br />
              LocalShift Support
            </p>
          </div>
        }
        type="info"
        closable
        onClose={handleDismiss}
        closeText={
          <Button type="primary" size="small" style={{ fontSize: '12px' }}>
            Dismiss
          </Button>
        }
        style={{
          width: '100%',
          fontSize: '12px',
          marginBottom: '5px',
        }}
      />
    </div>
  );
};

export default ExtensionBanner;
