import React from 'react';

import styled from 'styled-components';

import { displayRange } from '../../../utils/formatText.utils';
import { LabelBold, LabelR } from '../Text/Text.styled';

const ItemInfoCardWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const TextContainer = styled.div`
  justify-content: center;
  margin-left: 8px;
`;

const LabelBoldStyled = styled(LabelBold)`
  color: ${(props) => props.theme.colors.dimGray.minus2};
`;

const DISPLAY_PROPS = `display: block;
  object-fit: cover;
  width: 72px;
  height: 48px;
  left: 24px;
  top: calc(50% - 48px / 2);
  border-radius: 4px;`;

const ImgAvatar = styled.img`
  ${DISPLAY_PROPS}
`;

const VideoAvatar = styled.video`
  ${DISPLAY_PROPS}
`;

interface ItemInfoCardProps {
  item: string;
  avatar?: string;
  videoUrl?: string;
  imgError?: string;
  subtitle?: string;
}

const ItemInfoCard: React.FC<ItemInfoCardProps> = ({ avatar, item, imgError, videoUrl, subtitle }) => {
  const handleError = (e: React.ChangeEvent<HTMLImageElement>) => {
    e.target.src = imgError || '';
  };

  let DisplayElement = <ImgAvatar src={avatar} onError={handleError} />;
  if (videoUrl) {
    DisplayElement = <VideoAvatar src={videoUrl} />;
  }

  return (
    <ItemInfoCardWrapper>
      {DisplayElement}
      <TextContainer>
        <LabelBoldStyled>{displayRange(40, item)}</LabelBoldStyled>
        {subtitle && <LabelR>{subtitle}</LabelR>}
      </TextContainer>
    </ItemInfoCardWrapper>
  );
};
export default ItemInfoCard;
