import { gql, useQuery } from '@apollo/client';

import { IPaginatedResponse } from './paginated-query';

export interface LocationWithQueue {
  locationId: string;
  locationName: string;
  organizationName: string;
  queueItemsCount: number;
}
export interface GetLocationsWithQueueResponse {
  getLocationsWithQueue: IPaginatedResponse<LocationWithQueue>;
}

export interface GetLocationsWithQueueParams {
  name?: string;
  filter?: boolean;
  take?: number;
  after?: string;
}

export const GET_LOCATIONS_WITH_QUEUE = gql`
  query getLocationsWithQueue($name: String, $filter: Boolean!, $take: Int, $after: String) {
    getLocationsWithQueue(name: $name, filter: $filter, take: $take, after: $after) {
      nodes {
        locationId
        locationName
        organizationName
        queueItemsCount
      }
      hasNextPage
      totalCount
      endCursor
    }
  }
`;

export function useGetLocationWithQueue(params: GetLocationsWithQueueParams) {
  const { data, error, loading, refetch } = useQuery<GetLocationsWithQueueResponse, GetLocationsWithQueueParams>(
    GET_LOCATIONS_WITH_QUEUE,
    {
      variables: params,
      fetchPolicy: 'cache-and-network',
    }
  );

  return {
    refetch,
    queue: data?.getLocationsWithQueue,
    error,
    loading,
  };
}
