import React from 'react';

import styled from 'styled-components';

import { Heading4 } from '../../components/ui/Headings/Headings.styled';
import { Spacer } from '../../components/ui/Spacer';
import { LabelBodyR } from '../../components/ui/Text/Text.styled';
import { ONBOARDING_STEPS, useStepCtx } from './OnboardingStepProvider';

const WhiteLogo = styled.div`
  width: 250px;
  height: 48px;
  position: absolute;
  left: 40px;
  top: 40px;
  background-image: url('/logo/full-logo-white.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const LeftWrapper = styled.section`
  height: 100%;
  width: 100%;
  padding: 32px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

const LeftContainer = styled.div`
  width: 328px;
  height: 248px;
  display: flex;
  flex-direction: column;
`;

const StepContainer = styled.div<{ active?: boolean }>`
  display: flex;
  .step-number,
  .step-label {
    opacity: ${(props) => (props.active ? '1' : '0.5')};
  }
`;

const StepNumber = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.purpleNavi.base};
`;

interface StepItemProps {
  number: number;
  label: string;
  active?: boolean;
}

export const StepItem: React.FC<StepItemProps> = (props) => (
  <StepContainer active={props.active}>
    <StepNumber className="step-number">{props.number}</StepNumber>
    <Spacer type="inline" size="xs" />
    <Heading4 white className="step-label">
      {props.label}
    </Heading4>
  </StepContainer>
);

export const LeftContent = () => {
  const { activeStep } = useStepCtx();

  return (
    <>
      <WhiteLogo />
      <LeftWrapper>
        <LeftContainer>
          <div className="step-list">
            {ONBOARDING_STEPS.map((step) => (
              <React.Fragment key={step.order}>
                <StepItem number={step.order + 1} label={step.label} active={step.order === activeStep.order} />
                <Spacer type="stack" size="m" />
              </React.Fragment>
            ))}
          </div>
          <LabelBodyR white>
            Some of the data has already been pre-filled by your account creator, just fill in the missing data or
            change the current one if you want.
          </LabelBodyR>
        </LeftContainer>
      </LeftWrapper>
    </>
  );
};
