import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import LoginLayout from '../../components/Layout/LoginLayout';
import { Button as ButtonStyle } from '../../components/ui/Buttons/Button';
import LeftLoginContent from '../../components/ui/LeftLoginContent';

export const MainWrapper = styled.section`
  display: flex;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  align-items: center;
`;

export const LogoColor = styled.div`
  width: 48px;
  height: 48px;
  display: block;
  background-image: url('/logo/full-logo-color-icon.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Button = styled(ButtonStyle)`
  width: 100%;
`;

const MainContent: React.FC = () => {
  const history = useHistory();
  const params = new URLSearchParams(useLocation().search);
  const confirmEmailId = params.get('code')!;

  if (!confirmEmailId) {
    history.push('/login');
  } else {
    history.push('/onboarding/email', {
      code: confirmEmailId,
    });
  }
  return null;
};

export const Onboarding = () => {
  return (
    <LoginLayout
      leftContent={
        <LeftLoginContent
          children={'You are a few clicks away from activating your account. Choose how you want to sign up.'}
        />
      }
      mainContent={<MainContent />}
    ></LoginLayout>
  );
};

export const GoogleOnboarding: React.FC = () => {
  const params = new URLSearchParams(useLocation().search);
  const confirmEmailId = params.get('confirmEmail')!;
  window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/google/onboarding?confirmEmailId=${confirmEmailId}`;
  return null;
};
