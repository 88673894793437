import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const widgetScriptPresent = () => {
  return !!getWidgetScript();
};

const getWidgetScript = () => document.getElementById('podium-widget');
const getWidget = () => document.getElementById('podium-website-widget');

const removeWidget = () => {
  const widgetScript = getWidgetScript();
  if (widgetScript) {
    document.body.removeChild(widgetScript);
  }

  const widget = getWidget();
  if (widget) {
    document.body.removeChild(widget);
  }
};

export const usePodiumWebchat = () => {
  const location = useLocation();

  useEffect(() => {
    const showWidget = location.pathname.includes('/dashboard') || location.pathname.includes('/help');

    if (showWidget) {
      if (!widgetScriptPresent()) {
        const script = document.createElement('script');
        script.id = 'podium-widget';
        script.src = 'https://connect.podium.com/widget.js#ORG_TOKEN=f4c7ffab-0999-475b-9dde-5b54f8575e52';
        script.defer = true;
        script.setAttribute('data-organization-api-token', 'f4c7ffab-0999-475b-9dde-5b54f8575e52');

        document.body.appendChild(script);
      }
    } else {
      removeWidget();
    }

    return () => {
      // Cleanup: Optionally remove the script if navigating away from the specified pages
      if (!showWidget) {
        removeWidget();
      }
    };
  }, [location.pathname]);
};
