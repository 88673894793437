import { gql } from '@apollo/client';

export interface ResendInvitationRequest {
  userId: string;
}

export const RESEND_INVITATION_TO_USER = gql`
  mutation resendInvitation($userId: String!) {
    resendInvitation(userId: $userId)
  }
`;
