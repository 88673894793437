import { gql, useQuery } from '@apollo/client';

import { DashboardBaseInput } from './getTopPosts';

interface PostAggregateRequest extends DashboardBaseInput {}

export type PostMetricLabel = 'aggViewsPerPost' | 'aggClickPerPost' | 'aggMessagePerPost' | 'aggCallPerPost';
export interface Metric {
  label: PostMetricLabel;
  value: number;
}
export interface GetPostAggregatesResponse {
  getPostAggregates: {
    totalPosts: number;
    metrics: Metric[];
  };
}
export interface GetPostAggregatesParams {
  getPostAggregateRequest: PostAggregateRequest;
}

export const GET_POST_AGGREGATES = gql`
  query getPostAggregates($getPostAggregateRequest: PostAggregateRequest!) {
    getPostAggregates(input: $getPostAggregateRequest) {
      totalPosts
      metrics {
        label
        value
      }
    }
  }
`;

export function useGetPostAggregates(params: GetPostAggregatesParams) {
  const { data, error, loading } = useQuery<GetPostAggregatesResponse, GetPostAggregatesParams>(GET_POST_AGGREGATES, {
    variables: params,
    fetchPolicy: 'cache-and-network',
  });
  return {
    postsAgg: data?.getPostAggregates || null,
    error,
    loading,
  };
}
