import { gql } from '@apollo/client';

import { TikTokCreator } from './tikTokCreator';

export interface TikTokCreatorByOrgIdResponse {
  tikTokCreatorByOrgId: TikTokCreator[];
}

export interface TikByOrgIdParams {
  organizationId: string;
}

export const TIKTOK_CREATOR_BY_ORG_ID = gql`
  query tikTokCreatorByOrgId($organizationId: String!) {
    tikTokCreatorByOrgId(organizationId: $organizationId) {
      id
      name
    }
  }
`;
