import React from 'react';

import styled from 'styled-components';

import { LabelBodyB } from '../../ui/Text/Text.styled';

const ReviewsPageWrapper = styled.div`
  display: flex;
  max-height: 100vh;
`;

const FilterColumn = styled.div`
  padding: 40px;
  width: 280px;
  flex-shrink: 0;
  text-align: left;
`;
const ReviewsColumn = styled.div`
  padding: 32px 24px;
  flex-grow: 1;
  overflow-y: auto;
`;

const ReviewsPageHeader = styled.div`
  height: 48px;
  border-bottom: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: space-between;
`;

interface ReviewLayoutProps {
  pageName?: string;
  filterColumn: React.ReactNode;
  reviewColumn: React.ReactNode;
}

export const ReviewLayout: React.FC<ReviewLayoutProps> = ({ pageName, filterColumn, reviewColumn }) => {
  return (
    <>
      <ReviewsPageHeader>
        <LabelBodyB>{pageName}</LabelBodyB>
      </ReviewsPageHeader>
      <ReviewsPageWrapper>
        <FilterColumn>{filterColumn}</FilterColumn>
        <ReviewsColumn>{reviewColumn}</ReviewsColumn>
      </ReviewsPageWrapper>
    </>
  );
};
