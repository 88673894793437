import React from 'react';
import { DashboardFilter } from '../../../../pages/Dashboard/hooks/useFilters';
import { CardStyled } from './Components.styles';
import { Spin, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { LabelBodyB } from '../../Text/Text.styled';
import styled from 'styled-components';
import {
  LeadRevivalChannel,
  LeadRevivalMetrics,
  useGetLeadRevivalMetrics,
} from '../../../../graphql/query/getLeadRevivalMetrics';
import { Spacer } from '../../Spacer';

interface LeadRevivalMetricsProps {
  dashboardFilters: DashboardFilter;
}

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}
`;

export const LeadRevivalReport: React.FC<LeadRevivalMetricsProps> = ({ dashboardFilters }) => {
  const { leadRevivalMetrics: emailMetrics, loading: loadingEmails } = useGetLeadRevivalMetrics({
    leadRevivalMetricsRequest: {
      organizationIds: dashboardFilters.organizationIds,
      startDate: dashboardFilters.dateRange.startDate,
      endDate: dashboardFilters.dateRange.endDate,
      locationIds: dashboardFilters.locationIds,
      channel: LeadRevivalChannel.Email,
    },
  });

  const { leadRevivalMetrics: textMetrics, loading: loadingTexts } = useGetLeadRevivalMetrics({
    leadRevivalMetricsRequest: {
      organizationIds: dashboardFilters.organizationIds,
      startDate: dashboardFilters.dateRange.startDate,
      endDate: dashboardFilters.dateRange.endDate,
      locationIds: dashboardFilters.locationIds,
      channel: LeadRevivalChannel.Text,
    },
  });

  if (loadingEmails || loadingTexts) {
    return (
      <LoadingWrapper>
        <Spin size="large" />
      </LoadingWrapper>
    );
  }

  return (
    <>
      {emailMetrics?.length > 0 && (
        <CardStyled>
          <LabelBodyB>Lead Revival Email</LabelBodyB>
          <Table pagination={false} scroll={{ y: 500 }} className="wide-table" dataSource={emailMetrics} rowKey="name">
            <Column title="Campaign" dataIndex="campaign" key="campaign" />
            <Column
              title="Sent"
              dataIndex="sent"
              key="sent"
              sortOrder={'descend'}
              sorter={(a: LeadRevivalMetrics, b: LeadRevivalMetrics) => a.sent - b.sent}
              render={(_name, record: LeadRevivalMetrics) => {
                return record.sent || '-';
              }}
            />
            <Column
              title="Leads"
              dataIndex="leads"
              key="leads"
              render={(_name, record: LeadRevivalMetrics) => {
                return record.leads || '-';
              }}
            />
            <Column
              title="Sales"
              dataIndex="sales"
              key="sales"
              render={(_name, record: LeadRevivalMetrics) => {
                return record.sales || '-';
              }}
            />
            <Column
              title="Revenue"
              dataIndex="revenue"
              key="revenue"
              render={(_name, record: LeadRevivalMetrics) => {
                if (!record.revenue) return '-';

                return record.revenue.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                });
              }}
            />
            <Column
              title="Opens"
              dataIndex="opens"
              key="opens"
              render={(_name, record: LeadRevivalMetrics) => {
                return record.opens || '-';
              }}
            />
            <Column
              title="Clicks"
              dataIndex="clicks"
              key="clicks"
              render={(_name, record: LeadRevivalMetrics) => {
                return record.clicks || '-';
              }}
            />
          </Table>
        </CardStyled>
      )}
      {textMetrics?.length > 0 && (
        <>
          <Spacer type={'stack'} size={'m'} />
          <CardStyled>
            <LabelBodyB>Lead Revival Text</LabelBodyB>
            <Table pagination={false} scroll={{ y: 500 }} className="wide-table" dataSource={textMetrics} rowKey="name">
              <Column title="Campaign" dataIndex="campaign" key="campaign" />
              <Column
                title="Sent"
                dataIndex="sent"
                key="sent"
                sortOrder={'descend'}
                sorter={(a: LeadRevivalMetrics, b: LeadRevivalMetrics) => a.sent - b.sent}
                render={(_name, record: LeadRevivalMetrics) => {
                  return record.sent || '-';
                }}
              />
              <Column
                title="Leads"
                dataIndex="leads"
                key="leads"
                render={(_name, record: LeadRevivalMetrics) => {
                  return record.leads || '-';
                }}
              />
              <Column
                title="Sales"
                dataIndex="sales"
                key="sales"
                render={(_name, record: LeadRevivalMetrics) => {
                  return record.sales || '-';
                }}
              />
              <Column
                title="Revenue"
                dataIndex="revenue"
                key="revenue"
                render={(_name, record: LeadRevivalMetrics) => {
                  if (!record.revenue) return '-';

                  return record.revenue.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  });
                }}
              />

              <Column
                title="Clicks"
                dataIndex="clicks"
                key="clicks"
                render={(_name, record: LeadRevivalMetrics) => {
                  return record.clicks || '-';
                }}
              />
            </Table>
          </CardStyled>
        </>
      )}
    </>
  );
};
