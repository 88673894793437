import { gql, useQuery } from '@apollo/client';

import { KeywordsTypes } from '../../components/ui/CreatePostModal/helpers';

export interface KeywordsByLocationIdParams {
  locationId: string;
}

export interface KeywordsByLocationIdResponse {
  keywordsByLocationId: KeywordsTypes[];
}

export const KEYWORDS_BY_LOCATION_ID = gql`
  query keywordsByLocationId($locationId: String) {
    keywordsByLocationId(locationId: $locationId) {
      keyword
    }
  }
`;

export function useKeywordsByLocationId(params: KeywordsByLocationIdParams) {
  const { data, loading, error } = useQuery<KeywordsByLocationIdResponse, KeywordsByLocationIdParams>(
    KEYWORDS_BY_LOCATION_ID,
    {
      variables: params,
    }
  );

  return {
    keywords: data?.keywordsByLocationId,
    loading,
    error,
  };
}
