import React from 'react';

import { Tabs } from 'antd';

import styled from 'styled-components';

import { Button } from '../Buttons/Button';
import UploadButton from '../UploadButton';

const { TabPane } = Tabs;

const TabContainer = styled(TabPane)`
  flex: none;
  width: 100%;
  display: flex;
  justify-content: space-around;
`;
const TabOptionContainer = styled.div`
  .ant-tabs-ink-bar {
    background: ${(props) => props.theme.colors.purpleNavi.plus1};
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.purpleNavi.base};
  }
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
`;

interface SelectImageSourceProps {
  setImage: (imageUrl: string) => void;
  image: string;
}

const SelectImageSource: React.FC<SelectImageSourceProps> = ({ setImage, image }) => {
  return (
    <TabOptionContainer>
      <Tabs defaultActiveKey="2">
        <TabContainer tab="Upload" key="2">
          {image ? (
            <ImageSection>
              <img
                style={{ width: '542px', height: '352px', objectFit: 'cover', alignContent: 'center' }}
                src={image}
                alt="service"
              />
              <Button
                btnType="text"
                size="small"
                onClick={() => {
                  setImage('');
                }}
              >
                Remove previous Image
              </Button>
            </ImageSection>
          ) : (
            <UploadButton
              size="xLarge"
              onChange={() => {}}
              setExternalImage={setImage}
              radious={'0'}
              customSize={{ width: '542px', height: '352px' }}
              showLabel
            />
          )}
        </TabContainer>
        {/* Image tab disabled for now, implemented in the future */}
        <TabContainer tab="Images" key="1" disabled>
          Images showed in the future
        </TabContainer>
      </Tabs>
    </TabOptionContainer>
  );
};

export default SelectImageSource;
