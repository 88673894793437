import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

Sentry.init({
  dsn: 'https://4d4b1710871643bd8f06e92201d54e6a@o1229461.ingest.sentry.io/6229075',
  integrations: [new BrowserTracing()],
  tracesSampleRate: parseInt(process.env.REACT_APP_TRACE_SAMPLE_RATE ?? '0.25'),
  environment: process.env.NODE_ENV,
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <App />
      </QueryParamProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
