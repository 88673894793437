import { RadioGroupOption } from '../ui/RadioGroup/RadioGroup';

export enum ToggleBoxOptions {
  Yes = 1,
  No = 0,
}

export const toggleBoxOptions: RadioGroupOption[] = [
  {
    value: ToggleBoxOptions.Yes,
    label: 'Yes',
  },
  {
    value: ToggleBoxOptions.No,
    label: 'No',
  },
];

export function hasElement<EnumType>(enumList: EnumType[], element: EnumType): boolean {
  return enumList.includes(element) ?? false;
}
