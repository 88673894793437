export const calculatePrevEnd = (start: number) => {
  const prev = start;
  if (prev === 0) return undefined;
  return prev;
};

export const calculatePrevStart = (start: number, sizeArray: number, numberOfElements: number) => {
  const prev = start - numberOfElements;
  if (prev < 0) return Math.floor(sizeArray / numberOfElements) * numberOfElements;
  return prev;
};

export const calculateNextEnd = (end: number | undefined, sizeArray: number, numberOfElements: number) => {
  if (!end) return numberOfElements;
  const next = end + numberOfElements;
  if (next > sizeArray) return undefined;
  return next;
};

export const calculateNextStart = (end: number | undefined) => {
  const next = end;
  if (!next) return 0;
  return next;
};
